import { format, parseISO } from 'date-fns'
import React from 'react'

export function DriverCheckInStatus({ createdAt }: { createdAt: string }) {
  const formattedDate = format(parseISO(createdAt), "dd/MM  'às' HH:mm")

  return (
    <div className='driver-check-in-status'>
      <div>Aguarde ser chamado</div>

      <div>
        <p>Check-in realizado em {formattedDate}</p>
      </div>
    </div>
  )
}
