import React, { createContext, useEffect, useState } from 'react'
import {
  SettingsCompanyApi,
  driverCheckInServices,
  useGetSettings
} from '../../domain'
import { DriverCheckIn } from './driverContextTypes'
import { useIPNetwork } from '../../hooks/useIPNetwork'

interface SettingsCompany extends SettingsCompanyApi {}

interface DriverContextData {
  isDriverIPAuthorized: boolean | null
  settingsCompany: SettingsCompany | null
  driverCheckIn: DriverCheckIn | null
  driverId: string | null
  driverCompanyId: string | null
  driverCompanyName: string | undefined
  driverFirstName: string | undefined
  isLoading: boolean
  closeLoading: () => void
  fetchCheckInActive: () => Promise<void>
  updateCheckin: (checkin: DriverCheckIn | null) => void
}

export const DriverContext = createContext({} as DriverContextData)

export function DriverProvider({ children }: { children: React.ReactNode }) {
  const driverCompanyId = sessionStorage.getItem('@AppDriver:companyId')
  const driverId = sessionStorage.getItem('@AppDriver:id')

  const driverIPNetwork = useIPNetwork()

  const [isDriverIPAuthorized, setIsDriverIPAuthorized] = useState<
    boolean | null
  >(null)

  const { settings: settingsCompany } = useGetSettings(driverCompanyId!)

  const [isLoading, setIsLoading] = useState(true)

  const [driverCheckIn, setDriverCheckIn] = useState<DriverCheckIn | null>(null)

  const driverName = sessionStorage
    .getItem('@AppDriver:driver')
    ?.replace(/"/g, '')

  const driverCompanyName = sessionStorage
    .getItem('@AppDriver:companyName')
    ?.replace(/"/g, '')

  const driverFirstName = driverName?.split(' ')[0]

  async function fetchCheckInActive() {
    if (!driverCompanyId) return

    const checkInsActives = await driverCheckInServices.getCheckInActives(
      driverCompanyId
    )

    const driverCheckIn = checkInsActives.find(
      item => item?.queue?.driver?.id.toString() === driverId
    )

    if (!driverCheckIn) return

    setDriverCheckIn({
      id: driverCheckIn.id,
      driverQueueId: driverCheckIn.queue.id,
      driverCheckInActive: driverCheckIn.isPresent,
      checkInCreatedAt: driverCheckIn.updatedAt
    })
  }

  function updateCheckin(checkin: DriverCheckIn | null) {
    setDriverCheckIn(checkin)
  }

  function closeLoading() {
    setIsLoading(false)
  }

  useEffect(() => {
    if (settingsCompany) closeLoading()
  }, [settingsCompany])

  useEffect(() => {
    fetchCheckInActive()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverCompanyId])

  useEffect(() => {
    if (settingsCompany) {
      const isIPAuthorized = settingsCompany.checkinIp
        .replace(' ', '')
        .split(',')
        .includes(driverIPNetwork)

      if (isIPAuthorized) {
        setIsDriverIPAuthorized(isIPAuthorized)
      } else {
        setIsDriverIPAuthorized(false)
      }
    }
  }, [driverIPNetwork, settingsCompany])

  return (
    <DriverContext.Provider
      value={{
        isLoading,
        closeLoading,
        isDriverIPAuthorized,
        driverCheckIn,
        settingsCompany,
        driverId,
        driverCompanyId,
        driverCompanyName,
        driverFirstName,
        fetchCheckInActive,
        updateCheckin
      }}
    >
      {children}
    </DriverContext.Provider>
  )
}
