import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import Draft, { convertFromHTML } from 'draft-js'
import './styles.css'
import { useHistory, useParams } from 'react-router-dom'

import api from '../../../services/api'
import { useFormik } from 'formik'
import './styles.css'
import { toast } from 'react-toastify'
import { Checkbox } from '@material-ui/core'
import { useAuth } from '../../../contexts/auth'
import Input from '../../../components/Input'

interface RouteParams {
  companyId: string
  driverId: string
}

interface Term {
  active: boolean
  content: string
  createdAt: string
  id: number
  updatedAt: string
  title: string
}

interface Contact {
  id: number
  name: string
  fone: string
  email: string
  whatsapp: boolean
}

interface Driver {
  name: string
  cpf: string
  cnh: string
  birthDate: string
  contact?: Contact[]
}

const NewEditLgpd: React.FC = (): JSX.Element => {
  const EditorState = Draft.EditorState
  const ContentState = Draft.ContentState
  const driverStorageId = sessionStorage.getItem('@App:temporaryDriverId')

  const [isLoading, setIsLoading] = useState(true)
  const [term, setTerm] = useState<Term>({
    title: '',
    active: false,
    content: '',
    createdAt: '',
    id: 0,
    updatedAt: ''
  })

  const { Logout, driverLogout } = useAuth()
  const { companyId, driverId } = useParams<RouteParams>()

  const history = useHistory()

  const [contacts, setContacts] = useState<Contact[]>([])
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText(''))
  )

  const [contact, setContact] = useState<string>('')
  const [contactFone, setContactFone] = useState<string>('')
  const [contactEmail, setContactEmail] = useState<string>('')
  const [WhatsAppAtive, setWhatsAppAtive] = useState<boolean>(false)

  const { handleSubmit, values, setValues, handleChange } = useFormik({
    initialValues: {
      hasWhats: false,
      hasSMS: false,
      hasEmail: false
    },
    onSubmit: values => {
      if (
        (contact && contactEmail && contactFone && driverStorageId) ||
        !driverStorageId
      ) {
        contacts[0] = {
          id: 0,
          name: contact,
          fone: contactFone,
          email: contactEmail,
          whatsapp: values.hasWhats
        }

        let objectDriver = {
          hasWhats: values.hasWhats,
          hasSMS: values.hasSMS,
          hasEmail: values.hasEmail,
          driver: {
            id: Number(driverId)
          },
          useTerm: {
            id: term.id,
            updatedAt: term.updatedAt
          },
          contact: contacts
        }

        let objectUser = {
          hasWhats: values.hasWhats,
          hasSMS: values.hasSMS,
          hasEmail: values.hasEmail,
          user: {
            id: Number(driverId)
          },
          useTerm: {
            id: term.id,
            updatedAt: term.updatedAt
          },
          contact: []
        }

        api
          .post(
            'useTerm/authorization/save',
            driverStorageId ? objectDriver : objectUser
          )
          .then(() => {
            toast.success('Login realizado com sucesso')

            sessionStorage.removeItem('@App:temporaryCompanyId')

            if (sessionStorage.getItem('@App:userRole') === 'Transportadora') {
              setTimeout(() => {
                history.push('/expedicao/transportadora')
              }, 2500)
            }

            if (driverStorageId) {
              sessionStorage.removeItem('@App:temporaryDriverId')
              setTimeout(() => {
                history.push('/')
              }, 2500)
            } else {
              setTimeout(() => {
                history.push('/admin')
              }, 2500)
            }
          })
          .catch(e => {
            if (e.response.data.code === 'ER_DUP_ENTRY')
              toast.error('Erro ao Cadastrar: ' + e.response.data.message)
          })
      } else {
        toast.error('Preencha todos os campos!')
      }
    }
  })

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (driverStorageId) {
      api.get(`/driver/${driverStorageId}`).then(response => {
        setContact(response.data?.name)

        const contact = response.data.contact[0]

        setContacts(response.data.contact)
        setContactFone(contact?.fone)
        setContactEmail(contact?.email)
        setWhatsAppAtive(contact?.whatsapp)
      })
    }

    api.get(`/useTerm/user/company/${companyId}`).then(response => {
      const blocksFromHTML = convertFromHTML(response.data.content)
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )

      setEditorState(EditorState.createWithContent(state))

      setValues({
        hasWhats: false,
        hasSMS: false,
        hasEmail: false
      })

      setTerm(response.data)

      setIsLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleLogout() {
    if (driverStorageId) {
      sessionStorage.removeItem('@App:temporaryDriverId')
      driverLogout()
      setTimeout(() => {
        history.push('/')
      }, 2500)
    } else {
      Logout()
      sessionStorage.removeItem('@App:temporaryId')
      sessionStorage.removeItem('@App:temporaryCompanyId')
      setTimeout(() => {
        history.push('/admin')
      }, 2500)
    }
  }

  if (isLoading) {
    return <></>
  }

  async function handleChangeContactFone(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContactFone(event.target.value.slice(0, 16))
  }

  async function handleChangeContactEmail(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContactEmail(event.target.value)
  }

  return (
    <div className='user-aprove-term'>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>Política de Privacidade e Termos de Uso</strong>
          </div>
        </header>

        <div className='content'>
          <div className='lgpd-header'>
            <div>
              Versão{' '}
              <span>{new Date(term?.updatedAt).toLocaleDateString()}</span>
            </div>
          </div>
          <div>
            <Editor
              readOnly={false}
              editorState={editorState}
              wrapperClassName='wrapper-class'
              editorClassName='editor-class'
              toolbarClassName='toolbar-class'
            />
          </div>

          <div className='checkbox terms'>
            <div className='checkbox-title'>
              <h2>Confirme suas informações de contato</h2>
            </div>

            <div>
              <div>
                {driverStorageId && (
                  <div>
                    <label>Telefone Celular</label>
                    <Input
                      mask='(##) # ####-####'
                      name='fone'
                      placeholder='Telefone'
                      value={contactFone}
                      onChange={handleChangeContactFone}
                      label=''
                      readOnly
                    />
                  </div>
                )}

                <Checkbox
                  name='hasWhats'
                  checked={values.hasWhats || WhatsAppAtive}
                  value={values.hasWhats}
                  onChange={handleChange}
                  color='default'
                  size='medium'
                />
                <label>Autorizar mensagens ao whatsapp</label>
              </div>

              <div>
                <Checkbox
                  name='hasSMS'
                  checked={values.hasSMS}
                  value={values.hasSMS}
                  onChange={handleChange}
                  color='default'
                  size='medium'
                />
                <label>Autorizar envio de mensagens sms</label>
              </div>
            </div>

            <div>
              <div>
                {driverStorageId && (
                  <div>
                    <label>Email</label>
                    <Input
                      name='email'
                      placeholder='E-mail'
                      value={contactEmail}
                      onChange={handleChangeContactEmail}
                      label=''
                      readOnly
                    />
                  </div>
                )}

                <Checkbox
                  name='hasEmail'
                  checked={values.hasEmail}
                  value={values.hasEmail}
                  onChange={handleChange}
                  color='default'
                  size='medium'
                />
                <label>Autorizar envio de mensagens ao email</label>
              </div>
            </div>
          </div>

          <div className='bottom'>
            <div className='buttons-header'>
              <button
                onClick={() => {
                  handleLogout()
                }}
                id='btBack'
                type='button'
              >
                Não aceito
              </button>

              <button id='btSave' type='submit'>
                Eu aceito os termos acima
              </button>
            </div>
          </div>
        </div>
      </form>
      <br />
    </div>
  )
}

export default NewEditLgpd
