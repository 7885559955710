import React from 'react'
import Routes from './Routes/Routes'
import './assets/styles/global.css'
import { AuthProvider } from './contexts/auth'
import { ExpedProvider } from './contexts/exped'

import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { hot } from 'react-hot-loader/root'
import { ToastContainer } from 'react-toastify'

function App() {
  return (
    <AuthProvider>
      <ExpedProvider>
        <Routes />

        <ToastContainer />
      </ExpedProvider>
    </AuthProvider>
  )
}

export default hot(App)
