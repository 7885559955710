import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

import "./styles.css";

interface Log {
  id?: number;
  handleClose(): void;
  info: any;
}

const LogDetails: React.FC<Log> = ({ id, info, handleClose }) => {
  return (
    <div className="order-content">
      <div className="cancel-bar">
        <span>Log</span>
        <span className="close" onClick={handleClose}>
          <AiOutlineCloseCircle />
        </span>
      </div>

      <div className="log-info">
        {info?.details?.map((act: any) => (
          <>
            {<strong>{act?.key}</strong>}

            {typeof act.value === "object" ? (
              <div>
                {Object.values(act.value).map((value: any) => {
                  return (
                    <>
                      <p>{value}</p> <br />
                    </>
                  );
                })}
              </div>
            ) : (
              <p style={{ fontSize: 16 }}>{act?.value}</p>
            )}
          </>
        ))}

        <strong>Data</strong>
        <div>{info?.created_at}</div>
      </div>
    </div>
  );
};

export default LogDetails;
