import React from 'react';
import { Editor } from 'react-draft-wysiwyg';

import "./styles.css";

interface Modal {
    type: string,
    id: number,
    className: string,
    editorState: any,
    title: string,
    handleClose(): void,
}

const ModalDelete: React.FC<Modal> = ({ type, id, className, editorState, title, handleClose }) => {

    return (

        <div id="myModal" className={` ${className} modal`}  >
            <div className="modal-content">

                <div className="site-content cf">
                    <div className="boxed-group dangerzone">
                        <h3 className='title'>
                            <div >
                                <span>Politica de Privacidade</span>
                                <span>e Termos de Uso</span>
                            </div>
                            <span className="close" onClick={handleClose}>&times;</span>
                        </h3>

                        <div className="boxed-group-inner">
                            <section className="delete-user-form">

                                <h4>{title}</h4>
                            
                                <Editor
                                    readOnly={false}  
                                    editorState={editorState}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                />

                                
                            </section>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default ModalDelete;