//import id from 'date-fns/esm/locale/id/index.js';
import React, { createContext, useState, useEffect, useContext } from 'react'
import api from '../services/api'

interface AuthContextData {
  signed(): boolean
  usersigned: boolean
  driverSigned(): boolean
  user: object | null
  role: string
  authenticatedRole: string
  company?: number
  driver: string | null

  Login(user: object): Promise<boolean>
  driverLogin(driver: object): Promise<boolean>

  Logout(): void
  LogoutExped(): void
  driverLogout(): void
  isRoleLoaded(): Promise<boolean>
  isAdmin(): Promise<boolean>
  isAdminRoot(): Promise<boolean>
  isShipping(): Promise<boolean>
  getUserRole(): any
  setMenuExpand(): Promise<boolean>
  setMenuExpandFalse(): Promise<boolean>
  menu: any
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<object | null>({ name: '' })
  const [driver, setDriver] = useState<string | null>(null)

  const [role, setRole] = useState<string>('')
  const [authenticatedRole, setAuthenticatedRole] = useState<string>('')
  const [company, setCompany] = useState(0)
  const [usersigned, setUsersigned] = useState(false)
  const [menu, setMenu] = useState(false)
  const [isRoot, setIsRoot] = useState(false)

  useEffect(() => {
    const storagedUser = sessionStorage.getItem('@App:user')
    const storagedToken = sessionStorage.getItem('@App:token')

    const storagedDriver = sessionStorage.getItem('@AppDriver:driver')
    const storagedDriverToken = sessionStorage.getItem('@AppDriver:token')

    if (storagedToken && storagedUser) {
      setUser({ name: storagedUser })
      api.defaults.headers.Authorization = `Bearer ${storagedToken}`
    }

    if (storagedDriver && storagedDriverToken) {
      setDriver(JSON.parse(storagedDriver))
      api.defaults.headers.Authorization = `Bearer ${storagedDriverToken}`
    }

    getRole()
  }, [])

  async function getRole() {
    const storagedUserId = sessionStorage.getItem('@App:id')
    if (storagedUserId) {
      await api.get(`/users/${storagedUserId}`).then(response => {
        setRole(response.data.role)

        if (
          response.data.isRoot === true &&
          response.data.role === 'Administrador'
        ) {
          setIsRoot(true)
        }

        if (response.data.company) setCompany(response.data.company.id)
      })
    }
  }

  function setAuthenticatedUserRole(role: string) {
    setAuthenticatedRole(role)
  }

  async function Login(userData: object): Promise<boolean> {
    return await api.post('auth/login', userData).then(response => {
      setUser(response.data.user)
      setRole(response.data.role)
      setAuthenticatedUserRole(response.data.role)
      setCompany(response.data.company?.id)
      setUsersigned(true)

      api.defaults.headers.Authorization = `Bearer ${response.data.accessToken}`

      sessionStorage.setItem('@App:id', response.data.id)
      sessionStorage.setItem('@App:user', response.data.user)
      sessionStorage.setItem('@App:token', response.data.accessToken)
      sessionStorage.setItem('@App:userRole', response.data.role)
      sessionStorage.setItem('@App:logged', 'true')

      getRole()

      if (response.data.company && !isRoot) {
        localStorage.setItem('@App:companyId', response.data.company?.id)
        sessionStorage.setItem('@App:companyName', response.data.company?.name)
      }

      if (response.data.shippingCompanyId) {
        localStorage.setItem(
          '@App:shippingCompanyId',
          response.data?.shippingCompanyId
        )
      }

      if (
        response.data.role === 'Transportadora' &&
        response.data.useTerms === false
      ) {
        sessionStorage.setItem(
          '@App:temporaryCompanyId',
          response.data.company?.id
        )
        sessionStorage.setItem('@App:temporaryId', response.data.id)
      }

      return true
    })
  }

  function Logout() {
    setUser(null)
    sessionStorage.removeItem('@App:id')
    sessionStorage.removeItem('@App:user')
    sessionStorage.removeItem('@App:token')
    window.location.href = '/login'
  }

  function LogoutExped() {
    setUser(null)
    sessionStorage.removeItem('@App:id')
    sessionStorage.removeItem('@App:user')
    sessionStorage.removeItem('@App:token')
    localStorage.removeItem('attendance')
    localStorage.removeItem('@App:companyId')
    window.location.href = '/expedicao'
  }

  function getUserRole() {
    return role
  }

  async function setMenuExpand(): Promise<boolean> {
    setMenu(!menu)

    return menu ? true : false
  }

  async function setMenuExpandFalse(): Promise<boolean> {
    setMenu(true)

    return menu ? true : false
  }

  async function isAdmin(): Promise<boolean> {
    return role === 'Administrador' ? true : false
  }

  async function isAdminRoot(): Promise<boolean> {
    return isRoot === true ? true : false
  }

  async function isRoleLoaded(): Promise<boolean> {
    return role !== '' ? true : false
  }

  async function isShipping(): Promise<boolean> {
    return role === 'Transportadora' ? true : false
  }

  function signed(): boolean {
    /*     const t = await verifySiged();
    
        setUsersigned(t) */

    return sessionStorage.getItem('@App:user') ? true : false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function verifySiged() {
    const validaToken = await api
      .post('auth/validate', {
        token: sessionStorage.getItem('@App:token')
      })
      .catch(e => {
        return false
      })

    if (validaToken) return true
    return false
  }

  function driverSigned(): boolean {
    return sessionStorage.getItem('@AppDriver:driver') ? true : false
  }

  async function driverLogin(driverData: object) {
    sessionStorage.removeItem('@App:id')

    await api.post('auth/app/login', driverData).then(response => {
      if (response.status === 201) {
        setDriver(response.data.name)
        api.defaults.headers.Authorization = `Bearer ${response.data.accessToken}`

        sessionStorage.setItem(
          '@AppDriver:id',
          JSON.stringify(response.data.id)
        )
        sessionStorage.setItem(
          '@AppDriver:driver',
          JSON.stringify(response.data.name)
        )
        sessionStorage.setItem('@AppDriver:token', response.data.accessToken)
        sessionStorage.setItem(
          '@AppDriver:companyId',
          response.data.company?.id
        )
        sessionStorage.setItem(
          '@AppDriver:companyName',
          response.data.company?.name
        )
        sessionStorage.setItem('@App:logged', 'true')

        if (response.data.useTerms === false) {
          sessionStorage.setItem(
            '@App:temporaryCompanyId',
            response.data.company?.id
          )
          sessionStorage.setItem('@App:temporaryDriverId', response.data.id)
        }
        return true
      }
    })

    return true
  }

  function driverLogout() {
    setDriver(null)
    sessionStorage.removeItem('@AppDriver:id')
    sessionStorage.removeItem('@AppDriver:driver')
    sessionStorage.removeItem('@AppDriver:token')
    sessionStorage.removeItem('@AppDriver:companyName')
    sessionStorage.removeItem('@AppDriver:companyId')

    sessionStorage.removeItem('@App:logged')
  }

  return (
    <AuthContext.Provider
      value={{
        menu,
        authenticatedRole,
        user,
        role,
        getUserRole,
        usersigned,
        company,
        signed,
        driver,
        LogoutExped,
        isRoleLoaded,
        driverSigned,
        Login,
        setMenuExpand,
        setMenuExpandFalse,
        driverLogin,
        Logout,
        driverLogout,
        isAdmin,
        isAdminRoot,
        isShipping
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)

  return context
}
