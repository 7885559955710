/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { DateRangePicker } from 'react-date-range'
import { addDays, subDays } from 'date-fns'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import { useHistory } from 'react-router'
import { DataGrid, GridColDef, GridCellParams, ptBR } from '@mui/x-data-grid'
import ModalDelete from '../../components/ModalDelete'
import ModalDeletetHoliday from '../../components/ModalDelete'
import ModalDeleteTerm from '../../components/ModalDelete'
import { Checkbox } from '@material-ui/core'

import { Link } from 'react-router-dom'
import { MdDone } from 'react-icons/md'
import Input from '../../components/Input'
import api from '../../services/api'

import { useFormik } from 'formik'

import { toast, ToastOptions } from 'react-toastify'
import { useAuth } from '../../contexts/auth'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { AiOutlineSetting, AiOutlineDelete } from 'react-icons/ai'
import { GiBackwardTime, GiSettingsKnobs } from 'react-icons/gi'
import { AiFillCalendar, AiOutlineEdit } from 'react-icons/ai'
import Tooltip from '@material-ui/core/Tooltip'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import cx from 'clsx'

import {
  Modal as ModalResetQueue,
  ModalHeading,
  ModalTitle,
  ModalWrapper,
  ModalBox
} from '../../components/Modal'
import { Popups } from './components/Popups'

import './styles.scss'

interface Delay {
  id: any
  icon: string
  reason: string
  time: string
}

interface Holiday {
  id: number
  title: string
  dates: any
}

interface Term {
  title: string
  id: number
  active: boolean
  createdAt: string
}

interface iOption {
  label: string
  value: string
  company?: string
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ flexGrow: 1 }}
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  tabRoot: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224
  },
  tabs: {
    flexGrow: 1,
    borderRight: `1px solid ${theme.palette.divider}`
  }
}))

const periodOptions: iOption[] = [
  { value: '30', label: '30 Dias' },
  { value: '60', label: '60 Dias' },
  { value: '90', label: '90 Dias' },
  { value: '120', label: '120 Dias' },
  { value: '150', label: '150 Dias' },
  { value: '180', label: '180 Dias' }
]

const toastOptions: ToastOptions = {
  position: 'bottom-right'
}

const formInitialValues = {
  id: 0,
  checkinIp: '',
  enableCheckin: false,
  programmedTotal: 0,
  queue_edit_hours_limit: 0,
  timeAttendence: '00:00',
  start: '00:00',
  end: '00:00',
  startInterval: '00:00',
  endInterval: '00:00',
  googleMaps: '',
  holidayName: '',
  holidayStart: '',
  holidayEnd: '',
  weekDays: {
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false
  },
  delay: [
    {
      id: 0,
      reason: '',
      time: ''
    }
  ],
  scheduling_day_limit: 0,
  hour_entry_Limit: 0,
  enableTolerance: false,
  tolerance: '',
  enableRecaptcha: false,
  recaptchaSecret: '',
  enable_missing_validation: false,
  missing_limit: 0,
  missing_limit_after_block: 0,
  missing_validation_period: 0,
  enableQueueSave: true
}

const initializeCompanyIps = [
  {
    id: 'checkinIp-1',
    lable: 'IP Check-In Motoristas 1',
    value: ''
  },
  {
    id: 'checkinIp-2',
    lable: 'IP Check-In Motoristas 2',
    value: ''
  }
]

const Settings: React.FC = (): JSX.Element => {
  const history = useHistory()
  const customLibrary: any = library
  library.add(fas)

  const [periodValue, setPeriodValue] = useState<iOption>({
    value: '',
    label: ''
  })
  const [awesomeIcons, setAwessomeIcons] = useState<any[]>([])
  const [delays, setDelays] = useState<Delay[]>([])
  const [holidays, setHolidays] = useState<Holiday[]>([])
  const [terms, setTerms] = useState<Term[]>([])
  const [reason, setReason] = useState('')
  const [time, setTime] = useState('')
  const [schedulingLimit, setSchedulingLimit] = useState('')
  const [hour_entry_Limit, setHour_entry_Limit] = useState('')
  const [dates, setDates] = useState<any[]>([])
  const { setMenuExpandFalse } = useAuth()
  const [companies, setCompanies] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [companyValue, setCompanyValue] = useState<iOption>({
    value: '',
    label: ''
  })
  const [iconValue, setIconValue] = useState<iOption>({ value: '', label: '' })
  const [modal, setModal] = useState('')
  const [modalHoliday, setModalHoliday] = useState('')
  const [name, setName] = useState('')
  const [id, setId] = useState(0)
  const [modalDelay, setModalDelay] = useState('')

  const [image, setImage] = useState()
  const [cropper, setCropper] = useState<any>()

  const [isResettingQueue, setIsResettingQueue] = useState(false)
  const [modalResettingQueueIsOpen, setModalResettingQueueIsOpen] =
    useState(false)

  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const [companyIps, setCompanyIps] = useState(initializeCompanyIps)

  const onChange = (e: any) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result as any)
    }
    reader.readAsDataURL(files[0])
  }

  const getCropImg = () => {
    let cropImg = cropper.getCroppedCanvas().toDataURL()
    let arr = cropImg.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], 'imagename', { type: mime })
  }

  const handleAddHoliday = () => {
    if (dates.length !== 0) {
      api
        .post('holidays/save', {
          company: {
            id: Number(companyValue.value)
          },
          title: values.holidayName,
          dates: JSON.stringify(dates)
        })
        .then(() => {
          getHolidays(Number(companyValue.value))
          toast.success('Bloqueio de Datas salvo!', toastOptions)
        })
        .catch(() => {
          toast.error('Erro ao cadastrar', toastOptions)
        })
    } else {
      toast.error('Data é obrigatório', toastOptions)
    }
  }

  let formData = new FormData()

  const { company } = useAuth()

  const { handleSubmit, values, setValues, handleChange, setFieldValue } =
    useFormik({
      initialValues: formInitialValues,
      onSubmit: values => {
        if (image) {
          formData.append('logo', getCropImg())
          if (Number(companyValue.value))
            formData.append('company', companyValue.value)

          api
            .post('/settings/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {})
        }

        api
          .post('settings/save', {
            id: values.id,
            checkinIp: parseCompanyIps(companyIps),
            enableCheckin: values.enableCheckin,
            programmedTotal: values.programmedTotal,
            queue_edit_hours_limit: values.queue_edit_hours_limit,
            timeAttendence: values.timeAttendence,
            start: values.start,
            end: values.end,
            startInterval: values.startInterval,
            endInterval: values.endInterval,
            googleMaps: values.googleMaps,
            delay: delays,
            company: Number(companyValue.value),
            weekDays: JSON.stringify(values.weekDays),
            scheduling_day_limit: schedulingLimit,
            hour_entry_Limit: hour_entry_Limit,
            enableRecaptcha: values.enableRecaptcha,
            recaptchaSecret: values.recaptchaSecret,
            enableTolerance: values.enableTolerance,
            tolerance: values.tolerance,
            enable_missing_validation: values.enable_missing_validation,
            missing_limit: values.missing_limit,
            missing_limit_after_block: values.missing_limit_after_block,
            missing_validation_period: Number(periodValue.value),
            enableQueueSave: values.enableQueueSave
          })
          .then(() => {
            toast.success('Alterações salvas com sucesso', toastOptions)
            setTimeout(() => history.push('/settings'), 2500)
          })
          .catch(() => {
            toast.error('Erro ao cadastrar', toastOptions)
          })
      }
    })

  const getHolidays = (id: number) => {
    api.get(`/holidays/company/${id}`).then(response => {
      setHolidays(response.data)
    })
  }

  const getTerms = (id: number) => {
    api.get(`/useTerm/company/${id}`).then(response => {
      setTerms(response.data)
    })
  }

  const updateConfig = (id: number) => {
    api.get(`/settings/company/${id}`).then(response => {
      if (response.data) {
        setValues({
          id: response.data.id,
          enableCheckin: response.data.enableCheckin,
          checkinIp: response.data.checkinIp,
          programmedTotal: response.data.programmedTotal,
          queue_edit_hours_limit: response.data.queue_edit_hours_limit,
          timeAttendence: response.data.timeAttendence,
          start: response.data.start,
          end: response.data.end,
          startInterval: response.data.startInterval,
          endInterval: response.data.endInterval,
          googleMaps: response.data.googleMaps,
          weekDays: JSON.parse(response.data.weekDays),
          delay: response.data.delay,
          scheduling_day_limit: response.data.scheduling_day_limit,
          hour_entry_Limit: response.data.hour_entry_Limit,
          holidayName: '',
          holidayStart: '',
          holidayEnd: '',
          enableTolerance: response.data.enableTolerance,
          tolerance: response.data.tolerance,
          enableRecaptcha: response.data.enableRecaptcha,
          recaptchaSecret: response.data.recaptchaSecret,
          enable_missing_validation: response.data.enable_missing_validation,
          missing_limit: response.data.missing_limit,
          missing_limit_after_block: response.data.missing_limit_after_block,
          missing_validation_period: response.data.missing_validation_period,
          enableQueueSave: response.data.enableQueueSave
        })

        setPeriodValue({
          value: response.data.missing_validation_period + '',
          label: response.data.missing_validation_period + ' Dias'
        })
        setSchedulingLimit(response.data.scheduling_day_limit)
        setHour_entry_Limit(response.data.hour_entry_Limit)
      }
      if (response.data.delay) setDelays(response.data.delay)
    })
  }

  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  function IsJsonString(str: any) {
    try {
      JSON.parse(str)
    } catch (e) {
      return str
    }
    return JSON.parse(str)
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Code', width: 80 },
    {
      field: 'reason',
      headerName: 'Motivo',
      width: 200,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const iconName: any = params.row.icon
          ? params.row.icon
          : 'calendar-minus'

        return (
          <div>
            <FontAwesomeIcon icon={['fas', iconName]} /> {params.row.reason}
          </div>
        )
      }
    },
    { field: 'time', headerName: 'Tempo de atraso', width: 150 },
    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      width: 110,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Tooltip className='delete-icon' title={<h1>Deletar</h1>}>
              <Link to={{ pathname: `` }}>
                <AiOutlineDelete
                  onClick={() => {
                    handleCorfirmDeleteDelay(params.row.id, params.row.reason)
                  }}
                />
              </Link>
            </Tooltip>
          </>
        )
      }
    }
  ]

  const rows = delays.map((item, index) => {
    const validDelays = delays.filter(item => {
      return item.id !== null
    })

    return {
      id: item?.id ? item?.id : validDelays[validDelays.length - 1]?.id + index,
      icon: item?.icon,
      reason: item?.reason,
      time: item?.time
    }
  })

  const columnsHolidays: GridColDef[] = [
    { field: 'id', headerName: 'Code', width: 80 },
    { field: 'title', headerName: 'Nome', width: 200 },
    { field: 'dates', headerName: 'Tempo de Bloqueio', width: 250 },
    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      width: 110,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Tooltip className='delete-icon' title={<h1>Deletar</h1>}>
              <Link to={{ pathname: `` }}>
                <AiOutlineDelete
                  onClick={() => {
                    handleCorfirmDeleteHoliday(params.row.id, params.row.title)
                  }}
                />
              </Link>
            </Tooltip>
          </>
        )
      }
    }
  ]

  const rowsHolidays = holidays.map((item, index) => {
    let dates = IsJsonString(item.dates)

    return {
      id: item.id,
      title: item.title,
      dates: dates[0] + ' - ' + dates[dates.length - 1]
    }
  })

  const columnsTerms: GridColDef[] = [
    { field: 'id', headerName: 'Code', width: 80 },
    { field: 'title', headerName: 'Título', width: 200 },
    {
      field: 'active',
      headerName: 'Status',
      sortable: false,
      width: 110,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Tooltip className='edit-icon' title={<h1>Editar</h1>}>
              <Link
                to={{
                  pathname: `/termos-de-acesso/${companyValue.value}/novo/${params.row.id}`
                }}
              >
                <AiOutlineEdit />{' '}
              </Link>
            </Tooltip>
            <Tooltip className='delete-icon' title={<h1>Deletar</h1>}>
              <Link to={{ pathname: `` }}>
                <AiOutlineDelete
                  onClick={() => {
                    handleCorfirmDelete(params.row.id, params.row.title)
                  }}
                />
              </Link>
            </Tooltip>
          </>
        )
      }
    }
  ]

  const rowsTerms = terms.map((item, index) => {
    let date = new Date(item.createdAt).toLocaleDateString()

    return {
      id: item.id,
      created_at: date,
      active: item.active,
      title: item.title
    }
  })

  async function handleResetQueue() {
    if (!companyValue) {
      return
    }

    setIsResettingQueue(true)

    const companyIdSelected = companyValue.value

    await api.post('/queue/reset', {
      company: { id: Number(companyIdSelected) }
    })

    setIsResettingQueue(false)

    closeModalResetQueue()
    toast.success('Fila resetada', toastOptions)
  }

  function handleAddDelay(reason: string, time: string, iconValue: iOption) {
    setDelays([
      ...delays,
      { id: null, reason: reason, time: time, icon: iconValue.value }
    ])

    setReason('')
    setTime('')
  }

  function handleChangeReason(event: React.ChangeEvent<HTMLInputElement>) {
    setReason(event.target.value)
  }

  function handleChangeTime(event: React.ChangeEvent<HTMLInputElement>) {
    setTime(event.target.value)
  }

  function handleDeleteDelay(id: number) {
    hideModalDelay()

    api
      .delete(`/delay/delete/${id}`)
      .then(response => {
        if (response.status === 200) {
          toast.success('Excluido com sucesso!', toastOptions)
          const results = delays.filter(item => item.id !== id)
          setDelays(results)
        }
      })
      .catch(() => {
        toast.success('Erro ao excluir!', toastOptions)
      })
  }

  function handleDeleteTerm(id: number) {
    hideModal()

    api
      .delete(`/useTerm/delete/${id}`)
      .then(response => {
        if (response.status === 200) {
          toast.success('Excluido com sucesso!', toastOptions)
          const results = terms.filter(item => item.id !== id)
          setTerms(results)
        }
      })
      .catch(() => {
        toast.success('Erro ao excluir!', toastOptions)
      })
  }

  function handleDeleteHoliday(id: number) {
    hideModalHoliday()

    api
      .delete(`/holidays/delete/${id}`)
      .then(response => {
        if (response.status === 200) {
          toast.success('Excluido com sucesso!', toastOptions)
          const results = holidays.filter(item => item.id !== id)
          setHolidays(results)
        }
      })
      .catch(() => {
        toast.error('Erro ao excluir!', toastOptions)
      })
  }

  function handleCorfirmDelete(id: any, name: any) {
    showModal()
    setName(name)
    setId(parseInt(id))
  }

  function handleCorfirmDeleteHoliday(id: any, name: any) {
    showModalHoliday()
    setName(name)
    setId(parseInt(id))
  }

  function handleCorfirmDeleteDelay(id: any, name: any) {
    showModalDelay()
    setName(name)
    setId(parseInt(id))
  }

  function handleCloseModalDelay() {
    hideModalDelay()
  }

  const showModalDelay = () => {
    setModalDelay('show')
  }

  const hideModalDelay = () => {
    setModalDelay('')
  }

  function handleCloseModalHoliday() {
    hideModalHoliday()
  }

  function handleCloseModal() {
    hideModal()
  }

  const showModalHoliday = () => {
    setModalHoliday('show')
  }

  const hideModalHoliday = () => {
    setModalHoliday('')
  }

  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  const [state, setState] = useState<any>([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ])

  const handleOnChange = (ranges: any) => {
    const { selection } = ranges

    setState([selection])
    setDates(enumerateDaysBetweenDates(selection))
  }

  const enumerateDaysBetweenDates = function (selection: any) {
    var dates = []
    var currDate = moment(selection.startDate).startOf('day')
    var lastDate = moment(selection.endDate).startOf('day')

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate().toISOString().slice(0, 10))
    }

    dates.unshift(selection.startDate.toISOString().slice(0, 10))
    dates.push(selection.endDate.toISOString().slice(0, 10))

    return dates
  }

  function handleChangeOptionBrand(option: iOption) {
    setCompanyValue(option)
    getHolidays(Number(option.value))
    getTerms(Number(option.value))
    updateConfig(Number(option.value))
  }

  function handleChangeOptionIcon(option: iOption) {
    setIconValue(option)
  }

  function handleChangeOptionPeriod(option: iOption) {
    setPeriodValue(option)
  }

  const closeModalResetQueue = () => setModalResettingQueueIsOpen(false)
  const openModalResetQueue = () => setModalResettingQueueIsOpen(true)

  const CustomOption = (props: any) =>
    !props.isDisabled ? (
      <div className='options-icons' {...props.innerProps}>
        <FontAwesomeIcon icon={['fas', props.value]} /> {props.value}
      </div>
    ) : null

  useEffect(() => {
    const icons = Object.keys(customLibrary?.definitions?.fas)

    setAwessomeIcons(
      icons.map(icon => {
        return { value: icon, label: icon }
      })
    )

    api.get('/company').then(response => {
      setCompanies(
        response.data.map((item: any) => {
          return { value: item.id, label: item.name }
        })
      )
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  function handleChangeCompanyIp(
    e: React.ChangeEvent<HTMLInputElement>,
    fieldId: string
  ) {
    const { value } = e.target

    const updatedCompanyIps = companyIps.map(item =>
      item.id === fieldId ? { ...item, value } : item
    )

    setCompanyIps(updatedCompanyIps)
  }

  function parseCompanyIps(ips: typeof initializeCompanyIps) {
    const joinIps = ips.map(ip => ip.value)
    return joinIps.length > 1 ? joinIps.join(',') : joinIps[0]
  }

  useEffect(() => {
    if (values.checkinIp !== '') {
      const ips = values.checkinIp.split(',')

      const updatedCompanyIps = companyIps.map((item, index) => ({
        ...item,
        value: ips[index]
      }))

      setCompanyIps(updatedCompanyIps)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.checkinIp])

  return (
    <div className='settings-container'>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>Configurações</strong>
          </div>

          <div className='buttons-header'>
            <button id='btSave' type='submit'>
              <MdDone size={20} color='#fff' />
              Salvar
            </button>
          </div>
        </header>

        <div className='content'>
          <div className='row company'>
            <div className={`input`}>
              <label>Empresa</label>
              <Select
                className='select-options'
                value={companyValue}
                options={companies}
                onChange={option => handleChangeOptionBrand(option as iOption)}
              />
            </div>
          </div>

          {companyValue.value !== '' && (
            <div className={classes.tabRoot}>
              <Tabs
                orientation='vertical'
                variant='scrollable'
                value={value}
                onChange={handleChangeTabs}
                aria-label='Vertical tabs example'
              >
                <Tab
                  label='Sistema(Ativo)'
                  icon={<AiOutlineSetting />}
                  {...a11yProps(1)}
                />
                <Tab
                  label='Atraso'
                  icon={<GiBackwardTime />}
                  {...a11yProps(0)}
                />
                <Tab
                  label='Informações(Geral)'
                  onClick={() => {
                    setMenuExpandFalse()
                  }}
                  icon={<GiSettingsKnobs />}
                  {...a11yProps(2)}
                />
                <Tab
                  label='Bloqueios'
                  icon={<AiFillCalendar />}
                  {...a11yProps(3)}
                />
                <Tab
                  label='Termos'
                  icon={<AiFillCalendar />}
                  {...a11yProps(4)}
                />

                <Tab label='Pop-ups' {...a11yProps(5)} />
              </Tabs>

              <TabPanel value={value} index={1}>
                <div className='settings-delay-content'>
                  <div className='row'>
                    <div className='settings-delay input-block'>
                      <input
                        name='reason'
                        placeholder='Motivo'
                        value={reason}
                        onChange={handleChangeReason}
                      />
                    </div>

                    <div className={`input select`}>
                      <Select
                        value={iconValue}
                        Placeholder='Ícone'
                        options={awesomeIcons}
                        onChange={option =>
                          handleChangeOptionIcon(option as iOption)
                        }
                        components={{
                          Option: optionsProp => (
                            <CustomOption {...optionsProp} />
                          )
                        }}
                      />
                    </div>

                    <div className='setting-time-delay input-block'>
                      <input
                        name='time-delay'
                        value={time}
                        onChange={handleChangeTime}
                        type='time'
                      />
                    </div>
                  </div>

                  <button
                    type='button'
                    id='addDelay'
                    disabled={reason === ''}
                    onClick={() => {
                      handleAddDelay(reason, time, iconValue)
                    }}
                  >
                    + Adicionar
                  </button>
                </div>

                <DataGrid
                  autoHeight
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  disableColumnMenu
                  rowsPerPageOptions={[8]}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </TabPanel>

              <TabPanel value={value} index={0}>
                <div className='queue-toggle-area'>
                  <div>
                    <span>Fila fechada/aberta</span>

                    <label
                      className={cx(
                        'switch'
                        // {
                        //   disabled: !values.enableQueueSave
                        // }
                      )}
                    >
                      <input
                        type='checkbox'
                        name='status'
                        // disabled={!values.enableQueueSave}
                        placeholder='Status'
                        checked={values.enableQueueSave}
                        onChange={e =>
                          setFieldValue('enableQueueSave', e.target.checked)
                        }
                      />
                      <div className='slider round'>
                        <span className='on'></span>
                        <span className='off'></span>
                      </div>
                    </label>
                  </div>

                  {companyValue.value !== '' && (
                    <button
                      disabled={isResettingQueue}
                      className='button-reset-queue'
                      onClick={openModalResetQueue}
                      type='button'
                    >
                      {isResettingQueue ? 'Resetando fila...' : 'Resetar fila'}
                    </button>
                  )}
                </div>

                <div className='company-check-in'>
                  {companyIps.map(field => {
                    return (
                      <div key={field.id} className='company-check-in-field'>
                        <Input
                          name={field.id}
                          placeholder={field.lable}
                          value={field.value}
                          onChange={e => handleChangeCompanyIp(e, field.id)}
                          label={field.lable}
                        />
                      </div>
                    )
                  })}

                  <div className='company-check-in-toggle'>
                    <div>
                      <span>Check-In fechada/aberta</span>

                      <label
                        className={cx(
                          'switch'
                          // {
                          //   disabled: !values.enableCheckin
                          // }
                        )}
                      >
                        <input
                          type='checkbox'
                          name='checkin_status'
                          placeholder='Status'
                          checked={values.enableCheckin}
                          onChange={e =>
                            setFieldValue('enableCheckin', e.target.checked)
                          }
                        />
                        <div className='slider round'>
                          <span className='on'></span>
                          <span className='off'></span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div className='attendence-area'>
                  <div className='attendence-heading'>
                    Informações dos atendimentos
                  </div>

                  <div className='infor-container'>
                    <div className='row'>
                      <div className='input'>
                        <div>Horário de atendimento:</div>
                        <div className='timeAttendence'>
                          <Input
                            name='start'
                            placeholder='Início'
                            value={values.start}
                            type='time'
                            onChange={handleChange}
                            label='Início'
                            maxLength={25}
                          />
                        </div>
                      </div>

                      <div className='input'>
                        <div>Horário de atendimento:</div>
                        <div className='timeAttendence'>
                          <Input
                            name='end'
                            placeholder='Término'
                            value={values.end}
                            onChange={handleChange}
                            type='time'
                            label='Término'
                            maxLength={25}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='input'>
                        <Input
                          name='programmedTotal'
                          placeholder='Máximo de atendimentos por dia'
                          onChange={handleChange}
                          value={values.programmedTotal}
                          type='number'
                          label='Nº máximo de atendimentos por dia:'
                        />
                      </div>
                      <div className='input'>
                        <Input
                          name='timeAttendence'
                          placeholder='Tempo por atendimento'
                          onChange={handleChange}
                          value={values.timeAttendence}
                          type='time'
                          label='Tempo por atendimento:'
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='input'>
                        <Input
                          name='queue_edit_hours_limit'
                          placeholder='Limite de horas'
                          onChange={handleChange}
                          value={values.queue_edit_hours_limit}
                          type='number'
                          label='Limite de horas para edição de carregamentos:'
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='input timeIntervalAttendence'>
                        <div>Intervalo de atendimento:</div>

                        <Input
                          name='startInterval'
                          placeholder='Início'
                          value={values.startInterval}
                          type='time'
                          onChange={handleChange}
                          label='Início'
                          maxLength={25}
                        />

                        <Input
                          name='endInterval'
                          placeholder='Término'
                          value={values.endInterval}
                          onChange={handleChange}
                          type='time'
                          label='Término'
                          maxLength={25}
                        />
                      </div>

                      <div className='input week-days'>
                        <div>Dias de atendimento:</div>
                        <input
                          name='weekDays.mon'
                          type='checkbox'
                          checked={values.weekDays.mon}
                          onChange={handleChange}
                        />{' '}
                        Seg
                        <input
                          name='weekDays.tue'
                          type='checkbox'
                          checked={values.weekDays.tue}
                          onChange={handleChange}
                        />{' '}
                        Ter
                        <input
                          name='weekDays.wed'
                          type='checkbox'
                          checked={values.weekDays.wed}
                          onChange={handleChange}
                        />{' '}
                        Qua
                        <input
                          name='weekDays.thu'
                          type='checkbox'
                          checked={values.weekDays.thu}
                          onChange={handleChange}
                        />{' '}
                        Qui
                        <input
                          name='weekDays.fri'
                          type='checkbox'
                          checked={values.weekDays.fri}
                          onChange={handleChange}
                        />{' '}
                        Sex
                        <input
                          name='weekDays.sat'
                          type='checkbox'
                          checked={values.weekDays.sat}
                          onChange={handleChange}
                        />{' '}
                        Sáb
                        <input
                          name='weekDays.sun'
                          type='checkbox'
                          checked={values.weekDays.sun}
                          onChange={handleChange}
                        />{' '}
                        Dom
                      </div>
                    </div>

                    <div className='row'>
                      <div className='input tolerance'>
                        <div>
                          <div className='timeAttendence'>
                            <Input
                              name='tolerance'
                              placeholder='Início'
                              type='time'
                              value={values.tolerance}
                              onChange={handleChange}
                              label='Tolerância de Atraso'
                              maxLength={25}
                            />
                          </div>
                        </div>

                        <div>
                          <Checkbox
                            name='enableTolerance'
                            checked={values.enableTolerance}
                            value={values.enableTolerance}
                            onChange={handleChange}
                            color='default'
                          />
                          <label>Habilitar tolerância</label>
                        </div>
                      </div>

                      <div className='input tolerance'>
                        <div>
                          <div className='timeAttendence'>
                            <Input
                              name='recaptchaSecret'
                              placeholder='Chave'
                              value={values.recaptchaSecret}
                              onChange={handleChange}
                              label='Chave Recaptcha Backend'
                              maxLength={25}
                            />
                          </div>
                        </div>

                        <div>
                          <Checkbox
                            name='enableRecaptcha'
                            checked={values.enableRecaptcha}
                            value={values.enableRecaptcha}
                            onChange={handleChange}
                            color='default'
                          />
                          <label>Habilitar Recaptcha</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='scheduling-area'>
                  <div className='scheduling-heading'>
                    Informações dos pedidos
                  </div>

                  <div className='infor-container'>
                    <div className='row '>
                      <div className='input'>
                        <div>Limite de agendamento por pedido:</div>
                        <div className='scheduling-limit'>
                          <Input
                            name='start'
                            placeholder='Limite de agendadmento'
                            value={schedulingLimit}
                            type='number'
                            onChange={event => {
                              setSchedulingLimit(`${event.target.value}`)
                            }}
                            maxLength={25}
                          />
                        </div>
                      </div>

                      <div className='input'>
                        <div>Intervalo entre último agendamento:</div>

                        <div className='last-scheduling'>
                          <div>
                            <Input
                              name='start'
                              placeholder='Intervalo'
                              value={hour_entry_Limit}
                              type='number'
                              onChange={event => {
                                setHour_entry_Limit(`${event.target.value}`)
                              }}
                              maxLength={25}
                            />
                          </div>
                          <div className='h'>H</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='scheduling-area'>
                  <div className='scheduling-heading'>
                    Informações de bloqueio
                  </div>

                  <div className='infor-container'>
                    <div className='row '>
                      <div className='input tolerance'>
                        <div>
                          <div className='timeAttendence'>
                            <Input
                              name='missing_limit'
                              placeholder='Limite de faltas'
                              label='Limite de faltas'
                              value={values.missing_limit}
                              type='text'
                              onChange={handleChange}
                              maxLength={25}
                            />
                          </div>
                        </div>

                        <div>
                          <Checkbox
                            name='enable_missing_validation'
                            checked={values.enable_missing_validation}
                            value={values.enable_missing_validation}
                            onChange={handleChange}
                            color='default'
                          />
                          <label>Habilitar Limite de Falta</label>
                        </div>
                      </div>

                      <div className={`input select`}>
                        <div>Periodo de Validação:</div>
                        <Select
                          value={periodValue}
                          Placeholder='Ícone'
                          options={periodOptions}
                          onChange={option =>
                            handleChangeOptionPeriod(option as iOption)
                          }
                        />
                      </div>
                    </div>

                    <div className='row '>
                      <div className='input'>
                        <div>Limite de faltas após bloqueio:</div>
                        <div className='scheduling-limit'>
                          <Input
                            name='missing_limit_after_block'
                            placeholder='Limite de faltas após bloqueio'
                            value={values.missing_limit_after_block}
                            type='text'
                            onChange={handleChange}
                            maxLength={25}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel value={value} index={2}>
                <div className='row'>
                  <div style={{ width: '100%' }}>
                    <label> Logo:</label>
                    <br />
                    <input type='file' onChange={onChange} />

                    <br />
                    <br />
                    <Cropper
                      style={{ height: '264px', width: '640px' }}
                      zoomTo={0}
                      initialAspectRatio={1}
                      preview='.img-preview'
                      src={image}
                      viewMode={1}
                      guides={true}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={2}
                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                      onInitialized={instance => {
                        setCropper(instance)
                      }}
                    />
                  </div>
                  <div>
                    <div
                      className='box'
                      style={{ width: '100%', float: 'right' }}
                    >
                      <h1>Preview</h1>
                      <div
                        className='img-preview'
                        style={{
                          marginTop: 20,
                          width: '200px',
                          float: 'left',
                          height: '264px'
                        }}
                      />
                    </div>
                  </div>
                  <br style={{ clear: 'both' }} />
                </div>

                <div>
                  <Input
                    name='googleMaps'
                    placeholder='embed google maps'
                    value={values.googleMaps}
                    onChange={handleChange}
                    label='Localização'
                    maxLength={25}
                  />

                  <div>
                    <iframe
                      src={values.googleMaps}
                      width='400'
                      height='300'
                      loading='lazy'
                    ></iframe>
                  </div>
                </div>
              </TabPanel>

              <TabPanel value={value} index={3}>
                <div className='attendence-area'>
                  <div className='attendence-heading'>
                    Informações dos atendimentos
                  </div>
                  <div className='infor-container'>
                    <div className='row wrap'>
                      <div className='input'>
                        <Input
                          name='holidayName'
                          placeholder='Nome'
                          onChange={handleChange}
                          value={values.holidayName}
                          type='text'
                          label='Tempo de Bloqueio'
                        />
                      </div>

                      <div>
                        <label>Data</label>
                        <DateRangePicker
                          onChange={handleOnChange}
                          showMonthAndYearPickers={false}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          ranges={state}
                          direction='horizontal'
                        />
                      </div>
                    </div>

                    <div className='buttons-holiday'>
                      <button
                        type='button'
                        id='addDelay'
                        onClick={handleAddHoliday}
                      >
                        + Adicionar
                      </button>
                    </div>

                    <DataGrid
                      autoHeight
                      rows={rowsHolidays}
                      columns={columnsHolidays}
                      pageSize={10}
                      disableColumnMenu
                      rowsPerPageOptions={[8]}
                      localeText={
                        ptBR.components.MuiDataGrid.defaultProps.localeText
                      }
                    />
                  </div>
                </div>
              </TabPanel>

              <TabPanel value={value} index={4}>
                <div className='settings-delay-content'>
                  <Link
                    to={`/termos-de-acesso/${companyValue.value}/novo/undefined`}
                  >
                    <button type='button' id='addDelay'>
                      {' '}
                      Cadastrar{' '}
                    </button>
                  </Link>
                </div>

                <DataGrid
                  autoHeight
                  rows={rowsTerms}
                  columns={columnsTerms}
                  pageSize={10}
                  disableColumnMenu
                  rowsPerPageOptions={[8]}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </TabPanel>

              <TabPanel value={value} index={5}>
                <Popups companyId={Number(companyValue.value)} />
              </TabPanel>
            </div>
          )}
        </div>
      </form>

      <ModalResetQueue
        open={modalResettingQueueIsOpen}
        onOpenChange={closeModalResetQueue}
        backgroundOverlay='rgba(0, 0, 0, 0.44)'
      >
        <ModalWrapper>
          <ModalHeading>
            <ModalTitle>
              Deseja resetar a <span>fila?</span>
            </ModalTitle>
          </ModalHeading>

          <ModalBox>
            <div className='modal__reset-queue'>
              <div className='modal__description'>
                <p>
                  Ao fazer essa ação, todos os veículos serão realocados para o
                  inicio do período vigente e distribuídos nos dias seguintes de
                  acordo com as regras determinadas, podendo haver adiantamentos
                  abruptos de atendimento. Deseja continuar?
                </p>
              </div>

              <div className='modal__footer'>
                <button
                  className='modal__button-submit'
                  onClick={handleResetQueue}
                >
                  Sim
                </button>
                <button
                  className='modal__button-cancel'
                  onClick={closeModalResetQueue}
                >
                  Não
                </button>
              </div>
            </div>
          </ModalBox>
        </ModalWrapper>
      </ModalResetQueue>

      <ModalDelete
        type='Atraso'
        name={name}
        id={id}
        className={modalDelay}
        handleClose={handleCloseModalDelay}
        handleDeleteAction={handleDeleteDelay}
      />

      <ModalDeletetHoliday
        type='Tempo de Bloqueio'
        name={name}
        id={id}
        className={modalHoliday}
        handleClose={handleCloseModalHoliday}
        handleDeleteAction={handleDeleteHoliday}
      />

      <ModalDeleteTerm
        type='Termo de Uso'
        name={name}
        id={id}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteTerm}
      />
    </div>
  )
}

export default Settings
