import React, { useEffect, useState } from 'react';
import { MdTimelapse } from 'react-icons/md';

import api from '../../../services/api';
import { Doughnut } from 'react-chartjs-2';
import { BsCalendarFill } from 'react-icons/bs';
import { FaTruck } from 'react-icons/fa';
import { useAuth } from '../../../contexts/auth';
import './style.css'
import { Box, Button, Modal, Typography } from '@material-ui/core';
import Input from '../../../components/Input';
import load from '../../../assets/images/carregando.svg'
import Select from 'react-select';


interface Name {
  name: string;
} 

interface Data {
  body: Name;
}

interface Logs {
  id: number;
  operation_type: string;
  object_type: string;
  createdAt: string;
  data: Data;
}


interface Statistics {
    awaitingLoadAvg: string,
    loadingTimeAvg: string,
    queueFullFlowTimeAvg: string,
    attendedQueuesAvg: number,
    missingQueuesAvg: number,
    totalTransportedAmount: number,
    moreEntryQueueHour: string,
    moreAttendanceQueueHour: string,
    moreEntryQueueDay: string,
    moreAttendanceQueueDay: string,
    lessEntryQueueHour: string,
    lessAttendanceQueueHour: string,
    lessEntryQueueDay: string,
    lessAttendanceQueueDay: string,
    biggerTotalTransported: number,
    biggerTotalTransportedDate: string,
    attendedQueuesBeforeExpected: number,
    attendedQueuesAfterExpected: number,
    queueAvg: number
  }

  interface iOption {
    label: string;
    value: string;
  };
  
  interface Company{
    id: number,
    name: string
  }

const Logs: React.FC = () => {
  const { role } = useAuth();
  const companyId = localStorage.getItem('@App:companyId')
  const companyName = sessionStorage.getItem('@App:companyName')
  const maxInputDate = new Date().toISOString().split('T')[0]

  const [maxDate, setMaxDate] = useState<any>(new Date().toISOString().split('T')[0])
  const [minDate, setMinDate] = useState<any>(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0])
  const [loading, setLoading] = useState<boolean>(false)
  const [companyValue, setCompanyValue] = useState<any>({value: Number(companyId), label:companyName})
  const [companyOptions, setCompanyOptions] = useState<iOption[]>([{ value: "", label: "" }])
  const [adminRoot, setAdminRoot] = useState<any>("");

  const { isAdminRoot } = useAuth();

  const [queueStatistics, setQueueStatistics] = useState<Statistics>({
    awaitingLoadAvg: "00:00" ,
    loadingTimeAvg: "00:00" ,
    queueFullFlowTimeAvg: "00:00" ,
    attendedQueuesAvg: 0,
    missingQueuesAvg: 0,
    totalTransportedAmount: 0,
    moreEntryQueueHour: "00:00" ,
    moreAttendanceQueueHour: "00:00" ,
    moreEntryQueueDay: "00:00" ,
    moreAttendanceQueueDay: "00:00" ,
    lessEntryQueueHour: "00:00",
    lessAttendanceQueueHour: "00:00",
    lessEntryQueueDay: "00:00",
    lessAttendanceQueueDay: "00:00",
    biggerTotalTransported: 0,
    biggerTotalTransportedDate: "00:00" ,
    attendedQueuesBeforeExpected: 0,
    attendedQueuesAfterExpected: 0,
    queueAvg: 0
  })


  useEffect(() => {

    
    api.get(`/company`).then(response => {

      if(response.data){
        setCompanyOptions(response.data?.map((item:Company) => {
          return {label: `${item.id} - ${item.name}`, value: item.id}
        }))


        handleSearch()
        
      }
    })

   

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  isAdminRoot().then((result) => {
    setAdminRoot(result)
  });

  function handleSearch() {

    setLoading(true)


    let searchObject = {
      startDate:  minDate ,
      endDate: maxDate,
      companyId: companyValue?.value,
    }


    api.get('/dashboard', { params: searchObject }).then((response) => {
      setQueueStatistics(response.data)
      setLoading(false)


      if(response.data.moreEntryQueueDay === ""){
        setQueueStatistics({
          awaitingLoadAvg: "00:00" ,
          loadingTimeAvg: "00:00" ,
          queueFullFlowTimeAvg: "00:00" ,
          attendedQueuesAvg: 0,
          missingQueuesAvg: 0,
          totalTransportedAmount: 0,
          moreEntryQueueHour: "00:00" ,
          moreAttendanceQueueHour: "00:00" ,
          moreEntryQueueDay: "00:00" ,
          moreAttendanceQueueDay: "00:00" ,
          lessEntryQueueHour: "00:00",
          lessAttendanceQueueHour: "00:00",
          lessEntryQueueDay: "00:00",
          lessAttendanceQueueDay: "00:00",
          biggerTotalTransported: 0,
          biggerTotalTransportedDate: "00:00" ,
          attendedQueuesBeforeExpected: 0,
          attendedQueuesAfterExpected: 0,
          queueAvg: 0
        })
      }

    }).catch(error => {
      setLoading(false)

    })

  }


  const appointments = {
    labels: ['Faltosos', 'Atendidos'],
    datasets: [
      {
        
        backgroundColor: [
          '#cc0000',
          '#009933',
        

        ],
        hoverBackgroundColor: [
          '#cc0000',
          '#009933',
       
        ],
        data: [queueStatistics?.missingQueuesAvg, queueStatistics?.attendedQueuesAvg]
      }
    ]
  }

  const punctuality = {
    labels: ['Dentro da Tolerância', 'Pontualmente ou Antecipadamente'],
    datasets: [
      {
  
        backgroundColor: [
          '#fc8f00',
          '#cc0000',
         

        ],
        hoverBackgroundColor: [
          '#fc8f00',
          '#cc0000',
       
        ],
        data: [queueStatistics?.attendedQueuesAfterExpected, queueStatistics?.attendedQueuesBeforeExpected]
      }
    ]
  }

  function handleChangeOptionCompany(option: iOption) {
    setCompanyValue(option);
  }

  function ReturnSuspendWarning(){
    return (
      <>
        <Box className="modal load">
          <Modal
            open={loading}
            onClose={() => setLoading(true)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-instance" 
          >
            <Box className=" suspend-loading body">
              <Box className="container">
                <Box>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <div className="loading">
                      <img src={load} alt="load" />
                    </div> 
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      </>
    )
  }

  
  
  return (
      <div className='dashboard-page productList' >
        <header>
          <div>
            <strong>Transportadora Dashboard</strong>
          </div>
      
        </header>
        <ReturnSuspendWarning />
        <div className="attendence-area search">
            <div className="infor-container">
              <div className="row">
                      <div className={`input date`}>
                        <label>De:</label>
                        <div className="select">
                          <Input 
                            value={minDate}
                            onChange={(e) => setMinDate(e.target.value)}
                            type="date"
                            max={maxInputDate}

                          />
                          {minDate && (
                              <Button className="erase-button" onClick={() => setMinDate('')}>x</Button>
                          )}
                        </div>
                      </div>
                      <div className={`input date`}>
                        <label>Até:</label>
                        <div className="select">
                          <Input 
                            value={maxDate}
                            onChange={(e) => setMaxDate(e.target.value)}
                            type="date"
                            min={minDate}
                            max={maxInputDate}
                          
                          />
                          {maxDate && (
                                <Button className="erase-button" onClick={() => setMaxDate('')}>x</Button>
                          )}
                        </div>
                      </div> 
                      
                      {adminRoot ? (
                        <div className={`input select`}>
                          <label>Empresa:</label>
                          <div className="select">
                            <Select className="select-options" style={{ width: 400 }}
                              placeholder="Todos"
                              value={companyValue}
                              options={companyOptions}
                              onChange={(option) => handleChangeOptionCompany(option as iOption)}
                            />
                            {companyValue && (
                                <Button className="erase-button" onClick={() => setCompanyValue(null)}>x</Button>
                            )}        
                          </div>
                        </div>
                      ):(
                        <></>
                      )}
              </div>

              <button id="btSave" onClick={() => { handleSearch() }} type="submit">
                Pesquisar
              </button>
            </div>                  
        </div>
        
        <div className="logList" style={{ height:600 }}>
        {(role === 'Administrador' || role === 'Transportadora' || role === 'Usuario') && (
              <>
                <div className="data-container">
                  <div className="data-section mini">
                    <div className="header-title">
                      <div className="time-icon"></div>
                      <div className="title"> <MdTimelapse /> Médias de Tempo</div>
                    </div>
                    <div className="statistics">
                        <div className="block">
                    
                            <div className="name">Fila</div>
                            <div className="data">
                   
                              {queueStatistics?.awaitingLoadAvg?.slice(0,2) === '00' ? (
                                <>
                                  {queueStatistics?.awaitingLoadAvg?.slice(3,5)}<sup>m</sup>
                                </>
                              ):(
                                <>
                                  {queueStatistics?.awaitingLoadAvg?.slice(0,2)}
                                  <sup>h {Math.floor(Number(queueStatistics?.awaitingLoadAvg?.slice(3,5)))}m</sup>
                                  
                                </>
                              )}
                            </div>
                
                        </div>
                        <div className="block green">
                    
                            <div className="name ">Atendimento</div>
                            <div className="data">
                              {queueStatistics?.loadingTimeAvg?.slice(0,2) === '00' ? (
                                <>
                                  {queueStatistics?.loadingTimeAvg?.slice(3,5)}<sup>m</sup>
                                </>
                              ):(
                                <>
                                  {queueStatistics?.loadingTimeAvg?.slice(0,2)}
                                  <sup>h {Math.floor(Number(queueStatistics?.loadingTimeAvg?.slice(3,5)))}m</sup>
                                 
                                </>
                              )}
                            </div>
                        </div>
                        <div className="block blue">
                    
                            <div className="name ">Total</div>
                            <div className="data">
                   
                              {queueStatistics?.queueFullFlowTimeAvg?.slice(0,2) === '00' ? (
                                <>
                                  {queueStatistics?.queueFullFlowTimeAvg?.slice(3,5)}<sup>m</sup>
                                </>
                              ):(
                                <>
                                 {queueStatistics?.queueFullFlowTimeAvg?.slice(0,2)}
                                    <sup>h {Math.floor(Number(queueStatistics?.queueFullFlowTimeAvg?.slice(3,5)))}m</sup>
                                  
                                </>
                              )}
                            </div>
                
                        </div>
                    </div>
                  </div>
                  
                  <div className="data-section mini">
                    <div className="header-title">
                      <div className="time-icon"></div>
                      <div className="title"> <FaTruck />  Médias de Veículos/Dia</div>
                    </div>
                    <div className="statistics">
                        <div className="block green">
                    
                            <div className="name ">Atendidos</div>
                            <div className="data">
                              {queueStatistics?.attendedQueuesAvg}
                            </div>
                
                        </div>
                        <div className="block red">
                    
                            <div className="name ">Faltosos</div>
                            <div className="data">
                              {queueStatistics?.missingQueuesAvg}
                            </div>
                
                        </div>
                        <div className="block">
                    
                            <div className="name">Fila</div>
                            <div className="data">
                              {Number(queueStatistics?.missingQueuesAvg) + Number(queueStatistics?.attendedQueuesAvg)}
                            </div>
                
                        </div>

                    </div>
                  </div>
                </div>
                
                <div className="data-container base">
                  <div>
                    <div className="data-section">
                      <div className="header-title">
                        <div className="time-icon"></div>
                        <div className="title"><MdTimelapse /> Hora</div>
                      </div>
                      <div className="statistics">
                          <div className="block">
                      
                              <div className="name">Maior Entrada na Fila</div>
                              <div className="data">
                                {queueStatistics?.moreEntryQueueHour.slice(0,2)}<sup>h</sup>
                              </div>
                  
                          </div>
                          <div className="block green">
                      
                              <div className="name">Mais Atendimentos</div>
                              <div className="data">
                                {queueStatistics?.moreAttendanceQueueHour.slice(0,2)}<sup>h</sup>
                              </div>
                  
                          </div>
                          <div className="block">
                      
                              <div className="name">Menor Entrada na Fila</div>
                              <div className="data">
                                {queueStatistics?.lessEntryQueueHour.slice(0,2)}<sup>h</sup>
                              </div>
                  
                          </div>
                          <div className="block green">
                      
                              <div className="name">Menos Atendimentos</div>
                              <div className="data">
                              { queueStatistics?.lessAttendanceQueueHour.slice(0,2)}<sup>h</sup>
                              </div>
                  
                          </div>
                      </div>
                    </div>

                    <div className="data-section">
                      <div className="header-title">
                        <div className="time-icon"></div>
                        <div className="title"><BsCalendarFill /> Dias da Semana</div>
                      </div>
                      <div className="statistics">
                          <div className="block ">
                      
                              <div className="name">Maior Entrada na Fila</div>
                              <div className="data day">
                                {queueStatistics?.moreEntryQueueDay}
                              </div>
                  
                          </div>
                          <div className="block green">
                      
                              <div className="name">Mais Atendimentos</div>
                              <div className="data day">
                                {queueStatistics?.moreAttendanceQueueDay}
                              </div>
                  
                          </div>
                          <div className="block">
                      
                              <div className="name">Menor Entrada na Fila</div>
                              <div className="data day">
                                {queueStatistics?.lessEntryQueueDay}
                              </div>
                  
                          </div>
                          <div className="block green">
                      
                              <div className="name">Menos Atendimentos</div>
                              <div className="data day">
                                {queueStatistics?.lessAttendanceQueueDay}
                              </div>
                  
                          </div>
                        
                      </div>
                    </div>
                  </div>

                  <div>

                    <div>
                      <div className="pie-title"> <MdTimelapse /> Marcações</div>
                      <Doughnut width={400}
                        data={appointments}
                        options={{
                          tooltip: {
                            callbacks: {
                              title: function () {
                                return "my tittle";
                              }
                            }
                          }, 
                          legend: {
                            display: false,
                            position: 'right'
                          },
                          title: {
                            display: false,
                            text: 'Pedidos',
                            fontSize: 20,
                          },
                          tooltips: {
                            callbacks: {
                              label: function(tooltipItem: { datasetIndex: string | number; index: string | number; }, data: { datasets: { [x: string]: any; };  labels:any}, ) {
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                let total = meta.total;
                                let currentValue = dataset.data[tooltipItem.index];
                                let percentage = parseFloat((currentValue/total*100).toFixed(1));
                      
                                let dataLabel = [`${percentage}% ${data.labels[tooltipItem.index]}`, `(${data.datasets[0].data[tooltipItem.index]} Veículos)`]
                                return  dataLabel;
                              },
                      
                            }
                          }}}
                      />
                    </div>
                    
                    <div>
                      <div className="pie-title"> <MdTimelapse /> Pontualidade</div>
                      <Doughnut  width={400} height={150}
                        data={punctuality}
                        options={{
                          showAllTooltips: true,
                          legend: {
                            display: false,
                            position: 'right'
                          },
                          title: {
                            display: false,
                            text: 'Pedidos',
                            fontSize: 20,
                          },
                          tooltips: {
                            callbacks: {
                              label: function(tooltipItem: { datasetIndex: string | number; index: string | number; }, data: { datasets: { [x: string]: any; };  labels:any}, ) {
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                let total = meta.total;
                                let currentValue = dataset.data[tooltipItem.index];
                                let percentage = parseFloat((currentValue/total*100).toFixed(1));
                      
                                let dataLabel = [`${percentage}% ${data.labels[tooltipItem.index]}`, `(${data.datasets[0].data[tooltipItem.index]} Veículos)`]
                                return  dataLabel;
                              },
                      
                            }
                          }}}
                      />
                    </div>
                  </div>                
                </div>
              </>
            )}
        </div>
      </div>
  );
}

export default Logs;