import React, { Fragment } from 'react'
import { QueueByDay } from '../../../../../../domain'
import './styles.scss'
interface FilterTableByDaySidebarProps {
  dates: string[]
  returnRows: (indexRow: number) => QueueByDay[]
}

interface ScrollIntoViewOptionsType {
  behavior: 'auto' | 'smooth'
  block: 'start' | 'center' | 'end' | 'nearest'
  inline: 'start' | 'center' | 'end' | 'nearest'
}

export function FilterTableByDaySidebar({
  dates,
  returnRows
}: FilterTableByDaySidebarProps) {
  const scrollIntoViewOptions: ScrollIntoViewOptionsType = {
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  }

  function scrollToTop() {
    const element = document.getElementById('top')

    if (element) {
      element.scrollIntoView(scrollIntoViewOptions)
    }
  }

  function scrollToTable(tableIndex: number) {
    const table = document.getElementById(`table${tableIndex}`)

    if (table) {
      table.scrollIntoView(scrollIntoViewOptions)
    }
  }

  return (
    <div className='sidebarContainer'>
      <div className='days-list'>
        <button onClick={scrollToTop}>Top</button>

        {dates.map((_, index) => {
          const rows = returnRows(index)

          if (rows?.length > 0) {
            const date: string = dates[index]
            const dateArray: string[] = date.split('/')

            const month = dateArray[0]
            const day = dateArray[1]

            return (
              <Fragment key={date}>
                <button onClick={() => scrollToTable(index)}>
                  <span>{month + '/'}</span>
                  <sup>{day}</sup>
                </button>
              </Fragment>
            )
          }

          return null
        })}
      </div>
    </div>
  )
}
