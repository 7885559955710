import React from "react";
import { Button } from "@material-ui/core";
import { GrDocumentCsv } from "react-icons/gr";
import { CSVLink } from "react-csv";

interface ExportCSVLinkButtonProps {
  rows: any[];
  tolerance: string;
}

export function ExportCSVLinkButton({
  rows,
  tolerance
}: ExportCSVLinkButtonProps) {
  const rowsFormatted = rows.flat();

  const csv = rowsFormatted.map(row => {
    let toleranceHours = Number(tolerance.slice(0, 2)) * 60 * 60 * 1000;
    let toleranceMinutes = Number(tolerance.slice(3, 5)) * 60 * 1000;

    const toleranceTimeMilleseconds = new Date(row?.toleranceTime).getTime();

    const toleranceTime = new Date(
      toleranceTimeMilleseconds + (toleranceHours + toleranceMinutes)
    );

    const obj = {
      id: row?.id || "",
      position: row?.position || "",
      licensePlate: row?.licensePlate || "",
      random: "",
      attendanceTime: new Date(row?.time).toLocaleTimeString("pt-Br", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      }),

      toleranceTime: toleranceTime.toLocaleTimeString("pt-Br", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      })
    };

    return obj;
  });

  return (
    <Button>
      <CSVLink data={csv}>
        <GrDocumentCsv />
      </CSVLink>
    </Button>
  );
}
