import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
  ptBR
} from '@mui/x-data-grid'

import api from '../../../services/api'
import LogDetails from '../../../components/LogDetails'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import './style.css'
import Input from '../../../components/Input'
import { toast } from 'react-toastify'
import load from '../../../assets/images/carregando.svg'
import { useHistory, useParams } from 'react-router-dom'
import { format, startOfDay, sub } from 'date-fns'
import dateFNSPTBr from 'date-fns/locale/pt-BR'
import { LogOperationTypes } from '../../../domain'

interface Logs {
  id: number
  title: string
  details: any
  company: string
}

interface LogMessage {
  operation: string
  created_at: Date
  content?: string
  contentPosition?: 'center' | 'end'
}

interface RouteParams {
  id: string
}

const typeLogMessageMap: Record<LogOperationTypes, string> = {
  LOGIN: 'Logou no sistema',
  IN_QUEUE: 'Entrou na Fila',
  OUT_QUEUE: 'Saiu da Fila',
  ATT_QUEUE: 'Atendido pela expedição',
  FAU_QUEUE: 'Faltoso pela expedição',
  TOL_QUEUE: 'Faltoso por tolerância',
  REG_DRIVER: 'Resgistro Motorista',
  CAD_DRIVER: 'Cadastro Motorista',
  CAD_TRUCK: 'Cadastrou Caminhão',
  EDIT_TRUCK: 'Editou Caminhão',
  BIND_TRUNCK: 'Vinculou Caminhão',
  UNBIND_TRUCK: 'Desvinculou Caminhão',
  IN_ORDER: 'Adicionado no Pedido',
  OUT_COMPANY: 'Mudou de empresa',
  DEL_ADM: 'Excluiu do Sistema /Admin',
  DEL_APP: 'Excluiu do Sistema /App',
  READ_POPUP: 'Visualizou Popup',
  CHECK_IN: 'fez check-in',
  CHECK_OUT: 'cancelou check-in'
}

const Logs: React.FC = () => {
  const { id: driverId } = useParams<RouteParams>()

  const date7DaysAgo = sub(new Date(), { days: 7 })

  const [maxDate, setMaxDate] = useState(() => {
    const today = new Date()
    const maxDate = format(today, 'yyyy-MM-dd')
    return maxDate
  })

  const [minDate, setMinDate] = useState(() => {
    const minDate = format(startOfDay(date7DaysAgo), 'yyyy-MM-dd')
    return minDate
  })

  const [log, setLog] = useState<any>({})
  const [rows, setRows] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [driver, setDriver] = useState<any>({})

  const history = useHistory()

  useEffect(() => {
    api.get(`/driver/${driverId}`).then(response => {
      setDriver(response.data)
    })
  }, [driverId])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 75 },
    {
      field: 'title',
      headerName: 'Título',
      width: 800,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: params.row.title }}></div>
        )
      }
    },
    { field: 'company', headerName: 'Empresa', width: 200 },
    {
      field: 'details',
      headerName: 'Detalhes',
      width: 180,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div>
            {params.row.details?.length > 0
              ? 'Ver detalhes'
              : 'Não há detalhes'}
          </div>
        )
      }
    }
  ]

  function checkType(key: LogOperationTypes) {
    return typeLogMessageMap[key] || 'Logou no sistema'
  }

  function handleClose() {
    setLog('')
  }

  function handleSelectScheduling(x: any) {
    setLog(x.row)
  }

  let startDate = new Date(minDate)
  let finalDate = new Date(maxDate)
  startDate.setDate(startDate.getDate() - 1)
  finalDate.setDate(finalDate.getDate() + 1)

  function handleLogMessage({
    operation,
    created_at,
    content,
    contentPosition = 'end'
  }: LogMessage) {
    let message = ''

    const logDate = format(new Date(created_at), "dd/MM/yyyy 'às' HH:mm", {
      locale: dateFNSPTBr
    })

    if (content) {
      if (contentPosition === 'center') {
        message = `Motorista ${operation} ${content} ${logDate}`
      } else {
        message = `Motorista ${operation} ${logDate} ${content}`
      }
    } else {
      message = `Motorista ${operation} ${logDate}`
    }

    return message
  }

  async function getLogs() {
    try {
      setLoading(true)

      const searchObject = {
        startDate: minDate,
        endDate: maxDate
      }

      const response = await api.get(`/log-driver/${driverId}`, {
        params: searchObject
      })

      const formattedRows = response.data.map((item: any, index: number) => {
        item.id = index

        const keys = Object.keys(item.data)
        const values = Object.values(item.data) as unknown as any

        const operationType = item.operation_type as LogOperationTypes
        const operation = checkType(operationType)

        const date = new Date(item.createdAt)
        const details = []

        let title = handleLogMessage({
          created_at: date,
          operation
        })

        if (item.operation_type === 'IN_QUEUE') {
          const licensePlate = item.data?.truck?.licensePlate
          const existsLicensePlate = licensePlate

          const content = existsLicensePlate
            ? `com a Placa ${licensePlate}`
            : `como Autônomo`

          title = handleLogMessage({
            created_at: date,
            operation,
            content
          })
        }

        if (operationType === 'IN_ORDER') {
          const code = String(item.data?.scheduling?.order?.code)

          title = handleLogMessage({
            created_at: date,
            operation,
            content: code,
            contentPosition: 'center'
          })
        }

        if (operationType === 'OUT_COMPANY') {
          const content = `Para ${item.data?.companyTo?.company}`

          title = handleLogMessage({
            created_at: date,
            operation,
            content
          })
        }

        if (operationType === 'CAD_TRUCK') {
          const content = `Caminhão Placa ${item.data?.truck?.licensePlate}`

          title = handleLogMessage({
            created_at: date,
            operation,
            content
          })
        }

        if (operationType === 'EDIT_TRUCK') {
          const content = `Caminhão Placa ${item.data?.truck?.licensePlate}`

          title = handleLogMessage({
            created_at: date,
            operation,
            content
          })
        }

        if (operationType === 'UNBIND_TRUCK') {
          const content = `Caminhão Placa ${item.data?.truck?.licensePlate}`

          title = handleLogMessage({
            created_at: date,
            operation,
            content
          })
        }

        if (operationType === 'BIND_TRUNCK') {
          const content = `Caminhão Placa ${item.data?.truck?.licensePlate}`

          title = handleLogMessage({
            created_at: date,
            operation,
            content
          })
        }

        if (operationType === 'BIND_TRUNCK') {
          const content = `Caminhão Placa ${item.data?.truck?.licensePlate}`

          title = handleLogMessage({
            created_at: date,
            operation,
            content
          })
        }

        if (operationType === 'READ_POPUP') {
          const popupData = item.data.popup

          const popupTitle = popupData.title
          const popupLabel = popupData.label

          const popupTitleIsEmpty = popupTitle === ''
          const content = !popupTitleIsEmpty ? popupTitle : popupLabel

          title = handleLogMessage({
            operation,
            created_at: date,
            content,
            contentPosition: 'center'
          })
        }

        if (operationType === 'CHECK_IN') {
          title = handleLogMessage({
            operation,
            content: 'em',
            contentPosition: 'center',
            created_at: date
          })
        }

        if (operationType === 'CHECK_OUT') {
          title = handleLogMessage({
            operation,
            content: 'em',
            contentPosition: 'center',
            created_at: date
          })
        }

        for (let index = 0; index < keys.length; index++) {
          details.push({ key: keys[index], value: values[index] })
        }

        const companyId = item.data?.company?.id

        const data = {
          id: index + 1,
          title: title,
          company: companyId,
          details: details,
          created_at: date.toLocaleDateString()
        }

        return data
      })

      setRows(formattedRows)

      toast.success('Consulta finalizada!')
    } catch (error) {
      console.group('error get logs driver')
      console.log({
        error
      })

      toast.error('Erro ao consultar!')
    } finally {
      setLoading(false)
    }
  }

  function ReturnSuspendWarning() {
    return (
      <>
        <Box className='modal load'>
          <Modal
            open={loading}
            onClose={() => setLoading(true)}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            className='modal-instance'
          >
            <Box className=' suspend-loading body'>
              <Box className='container'>
                <Box>
                  <Typography
                    id='modal-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    <div className='loading'>
                      <img src={load} alt='load' />
                    </div>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      </>
    )
  }

  useEffect(() => {
    getLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`historic-list productList ${loading ? 'blur-1' : ''}`}>
      <ReturnSuspendWarning />

      <header>
        <div>
          <strong>Logs Motorista - </strong>
          {driver?.name}
        </div>
      </header>

      <div className='history-area log search'>
        <div className='infor-container'>
          <div className='info-content'>
            <div className='row'>
              <div className={`input date`}>
                <label>De:</label>
                <div className='select'>
                  <Input
                    value={minDate}
                    onChange={e => setMinDate(e.target.value)}
                    type='date'
                  />
                  {minDate && (
                    <Button
                      className='erase-button'
                      onClick={() => setMinDate('')}
                    >
                      x
                    </Button>
                  )}
                </div>
              </div>
              <div className={`input date`}>
                <label>Até:</label>
                <div className='select'>
                  <Input
                    value={maxDate}
                    onChange={e => setMaxDate(e.target.value)}
                    type='date'
                    min={minDate}
                  />
                  {maxDate && (
                    <Button
                      className='erase-button'
                      onClick={() => setMaxDate('')}
                    >
                      x
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <button className='button-back' onClick={history.goBack}>
              Voltar
            </button>
          </div>

          <button id='btSave' onClick={getLogs} type='submit'>
            {loading ? 'Pesquisando...' : 'Pesquisar'}
          </button>
        </div>
      </div>

      {log?.id > -1 ? (
        <div className='log-details'>
          <LogDetails id={log?.id} info={log} handleClose={handleClose} />
        </div>
      ) : (
        <></>
      )}

      <div className='logList' style={{ height: 658 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableColumnMenu
          components={{ Toolbar: GridToolbar }}
          rowsPerPageOptions={[8]}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          onRowClick={(e: any) => handleSelectScheduling(e)}
        />
      </div>
    </div>
  )
}

export default Logs
