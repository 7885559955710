import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import Input from '../Input'

import './styles.css'

interface Modal {
  type: string
  id: number
  className: string
  cpf: string
  birthDate: string
  handleClose(): void
  handleDeleteAction(id: number, cpf: string, birthDate: string): void
}

const ModalDelete: React.FC<Modal> = ({
  type,
  cpf,
  birthDate,
  id,
  className,
  handleClose,
  handleDeleteAction
}) => {
  const [cpfModal, setCpfModal] = useState<string>('')
  const [birthDateModal, setBirthDateModal] = useState<string>('')

  const buttonDisabled = cpfModal !== cpf || birthDateModal !== birthDate

  function handleAction() {
    handleDeleteAction(id, cpfModal, birthDateModal)
  }

  return (
    <div id='myModal' className={` ${className} modal`}>
      <div className='modal-content'>
        <div className='site-content cf'>
          <div className='boxed-group dangerzone'>
            <h3>
              {type !== 'Agendamento' ? `Excluir ${type}` : `Voltar para fila`}
              <span className='close' onClick={handleClose}>
                &times;
              </span>
            </h3>

            <div className='boxed-group-inner'>
              <section className='delete-user-form'>
                <>
                  <h4>
                    Atenção, todos seus dados e histórico de uso serão excluídos
                    da plataforma, deseja continuar?
                  </h4>
                </>

                <p> Preencha seu cpf e aniversário e acione o continuar. </p>

                <Input
                  name='cpf'
                  mask='###.###.###-##'
                  placeholder='Cpf'
                  type='text'
                  onChange={e => {
                    setCpfModal(e.target.value)
                  }}
                  label='Cpf'
                  maxLength={11}
                />

                <Input
                  name='birthDate'
                  placeholder='Data de nascimento'
                  type='date'
                  onChange={e => {
                    setBirthDateModal(e.target.value)
                  }}
                  label='Data de nascimento'
                  maxLength={11}
                />

                <Button
                  type='button'
                  className='btn btn-danger boxed-action'
                  id='delete-account'
                  disabled={buttonDisabled}
                  onClick={handleAction}
                >
                  Excluir
                </Button>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalDelete
