import React from 'react'
import { useEffect, useState } from 'react'
import { Close, Refresh, Search } from '@material-ui/icons'
import { FiAlertTriangle } from 'react-icons/fi'

import { useAuth } from '../../contexts/auth'
import api from '../../services/api'

import { handleMaskQtyTransported } from '../../utils/mask-qty-transported'
import { ServiceForecastDriver } from '../ServiceForecastDriver'
import { LicensePlate } from '../LicensePlate'

import {
  Modal as ModalComponent,
  ModalHeading,
  ModalTitle,
  ModalWrapper,
  ModalProps,
  ModalBox
} from '../Modal'

import './styles.scss'
import { toast } from 'react-toastify'

interface Order {
  id: number
  code: string
}

interface ModalVehicleMissingProps extends ModalProps {
  className?: string
  onCloseModal: () => void
  isExpeditionViewer: boolean
  isConciergeViewer?: boolean
}

interface VehiclesMissingData {
  id: number
  attended_at: string

  driver: {
    id: number
    name: string
  }

  scheduling: {
    id: number
    qtyTransported: number
  }

  truck: {
    licensePlate: string
  }
}

export function ModalVehicleMissing({
  open,
  className,
  onCloseModal,
  isExpeditionViewer,
  isConciergeViewer
}: ModalVehicleMissingProps) {
  const { company } = useAuth()
  const [vehiclesMissing, setVehiclesMissing] = useState(
    [] as VehiclesMissingData[]
  )

  const [vehiclesMissingFiltered, setVehiclesMissingFiltered] = useState(
    [] as VehiclesMissingData[]
  )

  const [isRecoveringDriver, setIsRecoveringDriver] = useState(false)
  const [isCreatingSolicitation, setIsCreatingSolicitation] = useState(false)

  const [whichDriverIsRecovering, setWhichDriverIsRecovering] = useState<
    number | null
  >(null)

  const companyId = company || localStorage.getItem('@App:companyId')

  const [searchByLicensePlate, setSearchByLicensePlate] = useState('')

  const [ordersCompany, setOrdersCompany] = useState<Order[]>([])
  const [orderId, setOrderId] = useState<string | null>('')

  const [newQtyTransported, setNewQtyTransported] = useState('')

  function handleEnableRecoveringDriver(driverId: number) {
    setIsRecoveringDriver(true)
    setWhichDriverIsRecovering(driverId)
  }

  function handleDisableRecoveringDriver() {
    setIsRecoveringDriver(false)
    setWhichDriverIsRecovering(null)
  }

  function closeModal() {
    onCloseModal()
    handleDisableRecoveringDriver()
    setSearchByLicensePlate('')
    setNewQtyTransported('')
    setOrderId('')
  }

  function updateQtyTransported(event: React.ChangeEvent<HTMLInputElement>) {
    const { qtyTransported } = handleMaskQtyTransported(event)
    setNewQtyTransported(qtyTransported)
  }

  async function handleCreateSolicitation(queueId: number) {
    if (!queueId || !orderId || !newQtyTransported) {
      return
    }

    setIsCreatingSolicitation(true)

    const formatQtyTransported = newQtyTransported.replace(/,/g, '.')

    await api.post('/queue-restore/save', {
      orderId: Number(orderId),
      queueId,
      qtyTransported: Number(formatQtyTransported),
      company: {
        id: companyId
      }
    })

    setIsCreatingSolicitation(false)
    closeModal()
    toast.success('Solicitação enviada')
  }

  const newQtyTransportedIsZero =
    Number(newQtyTransported.replace(/,/g, '')) === 0

  const isDisableButtonRecovering =
    isCreatingSolicitation || newQtyTransportedIsZero || !orderId

  useEffect(() => {
    async function getVehicleByLicensePlate() {
      if (searchByLicensePlate.length > 0) {
        const formattedLicensePlate = searchByLicensePlate.toUpperCase()

        const response = await api.get(
          `/queue/missings/${companyId}?licensePlate=${formattedLicensePlate}`
        )

        const data = response.data

        setVehiclesMissingFiltered(data)
      }
    }

    getVehicleByLicensePlate()
  }, [companyId, searchByLicensePlate])

  useEffect(() => {
    async function getVehiclesMissing() {
      const response = await api.get(`/queue/missings/${companyId}`)

      const data = response.data

      setVehiclesMissing(data)
    }

    getVehiclesMissing()
  }, [companyId, open])

  useEffect(() => {
    async function getOrdersCompany() {
      const response = await api.get<Order[]>('/order', {
        params: {
          company: companyId
        }
      })

      const orders = response.data

      setOrdersCompany(orders)
    }

    getOrdersCompany()
  }, [companyId, open])

  return (
    <ModalComponent open={open} onOpenChange={closeModal}>
      <ModalWrapper>
        <ModalHeading className={className}>
          <ModalTitle>
            Veículos <span>Faltosos</span>
          </ModalTitle>
        </ModalHeading>

        <ModalBox>
          <form>
            <input
              type='text'
              placeholder='Buscar placa'
              value={searchByLicensePlate}
              onChange={e => setSearchByLicensePlate(e.target.value)}
            />

            <div className='icon-search'>
              <Search fontSize='large' />
            </div>
          </form>

          <section className='modal__box-section'>
            <div className='modal__vehicles__missing__list'>
              {searchByLicensePlate.length > 0 ? (
                <>
                  {vehiclesMissingFiltered.map(vehicleMissingItem => {
                    const today = new Date()
                    const parseAttendedAt = new Date(
                      vehicleMissingItem.attended_at
                    )

                    return (
                      <div
                        className='modal__box-section__item'
                        key={vehicleMissingItem.id}
                      >
                        <div className='box__container'>
                          <div className='box__left'>
                            <ServiceForecastDriver
                              modeDefault
                              serviceForecastDate={
                                vehicleMissingItem.attended_at
                              }
                            />

                            <LicensePlate
                              themeColor='#666666'
                              licensePlate={
                                vehicleMissingItem?.truck?.licensePlate
                              }
                            />
                          </div>

                          <div className='box__right'>
                            <div className='modal__box-section__driver'>
                              <span
                                className='driver__name'
                                title={vehicleMissingItem?.driver?.name}
                              >
                                {vehicleMissingItem?.driver?.name}
                              </span>
                            </div>

                            {!isExpeditionViewer &&
                            !isConciergeViewer &&
                            isRecoveringDriver &&
                            whichDriverIsRecovering ===
                              vehicleMissingItem.driver.id ? (
                              <button
                                className='modal__box-section__button close'
                                type='button'
                                onClick={handleDisableRecoveringDriver}
                              >
                                <Close />
                              </button>
                            ) : (
                              <>
                                {parseAttendedAt.getDate() ===
                                  today.getDate() && (
                                  <button
                                    className='modal__box-section__button recovering'
                                    type='button'
                                    onClick={() =>
                                      handleEnableRecoveringDriver(
                                        vehicleMissingItem.driver.id
                                      )
                                    }
                                  >
                                    <Refresh />
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {!isExpeditionViewer &&
                          !isConciergeViewer &&
                          isRecoveringDriver &&
                          whichDriverIsRecovering ===
                            vehicleMissingItem.driver.id && (
                            <div className='modal__recovering-driver'>
                              <div className='modal__recovering-driver__group'>
                                <label htmlFor='tons'>Peso Líquido</label>
                                <input
                                  type='text'
                                  id='tons'
                                  className='modal__input-tons'
                                  placeholder='00,000'
                                  onChange={e => updateQtyTransported(e)}
                                  value={newQtyTransported}
                                />
                              </div>

                              <div className='modal__recovering-driver__group'>
                                <label htmlFor='request'>Pedido</label>

                                <select
                                  id='request'
                                  onChange={e => setOrderId(e.target.value)}
                                >
                                  <option>Selecione pedido</option>
                                  {ordersCompany.map(order => (
                                    <option key={order.id} value={order.id}>
                                      {order.code}
                                    </option>
                                  ))}
                                </select>

                                <button
                                  disabled={isDisableButtonRecovering}
                                  className='modal__button-submit'
                                  onClick={() =>
                                    handleCreateSolicitation(
                                      vehicleMissingItem.id
                                    )
                                  }
                                >
                                  Regastar
                                </button>
                              </div>
                            </div>
                          )}

                        {!isExpeditionViewer &&
                          !isConciergeViewer &&
                          isRecoveringDriver &&
                          whichDriverIsRecovering ===
                            vehicleMissingItem.driver.id && (
                            <div className='modal__recovering-driver__alert'>
                              <div className='alert__container'>
                                <div className='alert__content'>
                                  <div>
                                    <FiAlertTriangle size={24} fill='#FFDF6C' />

                                    <span>
                                      Atenção, o resgate será confirmado pelo
                                      <strong> Supervisor do Sistema</strong>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    )
                  })}
                </>
              ) : (
                <>
                  {vehiclesMissing.map(vehicleMissingItem => {
                    const today = new Date()
                    const parseAttendedAt = new Date(
                      vehicleMissingItem.attended_at
                    )

                    return (
                      <div
                        className='modal__box-section__item'
                        key={vehicleMissingItem.id}
                      >
                        <div className='box__container'>
                          <div className='box__left'>
                            <ServiceForecastDriver
                              modeDefault
                              serviceForecastDate={
                                vehicleMissingItem.attended_at
                              }
                            />

                            <LicensePlate
                              themeColor='#666666'
                              licensePlate={
                                vehicleMissingItem?.truck?.licensePlate
                              }
                            />
                          </div>

                          <div className='box__right'>
                            <div className='modal__box-section__driver'>
                              <span
                                className='driver__name'
                                title={vehicleMissingItem?.driver?.name}
                              >
                                {vehicleMissingItem?.driver?.name}
                              </span>
                            </div>

                            {!isExpeditionViewer &&
                            !isConciergeViewer &&
                            isRecoveringDriver &&
                            whichDriverIsRecovering ===
                              vehicleMissingItem.driver.id ? (
                              <button
                                className='modal__box-section__button close'
                                type='button'
                                onClick={handleDisableRecoveringDriver}
                              >
                                <Close />
                              </button>
                            ) : (
                              <>
                                {!isExpeditionViewer &&
                                  !isConciergeViewer &&
                                  parseAttendedAt.getDate() ===
                                    today.getDate() && (
                                    <button
                                      className='modal__box-section__button recovering'
                                      type='button'
                                      onClick={() =>
                                        handleEnableRecoveringDriver(
                                          vehicleMissingItem.driver.id
                                        )
                                      }
                                    >
                                      <Refresh />
                                    </button>
                                  )}
                              </>
                            )}
                          </div>
                        </div>

                        {!isExpeditionViewer &&
                          !isConciergeViewer &&
                          isRecoveringDriver &&
                          whichDriverIsRecovering ===
                            vehicleMissingItem.driver.id && (
                            <div className='modal__recovering-driver'>
                              <div className='modal__recovering-driver__group'>
                                <label htmlFor='tons'>Peso Líquido</label>
                                <input
                                  type='text'
                                  id='tons'
                                  className='modal__input-tons'
                                  placeholder='00,000'
                                  onChange={e => updateQtyTransported(e)}
                                  value={newQtyTransported}
                                />
                              </div>

                              <div className='modal__recovering-driver__group'>
                                <label htmlFor='order'>Pedido</label>

                                <select
                                  id='order'
                                  onChange={e => setOrderId(e.target.value)}
                                >
                                  <option>Selecione pedido</option>
                                  {ordersCompany.map(order => (
                                    <option key={order.id} value={order.id}>
                                      {order.code}
                                    </option>
                                  ))}
                                </select>

                                <button
                                  disabled={isDisableButtonRecovering}
                                  className='modal__button-submit'
                                  onClick={() =>
                                    handleCreateSolicitation(
                                      vehicleMissingItem.id
                                    )
                                  }
                                >
                                  Regastar
                                </button>
                              </div>
                            </div>
                          )}

                        {!isExpeditionViewer &&
                          !isConciergeViewer &&
                          isRecoveringDriver &&
                          whichDriverIsRecovering ===
                            vehicleMissingItem.driver.id && (
                            <div className='modal__recovering-driver__alert'>
                              <div className='alert__container'>
                                <div className='alert__content'>
                                  <div>
                                    <FiAlertTriangle size={24} fill='#FFDF6C' />

                                    <span>
                                      Atenção, o resgate será confirmado pelo
                                      <strong> Supervisor do Sistema</strong>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    )
                  })}
                </>
              )}
            </div>
          </section>
        </ModalBox>
      </ModalWrapper>
    </ModalComponent>
  )
}
