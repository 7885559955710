import React, { useEffect, useState } from "react";
import { FiSettings } from "react-icons/fi";
import { RiLogoutBoxLine } from "react-icons/ri";
import { useAuth } from "../../contexts/auth";

import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";

import empresaIcon from "../../assets/images/icons/factory.svg";
import pedidoIcon from "../../assets/images/icons/order.svg";
import clienteIcon from "../../assets/images/icons/costumer.svg";
import transportadoraIcon from "../../assets/images/icons/transportadora.svg";
import caminhaoIcon from "../../assets/images/icons/truck.svg";
import productIcon from "../../assets/images/icons/product.svg";
import userIcon from "../../assets/images/icons/user.svg";
import driverIcon from "../../assets/images/icons/Motorista.svg";
import logIcon from "../../assets/images/icons/historico.svg";

import "./style.css";

const Sidebar: React.FC = () => {
  const { isAdmin, isShipping, isRoleLoaded, isAdminRoot, role, Logout } =
    useAuth();

  const [shippingCompanyId, setShippingCompanyId] = useState("");
  const [admin, setAdmin] = useState<any>("");
  const [adminRoot, setAdminRoot] = useState<any>("");
  const [shipping, setShippingn] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  function handleLogout() {
    Logout();
  }

  isAdmin().then(result => {
    setAdmin(result);
  });

  isAdminRoot().then(result => {
    setAdminRoot(result);
  });

  isShipping().then(result => {
    setShippingn(result);
  });

  isRoleLoaded().then(result => {
    setIsLoaded(result);
  });

  useEffect(() => {
    if (role === "Transportadora") {
      const shippingCompanyId = localStorage.getItem("@App:shippingCompanyId")!;

      const encryptedId = CryptoJS.AES.encrypt(shippingCompanyId, "SecretCpf")
        .toString()
        .replaceAll("+", "xMl3Jk")
        .replaceAll("/", "Por21Ld")
        .replaceAll("=", "Ml32");
      setShippingCompanyId(encryptedId);
    }
  }, [role]);

  return (
    <aside id="sidebar" className="sidebar">
      <nav className="sidebar-nav">
        {isLoaded ? (
          <ul id="list">
            {admin && (
              <li>
                <Link to="/empresas">
                  <span className="icon">
                    {" "}
                    <img src={empresaIcon} alt="" />
                  </span>
                  <span className="label">Empresa</span>
                </Link>
              </li>
            )}




            {shipping && (
              <li>
                <Link to={`/expedicao/${shippingCompanyId}`}>
                  <span className="icon">
                    {" "}
                    <img src={caminhaoIcon} alt="" />
                  </span>
                  <span className="label">Fila</span>
                </Link>
              </li>
            )}

            {role !== "Transportadora" && (
              <li>
                <Link to="/pedidos">
                  <span className="icon">
                    <img src={pedidoIcon} style={{ width: "45px" }} alt="" />
                  </span>

                  <span className="label">Pedidos</span>
                </Link>
              </li>
            )}

            {!shipping && !admin && (
              <li>
                <Link to="/clientes">
                  <span className="icon">
                    {" "}
                    <img src={clienteIcon} alt="" />
                  </span>
                  <span className="label">Clientes</span>
                </Link>
              </li>
            )}

            {!shipping && !admin && (
              <li>
                <Link to="/transportadoras">
                  <span className="icon">
                    {" "}
                    <img src={transportadoraIcon} alt="" />
                  </span>
                  <span className="label">Transportadora</span>
                </Link>
              </li>
            )}

            {!admin && (
              <li>
                <Link to="/caminhoes">
                  <span className="icon">
                    {" "}
                    <img src={caminhaoIcon} alt="" />
                  </span>
                  <span className="label">Caminhões</span>
                </Link>
              </li>
            )}

            {!admin && (
              <li>
                <Link to="/motoristas">
                  <span className="icon">
                    <img src={driverIcon} alt="" />
                  </span>
                  <span className="label">Motoristas</span>
                </Link>
              </li>
            )}

            {!shipping && !admin && (
              <li>
                <Link to="/produtos">
                  <span className="icon">
                    {" "}
                    <img src={productIcon} alt="" />
                  </span>
                  <span className="label">Produtos</span>
                </Link>
              </li>
            )}

            {adminRoot && (
              <li>
                <Link to="/conjuntos">
                  <span className="icon">
                    <img src={caminhaoIcon} alt="" />
                  </span>
                  <span className="label">Conjuntos</span>
                </Link>
              </li>
            )}

            {admin && (
              <>
                <li>
                  <Link to="/usuarios">
                    <span className="icon">
                      {" "}
                      <img src={userIcon} alt="" />
                    </span>
                    <span className="label">Usuários</span>
                  </Link>
                </li>

                <li>
                  <Link to="/motoristas">
                    <span className="icon">
                      {" "}
                      <img src={driverIcon} alt="" />
                    </span>
                    <span className="label">Motoristas</span>
                  </Link>
                </li>
                <li>
                  <Link to="/caminhoes">
                    <span className="icon">
                      {" "}
                      <img src={caminhaoIcon} alt="" />
                    </span>
                    <span className="label">Caminhões</span>
                  </Link>
                </li>



                <li>
                  <Link to="/historico">
                    <span className="icon">
                      {" "}
                      <img src={caminhaoIcon} alt="" />
                    </span>
                    <span className="label">Histórico</span>
                  </Link>
                </li>

                <li>
                  <Link to="/marcas">
                    <span className="icon">
                      {" "}
                      <img src={caminhaoIcon} alt="" />
                    </span>
                    <span className="label">Marcas</span>
                  </Link>
                </li>

                <li>
                  <Link to="/modelos">
                    <span className="icon">
                      {" "}
                      <img src={caminhaoIcon} alt="" />
                    </span>
                    <span className="label">Modelos</span>
                  </Link>
                </li>
              </>
            )}

            {adminRoot && (
              <li>
                <Link to="/settings">
                  <span className="icon">
                    <FiSettings fontSize="x-large" />{" "}
                  </span>
                  <span className="">Configuração</span>
                </Link>
              </li>
            )}


            {adminRoot && (
              <li>
                <Link to="/recuperar">
                  <span className="icon">
                    <img src={driverIcon} alt="" />
                  </span>
                  <span className="">Recuperar</span>
                </Link>
              </li>
            )}


            {admin && (
              <li>
                <Link to="/logs">
                  <span className="icon">
                    <img src={logIcon} alt="" />
                  </span>
                  <span className="label">Logs</span>
                </Link>
              </li>
            )}

            <li onClick={handleLogout}>
              <Link to="#">
                <span className="icon">
                  {" "}
                  <RiLogoutBoxLine size={20} />
                </span>
                <span className="label">Logout</span>
              </Link>
            </li>
          </ul>
        ) : (
          <></>
        )}
      </nav>
    </aside>
  );
};

export default Sidebar;
