import React, { useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
import LayoutDefault from '../pages/_layouts/default'
import { AppDriverLayout } from '../pages/_layouts/App/AppDriverLayout/AppDriverLayout'

import { useAuth } from '../contexts/auth'
import Login from '../pages/Login'
import { DriverLogin } from '../pages/App/Driver/DriverLogin/DriverLogin'
import { ExpeditionLogin } from '../pages/App/Expedition/ExpeditionLogin/ExpeditionLogin'

import Landing from '../pages/Landing'
import PubLayout from '../pages/_layouts/pub'

interface RouterProps {
  path: string
  exact?: boolean
  ext?: any
  title?: string
  roles?: string[]
  params?: any
  computedMatch?: any
}
const RouterWrapper: React.FC<RouterProps> = ({
  path,
  title,
  ext = false,
  exact,
  children,
  roles,
  ...rest
}) => {
  const { signed, isRoleLoaded } = useAuth()
  const [isLoaded, setIsLoaded] = useState<any>()

  isRoleLoaded().then(result => {
    setIsLoaded(result)
  })

  const history = useHistory()
  const role = sessionStorage!.getItem('@App:userRole')!

  return (
    <Route
      {...rest}
      render={props => {
        if (window.location.pathname === '/login') {
          return <Login />
        }

        if (signed() && roles!.indexOf(role) > -1) {
          return (
            <LayoutDefault ext={ext} title={title || ''}>
              {isLoaded ? children : <Landing />}
            </LayoutDefault>
          )
        } else {
          setTimeout(() => {
            history.push('/login')
          }, 500)
        }
      }}
    />
  )
}

const RouterTermWrapper: React.FC<RouterProps> = ({
  path,
  title,
  ext = false,
  exact,
  children,
  roles,
  ...rest
}) => {
  const { signed } = useAuth()

  return (
    <Route
      {...rest}
      render={props =>
        signed() ? (
          <AppDriverLayout title={title || ''}>
            <div className='desktop'>{children}</div>
          </AppDriverLayout>
        ) : (
          <Login />
        )
      }
    />
  )
}

const RouterAppWrapper: React.FC<RouterProps> = ({
  path,
  title,
  exact,
  children,
  ...rest
}) => {
  const { driverSigned } = useAuth()

  const driverLogged = driverSigned()

  return (
    <Route
      {...rest}
      render={props =>
        driverLogged ? (
          <AppDriverLayout title={title || ''}>{children}</AppDriverLayout>
        ) : (
          <DriverLogin />
        )
      }
    />
  )
}

const RouterRegisterAppWrapper: React.FC<RouterProps> = ({
  path,
  title,
  exact,
  children,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <AppDriverLayout title={title || ''}>{children}</AppDriverLayout>
      )}
    />
  )
}

const RouterExpeditionWrapper: React.FC<RouterProps> = ({
  path,
  title,
  exact,
  children,
  roles,
  ...rest
}) => {
  const { signed } = useAuth()

  const role = sessionStorage!.getItem('@App:userRole')!
  let shippingCompanyId = 0

  if (role === 'Transportadora') {
    shippingCompanyId = Number(sessionStorage!.getItem('@App:id'))
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (signed() && (roles!.indexOf(role) > -1 || shippingCompanyId)) {
          return (
            <>
              {!shippingCompanyId ? (
                <AppDriverLayout title={title || ''}>
                  {children}
                </AppDriverLayout>
              ) : (
                <LayoutDefault ext={true} title={title || ''}>
                  <div className='app mobile'>{children}</div>
                </LayoutDefault>
              )}
            </>
          )
        } else {
          return <ExpeditionLogin />
        }
      }}
    />
  )
}
const RouterPubWrapper: React.FC<RouterProps> = ({
  path,
  title,
  exact,
  children,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => <PubLayout title={title || ''}>{children}</PubLayout>}
    />
  )
}

export {
  RouterWrapper,
  RouterTermWrapper,
  RouterAppWrapper,
  RouterExpeditionWrapper,
  RouterRegisterAppWrapper,
  RouterPubWrapper
}
