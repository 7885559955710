import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import api from '../../services/api'

import './styles.css'
import {
  GridCellParams,
  GridColDef,
  DataGrid,
  GridToolbar,
  ptBR
} from '@mui/x-data-grid'

import { toast } from 'react-toastify'
import { MdSearch } from 'react-icons/md'
import ModalDelete from '../../components/ModalDelete'

import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import Tooltip from '@material-ui/core/Tooltip'
import Select from 'react-select'
import Button from '@material-ui/core/Button'
import { Chip } from '@material-ui/core'

interface ShippingCompany {
  name: string
}
interface Company {
  id: number
  name: string
}

interface User {
  id: number
  name: string
  email: string
  isEnabled: boolean
  role: string
  company: Company
  shippingCompany: ShippingCompany
  isRoot: boolean
}

interface iOption {
  label: string
  value: string
}

const User: React.FC = () => {
  const [users, setUsers] = useState<User[]>([])
  const [usersFilter, setUsersFilter] = useState<User[]>([])
  const [modal, setModal] = useState('')
  const [name, setName] = useState('')
  const [id, setId] = useState(0)
  const [companies, setCompanies] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [companyValue, setCompanyValue] = useState<iOption>({
    value: '',
    label: ''
  })
  const [roles, setRoles] = useState<iOption[]>([{ value: '', label: '' }])
  const [roleValue, setRoleValue] = useState<iOption>({ value: '', label: '' })

  useEffect(() => {
    api.get(`/company`).then(response => {
      if (response.data) {
        setCompanies(
          response.data?.map((item: Company) => {
            return { label: `${item.id} - ${item.name}`, value: item.id }
          })
        )
      }
    })

    api.get('/users').then(response => {
      setUsers(response.data)
      setUsersFilter(response.data)
    })

    setRoles([
      { value: 'Usuario', label: 'Usuario' },
      { value: 'Administrador', label: 'Administrador' },
      { value: 'Expedicao', label: 'Expedicao' },
      { value: 'Transportadora', label: 'Transportadora' },
      { value: 'Espectador', label: 'Espectador' },
      { value: 'Portaria', label: 'Portaria' }
    ])
  }, [])

  // definição da tabela
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Code', width: 90 },
    {
      field: 'name',
      headerName: 'Nome',
      width: 250,
      filterable: true,
      disableColumnMenu: false
    },
    { field: 'email', headerName: 'e-Mail', width: 250 },
    { field: 'role', headerName: 'Papel usuário', width: 180 },
    { field: 'company', headerName: 'Empresa', width: 180 },
    { field: 'isRoot', headerName: 'Root', width: 100 },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {params.row.status ? (
              <Chip
                variant='outlined'
                style={{
                  height: '30px',
                  backgroundColor: '#FFF',
                  borderColor: 'green',
                  fontSize: '15px'
                }}
                label='Ativo'
              />
            ) : (
              <Chip
                variant='outlined'
                style={{
                  height: '30px',
                  backgroundColor: '#FFF',
                  borderColor: 'red',
                  fontSize: '15px'
                }}
                label='Inativo'
              />
            )}
          </>
        )
      }
    },
    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      width: 80,

      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Tooltip className='edit-icon' title={<h1>Editar</h1>}>
              <Link to={{ pathname: `/usuario/novo/${params.row.id}` }}>
                <AiOutlineEdit />{' '}
              </Link>
            </Tooltip>
            <Tooltip className='delete-icon' title={<h1>Deletar</h1>}>
              <Link to={{ pathname: `` }}>
                <AiOutlineDelete
                  onClick={() => {
                    handleCorfirmDelete(params.row.id, params.row.name)
                  }}
                />
              </Link>
            </Tooltip>
          </>
        )
      }
    }
  ]
  // add linhas a tabela
  const rows = users.map(item => {
    return {
      id: item.id,
      name: item.name,
      email: item.email,
      isRoot: item.isRoot ? 'Sim' : 'Não',
      role: item.role,
      status: item.isEnabled,
      company: item.company?.name || item.shippingCompany?.name
    }
  })

  function handleChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const search = event.target.value

    const results = usersFilter.filter(user =>
      user.name.toLowerCase().includes(search)
    )
    setUsers(results)
  }

  function handleCorfirmDelete(id: any, name: any) {
    showModal()
    setName(name)
    setId(parseInt(id))
  }

  function handleCloseModal() {
    hideModal()
  }

  const showModal = () => {
    setModal('show')
  }

  const hideModal = () => {
    setModal('')
  }

  function handleDeleteUser(id: number) {
    hideModal()

    api
      .delete(`/users/delete/${id}`)
      .then(response => {
        if (response.status === 200) {
          toast.success('Excluido com sucesso!')
          const results = users.filter(item => item.id !== id)

          setUsers(results)
        }
      })
      .catch(() => {
        toast.success('Erro ao excluir!')
      })
  }

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: iOption[] = []

    if (value) {
      let path = `/${patch}/like/${value}`

      if (value === '') {
        path = `/${patch}`
      }

      api.get(path).then(response => {
        options_ = response.data.map((item: any) => {
          return {
            value: item.id,
            label: `${item.code ? item.code : item.id} - ${item.name}`
          }
        })
        setOption(options_)
      })
    }
  }
  function handleChangeOption(option: iOption, setValue: any, type: string) {
    setValue(option)
    const value = Number(option.value)

    if (type === 'company') {
      const results = usersFilter.filter(item => item.company?.id === value)
      setUsers(results)
    } else {
      const results = usersFilter.filter(item => item.role === option.label)
      setUsers(results)
    }
  }

  return (
    <div className='userList'>
      <ModalDelete
        type='Usuário'
        name={name}
        id={id}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteUser}
      />

      <header>
        <div>
          <strong>Gerenciar Usuários</strong>
        </div>

        <div className='search-header'>
          <div className='search'>
            <input onChange={handleChangeSearch} placeholder='Buscar usuário' />
            <MdSearch fontSize={26} color='#999999' />
          </div>

          <div className='transportadora'>
            <div className='select-shipping' style={{ width: 500 }}>
              <label>Empresa</label>
              <Select
                className='select-options'
                value={companyValue}
                options={companies}
                onInputChange={value =>
                  handleChangeInputSelect(value, 'company', setCompanies)
                }
                onChange={option =>
                  handleChangeOption(
                    option as iOption,
                    setCompanyValue,
                    'company'
                  )
                }
              />
            </div>

            {companyValue && (
              <div className='truck-profile'>
                <Button
                  className='erase-button t'
                  onClick={() => {
                    setCompanyValue({ value: '', label: '' })
                    setUsers(usersFilter)
                  }}
                >
                  x
                </Button>
              </div>
            )}
          </div>

          <div className='transportadora'>
            <div className='select-shipping' style={{ width: 500 }}>
              <label>Papel do Usuário</label>
              <Select
                className='select-options'
                value={roleValue}
                options={roles}
                onInputChange={value =>
                  handleChangeInputSelect(value, 'role', setRoles)
                }
                onChange={option =>
                  handleChangeOption(option as iOption, setRoleValue, 'role')
                }
              />
            </div>

            {roleValue && (
              <div className='truck-profile'>
                <Button
                  className='erase-button t'
                  onClick={() => {
                    setRoleValue({ value: '', label: '' })
                    setUsers(usersFilter)
                  }}
                >
                  x
                </Button>
              </div>
            )}
          </div>

          <div className='new'>
            {' '}
            <Link to='/usuario/novo'>
              {' '}
              <button type='button'> Cadastrar </button>{' '}
            </Link>
          </div>
        </div>
      </header>

      <div className='grid'>
        <div className='content'>
          <div style={{ height: 550, minHeight: 'fit-content' }}>
            <DataGrid
              disableDensitySelector
              rows={rows}
              columns={columns}
              pageSize={8}
              components={{
                Toolbar: GridToolbar
              }}
              rowsPerPageOptions={[8]}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default User
