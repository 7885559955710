import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
  ptBR
} from '@mui/x-data-grid'

import { MdSearch } from 'react-icons/md'
import api from '../../services/api'
import { toast } from 'react-toastify'
import Tooltip from '@material-ui/core/Tooltip'
import { LicensePlate } from '../../components/LicensePlate'

import { ConfirmRecoverModal } from './ConfirmRecoverModal'
import './styles.scss'

interface Attendance {
  id: number
  status: boolean

  qtyTransported: string

  order: {
    code: string
  }
  driver: {
    name: string
  }

  truck: {
    licensePlate: string
  }
}

const Recuperar = () => {
  const [attendances, setAttendances] = useState<Attendance[]>([])
  const [attendancesFilter, setAttendancesFilter] = useState<any[]>([])

  const [isOpenModalRecover, setIsOpenModalRecover] = useState(false)
  const [currentVehicleToBeRestored, setCurrentVehicleToBeRestored] =
    useState<Attendance | null>(null)

  const companyId = localStorage.getItem('@App:companyId')

  async function handleConfirmRecover() {
    try {
      await api.put('queue-restore/restore', {
        id: currentVehicleToBeRestored?.id,
        company: {
          id: companyId
        }
      })

      const updatedVehiclesAttendances = attendances.filter(
        vehicle => vehicle.id !== currentVehicleToBeRestored?.id
      )

      setAttendances(updatedVehiclesAttendances)

      handleCloseModalRecover()
      toast.success('Aprovação realizada com sucesso')
    } catch (error) {
      toast.error('Erro ao aprovar')
    }
  }

  function handleOpenModalRecover(vehicle: Attendance) {
    setIsOpenModalRecover(true)

    setCurrentVehicleToBeRestored(vehicle)
  }

  function handleCloseModalRecover() {
    setIsOpenModalRecover(false)
    setCurrentVehicleToBeRestored(null)
  }

  function handleChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const search = event.target.value

    const results = attendancesFilter.filter(
      attendance =>
        attendance.truck.licensePlate
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        attendance.driver.name.toLowerCase().includes(search.toLowerCase())
    )
    setAttendances(results)
  }

  const rows = attendances.map(item => {
    return {
      id: item.id,
      order: item.order,
      driver: item.driver,
      truck: item.truck,
      status: item.status,
      qtyTransported: item.qtyTransported
    }
  })

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 95, hide: true },
    {
      field: 'truck',
      headerName: 'Placa',
      width: 140,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <LicensePlate
              licensePlate={params.row.truck?.licensePlate}
              themeColor={'#666'}
            />
          </>
        )
      }
    },
    {
      field: 'driver',
      headerName: 'Motorista',
      width: 150,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='driver'>
              <div>
                <div className='name'>
                  <span>
                    {params.row?.driver?.name
                      .replace(/[de]/g, '')
                      .replace(/[das]/g, '')
                      .split(' ')
                      .slice(0, 2)
                      .join(' ')}
                  </span>
                </div>
                <div className='weight'>
                  <span>{params.row.qtyTransported}</span>
                </div>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      width: 130,
      renderCell: (params: GridCellParams) => {
        const vehicle = params.row

        return (
          <>
            {!vehicle.status && (
              <Tooltip className='aprove' title={<h1>Aprovar Resgate</h1>}>
                <button
                  className='aprove-button'
                  onClick={() => handleOpenModalRecover(vehicle)}
                >
                  Aprovar
                </button>
              </Tooltip>
            )}
          </>
        )
      }
    }
  ]

  useEffect(() => {
    async function getQueueRestore() {
      const response = await api.get<Attendance[]>('queue-restore')

      function sortByStatus(vehicle: Attendance) {
        if (vehicle.status) {
          return 1
        }

        if (!vehicle.status) {
          return -1
        }

        return 0
      }

      const data = response.data.sort(sortByStatus)

      setAttendances(data)
      setAttendancesFilter(data)
    }

    getQueueRestore()
  }, [])

  return (
    <div className='productList recover-screen'>
      <header>
        <div>
          <strong>Gerenciar Resgates</strong>
        </div>

        <div className='search-header'>
          <div className='search'>
            <input onChange={handleChangeSearch} placeholder='Buscar Resgate' />
            <MdSearch fontSize={26} color='#999999' />
          </div>
        </div>
      </header>

      <div className='grid'>
        <div className='content'>
          <div style={{ height: 600 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={6}
              rowHeight={70}
              disableColumnMenu
              components={{
                Toolbar: GridToolbar
              }}
              rowsPerPageOptions={[8]}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>
        </div>
      </div>

      <ConfirmRecoverModal
        open={isOpenModalRecover}
        onCloseModal={handleCloseModalRecover}
        onConfirmRecover={handleConfirmRecover}
        order={currentVehicleToBeRestored?.order.code}
        licensePlate={currentVehicleToBeRestored?.truck.licensePlate}
        qtyTransported={currentVehicleToBeRestored?.qtyTransported}
      />
    </div>
  )
}

export default Recuperar
