import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../components/Input'
import api from '../../../services/api'
import axios from 'axios'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import './styles.css'
import { toast } from 'react-toastify'
import ModalDelete from '../../../components/ModalDelete'

interface RouteParams {
  id: string
}

interface Contact {
  id: number
  name: string
  fone: string
  email: string
}
const NewEditCompany: React.FC = (): JSX.Element => {
  const [status, setStatus] = useState<Boolean>(true)
  // const [file, setFile] = useState<File>();

  // Validate data from form
  const companySchema = Yup.object().shape({
    name: Yup.string()
      .max(80, 'Tamanho máximo de 80 caracteres')
      .required('Nome fantasia é obrigatório!'),
    cnpj: Yup.string().required('Número de CNPJ é obrigatório!'),
    company: Yup.string()
      .max(50, 'Tamanho máximo de 50 caracteres')
      .required('Razão social é obrigatório!'),
    uf: Yup.string().required('Estado é obrigatório!'),
    street: Yup.string()
      .max(50, 'Tamanho máximo de 50 caracteres')
      .required('Nome da rua é obrigatório!'),
    number: Yup.number().nullable(),
    complement: Yup.string()
      .nullable()
      .max(25, 'Tamanho máximo de 25 caracteres'),
    city: Yup.string()
      .max(50, 'Tamanho máximo de 50 caracteres')
      .required('Nome da cidade é obrigatório!'),
    district: Yup.string().required('Nome do bairro é obrigatório!'),
    zipCode: Yup.string().required('CEP é obrigatório!')
  })

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      name: '',
      cnpj: '',
      company: '',
      uf: '',
      street: '',
      number: '',
      complement: '',
      website: '',
      city: '',
      district: '',
      zipCode: ''
    },

    validationSchema: companySchema,
    onSubmit: values => {
      api
        .post('company/create', {
          id: parseInt(id),
          status,
          name: values.name,
          cnpj: values.cnpj.slice(0, 18),
          company: values.company,
          uf: values.uf,
          street: values.street,
          number: values.number,
          complement: values.complement,
          website: values.website,
          city: values.city,
          district: values.district,
          zipCode: values.zipCode,
          contact: contacts
        })
        .then(() => {
          toast.success('Cadastro realizado com sucesso')
          setTimeout(() => {
            history.push('/empresas')
          }, 2500)
        })
        .catch(e => {
          toast.error('Erro ao Cadastrar: ' + e.response.data.code)
        })
    }
  })

  const { id } = useParams<RouteParams>()

  const [contacts, setContacts] = useState<Contact[]>([])
  const [contact, setContact] = useState<string>('')
  const [zipCode, setZipeCode] = useState<string>('')

  const [contactFone, setContactFone] = useState<string>('')
  const [contactEmail, setContactEmail] = useState<string>('')
  const [modal, setModal] = useState('')
  const [nameModal, setNameModal] = useState('')
  const [idContact, setIdContact] = useState(0)
  const [key, setKey] = useState('')

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0
  })

  const history = useHistory()

  useEffect(() => {
    if (id) {
      api.get(`/company/${id}`).then(response => {
        setValues({
          cnpj: response.data.cnpj,
          name: response.data.name,
          company: response.data.company,
          uf: response.data.uf,
          street: response.data.street,
          number: response.data.number,
          complement: response.data.complement,
          city: response.data.city,
          district: response.data.district,
          zipCode: response.data.zipCode,
          website: response.data.website
        })
        setContacts(response.data.contact)
        setZipeCode(response.data?.zipCode)

        setStatus(response.data.status)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleAddContact() {
    setContacts([
      ...contacts,
      { id: 0, name: contact, fone: contactFone, email: contactEmail }
    ])
    setContact('')
    setContactFone('')
    setContactEmail('')
  }

  async function handleChangeStatus(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setStatus(event.target.checked)
  }

  async function handleChangeContact(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContact(event.target.value)
  }

  async function handleChangeContactFone(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContactFone(event.target.value.slice(0, 16))
  }

  async function handleChangeContactEmail(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContactEmail(event.target.value)
  }

  async function handleEditContact(
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) {
    const value =
      type === 'fone' ? event.target.value.slice(0, 16) : event.target.value

    const newContacts = contacts.map(item => {
      if (item.id === id)
        return {
          id: id,
          name: type === 'name' ? value : item.name,
          fone: type === 'fone' ? value : item.fone,
          email: type === 'email' ? value : item.email
        }
      else return item
    })

    setContacts(newContacts)
  }

  function onEdit(id: number) {
    setInEditMode({
      status: true,
      rowKey: id
    })
  }

  async function handleChangeZipCode(zipCode: string) {
    const _zipCode = zipCode
    if (zipCode.length > 8) {
      _zipCode.replace('/[^0-9]/', '')
      await axios
        .get(`https://viacep.com.br/ws/${zipCode}/json/`)
        .then(response => {
          setValues({
            ...values,
            city: response.data.localidade,
            district: response.data.bairro,
            street: response.data.logradouro,
            uf: response.data.uf,
            complement: response.data.complemento,
            zipCode: zipCode
          })
        })
    }
    setZipeCode(zipCode)
  }
  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete(contact: Contact) {
    showModal()
    setNameModal(contact.name)
    setIdContact(contact.id)
    setKey(contact.fone)
  }

  function handleDeleteContact(id: number) {
    hideModal()

    if (id) {
      api
        .delete(`/contact/delete/${id}`)
        .then(response => {
          if (response.status === 200) {
            toast.success('Excluido com sucesso!')
            const results = contacts.filter(item => item.id !== id)
            setContacts(results)
          }
        })
        .catch(() => {
          toast.success('Erro ao excluir!')
        })
    } else {
      const results = contacts.filter(item => item.fone !== key)
      setContacts(results)
    }
  }

  /*  function onFileChange(fileChangeEvent :React.ChangeEvent<HTMLInputElement>) {
     if(fileChangeEvent.target.files){
  
      setFile(fileChangeEvent.target.files[0] );
     }
    } */
  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  return (
    <div className='new-company-container'>
      <ModalDelete
        type='Contato'
        name={nameModal}
        id={idContact}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteContact}
      />

      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>{`${!id ? ' Cadastrar ' : 'Editar'}`} empresa</strong>
          </div>

          <div className='buttons-header'>
            <Link to='/empresas'>
              <button id='btBack' type='button'>
                <MdKeyboardArrowLeft size={20} color='#fff' />
                Voltar
              </button>
            </Link>
            <button id='btSave' type='submit'>
              <MdDone size={20} color='#fff' />
              Salvar
            </button>
          </div>
        </header>

        <div className='content'>
          <div className='row'>
            <div className={`input ${errors.cnpj ? 'error' : ''}`}>
              <Input
                name='cnpj'
                mask='##.###.###/####-##'
                placeholder='CNPJ'
                onChange={handleChange}
                value={values.cnpj.slice(0, 18)}
                label='CNPJ'
                maxLength={18}
              />

              {errors.cnpj && (
                <div className='validateError'>{errors.cnpj}</div>
              )}
            </div>

            <div className={`input ${errors.cnpj ? 'error' : ''}`}>
              <label>Status</label>
              <div>
                <label className='switch'>
                  <input
                    name='status'
                    placeholder='Status'
                    onChange={handleChangeStatus}
                    type='checkbox'
                    {...(status === true
                      ? { checked: true }
                      : { checked: false })}
                  />
                  <div className='slider round'>
                    <span className='on'>Ativo</span>
                    <span className='off'>Inativo</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className={`input ${errors.name ? 'error' : ''}`}>
              <Input
                name='name'
                placeholder='Nome fantasia da empresa'
                onChange={handleChange}
                value={values.name}
                label='Nome fantasia'
                maxLength={25}
              />
              {errors.name && (
                <div className='validateError'>{errors.name}</div>
              )}
            </div>
            <div className={`input ${errors.company ? 'error' : ''}`}>
              <Input
                name='company'
                placeholder='Razão social'
                value={values.company}
                onChange={handleChange}
                label='Razão social'
                maxLength={25}
              />

              {errors.company && (
                <div className='validateError'>{errors.company}</div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className={`input ${errors.zipCode ? 'error' : ''}`}>
              <Input
                mask='#####-###'
                name='zipCode'
                placeholder='CEP'
                value={zipCode}
                onChange={e => handleChangeZipCode(e.target.value)}
                label='CEP'
                maxLength={9}
              />

              {errors.zipCode && (
                <div className='validateError'>{errors.zipCode}</div>
              )}
            </div>

            <div className={`input ${errors.city ? 'error' : ''}`}>
              <Input
                name='city'
                placeholder='Cidade'
                value={values.city}
                onChange={handleChange}
                label='Cidade'
                maxLength={25}
              />

              {errors.city && (
                <div className='validateError'>{errors.city}</div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className={`input ${errors.street ? 'error' : ''}`}>
              <Input
                name='street'
                placeholder='Rua'
                value={values.street}
                onChange={handleChange}
                label='Rua'
                maxLength={25}
              />

              {errors.street && (
                <div className='validateError'>{errors.street}</div>
              )}
            </div>

            <div className={`input ${errors.number ? 'error' : ''}`}>
              <Input
                name='number'
                placeholder='Numero'
                value={values.number}
                onChange={handleChange}
                label='Numero'
                maxLength={5}
              />
            </div>
          </div>

          <div className='row'>
            <div className={`input ${errors.district ? 'error' : ''}`}>
              <Input
                name='district'
                placeholder='Bairro'
                value={values.district}
                onChange={handleChange}
                label='Bairro'
                maxLength={25}
              />

              {errors.district && (
                <div className='validateError'>{errors.district}</div>
              )}
            </div>

            <div className={`uf input ${errors.uf ? 'error' : ''}`}>
              <Input
                name='uf'
                placeholder='Estado'
                value={values.uf}
                onChange={handleChange}
                label='Estado'
                maxLength={2}
              />

              {errors.uf && <div className='validateError'>{errors.uf}</div>}
            </div>
          </div>

          <div className='row'>
            <div className='input'>
              <Input
                name='complement'
                placeholder='Complemento'
                value={values.complement}
                onChange={handleChange}
                label='Complemento'
              />
            </div>

            <div className='input'>
              <Input
                name='website'
                placeholder='Website'
                value={values.website}
                onChange={handleChange}
                label='Website'
              />
            </div>
          </div>

          <div className='contacts'>
            <fieldset>
              <legend>Contatos</legend>
              <table>
                <thead>
                  <tr>
                    <th>Contato</th>
                    <th>Telefone</th>
                    <th>Email</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.length > 0 ? (
                    contacts.map((contact, index) => (
                      <tr key={index}>
                        <td>
                          {inEditMode.status && inEditMode.rowKey === index ? (
                            <Input
                              value={contact.name}
                              onChange={event =>
                                handleEditContact(contact.id, event, 'name')
                              }
                            />
                          ) : (
                            contact.name
                          )}
                        </td>
                        <td>
                          {inEditMode.status && inEditMode.rowKey === index ? (
                            <Input
                              mask='(##) # ####-####'
                              value={contact.fone}
                              onChange={event =>
                                handleEditContact(contact.id, event, 'fone')
                              }
                            />
                          ) : (
                            contact.fone
                          )}
                        </td>
                        <td>
                          {inEditMode.status && inEditMode.rowKey === index ? (
                            <Input
                              value={contact.email}
                              onChange={event =>
                                handleEditContact(contact.id, event, 'email')
                              }
                            />
                          ) : (
                            contact.email
                          )}
                        </td>

                        <td>
                          <button
                            type='button'
                            className='actionButton blue'
                            onClick={() => onEdit(index)}
                          >
                            editar
                          </button>

                          <button
                            type='button'
                            className='actionButton red'
                            onClick={() => {
                              handleCorfirmDelete(contact)
                            }}
                          >
                            excluir
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} style={{ textAlign: 'center' }}>
                        Nenhum resultado foi encontrado
                      </td>
                    </tr>
                  )}
                  <tr className='contact'>
                    <td>
                      <Input
                        name='contato'
                        placeholder='Contato'
                        value={contact}
                        onChange={handleChangeContact}
                        label=''
                      />
                    </td>
                    <td>
                      <Input
                        mask='(##) # ####-####'
                        name='fone'
                        placeholder='Telefone'
                        value={contactFone}
                        onChange={handleChangeContactFone}
                        label=''
                      />
                    </td>
                    <td>
                      <Input
                        name='email'
                        placeholder='E-mail'
                        value={contactEmail}
                        onChange={handleChangeContactEmail}
                        label=''
                      />
                    </td>
                    <td>
                      <button
                        type='button'
                        id='addContact'
                        onClick={handleAddContact}
                        {...(!contact || !contactFone || !contactEmail
                          ? { disabled: true }
                          : { disabled: false })}
                      >
                        + Adicionar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
      </form>

      <br />
    </div>
  )
}

export default NewEditCompany
