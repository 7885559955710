import { DataGrid, GridColDef, GridCellParams, ptBR } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'

import _Iniciar from '../../../../assets/images/Atendimento_Finalizado.svg'

import Input from '../../../../components/Input'

import { OrderDetails } from '../../components/OrderDetails'
import api from '../../../../services/api'

import { useAuth } from '../../../../contexts/auth'
import AppToolBar from '../../components/AppToolBar/tele'

import { useStopwatch } from 'react-timer-hook'
import { Queue } from '../../../../domain'
import './styles.css'

const initialQueueState: Queue | null = null

export function DriverPanel() {
  const { company } = useAuth()

  const today = new Date().toLocaleDateString()
  const [disable, setDisable] = useState(1)
  const [confirm, setConfirm] = useState(1)
  const [suspend, setSuspend] = useState(1)
  const [attendance, setAttendance] = useState(0)
  const [delayStatus, setDelayStatus] = useState(false)
  const [delayedTime, setDelayedTime] = useState(0)

  const { minutes } = useStopwatch({ autoStart: true })

  const [queues, setQueues] = useState<Queue[]>([])
  const [queue, setQueue] = useState<Queue | null>(null)

  const [active, setActive] = useState<Queue>()
  const [qtyTransported, setQtyTransported] = useState('')
  const [time, setTime] = useState(0)
  const [toleranceIsEnabled, setToleranceIsEnabled] = useState<boolean>(false)

  const companyId = company || sessionStorage.getItem('@AppDriver:companyId')
  const userId = sessionStorage.getItem('@App:id')

  useEffect(() => {
    api.get(`/queue/company/${companyId}`).then(response => {
      const _queue = response.data
      setQueues(_queue)
    })

    api.get(`/attendances/today/company/${companyId}`).then(response => {
      setAttendance(
        response.data?.id && response.data?.status ? response.data.id : 0
      )
    })

    api.get(`/queue/active/${companyId}`).then(response => {
      if (response.data) setActive(response.data)

      const timer =
        new Date().getTime() - new Date(response.data.expedition).getTime()
      setTime(Math.floor(timer / (60 * 1000)))
    })

    api.get(`settings/company/${companyId}`).then(response => {
      setToleranceIsEnabled(response.data.enableTolerance)
    })

    api.get(`queue/delayStatus/company/${companyId}`).then(response => {
      if (response.data) {
        setDelayStatus(true)

        setDelayedTime(0)

        const timer =
          new Date().getTime() - new Date(response.data.expedition).getTime()
        setTime(Math.floor(timer / (60 * 1000)))
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTimeout(function () {
      //window.location.reload();
    }, 30000)

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // definição da tabela
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 50, hide: true },
    { field: 'position', headerName: 'Fila', width: 60, sortable: false },
    {
      field: 'licensePlate',
      headerName: 'Previsão',
      width: 110,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div>{params.row.licensePlate}</div>
          </>
        )
      }
    },
    {
      field: 'time',
      headerName: 'Tempo',
      width: 150,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div>{returnTime(params.row.time)}</div>
          </>
        )
      }
    }
  ]

  function returnTime(attendanceTime: any) {
    let attendanceData = new Date(attendanceTime).toLocaleString('pt-BR', {
      hour: 'numeric',
      minute: '2-digit'
    })

    return attendanceData
  }

  // add linhas a tabela
  const rows = queues.map((item, index) => {
    return {
      id: item.id,
      position: index + 1 + '°',
      time: item.date,
      licensePlate: item.scheduling
        ? item.scheduling.truck?.licensePlate
        : item.truck?.licensePlate,
      freelancer: item.scheduling ? false : true,
      vehicleModel: item.scheduling?.truck?.vehicleModel.name
    }
  })

  function handleClose() {
    setQueue(initialQueueState)
  }

  function handleCancel() {
    setConfirm(1)
    setSuspend(1)
  }
  function handleConfirm() {
    api
      .post('scheduling/save', [
        {
          id: active?.scheduling?.id,
          status: true,
          qtyTransported: parseFloat(qtyTransported),
          expedInitialDate: active?.expedition || '',
          expedDate: new Date()
        }
      ])
      .then(() => {
        api.delete(`queue/delete/${active?.id}`).then(() => {
          setDisable(0)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
      })
      .catch(e => {})
  }

  function handleQtyTransported(qty: string) {
    setQtyTransported(qty)
  }

  function handleConfirmSuspend() {
    if (!attendance) {
      api
        .post(`/attendances/save`, {
          user: { id: userId },
          company: { id: companyId }
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
    } else {
      api
        .put(`/attendances/update`, {
          id: attendance
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
    }
  }

  return (
    <div className='home-expedition'>
      <div className='container painel-page'>
        <AppToolBar pathToBack='/expedicao' />

        <div className={disable ? 'disable' : 'sucess-schedule'}>
          <img
            src={_Iniciar}
            width={'30%'}
            style={{ maxWidth: '200px' }}
            alt=''
          />

          <div>Atendimento</div>
          <strong>Encerrado</strong>
        </div>

        <div className={suspend ? 'disable' : 'confirm-suspend'}>
          <div>
            <div>
              Deseja <strong>{!attendance ? 'Iniciar' : 'Encerrar'}</strong>
            </div>
            <div>Atendimento?</div>
            <div>{active?.scheduling?.truck?.licensePlate}</div>
            <div className='buttons'>
              <button onClick={handleCancel}>Não</button>
              <button onClick={handleConfirmSuspend}>Sim</button>
            </div>
          </div>
        </div>

        <div className={confirm ? 'disable' : 'confirm-schedule'}>
          <div>
            <Input
              name='qtyTrasported'
              type='number'
              pattern='[0-9]+([\.,][0-9]+)?'
              step='0.001'
              placeholder='Total Transportado'
              label='Total Transportado em toneladas:'
              value={qtyTransported}
              onChange={e => handleQtyTransported(e.target.value || '0')}
            />
            <div className='buttons'>
              <button onClick={handleCancel}>Cancelar</button>
              {parseFloat(qtyTransported) > 0 ? (
                <button onClick={handleConfirm}>Confirmar</button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        {queue?.id ? (
          <div className='order-details painel-page'>
            <OrderDetails
              queue={queue}
              handleClose={handleClose}
              tolerance={toleranceIsEnabled}
            />
          </div>
        ) : (
          <></>
        )}

        <div className='content painel-page'>
          <div className='today'>
            <strong>Hoje, </strong> {today}
          </div>

          {(delayStatus && (
            <div className='atendimento atrasado'>
              <div></div>
              <div className='atraso aviso'>
                Por favor, cancele o atraso caso queira voltar ao atendimento
              </div>

              <div>
                <div>
                  <strong>{minutes + delayedTime}</strong> min
                </div>
              </div>
            </div>
          )) ||
            (active && (
              <div className='atendimento painel-page'>
                <div>Veículo em atendimento</div>

                <div className='licensePlate'>
                  <div className='license'>
                    {active?.scheduling?.truck?.licensePlate}
                  </div>
                </div>
                <div>
                  <div>
                    <strong>{minutes + time}</strong> min
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className='scheduling-exp painel-page'>
          <div style={{ height: 520 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableColumnMenu
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              pageSize={10}
              rowsPerPageOptions={[10]}
              headerHeight={30}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
