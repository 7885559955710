import React from "react";
import styles from "./styles.module.scss";
import { Popup } from "../../../../components/Popup";

interface PopupPreview {
  label: string;
  title: string;
  imageUrl: string | null;
}

interface PopupPreviewProps {
  popupData: PopupPreview;
  popupImagePreview: string;
  popupTextPreview: any;
}

export function PopupPreview({
  popupData,
  popupTextPreview,
  popupImagePreview
}: PopupPreviewProps) {
  const { label, title } = popupData;

  return (
    <div className={styles["popup-preview"]}>
      <div className={styles["popup-preview-content"]}>
        <h3>Preview</h3>

        <div className={styles["popup-preview-screen-driver"]}>
          <Popup
            title={title}
            content={popupTextPreview}
            imageUrl={popupImagePreview}
            label={label}
          />
        </div>
      </div>
    </div>
  );
}
