import React from 'react'
import clsx from 'clsx'

import './styles.scss'

interface ServiceForecastDriver {
  position?: string
  toleranceTime?: string
  modeTolerance?: boolean
  modeDefault?: boolean
  serviceForecastDate?: string
  modeCheckIn?: boolean
}

export function ServiceForecastDriver({
  position,
  toleranceTime,
  modeCheckIn,
  modeDefault,
  modeTolerance,
  serviceForecastDate
}: ServiceForecastDriver) {
  if (position) {
    const date = new Date(toleranceTime!)

    const hours = date.getHours()
    const minutes = date.getMinutes()

    const [minuteLeft, minuteRight] = String(minutes).padStart(2, '0').split('')

    return (
      <div
        className={clsx('service-forecast-driver', {
          mode__default: modeDefault,
          mode__anonymous: !modeDefault && !modeTolerance,
          mode__tolerance: modeTolerance,
          mode__checkin: !modeDefault && !modeTolerance && modeCheckIn
        })}
      >
        <span>{position}</span>

        <time className='markup__time'>
          {hours}:{minuteLeft}
          {minuteRight}
        </time>
      </div>
    )
  }

  if (serviceForecastDate) {
    const dateAttendedAt = new Date(serviceForecastDate)
    const attendedAtHours = dateAttendedAt.getHours()
    const attendedAtMinutes = dateAttendedAt.getMinutes()

    const [attendedAtMinutesLeft, attendedAtMinutesRight] = String(
      attendedAtMinutes
    )
      .padStart(2, '0')
      .split('')

    const dateAttendedAtFormatted = new Intl.DateTimeFormat('pr-BR', {
      day: '2-digit',
      month: '2-digit',
      timeZone: 'UTC'
    }).format(dateAttendedAt)

    return (
      <div
        className={clsx('service-forecast-driver', {
          mode__default: modeDefault,
          mode__anonymous: !modeDefault && !modeTolerance,
          mode__tolerance: modeTolerance
        })}
      >
        <span>{dateAttendedAtFormatted}</span>

        <time className='markup__time'>
          {attendedAtHours}:{attendedAtMinutesLeft}
          {attendedAtMinutesRight}
        </time>
      </div>
    )
  }

  return <></>
}
