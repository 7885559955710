import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
  ptBR,
  GridRowId
} from '@mui/x-data-grid'

import { Header } from './components/Header'

import api from '../../../services/api'
import Select, { components } from 'react-select'
import { toast, ToastContainer } from 'react-toastify'
import { useAuth } from '../../../contexts/auth'
import { MdSearch } from 'react-icons/md'
import { DriversGroupsParams } from '../hooks/get-drivers-groups'
import {
  DriverApi,
  driverServices,
  TruckApi,
  truckServices,
  useDriverGetList
} from '../../../domain'
import { SearchResultRow } from './components/SearchResult'

interface Option {
  label: string
  value: string
}

interface iOption {
  netCapacity?: number | undefined
  label: string
  value: string
  vehicleModel?: {
    name: string
  }
}

interface Company {
  id: number
  name: string
}

type TruckOption = Option
type DriverOption = Option

export type Driver = Omit<DriverApi, 'truck' | 'authorization'>
export type Truck = Omit<TruckApi, 'contact' | 'driver'>

interface SearchResultsJoint {
  id: string
  driver: Driver
  truck: Truck
}

interface RowToBind {
  id: string
  driver: Driver
  truck: Truck
}

const Joints: React.FC = () => {
  const { role, company } = useAuth()

  const companyId = company || localStorage.getItem('@App:companyId')

  const { list: driverList } = useDriverGetList()

  const [driversGroups, setDriversGroups] = useState<DriverApi[]>([])
  const [driversGroupsFilter, setDriversGroupsFilter] = useState<DriverApi[]>(
    []
  )

  const [selectedTruckToAdd, setSelectedTruckToAdd] = useState<any>()
  const [selectedDriverToAdd, setSelectedDriverToAdd] = useState<any>()

  const [searchResultsJoint, setSearchResultsJoint] = useState<
    SearchResultsJoint[]
  >([])

  const [shippingCompanies, setShippingCompanies] = useState<Option[]>([])

  const [shippingCompanyValue, setShippingCompanyValue] = useState<Option>(
    {} as Option
  )

  const [rowsToBind, setRowsToBind] = useState<RowToBind[]>([])
  const [vehicleModel, setVehiculoModel] = useState('')

  const [licensePlateOptions, setLicensePlateOptions] = useState<any[]>([
    { value: '', label: '' }
  ])
  const [driverOptions, setDriverOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])

  const [searchDriverName, setSearchDriverName] = useState<DriverOption | null>(
    null
  )
  const [searchLicensePlate, setSearchLicensePlate] =
    useState<TruckOption | null>(null)

  const [toBindIndexes, setToBindIndexes] = useState<GridRowId[]>([])
  const [alreadyBindIndexes, setAlreadyBindIndexes] = useState<GridRowId[]>([])

  async function fetchDriversGroups(params?: DriversGroupsParams) {
    const response = await api.get<DriverApi[]>('/driver/groups', {
      params
    })

    const data = response.data

    const newListDrivers = handleAddNewRowTableToTruck(data)

    setDriversGroups(newListDrivers)
    setDriversGroupsFilter(newListDrivers)
  }

  function handleAddNewRowTableToTruck(drivers: DriverApi[]): DriverApi[] {
    return drivers.flatMap(driver => {
      if (driver.truck.length) {
        return driver.truck.map(truck => ({
          ...driver,
          truck: [truck]
        }))
      }

      return [driver]
    })
  }

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: Option[] = []

    if (value) {
      let path = `/${patch}/like/${value}`

      if (value === '') {
        path = `/${patch}`
      }

      if (role !== 'Administrador') {
        path = path + `/company/${companyId}`
      }

      if (value === 'default') {
        path = `/${patch}`
      }

      api.get(path).then(response => {
        options_ = response.data.map((item: any) => {
          return {
            value: item.id,
            label: `${item.code ? item.code : item.id} - ${
              item.name ? item.name : item.licensePlate
            }`
          }
        })

        setOption(options_)
      })
    }
  }

  async function handleChangeOption(
    option: Option,
    setValue: any,
    type: string
  ) {
    setValue(option)

    const shippingCompanyId = Number(option.value)

    await fetchDriversGroups({
      shippingCompanyId
    })
  }

  async function handleChangeOptionLicensePlate(option: iOption) {
    setSearchLicensePlate(option)

    setVehiculoModel(option.vehicleModel?.name || '')

    const truck = await truckServices.getById(option.value)
    const truckOwner = truck.driver.name

    if (!truckOwner) return
    setSelectedTruckToAdd(truck)

    const foundedDriver = driverList.find(item => {
      const currentDriver = item.name

      return currentDriver === truckOwner
    })

    if (foundedDriver) {
      const option = {
        value: foundedDriver.id.toString(),
        label: foundedDriver.name
      }

      setDriverOptions(prevState => [...prevState, option])

      setSearchDriverName(option)
      setSelectedDriverToAdd(foundedDriver)
    }
  }

  function createRowForEachTruck(driver: DriverApi) {
    const rows = driver.truck.map(truck => ({
      ...driver,
      truck: truck
    }))

    return rows
  }

  async function handleChangeOptionDriver(option: iOption) {
    setSearchDriverName(option)

    const driverSearch = option.label.replace(/[^A-Z]/g, '')

    const foundedDriver = driverList.find(item => {
      const currentDriver = item.name.replace(/[^A-Z]/gi, '')

      return currentDriver === driverSearch
    })

    if (foundedDriver) {
      setSelectedDriverToAdd(foundedDriver)
    }

    const driver = await driverServices.getById(option.value)
    const trucks = driver.truck

    if (trucks.length > 0) {
      const rows = createRowForEachTruck(driver)

      rows.forEach(row => {
        const { truck, ...restDriver } = row

        setSearchResultsJoint(prevState => [
          ...prevState,
          {
            id: `${row.id}-${row.truck.id}`,
            driver: restDriver,
            truck
          }
        ])
      })
    }
  }

  function reset() {
    setDriverOptions([])

    handleChangeInputSelect('', 'truck', setLicensePlateOptions)
    handleChangeOptionLicensePlate({} as iOption)

    setSearchResultsJoint([])
    setSearchDriverName(null)
    setSearchLicensePlate(null)
  }

  function handleAddRowsToBind() {
    setRowsToBind(prevState => {
      if (selectedDriverToAdd && selectedTruckToAdd) {
        return [
          ...prevState,
          {
            id: `${selectedDriverToAdd.id}-${selectedTruckToAdd.id}`,
            driver: selectedDriverToAdd,
            truck: selectedTruckToAdd
          }
        ]
      }

      if (searchResultsJoint.length > 0) {
        const newSearchResults = searchResultsJoint.map(item => ({
          ...item,
          id: `${item.driver.id}-${item.truck.id}`
        }))

        return [...prevState, ...newSearchResults]
      }

      return prevState
    })

    setSelectedDriverToAdd(null)
    setSelectedTruckToAdd(null)
    setSearchResultsJoint([])

    reset()
  }

  function handleCancelBinding() {
    const _rowsToBind = [...rowsToBind]

    toBindIndexes.forEach(element => {
      const rowIndex = rowsToBind.findIndex(item => item.id === element)
      // @ts-ignore
      _rowsToBind[rowIndex] = null
    })

    setRowsToBind([..._rowsToBind.filter(row => row)])
    setToBindIndexes([])
  }

  async function handleBindVehicles() {
    await handleAddScheduling()

    await fetchDriversGroups({
      shippingCompanyId: Number(shippingCompanyValue.value)
    })
  }

  async function handleAddScheduling() {
    const _rowsToBind = [...rowsToBind]
    const _groups: any[] = []
    let companyIdBind: number | undefined

    toBindIndexes.map(async element => {
      // @ts-ignore
      const rowIndex = rowsToBind.findIndex(item => item.id === element)

      const row = rowsToBind[rowIndex]

      const rowTruck = row.truck
      const rowDriver = row.driver

      const rowDriverShippingCompany = rowDriver?.shippingCompany

      // @ts-ignore
      _rowsToBind[rowIndex] = null

      _groups.push({
        truck: {
          ...rowTruck,
          shippingCompany: rowDriverShippingCompany
        },
        driver: rowDriver
      })

      companyIdBind = rowDriver?.company?.id
    })

    await api
      .post('shipping-company/bind', {
        shippingCompany: {
          id: shippingCompanyValue.value
        },
        groups: _groups,
        company: { id: companyIdBind }
      })
      .then(() =>
        toast.success('Cadastro realizado com sucesso', {
          position: 'bottom-right'
        })
      )
      .catch(e =>
        toast.error('Erro ao Cadastrar: ' + e?.response?.data?.message, {
          position: 'bottom-right'
        })
      )

    setRowsToBind([..._rowsToBind.filter(row => row)])
  }

  console.log({ alreadyBindIndexes })

  async function handleUnbind() {
    try {
      alreadyBindIndexes.map(async element => {
        // const rowIndex = rowsToBind.findIndex(item => item.id === element)

        const index = Number(element)

        const currentRow = rows[index]
        const currentRowDriverId = currentRow.driver.id

        await api.patch('/driver/unbind-shipping-company', {
          driver: {
            id: currentRowDriverId
          }
        })

        if (currentRow.truck?.id) {
          await api.patch('/truck/unbind-shipping-company', {
            truck: {
              id: currentRow.truck?.id
            }
          })
        }

        setDriversGroups(prevState =>
          prevState.filter(driver => driver.id !== currentRowDriverId)
        )

        setDriversGroupsFilter(prevState =>
          prevState.filter(driver => driver.id !== currentRowDriverId)
        )
      })

      toast.success('Motorista desvinculado com sucesso', {
        position: 'bottom-right'
      })
    } catch (error: any) {
      toast.error('Erro ao Desvincular: ' + error.response.data.statusCode, {
        position: 'bottom-right'
      })
    }

    setAlreadyBindIndexes([])
  }

  function handleChangeSearchCPFName(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const search = event.target.value.toLowerCase()

    const results = driversGroupsFilter.filter(
      driver =>
        driver.name.toString().toLowerCase().includes(search) ||
        driver.cpf.toString().includes(search)
    )

    setDriversGroups(results)
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Motorista',
      width: 200,
      renderCell: (params: GridCellParams) => {
        const driverNameRow = params.row.driver?.name
          ?.split(' ')
          ?.slice(0, 2)
          ?.join()
          ?.replace(',', ' ')

        const driverNameTitleRow = params.row.driver?.name

        return (
          <>
            <div style={{ lineHeight: 1 }} className='driver-name'>
              <div>
                <div className='code'>
                  <span className='code-number'>{params.row?.driver?.id}</span>
                  <span
                    className={`status ${
                      params.row.driver?.isEnabled ? 'active' : 'deactived'
                    }`}
                  >
                    {params?.row?.driver?.isEnabled ? 'Ativo' : 'Inativo'}
                  </span>
                </div>
                <div className='name'>
                  <span title={driverNameTitleRow}>{driverNameRow}</span>
                </div>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'cnh',
      headerName: 'Veículo',
      width: 120,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='driver-name'>
              <div className='name'>
                <span>{params.row?.truck?.licensePlate}</span>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'birthDate',
      headerName: 'Modelo',
      width: 130,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='driver-name'>
              <div className='name'>
                <span>{params.row?.truck?.vehicleModel?.name}</span>
              </div>
            </div>
          </>
        )
      }
    }
  ]

  const rows = driversGroups.map((item, index) => {
    return {
      id: index,
      driver: item,
      truck: item.truck[0]
    }
  })

  const shippingCompanyValueIsEmpty =
    Object.keys(shippingCompanyValue).length === 0

  const toBindIndexesIsEmpty = toBindIndexes.length === 0

  const buttonBindVehiclesDisabled =
    shippingCompanyValueIsEmpty || toBindIndexesIsEmpty

  const totalRowsToBind = rowsToBind.length

  const buttonUnbindDisabled = alreadyBindIndexes.length === 0
  const buttonCancelBindingDisabled = totalRowsToBind === 0

  const showSearchResultCombined =
    searchLicensePlate !== null && !searchDriverName !== null

  const totalSearchResultsJoint = searchResultsJoint.length
  const showSearchResultsJoint =
    searchDriverName !== null && totalSearchResultsJoint > 0

  useEffect(() => {
    api
      .get(`/shipping-company`, {
        params: {
          withTrucks: 1
        }
      })
      .then(response => {
        if (response.data) {
          setShippingCompanies(
            response.data.map((item: Company) => {
              return { label: `${item.id} - ${item.name}`, value: item.id }
            })
          )
        }
      })
  }, [companyId, role])

  return (
    <section className='joint-create-screen'>
      <Header />

      <main>
        <aside>
          <div className='search-header'>
            <div className='company'>
              <div className='select-company' style={{ width: 320 }}>
                <label>Transportadora</label>
                <Select
                  className='select-options'
                  value={shippingCompanyValue}
                  options={shippingCompanies}
                  onInputChange={value =>
                    handleChangeInputSelect(
                      value,
                      'shipping-company',
                      setShippingCompanies
                    )
                  }
                  onChange={option =>
                    handleChangeOption(
                      option as Option,
                      setShippingCompanyValue,
                      'shipping-company'
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div className='joint-list'>
            <div className='new-joint-container'>
              <form autoComplete='off'>
                <div className='new-joint-form'>
                  <fieldset
                    id='new-schedule'
                    style={{
                      background: 'white',
                      marginTop: '80px !important'
                    }}
                  >
                    <legend>Selecionar conjuntos</legend>
                    <div className='schedule-content'>
                      <div className='row'>
                        <div className='input-block'>
                          <div className='select-truck'>
                            <label>Caminhão:</label>
                            <Select
                              className='select-options'
                              components={{ DropdownIndicator }}
                              placeholder='Buscar Placa'
                              value={searchLicensePlate}
                              options={licensePlateOptions}
                              onInputChange={value =>
                                handleChangeInputSelect(
                                  value,
                                  'truck',
                                  setLicensePlateOptions
                                )
                              }
                              onChange={option =>
                                handleChangeOptionLicensePlate(
                                  option as iOption
                                )
                              }
                            />
                            {vehicleModel && (
                              <span
                                style={{
                                  fontSize: 14,
                                  marginLeft: 10,
                                  color: 'grey'
                                }}
                              >
                                Modelo: {vehicleModel}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='input-block'>
                          <div className='select-driver'>
                            <label>Motorista:</label>
                            <Select
                              className='select-options'
                              components={{ DropdownIndicator }}
                              placeholder='Nome ou CPF'
                              value={searchDriverName}
                              options={driverOptions}
                              onInputChange={value =>
                                handleChangeInputSelect(
                                  value,
                                  'driver',
                                  setDriverOptions
                                )
                              }
                              onChange={option =>
                                handleChangeOptionDriver(option as iOption)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className='result'>
                        {showSearchResultCombined && (
                          <SearchResultRow
                            driver={selectedDriverToAdd}
                            truck={selectedTruckToAdd}
                          />
                        )}

                        {showSearchResultsJoint &&
                          searchResultsJoint.map(item => (
                            <SearchResultRow
                              key={`${item.driver.id}-${item.truck.id}`}
                              driver={item.driver}
                              truck={item.truck}
                            />
                          ))}
                      </div>

                      <div className='bottom '>
                        <button
                          type='button'
                          id='addProduct'
                          onClick={handleAddRowsToBind}
                        >
                          + Incluir
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className='grid-container' style={{ height: 300 }}>
                  <DataGrid
                    selectionModel={toBindIndexes}
                    checkboxSelection
                    getRowId={row => `${row.driver.id}-${row.truck.id}`}
                    onSelectionModelChange={itm => {
                      setToBindIndexes(itm)
                    }}
                    rowHeight={62}
                    rows={rowsToBind}
                    columns={columns}
                    components={{
                      Toolbar: GridToolbar
                    }}
                    pageSize={2}
                    rowsPerPageOptions={[2]}
                    localeText={
                      ptBR.components.MuiDataGrid.defaultProps.localeText
                    }
                  />

                  <div className='bottom'>
                    <button
                      type='button'
                      id='excludeJoint'
                      onClick={handleCancelBinding}
                      disabled={buttonCancelBindingDisabled}
                    >
                      Excluir
                    </button>

                    <button
                      type='button'
                      id='addJoint'
                      onClick={handleBindVehicles}
                      disabled={buttonBindVehiclesDisabled}
                    >
                      Vincular Veículos
                    </button>
                  </div>
                </div>
              </form>
              <br />
            </div>
          </div>
        </aside>

        <aside>
          <div className='search-header'>
            <div className='company'>
              <div className='search'>
                <input
                  onChange={handleChangeSearchCPFName}
                  placeholder='Filtro'
                />
                <MdSearch fontSize={26} color='#999999' />
              </div>

              <div className='unbind'>
                <button
                  onClick={handleUnbind}
                  type='button'
                  disabled={buttonUnbindDisabled}
                >
                  Desvincular
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className='grid joint-list'>
              <div className='content'>
                <div style={{ height: 610 }}>
                  <DataGrid
                    checkboxSelection
                    selectionModel={alreadyBindIndexes}
                    onSelectionModelChange={itm => {
                      setAlreadyBindIndexes(itm)
                    }}
                    rowHeight={62}
                    rows={rows}
                    columns={columns}
                    disableColumnMenu
                    components={{
                      Toolbar: GridToolbar
                    }}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    localeText={
                      ptBR.components.MuiDataGrid.defaultProps.localeText
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </aside>
      </main>

      <ToastContainer
        position='bottom-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  )
}

export default Joints

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width='23'
        height='23'
        viewBox='0 0 23 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M16.438 14.4654H15.3991L15.0309 14.1103C16.3196 12.6112 17.0955 10.665 17.0955 8.54774C17.0955 3.82676 13.2687 0 8.54774 0C3.82676 0 0 3.82676 0 8.54774C0 13.2687 3.82676 17.0955 8.54774 17.0955C10.665 17.0955 12.6112 16.3196 14.1103 15.0309L14.4654 15.3991V16.438L21.0406 23L23 21.0406L16.438 14.4654ZM8.54774 14.4654C5.2733 14.4654 2.63007 11.8222 2.63007 8.54774C2.63007 5.2733 5.2733 2.63007 8.54774 2.63007C11.8222 2.63007 14.4654 5.2733 14.4654 8.54774C14.4654 11.8222 11.8222 14.4654 8.54774 14.4654Z'
          fill='#999999'
        />
      </svg>
    </components.DropdownIndicator>
  )
}

// TODO
/**
 * RENDERIZAR CONDICIONAL 1 ITEM PESQUISADO AO INVES DA LISTA, E VICE-VERSA
 *
 */
