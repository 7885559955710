import React from 'react'
import cx from 'clsx'
import { TabQueueOptions } from '../../expedTypes'

interface ExpeditionTabProps {
  id: string
  label: string
  count: number
  tabSelected: TabQueueOptions
  onChangeTab: (tabOption: TabQueueOptions) => void
}

export function ExpeditionTab({
  id,
  count,
  label,
  onChangeTab,
  tabSelected
}: ExpeditionTabProps) {
  return (
    <div
      id={id}
      className={cx('tab__option', {
        active: tabSelected === id
      })}
      onClick={() => onChangeTab(tabSelected)}
    >
      <strong>
        {label}
        <span className='tab__ball'>{count}</span>
      </strong>
    </div>
  )
}
