import React, { useEffect, useState } from "react";
import { MdCheckCircle } from "react-icons/md";
import api from "../../services/api";
import flagBrasil from "../../assets/images/icons/flagBrasil.png";

import "./styles.css";
import { Grid } from "@material-ui/core";
import logo from "../../assets/logo.png";

interface Truck {
  vehicleModel: {
    name: string;
  };
  licensePlate: string;
  selected?: boolean;
}
interface Contact {
  id: number;
  name: string;
  fone: string;
  email: string;
}

interface Driver {
  id: number;
  name: string;
  contact: Contact[];
  truck: Truck[];
}
interface Customer {
  code: number;
  name: string;
  city: string;
}

interface ShippingCompany {
  name: string;
}

interface Product {
  code: number;
  name: string;
}

interface OrderItem {
  id: number;
  quantity: number;
  product: Product;
}

interface Order {
  id: number;
  status: string;
  customer: Customer;
  shippingCompany: ShippingCompany;
  orderItem: OrderItem[];
}

interface Scheduling {
  id?: number;
  qtyOrdered?: number;
  qtyTransported?: number;
  order?: Order;
  driver?: Driver;
  truck?: Truck;
}
interface Queue {
  id: number;
  date: Date;
  scheduling: Scheduling;
  expedition?: Date;
  driver?: Driver;
  truck?: Truck;
}

interface Company {
  id: number;
  logo: string;
  name: string;
}

const Home: React.FC = () => {
  const today = new Date().toLocaleDateString();
  const [queuesByDays, setQueuesByDays] = useState<any[]>([]);

  const [active, setActive] = useState<Queue[]>([]);
  const timestamp = sessionStorage
    .getItem("@reloadedTimestamp")
    ?.replace(/"/g, "");
  const companyId = localStorage.getItem("@App:companyId") || null;
  const [company, setCompany] = useState<Company>();

  useEffect(() => {
    api
      .get(`/company/host/`, {
        params: {
          host: window.location.host
        }
      })
      .then(response => {
        setCompany(response.data);
      });

    let _enableTolerance: any = false;
    let input = navigator.onLine;

    if (input) {
      setTimeout(() => {
        sessionStorage.setItem("@checkedConnect", "true");
      }, 2000);
    }

    queryQueue();

    api.get(`/settings/company/${companyId}`).then(response => {
      _enableTolerance = response.data.enableTolerance;
      localStorage.setItem("@enableTolerance", _enableTolerance + "");
    });

    api.get(`/attendances/today/company/${companyId}`).then(response => {
      localStorage.setItem(
        "attendance",
        response.data?.id && response.data?.status ? response.data.id : 0
      );
    });

    api.get(`queue/delayStatus/company/${companyId}`).then(response => {
      if (response.data) {
        let timer =
          new Date().getTime() - new Date(response.data.expedition).getTime();

        if (isNaN(timer)) {
          let date = new Date(response.data.created_at);

          timer = new Date().getTime() - date.getTime();
        }
        //setTime(Math.floor(timer / (60 * 1000)));
      }
    });

    setTimeout(() => {
      sessionStorage.setItem(
        "@reloadedTimestamp",
        new Date().toLocaleDateString("pt-br") +
          " ás " +
          new Date().toLocaleTimeString()
      );
      window.location.reload();
    }, 180000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  localStorage.setItem("@last-position", 0 + "");

  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  function returnRowsByDay(rows: any, date: any, month: string, active: any) {
    let array = [];
    let position: string = "";

    for (let index = 0; index < rows.length; index++) {
      const element = rows[index];
      let scheduleDay = new Date(element.date).getDate();
      let scheduleMonth = new Date(element.date).getMonth();
      let getLastPosition: any = localStorage.getItem("@last-position");

      let isActive = false;

      for (let index = 0; index < active.length; index++) {
        let id = element.id;
        if (active[index].id === id) {
          isActive = true;
        }
      }

      if (`${scheduleDay}` !== date) {
        localStorage.setItem("@last-position", getLastPosition);
      } else {
        if (getLastPosition === "0") {
          localStorage.setItem("@last-position", Number(getLastPosition) + "");
        } else if (!isActive && getLastPosition !== "0") {
          localStorage.setItem(
            "@last-position",
            Number(getLastPosition) + 1 + ""
          );
        }
      }

      let lastPosition: any = localStorage.getItem("@last-position");
      position = Number(getLastPosition) + 1 + "°";

      if (getLastPosition === "0" && `${scheduleDay}` === date && !isActive) {
        localStorage.setItem("@last-position", Number(lastPosition) + 1 + "");
      }

      let licensePlate = element.scheduling
        ? element.scheduling.truck?.licensePlate
        : element.truck?.licensePlate;
      licensePlate = licensePlate?.split("-").join("");

      //***Tolerancia** */

      const date1: any = new Date();
      const date2: any = new Date(element.date);
      const diffTime = Math.floor(date2 - date1);

      let isInTolerance = false;
      let _enableTolerance: any = localStorage.getItem("@enableTolerance");

      _enableTolerance === "true"
        ? (_enableTolerance = true)
        : (_enableTolerance = false);

      if (diffTime < 0 && _enableTolerance === true) {
        isInTolerance = true;
      } else {
        isInTolerance = false;
      }

      if (
        array[array.length - 1]?.isInTolerance === true &&
        isInTolerance === false
      ) {
        localStorage.setItem("@last-position", Number(1) + "");
        position = 1 + "°";
      }

      let rendered = {
        id: element?.id || "",
        position: position || "",
        licensePlate: licensePlate || "",
        netCapacity: element?.truck?.netCapacity || "",
        time: element?.date || "",
        toleranceTime: element?.date || "",
        scheduling: element?.scheduling || "",
        driver: element.driver?.name || "",
        isInTolerance: isInTolerance || "",
        isActive: isActive || "",
        freelancer: element.scheduling ? false : true,
        vehicleModel: element.scheduling?.truck?.vehicleModel?.name || ""
      };

      if (
        scheduleDay + "" === date &&
        month.slice(month.length - 2, month.length) ===
          scheduleMonth + 1 + "" &&
        !isActive
      ) {
        array.push(rendered);
      }
    }

    return array;
  }

  async function queryQueue() {
    const route = `/queue/company/${companyId}`;
    const activeRoute = `/queue/active/company/${companyId}`;

    api.get(route).then(response => {
      let _queue = response.data;

      api.get(`${activeRoute}`).then(response => {
        let _active = [];

        if (response.data[0]) {
          setActive(response.data);
          _active = response.data;
        }

        let _days: any[] = [];
        let _dates: any[] = [];

        for (let index = 0; index < _queue.length; index++) {
          let date: any = `${new Date(_queue[index].date).getDate()}`;
          _days.push(date);
        }

        for (let index = 0; index < _queue.length; index++) {
          let date: any = `${new Date(_queue[index].date).getDate()}/${
            new Date(_queue[index].date).getMonth() + 1
          }`;
          _dates.push(date);
        }

        let _queuesByDays: any[] = [];
        let unique = _days.filter(onlyUnique);
        let uniqueDates = _dates.filter(onlyUnique);

        for (let index = 0; index < unique.length; index++) {
          _queuesByDays.push(
            returnRowsByDay(_queue, unique[index], uniqueDates[index], _active)
          );
        }

        setQueuesByDays(_queuesByDays);
      });
    });
  }

  // function returnQueue(element: any, position: any) {
  //   let isActive = false;

  //   for (let index = 0; index < active.length; index++) {
  //     let id = element.id;
  //     if (active[index].id === id) {
  //       isActive = true;
  //     }
  //   }

  //   let licensePlate: any = element.scheduling
  //     ? element.scheduling.truck?.licensePlate
  //     : element.truck?.licensePlate;

  //   let rendered = {
  //     id: element.id,
  //     time: element.date,
  //     isActive: isActive,
  //     position: position,
  //     licensePlate: licensePlate,
  //     freelancer: element.scheduling ? false : true,
  //     vehicleModel: element.scheduling?.truck?.vehicleModel?.name
  //   };

  //   return rendered;
  // }

  function returnRows(index: any) {
    return queuesByDays[index];
  }

  return (
    <div className="home-expedition elemidia">
      <div id="top" className="container">
        <div className="divisor">
          <div className={window.innerWidth < 768 ? "full" : ""}>
            <div className="content">
              <div className="logos-container">
                <div className="logo">
                  <img src={logo} alt="" />
                </div>

                <div className="logo">
                  <img
                    src={
                      company?.logo
                        ? `${process.env.REACT_APP_API}/${company?.logo}`
                        : logo
                    }
                    alt=""
                  />
                </div>
              </div>

              {timestamp && (
                <div className="today">
                  <strong>Hoje, </strong> {today}
                  <div className="reloaded">
                    <strong>Última atualização: {" " + timestamp} </strong>
                  </div>
                </div>
              )}

              <div className="in-attendance">
                <div className="title">Em atendimento</div>
                <Grid container spacing={3}>
                  {active.length === 0 && (
                    <Grid item xs={12} sm={12}>
                      <div className="warning-no-queue">
                        Aguardando os próximos veículos
                      </div>
                    </Grid>
                  )}

                  {active.map((item: any, index: any) => {
                    return (
                      <>
                        {active && (
                          <Grid item xs={12} sm={6}>
                            <div className="licensePlates">
                              <div
                                key={index}
                                className="license-container"
                                id={item.scheduling.truck.licensePlate}
                              >
                                <span
                                  className={item.selected ? "checked" : ""}
                                  style={{ display: "none" }}
                                >
                                  <MdCheckCircle />
                                </span>
                                <div className="licensePlate">
                                  <div className="info">
                                    <div>BRASIL</div>
                                    <span>
                                      <img width={24} src={flagBrasil} alt="" />
                                    </span>
                                  </div>
                                  <div className="license">
                                    {item.scheduling.truck.licensePlate}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        )}
                      </>
                    );
                  })}
                </Grid>
              </div>
            </div>

            <div className="scheduling-exp">
              <div className="title">
                <div>Próximos Veículos</div>
              </div>

              <div className="plates-container" style={{ height: 540 }}>
                <Grid container spacing={3}>
                  {returnRows(0)?.map((item: any, index: number) => {
                    return (
                      <Grid item xs={12} sm={6}>
                        <div className="licensePlates">
                          <div
                            key={index}
                            className="license-container"
                            id={item.licensePlate}
                          >
                            <span
                              className={item.selected ? "checked" : ""}
                              style={{ display: "none" }}
                            >
                              <MdCheckCircle />
                            </span>
                            <div className="licensePlate">
                              <div className="info">
                                <div>BRASIL</div>
                                <span>
                                  <img width={24} src={flagBrasil} alt="" />
                                </span>
                              </div>
                              <div className="license">{item.licensePlate}</div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
