import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import Draft, { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import './RichTextEditor.css';
import React from 'react';

const EditorComponent = ({getContent, initialText, readOnly}:any) => {

    const EditorState = Draft.EditorState;

    const [editorState, setEditorState] = useState(() => {
        
        if(initialText !== ""){
           return EditorState.createWithContent(initialText)
        }else {
           return EditorState.createEmpty()

        }

    
    });

    useEffect(() => {
      
    },[])

    const handleEditorChange = (state:any) => {
      
        setEditorState(state);
        sendContent();
    };

    const sendContent = () => {
        getContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };


    return (
        <div>
            <Editor
                readOnly={false}  
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
            />
        
        </div>
    );
};

export default EditorComponent;