import React from 'react'
import { WarningIcon } from '../../components/WarningIcon/WarningIcon'

export function QueueWarning() {
  return (
    <div className='queue-warning'>
      <h2>
        <WarningIcon />
        Atenção
      </h2>

      <p>
        Para melhor atender nossos clientes e motoristas, no momento, estamos em
        manutenção de nosso sistema e{' '}
        <b>
          a entrada de novos veículos na fila está indisponível temporáriamente.
        </b>
      </p>

      <strong>
        Em breve, a entrada de novos veiculos será liberada novamente.
      </strong>
    </div>
  )
}
