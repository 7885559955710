import api from '../../../services/api'
import { DriverLog, GetListParam } from './driverLogTypes'

async function getList({ driverId, period }: GetListParam) {
  const response = await api.get<DriverLog[]>(`/log-driver/${driverId}`, {
    params: period
  })

  return response.data
}

export const driverLogApi = { getList }
