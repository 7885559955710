export function handleMaskQtyTransported(
  event: React.ChangeEvent<HTMLInputElement>
) {
  event.currentTarget.maxLength = 6;
  let value = event.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d)/, "$1,$2");
  event.currentTarget.value = value;

  return {
    qtyTransported: event.currentTarget.value
  };
}
