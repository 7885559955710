import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
  ptBR
} from '@mui/x-data-grid'

import { Link } from 'react-router-dom'
import { MdSearch } from 'react-icons/md'
import api from '../../services/api'

import './styles.css'
import ModalDelete from '../../components/ModalDelete'
import { toast } from 'react-toastify'
import { useAuth } from '../../contexts/auth'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import Tooltip from '@material-ui/core/Tooltip'

interface Product {
  id: number
  code: number
  name: string
  group: string
  ncmcode: number
}
const Product: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([])
  const [productsFilter, setProductsFilter] = useState<Product[]>([])
  const [modal, setModal] = useState('')
  const [name, setName] = useState('')
  const [id, setId] = useState(0)

  const { role, company } = useAuth()
  const companyId = company || localStorage.getItem('@App:companyId')

  useEffect(() => {
    let path = ''

    if (role === 'Administrador') {
      path = '/product'
    } else if (role === 'Usuario') {
      path = `/product/company/${companyId}`
    }

    api.get(path).then(response => {
      setProducts(response.data)
      setProductsFilter(response.data)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // definição da tabela
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 95, hide: true },
    {
      field: 'code',
      headerName: 'Produto',
      width: 600,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='product'>
              <div>
                <div className='code'>
                  <label>Código:</label>
                  <span>{params.row.code}</span>
                </div>
                <div className='name'>
                  <span>{params.row.name}</span>
                </div>
              </div>
            </div>
          </>
        )
      }
    },
    { field: 'group', headerName: 'Grupo', width: 180 },
    { field: 'ncmcode', headerName: 'NCM Código', width: 150 },
    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      width: 80,

      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Tooltip className='edit-icon' title={<h1>Editar</h1>}>
              <Link to={{ pathname: `/produto/novo/${params.row.id}` }}>
                {' '}
                <AiOutlineEdit />{' '}
              </Link>
            </Tooltip>
            <Tooltip className='delete-icon' title={<h1>Deletar</h1>}>
              <Link to={{ pathname: `` }}>
                <AiOutlineDelete
                  onClick={() => {
                    handleCorfirmDelete(params.row.id, params.row.name)
                  }}
                />
              </Link>
            </Tooltip>
          </>
        )
      }
    }
  ]
  // add linhas a tabela
  const rows = products.map(item => {
    return {
      id: item.id,
      code: item.code,
      name: item.name,
      group: item.group ? 'SIDERURGICA' : 'AGRICOLA',
      ncmcode: item.ncmcode
    }
  })

  function handleChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const search = event.target.value

    const results = productsFilter.filter(
      product =>
        product.name.toLowerCase().includes(search.toLowerCase()) ||
        product.ncmcode.toString().includes(search) ||
        product.code.toString().includes(search)
    )
    setProducts(results)
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete(id: any, name: any) {
    showModal()
    setName(name)
    setId(parseInt(id))
  }

  function handleDeleteProduct(id: number) {
    hideModal()

    api
      .delete(`/product/delete/${id}`)
      .then(response => {
        if (response.status === 200) {
          const results = products.filter(item => item.id !== id)

          setProducts(results)
        }
      })
      .then(() => {
        toast.success('Excluido com sucesso!')
      })
      .catch(e => {
        if (e.response.data.message === 'ER_ROW_IS_REFERENCED_2')
          toast.error('Erro ao excluir,  existe(m) pedido(s) com este produto!')
        else
          toast.error(
            'Erro ao excluir: ' + e.response.data.message || e.message
          )
      })
  }
  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  return (
    <div className='productList'>
      <ModalDelete
        type='Produto'
        name={name}
        id={id}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteProduct}
      />

      <header>
        <div>
          <strong>Gerenciar Produtos</strong>
        </div>

        <div className='search-header'>
          <div className='search'>
            <input onChange={handleChangeSearch} placeholder='Buscar Produto' />
            <MdSearch fontSize={26} color='#999999' />
          </div>
          <div className='new'>
            {' '}
            <Link to='/produto/novo'>
              {' '}
              <button type='button'> Importar </button>{' '}
            </Link>
          </div>
        </div>
      </header>

      <div className='grid'>
        <div className='content'>
          <div style={{ height: 608 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={9}
              disableColumnMenu
              components={{
                Toolbar: GridToolbar
              }}
              rowsPerPageOptions={[8]}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
