import React from "react";
import Skeleton from "react-loading-skeleton";
import "./styles.css";

export function ExpeditionSkeleton() {
  return (
    <div className="expedition-skeleton-container">
      <div className="header">
        <div className="brand">
          <Skeleton height={64} />
        </div>

        <div className="header-actions">
          <Skeleton height={62} />
          <Skeleton
            height={62}
            // style={{
            //   borderLeft: "1px solid #e1e1e1",
            //   borderRight: "1px solid #e1e1e1"
            // }}
          />
          <Skeleton height={62} />
        </div>
      </div>

      <div className="main">
        <div>
          <div className="section-today">
            <Skeleton height={126} />
          </div>

          <div className="section-expedition-status">
            <div className="grid-columns">
              <Skeleton height={90} />
              <Skeleton height={90} />

              <Skeleton height={90} />
              <Skeleton height={90} />
            </div>
          </div>

          <div className="section-search-vehicle">
            <Skeleton width={240} height={58} />
          </div>

          <div className="section-vehicles-attendance">
            <div className="vehicles-attendance-content">
              <Skeleton height={140} />
              <Skeleton height={140} />
            </div>
          </div>

          <div className="section-queue-table">
            <div className="table-container">
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <div
                    className="table-content"
                    key={`table__content-${index}`}
                  >
                    <div className="date">
                      <Skeleton width={334} height={40} />
                    </div>

                    <div key={index} className="table-list">
                      <Skeleton count={5} height={70} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="sidebar-skeleton">
          <div className="sidebar-skeleton-buttons">
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </div>
        </div>
      </div>
    </div>
  );
}
