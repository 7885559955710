import { DataGrid, GridColDef, GridCellParams, ptBR } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import './styles.scss'

import { toast } from 'react-toastify'

import api from '../../services/api'

import './styles.css'
import { useAuth } from '../../contexts/auth'

interface iOption {
  netCapacity?: number
  label: string
  value: string
  vehicleModel?: {
    name: string
  }
}

interface Contact {
  id: number
  name: string
  fone: string
  email: string
}

interface Customer {
  code: number
  name: string
  city: string
}

interface ShippingCompany {
  name: string
}

interface Product {
  code: number
  name: string
}

interface OrderItem {
  id: number
  quantity: number
  product: Product
}

interface Order {
  id: number
  code: number
  status: string
  customer: Customer
  shippingCompany: ShippingCompany
  orderItem: OrderItem[]
}

interface Scheduling {
  id?: number
  qtyOrdered?: number
  qtyTransported?: number
  order?: Order
  driver?: Driver
  truck?: Truck
}
interface Queue {
  id: number
  scheduling: Scheduling
  expedition?: Date
  driver?: Driver
}
interface Delay {
  id: number
  reason: string
  time: string
}

interface Truck {
  id: number
  licensePlate: string
  netCapacity?: number
  vehicleModel?: string
}

interface Driver {
  id: number
  name: string
  truck?: Truck
}
const QueueAdmin: React.FC = () => {
  const { company } = useAuth()

  const [orders, setOrders] = useState<Order[]>([])
  const [selectedOrder, setSelectedOrder] = useState(0)

  const today = new Date().toLocaleDateString()

  const [time, setTime] = useState(0)
  const [totalProgrammed, setTotalProgrammed] = useState(0)

  const [licensePlateOptions, setLicensePlateOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [licensePlateValue, setLicensePlateValue] = useState<Truck>({
    id: 0,
    licensePlate: '',
    netCapacity: 0
  })
  const [vehicleModel, setVehiculoModel] = useState('')
  const [vehicleNetCapacity, setVehiculoNetCapacity] = useState(0)

  const [driverOptions, setDriverOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [driverValue, setDriverValue] = useState<Driver>({ id: 0, name: '' })

  const [totals, setTotals] = useState({ attended: 0, faulty: 0 })
  const [rows, setRows] = useState([
    {
      id: 0,
      position: '',
      licensePlate: '' || undefined,
      vehicleModel: '' || undefined
    }
  ])

  const companyId = company || localStorage.getItem('@App:companyId')
  useEffect(() => {
    api.get(`/queue/company/${companyId}`).then(response => {
      const _queue = response.data
      // add linhas a tabela
      setRows(
        _queue.map((item: any, index: any) => {
          return {
            id: item.id,
            position: index + 1 + '°',
            licensePlate: item.scheduling
              ? item.scheduling.truck?.licensePlate
              : item.driver?.truck[0]?.licensePlate,
            freelancer: item.scheduling ? false : true,
            vehicleModel: item.scheduling?.truck?.vehicleModel.name
          }
        })
      )

      api.get(`/scheduling/totals/company/${companyId}`).then(response => {
        setTotals(response.data)
        const { attended, faulty } = response.data
        api.get(`/settings/company/${companyId}`).then(response => {
          setTotalProgrammed(
            _queue.length <= response.data.programmedTotal
              ? _queue.length + attended + faulty
              : response.data.programmedTotal
          )
        })
      })
    })

    api.get(`/order/company/${companyId}`).then(response => {
      setOrders(
        response.data.filter((order: Order) => parseInt(order.status) !== 100)
      )
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    time > 0 && setTimeout(() => setTime(time + 1), 1000 * 60)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  // definição da tabela
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 50, hide: true },
    { field: 'position', headerName: 'Fila', width: 60, sortable: false },
    {
      field: 'licensePlate',
      headerName: 'Placa',
      width: 110,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {params.row.freelancer === true && (
              <>
                <span
                  className='freelancer'
                  style={{ fontSize: 10, fontWeight: 100, color: 'black' }}
                >
                  Autônomo
                </span>
              </>
            )}
            <div>{params.row.licensePlate}</div>
          </>
        )
      }
    },
    { field: 'vehicleModel', headerName: 'Modelo', width: 150, sortable: false }
  ]

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: iOption[] = []

    if (value) {
      let path = `/${patch}/like/${value}/company/${companyId}`

      api.get(path).then(response => {
        // eslint-disable-next-line array-callback-return
        options_ = response.data.map((item: any) => {
          if (patch === 'truck')
            return {
              value: item.id,
              label: `${item.licensePlate}`,
              vehicleModel: { name: item.vehicleModel?.name },
              netCapacity: item.netCapacity
            }

          if (patch === 'driver')
            return { value: item.id, label: `${item.name} - ${item.cpf}` }
        })
        setOption(options_)
      })
    }
  }

  function handleChangeOptionLicensePlate(option: iOption) {
    setLicensePlateValue({
      id: parseInt(option.value),
      licensePlate: option.label,
      netCapacity: option.netCapacity || 0
    })
    setVehiculoModel(option.vehicleModel?.name || '')
    setVehiculoNetCapacity(option.netCapacity || 0)
  }

  function handleChangeOptionDriver(option: iOption) {
    setDriverValue({ id: parseInt(option.value), name: option.label })
  }

  function handleChangeOrder(code: string) {
    setSelectedOrder(parseInt(code))
  }

  function handleAddScheduling() {
    api
      .post('scheduling/saveOne', {
        id: 0,
        qtyOrdered: 10,
        qtyTransported: 0,
        order: { id: selectedOrder },
        truck: { id: licensePlateValue.id },
        driver: { id: driverValue.id },
        company: { id: companyId }
      })
      .then(response => {
        api
          .post('queue/save', {
            driver: { id: driverValue.id },
            company: companyId,
            scheduling: { id: response.data?.id } || null
          })
          .then(() => {
            toast.success('Cadastro realizado com sucesso')

            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
          .catch(e => {
            toast.error('Erro ao Cadastrar: ' + e.response.data.code)
          })
      })
  }

  return (
    <div className='virtual-queue'>
      <div className=''>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '60%', marginRight: 60 }}>
            <div
              id='new-schedule'
              style={{ background: 'white', marginTop: '80px !important' }}
            >
              <div className='schedule-header'>
                <h2>Adicionar agendamento a fila</h2>
              </div>
              <div className='schedule-content'>
                <div className='row'>
                  <div className='input-block' style={{ marginTop: '25px' }}>
                    <div className='select-truck'>
                      <label>Caminhão:</label>
                      <Select
                        className='select-options'
                        options={licensePlateOptions}
                        onInputChange={value =>
                          handleChangeInputSelect(
                            value,
                            'truck',
                            setLicensePlateOptions
                          )
                        }
                        onChange={option =>
                          handleChangeOptionLicensePlate(option as iOption)
                        }
                      />
                      {vehicleModel && (
                        <span
                          style={{
                            fontSize: 14,
                            marginLeft: 10,
                            color: 'grey'
                          }}
                        >
                          Modelo: {vehicleModel}
                        </span>
                      )}
                      {vehicleNetCapacity > 0 && (
                        <span
                          style={{
                            fontSize: 14,
                            marginLeft: 10,
                            color: 'grey'
                          }}
                        >
                          Peso liquído: {vehicleNetCapacity}t
                        </span>
                      )}
                    </div>
                  </div>

                  <div
                    className='orders'
                    style={{ textAlign: 'left', marginTop: '25px' }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ width: '100px' }}>Code</th>
                          <th>Cliente</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <input
                                  type='radio'
                                  value={item.id}
                                  name='order'
                                  onChange={e =>
                                    handleChangeOrder(e.target.value)
                                  }
                                />
                              </td>
                              <td style={{ width: '100px' }}> {item.code} </td>
                              <td> {item.customer.name} </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className='input-block' style={{ marginTop: '25px' }}>
                    <div className='select-driver'>
                      <label>Motorista:</label>
                      <Select
                        className='select-options'
                        options={driverOptions}
                        onInputChange={value =>
                          handleChangeInputSelect(
                            value,
                            'driver',
                            setDriverOptions
                          )
                        }
                        onChange={option =>
                          handleChangeOptionDriver(option as iOption)
                        }
                      />
                    </div>
                  </div>

                  <button
                    type='button'
                    id='addProduct'
                    onClick={handleAddScheduling}
                    {...(!selectedOrder ||
                    !licensePlateValue.id ||
                    !driverValue.id
                      ? { disabled: true }
                      : { disabled: false })}
                  >
                    + Adicionar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: '40%' }}>
            <div className='today'>
              <strong>Hoje, </strong> {today}
            </div>

            <div className='service-status'>
              <div className='column'>
                <div>{totalProgrammed}</div>
                <span>veículos</span>
                <strong>Programados</strong>
              </div>
              <div className='column green'>
                <div className='green'>{totals.attended}</div>
                <span>veículos</span>
                <strong>Atendidos</strong>
              </div>
              <div className='column red'>
                <div className='red'>{totals.faulty}</div>
                <span>veículos</span>
                <strong>Faltosos</strong>
              </div>
            </div>

            <div
              className='service-data'
              style={{ height: 24, border: 'solid 2px #CCC' }}
            >
              <div
                style={{
                  backgroundColor: '#32A457',
                  height: 20,
                  width: `${(totals.attended * 100) / totalProgrammed}%`
                }}
              ></div>

              <div
                style={{
                  backgroundColor: '#C01616',
                  position: 'relative',
                  top: -20,
                  height: 15,
                  width: `${(totals.faulty * 100) / totalProgrammed}%`
                }}
              ></div>
              <span
                style={{
                  position: 'relative',
                  top: -40,
                  right: '-70%'
                }}
              >
                Restam{' '}
                {totalProgrammed
                  ? totalProgrammed - totals.attended > 0
                    ? totalProgrammed - totals.attended
                    : totalProgrammed
                  : 0}
              </span>
            </div>

            <div className='scheduling-exp'>
              <div style={{ height: 520 }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  headerHeight={30}
                  disableColumnMenu
                  rowsPerPageOptions={[8]}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QueueAdmin
