import React, { FormEvent, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import Select from 'react-select'
import { CompactPicker } from 'react-color'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../../components/Input'
import { toast } from 'react-toastify'
import api from '../../../../services/api'

import * as Yup from 'yup'

import './styles.css'

interface RouteParams {
  id: string
}
interface Contact {
  id: number
  name: string
  fone: string
  email: string
  whatsapp: boolean
}

interface IOption {
  label: string
  value: string
}

const implementTypes = [
  { type: 'TRUCK', shaftQuantity: 3, grossCapacity: 23.0, netCapacity: 16.0 },
  {
    type: 'BITRUCK',
    shaftQuantity: 4,
    grossCapacity: 29.0,
    netCapacity: 19.0
  },
  { type: 'TOCO', shaftQuantity: 5, grossCapacity: 41.5, netCapacity: 28.0 },
  {
    type: 'ROMEU & JULIETA',
    shaftQuantity: 6,
    grossCapacity: 50.0,
    netCapacity: 32.5
  },
  {
    type: 'LS (MENOR 16 MT)',
    shaftQuantity: 6,
    grossCapacity: 45.0,
    netCapacity: 31.0
  },
  {
    type: 'LS (MAIOR 16 MT)',
    shaftQuantity: 6,
    grossCapacity: 48.5,
    netCapacity: 33.5
  },
  {
    type: 'VANDERLEIA',
    shaftQuantity: 6,
    grossCapacity: 53.0,
    netCapacity: 36.0
  },
  {
    type: 'BITREM 7 EIXOS',
    shaftQuantity: 7,
    grossCapacity: 57.0,
    netCapacity: 42.0
  },
  {
    type: 'BITREM 8 EIXOS',
    shaftQuantity: 8,
    grossCapacity: 65.5,
    netCapacity: 46.5
  },
  {
    type: 'RODOTREM 9 EIXOS',
    shaftQuantity: 9,
    grossCapacity: 74.0,
    netCapacity: 52.0
  },
  {
    type: 'RODOTREM 11 EIXOS',
    shaftQuantity: 11,
    grossCapacity: 91.0,
    netCapacity: 65.6
  }
]

const truckSchema = Yup.object().shape({
  owner: Yup.string()
    .max(80, 'Tamanho máximo de 80 caracteres')
    .required('Nome do proprietário é obrigatório!'),
  licensePlate: Yup.string()
    .max(7, 'Tamanho máximo de 7 caracteres')
    .required('Placa é obrigatório!'),
  renavam: Yup.string()
    .max(12, 'Tamanho máximo de 12 caracteres')
    .required('Renavam é obrigatório!'),
  vehicleModel: Yup.string()
    .max(50, 'Tamanho máximo de 50 caracteres')
    .required('Modelo é obrigatório!'),
  implementType: Yup.string()
    .max(80, 'Tamanho máximo de 25 caracteres')
    .required('Tipo de implemento é obrigatório!'),
  shaftQuantity: Yup.number().moreThan(0, 'Qtd de eixo é obrigatório!'),

  grossCapacity: Yup.number().moreThan(0, 'Capac. bruta é obrigatório!'),

  netCapacity: Yup.number().moreThan(0, 'Capac. líquida é obrigatório!')
})

export function DriverEditTruck() {
  const { id } = useParams<RouteParams>()
  const history = useHistory()

  const [implementsTypes, setimplementsTypes] = useState<IOption[]>([])
  const [brandTypes, setBrandTypes] = useState<IOption[]>([])
  const [modelTypes, setModelTypes] = useState<IOption[]>([])

  const [contacts, setContacts] = useState<Contact[]>([])
  const [implementValue, setImplementValue] = useState<IOption>({} as IOption)
  const [brandValue, setBrandValue] = useState<IOption>({} as IOption)
  const [modelValue, setModelValue] = useState<IOption>({} as IOption)
  const [color, setColor] = useState<string>('')

  const driverId = sessionStorage.getItem('@AppDriver:id')?.replace(/"/g, '')
  const companyIdStorage = sessionStorage.getItem('@AppDriver:companyId')

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      licensePlate: '',
      renavam: '',
      owner: '',
      vehicleModel: '',
      implementType: '',
      shaftQuantity: 0,
      grossCapacity: 0,
      netCapacity: 0
    },
    validationSchema: truckSchema,
    onSubmit: values => {
      api
        .post('truck/app/edit', {
          id: parseInt(id),
          company: {
            id: parseInt(`${companyIdStorage}`)
          },
          driver: {
            id: Number(driverId)
          },
          color: color,
          ...values,
          vehicleModel: {
            id: values.vehicleModel
          },
          contact: contacts
        })
        .then(() => {
          toast.success('Cadastro realizado com sucesso')
          setTimeout(() => {
            history.push('/app/edit')
          }, 2500)
        })
        .catch(e => {
          toast.error('Erro ao Cadastrar: ' + e.response.data.code)
        })
    }
  })

  useEffect(() => {
    if (id) {
      api.get(`/truck/${id}`).then(response => {
        let licensePlate = response.data.licensePlate

        setValues({
          licensePlate: licensePlate.replace('-', ''),
          renavam: response.data.renavam,
          owner: response.data.owner,
          vehicleModel: response.data.vehicleModel.id,
          implementType: response.data.implementType,
          shaftQuantity: response.data.shaftQuantity,
          grossCapacity: response.data.grossCapacity,
          netCapacity: response.data.netCapacity
        })

        if (response.data.vehicleModel.truckBrand) {
          setBrandValue({
            value: response.data.vehicleModel.truckBrand.id,
            label: response.data.vehicleModel.truckBrand.name
          })
          setModelValue({
            value: response.data.vehicleModel.id,
            label: response.data.vehicleModel.name
          })
        }

        if (response.data.color) setColor(response.data.color)

        setContacts(response.data.contact)
        setImplementValue({
          value: response.data.implementType,
          label: response.data.implementType
        })
      })
    }

    const types: IOption[] = implementTypes.map((item, index) => {
      return { value: item.type, label: item.type }
    })

    setimplementsTypes(types)

    api.get(`/truck-brand/`).then(response => {
      setBrandTypes(
        response.data.map((item: any) => {
          return { value: item.id, label: item.name }
        })
      )
    })
  }, [id, setValues])

  function handleChangeOptionImplement(option: IOption) {
    const _findImplement = implementTypes.find(
      item => item.type === option.value
    )

    setValues({
      ...values,
      implementType: _findImplement?.type || '',
      shaftQuantity: _findImplement?.shaftQuantity || 0,
      grossCapacity: _findImplement?.grossCapacity || 0,
      netCapacity: _findImplement?.netCapacity || 0
    })
    setImplementValue(option)
  }

  function handleChangeOptionBrand(option: IOption) {
    setBrandValue(option)

    api.get(`/truck-model/brand/${option.value}`).then(response => {
      setModelTypes(
        response.data.map((item: any) => {
          return { value: item.id, label: item.name }
        })
      )
    })
  }

  function handleChangeOptionModel(option: IOption) {
    setModelValue(option)
    setValues({ ...values, vehicleModel: option.value })
  }

  function handleChangeComplete(color: string) {
    setColor(color)
  }

  async function handleUpdateTruck(event: FormEvent) {
    event.preventDefault()
    handleSubmit()
  }

  return (
    <div className='new-truck-container'>
      <form onSubmit={handleUpdateTruck} autoComplete='off'>
        <header>
          <div>
            <strong>Editar Caminhão</strong>
          </div>

          <div className='buttons-header'>
            <Link to='/app/edit'>
              <button id='btBack' type='button'>
                <MdKeyboardArrowLeft size={20} color='#fff' />
                Voltar
              </button>
            </Link>
            <button id='btSave' type='submit'>
              <MdDone size={20} color='#fff' />
              Salvar
            </button>
          </div>
        </header>

        <div className='content'>
          <div className='row'>
            <div className={`input ${errors.owner ? 'error' : ''}`}>
              <Input
                name='owner'
                disabled
                placeholder='Proprietário'
                onChange={handleChange}
                value={values.owner}
                label='Proprietário'
                maxLength={50}
              />
              {errors.owner && (
                <div className='validateError'>{errors.owner}</div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className={`input ${errors.licensePlate ? 'error' : ''}`}>
              <Input
                name='licensePlate'
                placeholder='Placa (Apenas letras e números)'
                maskText='#######'
                onChange={e => {
                  if (
                    e.target.value !== '-' &&
                    ' ' &&
                    e.target.value.length < 8
                  ) {
                    e.target.value = e.target.value
                      .trim()
                      .replace(/[^\w\s]/gi, '')

                    handleChange(e)
                  }
                }}
                value={values.licensePlate.slice(0, 9)}
                label='Placa'
                maxLength={9}
              />
              {errors.licensePlate && (
                <div className='validateError'>{errors.licensePlate}</div>
              )}
            </div>

            <div className={`input ${errors.renavam ? 'error' : ''}`}>
              <Input
                name='renavam'
                mask='#########-##'
                placeholder='Renavam'
                value={values.renavam.slice(0, 12)}
                onChange={handleChange}
                label='Renavam'
                maxLength={12}
              />

              {errors.renavam && (
                <div className='validateError'>{errors.renavam}</div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className={`input`}>
              <label>Marca: </label>
              <Select
                className='select-options'
                value={brandValue}
                options={brandTypes}
                onChange={option => handleChangeOptionBrand(option as IOption)}
              />
            </div>

            <div className={`input ${errors.vehicleModel ? 'error' : ''}`}>
              <label>Modelo: </label>

              <Select
                className='select-options'
                value={modelValue}
                options={modelTypes}
                onChange={option => handleChangeOptionModel(option as IOption)}
              />

              {errors.vehicleModel && (
                <div className='validateError'>{errors.vehicleModel}</div>
              )}
            </div>
          </div>

          <div className='row wrap'>
            <div
              className={`input ${errors.implementType ? 'error' : ''}`}
              style={{ top: -50 }}
            >
              <label>Tipo de implemento</label>
              <Select
                className='select-options'
                value={implementValue}
                options={implementsTypes}
                onChange={option =>
                  handleChangeOptionImplement(option as IOption)
                }
              />

              {errors.implementType && (
                <div className='validateError'>{errors.implementType}</div>
              )}
            </div>

            <div className='input'>
              <label>Cor:</label>
              <CompactPicker
                color={color}
                onChangeComplete={e => handleChangeComplete(e.hex)}
              />
            </div>
          </div>

          <div className='row'>
            <div
              className={`input-number ${errors.shaftQuantity ? 'error' : ''}`}
            >
              <Input
                name='shaftQuantity'
                type='number'
                value={values.shaftQuantity}
                onChange={handleChange}
                label='Qtd de eixo'
                disabled
              />

              {errors.shaftQuantity && (
                <div className='validateError'>{errors.shaftQuantity}</div>
              )}
            </div>

            <div
              className={`input-number ${errors.grossCapacity ? 'error' : ''}`}
            >
              <Input
                name='grossCapacity'
                type='number'
                value={values.grossCapacity}
                onChange={handleChange}
                label='Capac. bruta'
                disabled
              />

              {errors.grossCapacity && (
                <div className='validateError'>{errors.grossCapacity}</div>
              )}
            </div>

            <div
              className={`input-number ${errors.netCapacity ? 'error' : ''}`}
            >
              <Input
                name='netCapacity'
                type='number'
                value={values.netCapacity}
                onChange={handleChange}
                label='Capac. líquida'
                disabled
              />

              {errors.shaftQuantity && (
                <div className='validateError'>{errors.shaftQuantity}</div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
