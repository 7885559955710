import React from "react";

import { AiOutlineWarning } from "react-icons/ai";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import api from "../../../../../../services/api";

import { Button } from "@material-ui/core";

interface ToleranceWarningModalProps {
  modalSuspend: any;
  setModalSuspend: any;
  modalTolerance: any;
  companyId: any;
  updateAttendances: any;
  isClosingAttendance: any;
}

export const ToleranceWarningModal: React.FC<ToleranceWarningModalProps> = ({
  modalSuspend,
  setModalSuspend,
  modalTolerance,
  companyId,
  updateAttendances,
  isClosingAttendance,
}) => {
  return (
    <Box className="modal expedition">
      <Modal
        open={modalSuspend}
        onClose={() => setModalSuspend(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className=" suspend-warning body">
          <Box className="container">
            <Box>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="advice">
                  <div>
                    <div className="warn-icon">
                      <AiOutlineWarning />
                    </div>
                    <div className="bold-text">ATENÇÃO! </div>
                  </div>
                  {modalTolerance ? (
                    <div>
                      Ao desativar a tolerância, a fila virtual passa a não
                      descartar veículos atrasados automaticamente e, assim,
                      ficando com o processo mais lento. Deseja continuar?
                    </div>
                  ) : (
                    <div>
                      Ao encerrar o atendimento os carros excedentes com
                      previsão de atendimento para hoje serão deslocados para o
                      dia seguinte, deseja concluir o atendimento por hoje?
                    </div>
                  )}
                </div>
              </Typography>
            </Box>

            <Box className="actions buttons">
              <Button onClick={() => setModalSuspend(false)}>Cancelar</Button>

              {modalTolerance ? (
                <Button
                  onClick={() => {
                    api
                      .post("settings/toggle-tolerance", {
                        company: companyId,
                        tolerance: false,
                      })
                      .then(() => {
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      });
                  }}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  disabled={isClosingAttendance}
                  onClick={updateAttendances}
                >
                  {isClosingAttendance ? "Encerrando..." : "Encerrar"}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
