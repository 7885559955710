import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridCellParams, GridColDef, ptBR } from "@mui/x-data-grid";
import { MdSearch } from "react-icons/md";
import Tooltip from "@material-ui/core/Tooltip";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import cx from "clsx";
import { format } from "date-fns";
import ptBRDateFns from "date-fns/locale/pt-BR";
import api from "../../../../services/api";
import { PopupForm } from "../PopupForm";
import { toast } from "react-toastify";
import { zonedTimeToUtc } from "date-fns-tz";

import "./styles.css";

interface PopupProps {
  companyId: number;
}

interface Popup {
  id: number | null;
  label: string;
  title: string;
  content: string;
  active: boolean;
  start_at: Date;
  end_at: Date;
  imageUrl: string | null;
  views?: number;
  viewsUnique?: number;
  company: {
    id: number;
  };
}

export function Popups({ companyId }: PopupProps) {
  const [popups, setPopups] = useState<Popup[]>([]);

  const [popupFormIsActive, setPopupFormIsActive] = useState(false);

  const [popup, setPopup] = useState<Popup | null>(null);

  function handlePopupForm() {
    setPopupFormIsActive(!popupFormIsActive);
    setPopup(null);
  }

  function handleEditPopup(popup: Popup) {
    setPopup(popup);
    setPopupFormIsActive(true);
  }

  async function handleDeletePopup(id: number) {
    try {
      const response = await api.delete(`/popups/delete/${id}`);

      if (response.status === 200) {
        toast.success("Pop-up deletado com sucesso!");
        setPopups(popups.filter(popup => popup.id !== id));
      }
    } catch (error) {
      toast.error("Erro ao deletar pop-up!");
      console.log(error);
    }
  }

  function handleFormatteDate(date: Date) {
    const timezone = "America/Sao_Paulo";

    const utcDate = zonedTimeToUtc(date, timezone);

    const formattedDate = format(utcDate, "dd/MM/yyyy", {
      locale: ptBRDateFns
    });

    return formattedDate;
  }

  const columns: GridColDef[] = [
    {
      field: "popup",
      headerName: "Pop-up",
      sortable: false,
      width: 280,
      renderCell: (params: GridCellParams) => {
        const { active, id, label } = params.row as Popup;

        return (
          <div>
            <div>
              <span className="popup-id">{id}</span>
              <span
                className={cx("popup-status", {
                  active: active,
                  inactive: !active
                })}
              >
                {active ? "Ativo" : "Inativo"}
              </span>
            </div>

            <p className="popup-description">{label}</p>
          </div>
        );
      }
    },
    {
      field: "inicio",
      headerName: "Início",
      width: 150,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { start_at } = params.row as Popup;
        const formattedDate = handleFormatteDate(new Date(start_at));

        return (
          <div>
            <span>{formattedDate}</span>
          </div>
        );
      }
    },
    {
      field: "fim",
      headerName: "Fim",
      width: 150,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { end_at } = params.row as Popup;
        const formattedDate = format(new Date(end_at), "dd/MM/yyyy");

        return (
          <div>
            <span>{formattedDate}</span>
          </div>
        );
      }
    },
    {
      field: "visual",
      headerName: "Visual.",
      width: 150,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { views } = params.row as Popup;

        return (
          <div className="popup-views">
            <span>{views}</span>
          </div>
        );
      }
    },
    {
      field: "unicos",
      headerName: "Únicos",
      width: 150,
      sortable: false,

      renderCell: (params: GridCellParams) => {
        const { viewsUnique } = params.row as Popup;

        return (
          <div className="popup-views">
            <span>{viewsUnique}</span>
          </div>
        );
      }
    },
    {
      field: "action",
      headerName: "Ações",
      width: 150,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const popup = params.row as Popup;

        return (
          <div
            style={{
              display: "flex",
              gap: 4,
              alignItems: "center"
            }}
          >
            <Tooltip className="edit-icon" title={<h1>Editar</h1>}>
              <Link to={`#`}>
                <AiOutlineEdit onClick={() => handleEditPopup(popup)} />
              </Link>
            </Tooltip>

            <Tooltip className="delete-icon" title={<h1>Deletar</h1>}>
              <Link to={`#`}>
                <AiOutlineDelete onClick={() => handleDeletePopup(popup.id!)} />
              </Link>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  async function getPopups() {
    const response = await api.get<Popup[]>(`/popups/company/${companyId}`);

    const popups = response.data;

    const popupsUpdated = await Promise.all(
      popups.map(async popup => {
        const viewsCountResponse = await api.get<{ total: number }>(
          `/popups/view-count/${popup.id}`
        );

        const views = viewsCountResponse.data.total;

        const viewsUniqueResponse = await api.get<{ total: number }>(
          `/popups/users-count/${popup.id}`
        );
        const viewsUnique = viewsUniqueResponse.data.total;

        return {
          ...popup,
          views,
          viewsUnique
        };
      })
    );

    setPopups(popupsUpdated);
  }

  useEffect(() => {
    async function get() {
      getPopups();
    }

    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function get() {
      getPopups();
    }

    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupFormIsActive]);

  return (
    <div className="popups">
      {popupFormIsActive ? (
        <PopupForm
          popup={popup}
          companyId={companyId}
          onClosePopupForm={handlePopupForm}
        />
      ) : (
        <>
          <div className="popups-actions">
            <div>
              <input type="text" placeholder="Filtro" />
              <MdSearch fontSize={26} color="#999999" />
            </div>

            <button type="button" onClick={handlePopupForm}>
              Novo popup
            </button>
          </div>

          {popups.length > 0 && (
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              columns={columns}
              rows={popups}
              disableColumnMenu
            />
          )}
        </>
      )}
    </div>
  );
}
