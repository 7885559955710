import React from 'react'
import { ExpeditionQueueStatusBox } from './ExpeditionQueueStatusBox/ExpeditionQueueStatusBox'
import { QueueStatusService } from '../../ExpeditionHome'
import './styles.scss'

export interface ExpeditionQueueStatusProps {
  queueStatus: QueueStatusService
  isExpeditionViewer: boolean
  isConciergeViewer: boolean
  checkinTotal: number
}

export function ExpeditionQueueStatus({
  queueStatus,
  isExpeditionViewer,
  isConciergeViewer,
  checkinTotal
}: ExpeditionQueueStatusProps) {
  const queueStatusIsVisible = isConciergeViewer ? false : true
  const queueTransportedToday = queueStatus.totalTransportedToday
    .toString()
    .substring(0, 4)

  const serviceStatus = [
    {
      key: 'scheduled',
      status: 'Programados',
      value: queueStatus.totals,
      isVisible: true
    },
    {
      key: 'courtyard',
      status: 'Pátio',
      value: checkinTotal,
      isVisible: true
    },
    {
      key: 'attended',
      status: 'Atendidos',
      value: queueStatus.attended,
      isVisible: queueStatusIsVisible
    },
    {
      key: 'missing',
      status: 'Faltosos',
      value: queueStatus.missing,
      isVisible: true
    },
    {
      key: 'tons',
      title: 'Toneladas',
      status: '',
      value: queueTransportedToday,
      isVisible: queueStatusIsVisible
    },
    {
      key: 'dropout',
      status: 'Desistentes',
      value: queueStatus.dropouts,
      isVisible: true
    }
  ]

  return (
    <div className='service-status-container'>
      <div>
        {serviceStatus.slice(0, 3).map(service => (
          <ExpeditionQueueStatusBox
            key={service.key}
            service={service}
            className={service.key}
            isExpeditionViewer={isExpeditionViewer}
            isConciergeViewer={isConciergeViewer}
          />
        ))}
      </div>

      <div>
        {serviceStatus.slice(3, 6).map(service => (
          <ExpeditionQueueStatusBox
            key={service.key}
            service={service}
            className={service.key}
            isExpeditionViewer={isExpeditionViewer}
            isConciergeViewer={isConciergeViewer}
          />
        ))}
      </div>
    </div>
  )
}
