import React from "react";
import jsPDF from "jspdf";
import { Button } from "@material-ui/core";
import { GridColDef } from "@mui/x-data-grid";
import { GrDocumentPdf } from "react-icons/gr";
import { UserOptions } from "jspdf-autotable";

interface ExportPDFButtonProps {
  rows: any[];
  columns: GridColDef[] | string[];
}

interface jsPDFCustom extends jsPDF {
  autoTable: (content: UserOptions) => void;
}

export function ExportPDFButton({
  rows,
  columns,
}: ExportPDFButtonProps) {
  const exportPDF = (rows: any) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size) as jsPDFCustom;

    doc.setFontSize(15);


    const title = `Conjuntos`;
    const headers = [[...columns]];

    const data = rows.map((e: any) => {
      e = {
        name: e.name,
        cpf: e.cpf,
        truck: e.truck?.licensePlate,
        shippingCompany: e.shippingCompany,
        company: e.company?.name
      }


      const obReportject: any = Object.values(e);
      console.log({ e })

      obReportject.shift();

      return obReportject;
    });

    let content = {
      startY: 50,
      head: headers,
      body: data,
      headStyles: {
        fillColor: "#fc8f00",
      },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content as unknown as UserOptions);
    doc.save(`Conjuntos.pdf`);
  };

  return (
    <Button onClick={() => exportPDF(rows)}>
      <GrDocumentPdf />
    </Button>
  );
}
