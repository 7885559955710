import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import AppToolBar from '../App/components/AppToolBar';
import "./styles.css"

const Maps: React.FC = () =>{
    const [map, setMap] = useState('')
    // const [company, setCompany] = useState()

    useEffect(() => {
        api.get(`/company/host/`, {
            params: {
              host: window.location.host
            }
          }).then(response => {
            // setCompany(response.data)

            api.get(`/settings/company/${response.data.id}`).then(response => {
                setMap(response.data.googleMaps)
            })
        })
    }, [])

    return (
        <section className='locationCompany'>
            <div className="header">
              <AppToolBar pathToBack="/" isStatic={false}/>
            </div>
            {map ? (
                <iframe title="locationCompany" src={map} ></iframe>
            ):(
                <></>
            )}
        </section>
    )
}
export default Maps;