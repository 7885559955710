import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { Link, useHistory, useParams } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../components/Input'
import api from '../../../services/api'

import { useFormik } from 'formik'

import './styles.css'
import { toast } from 'react-toastify'
import ModalDelete from '../../../components/ModalDelete'
import { useAuth } from '../../../contexts/auth'

interface RouteParams {
  id: string
}

interface Customer {
  code: number
  name: string
}

interface ShippingCompany {
  name: string
}

interface iOption {
  label: string
  value: string
}

interface Order {
  id: number
  status: string
  customer: Customer
}

interface Product {
  id: number
  name: string
}

interface OrderItem {
  id: number
  quantity: number
  product: Product
}

const NewEditOrder: React.FC = (): JSX.Element => {
  const [customerOptions, setCustomerOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [customerValue, setCustomerValue] = useState<iOption>({
    value: '',
    label: ''
  })

  const [shippingOptions, setShippingOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [shippingValue, setShippingValue] = useState<iOption>({
    value: '',
    label: ''
  })

  const [productOptions, setProductOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [productValue, setProductValue] = useState<Product>({ id: 0, name: '' })

  const [orderItems, setOrderItems] = useState<OrderItem[]>([])
  const [quantity, setQuantity] = useState<string>('')
  const [quantityTransported, setQuantityTransported] = useState<string>('')

  const [modal, setModal] = useState('')
  const [nameModal, setNameModal] = useState('')
  const [orderItemId, setOrderItemId] = useState(0)
  const [key, setKey] = useState(0)

  const [sendEmail, setSendEmail] = useState(false)

  const { role, company } = useAuth()

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      id: '',
      code: 0
    },

    onSubmit: values => {
      api
        .post('order/save', {
          id: parseInt(values.id),
          code: values.code,
          customer: { id: customerValue.value },
          qtyTransported: quantityTransported,
          sendEmail: sendEmail,
          shippingCompany: { id: parseInt(shippingValue.value) },
          orderItem: orderItems,
          company: { id: company ? company : null }
        })
        .then(() => {
          toast.success('Cadastro realizado com sucesso')
          setTimeout(() => {
            history.push('/pedidos')
          }, 2500)
        })
        .catch(e => {
          toast.error('Erro ao Cadastrar: ' + e.response.data.message)
        })
    }
  })

  const { id } = useParams<RouteParams>()

  const history = useHistory()

  useEffect(() => {
    if (id) {
      api.get(`/order/${id}`).then(response => {
        setValues({
          id: response.data.id,
          code: response.data.code
        })

        setQuantityTransported(response.data?.qtyTransported)
        const { customer } = response.data
        setCustomerValue({
          value: customer.id,
          label: `${customer.code} -  ${customer.name}`
        })

        const { shippingCompany } = response.data
        setShippingValue({
          value: shippingCompany.id,
          label: `${shippingCompany.code} -  ${shippingCompany.name}`
        })

        setOrderItems(response.data.orderItem)
        if (response.data.orderItem[0]) {
          setProductValue({
            id: parseInt(response.data.orderItem[0].product.id),
            name: response.data.orderItem[0].product.name
          })
          setProductOptions([
            {
              value: response.data.orderItem[0].product.id,
              label: response.data.orderItem[0].product.name
            }
          ])
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: iOption[] = []

    if (value) {
      let path = `/${patch}/like/${value}`

      if (role !== 'Administrador') {
        path = path + `/company/${company}`
      }
      api.get(path).then(response => {
        options_ = response.data.map((item: any) => {
          return { value: item.id, label: `${item.code} - ${item.name}` }
        })
        setOption(options_)
      })
    }
  }

  function handleChangeOptionCustomer(option: iOption) {
    setCustomerValue(option)
  }

  function handleChangeOptionProduct(option: iOption) {
    setProductValue({
      id: parseInt(option.value),
      name: option.label.substring(option.label.indexOf('-') + 2)
    })
  }

  function handleChangeOptionShipping(option: iOption) {
    setShippingValue(option)
  }

  function handleChangeQuantity(event: React.ChangeEvent<HTMLInputElement>) {
    setQuantity(event.target.value)
  }

  function handleChangeQuantityTransported(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setQuantityTransported(event.target.value)
  }

  function handleAddProduct() {
    setOrderItems([
      ...orderItems,
      {
        id: 0,
        quantity: parseInt(quantity),
        product: { id: productValue.id, name: productValue.name }
      }
    ])
    setQuantity('')
  }

  function handleCorfirmDelete(orderItem: OrderItem) {
    showModal()
    setNameModal(orderItem.product.name)
    setOrderItemId(orderItem.id)
    setKey(orderItem.id)
  }

  function handleDeleteContact(id: number) {
    hideModal()

    if (id) {
      api
        .delete(`/orderItem/delete/${id}`)
        .then(response => {
          if (response.status === 200) {
            toast.success('Excluido com sucesso!')
            const results = orderItems.filter(item => item.id !== id)
            setOrderItems(results)
          }
        })
        .catch(() => {
          toast.success('Erro ao excluir!')
        })
    } else {
      const results = orderItems.filter(item => item.id !== key)
      setOrderItems(results)
    }
  }

  function handlesendEmail() {
    setSendEmail(!sendEmail)
  }

  function handleCloseModal() {
    hideModal()
  }
  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  return (
    <div className='new-order-container'>
      <ModalDelete
        type='Produto'
        name={nameModal}
        id={orderItemId}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteContact}
      />

      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>
              {`${!id ? ' Cadastrar ' : 'Editar'}`} Pedido{!id ? 's' : ''}
            </strong>
          </div>

          <div className='buttons-header' style={{ flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Link to='/pedidos'>
                <button id='btBack' type='button'>
                  <MdKeyboardArrowLeft size={20} color='#fff' />
                  Voltar
                </button>
              </Link>
              <button style={{ marginLeft: 10 }} id='btSave' type='submit'>
                <MdDone size={20} color='#fff' />
                Salvar
              </button>
            </div>
            <div>
              <input
                type='checkbox'
                {...(sendEmail ? { checked: true } : '')}
                onChange={handlesendEmail}
              />
              <label> Informar transportadora por e-mail:</label>
            </div>
          </div>
        </header>

        <div className='content'>
          <div className='row'>
            <div
              style={{ width: '140px' }}
              className={`input ${errors.id ? 'error' : ''}`}
            >
              <Input
                name='code'
                placeholder='Código'
                onChange={handleChange}
                value={values.code}
                label='Código'
                disabled={id ? true : false}
              />

              {errors.id && <div className='validateError'>{errors.id}</div>}
            </div>

            <div className='qntTransported' style={{ width: '250px' }}>
              <Input
                name='qntTransported'
                placeholder='Quantidade'
                onChange={e => handleChangeQuantityTransported(e)}
                value={quantityTransported}
                type='number'
                label='Quantidade entregue:'
              />
            </div>
          </div>

          <div className='row'>
            <div className='select-customer'>
              <label>Cliente:</label>
              <Select
                className='select-options'
                value={customerValue}
                options={customerOptions}
                onInputChange={value =>
                  handleChangeInputSelect(value, 'customer', setCustomerOptions)
                }
                onChange={option =>
                  handleChangeOptionCustomer(option as iOption)
                }
              />
            </div>
          </div>
          <br />
          <div className='row'>
            <div className='select-shipping'>
              <label>Transportadora:</label>
              <Select
                className='select-options'
                value={shippingValue}
                options={shippingOptions}
                onInputChange={value =>
                  handleChangeInputSelect(
                    value,
                    'shipping-company',
                    setShippingOptions
                  )
                }
                onChange={option =>
                  handleChangeOptionShipping(option as iOption)
                }
              />
            </div>
          </div>
          <br />
          <div className='row flexpertinho'>
            <div className='select-product'>
              <label>Produtos:</label>
              <Select
                className='select-options'
                value={productOptions[0]}
                options={productOptions}
                onInputChange={value =>
                  handleChangeInputSelect(value, 'product', setProductOptions)
                }
                onChange={option =>
                  handleChangeOptionProduct(option as iOption)
                }
              />
            </div>

            <div className='qnt' style={{ width: '140px' }}>
              <Input
                name='quantity'
                placeholder='Quantidade'
                onChange={e => handleChangeQuantity(e)}
                value={quantity}
                type='number'
                label='Quantidade:'
              />
            </div>
            <button
              type='button'
              id='addProduct'
              onClick={handleAddProduct}
              {...(!quantity || !productValue.id
                ? { disabled: true }
                : { disabled: false })}
            >
              + Adicionar
            </button>
          </div>
        </div>

        <div className='products'>
          <fieldset>
            <legend>Produtos</legend>
            <table>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Nome</th>
                  <th>Quantidade</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {orderItems.length > 0 ? (
                  orderItems.map((orderItem, index) => (
                    <tr key={index}>
                      <td>{orderItem.product.id}</td>
                      <td>{orderItem.product.name}</td>
                      <td>{orderItem.quantity}t</td>

                      <td>
                        <button type='button' className='actionButton blue'>
                          editar
                        </button>

                        <button
                          type='button'
                          className='actionButton red'
                          onClick={() => handleCorfirmDelete(orderItem)}
                        >
                          excluir
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      Nenhum resultado foi encontrado
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </fieldset>
        </div>
      </form>

      <br />
    </div>
  )
}

export default NewEditOrder
