import { useEffect, useState } from 'react'
import { queueServices } from '../queueService'
import { Queue } from '../queueTypes'

type From = 'company' | 'shipping-company'

export function useGetQueue(companyId: string, from: From) {
  const [data, setData] = useState<Queue[]>([])
  const [loading, setLoading] = useState(false)

  async function fetchData() {
    try {
      setLoading(true)
      const queue = await queueServices.getQueueCompany({
        companyId,
        from
      })

      setData(queue)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    list: data,
    isLoading: loading
  }
}
