import api from '../../services/api'
import { Queue, QueuePosition, ServiceStatusApi } from './queueTypes'

async function getQueueShippingCompanyPositions(
  shippingCompanyId: string
): Promise<QueuePosition[]> {
  const path = `/queue/shipping-company/${shippingCompanyId}/positions`

  const response = await api.get(path)
  const data = response.data

  return data
}

async function getQueueCompany(companyId: string) {
  const response = await api.get<Queue[]>(`queue/company/${companyId}`)
  return response.data
}

async function getQueueShippingCompany(shippingCompanyId: string) {
  const response = await api.get<Queue[]>(
    `queue/shipping-company/${shippingCompanyId}`
  )

  return response.data
}

async function getQueueActiveCompany(companyId: string) {
  const response = await api.get<Queue[]>(`queue/active/company/${companyId}`)

  return response.data
}

async function getQueueActiveShippingCompany(companyId: string) {
  const response = await api.get<Queue[]>(
    `queue/active/shipping-company/${companyId}`
  )

  return response.data
}

export async function getServicesStatus(companyId: string) {
  const response = await api.get<ServiceStatusApi>(
    `/queue/totals/company/${companyId}`
  )
  return response.data
}

export const queueApi = {
  getQueueCompany,
  getQueueShippingCompany,
  getQueueActiveCompany,
  getQueueActiveShippingCompany,
  getQueueShippingCompanyPositions,
  getServicesStatus
}
