import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
  ptBR
} from '@mui/x-data-grid'
import Select from 'react-select'

import api from '../../services/api'
import LogDetails from '../../components/LogDetails'
import { Box, Button, Modal, Tooltip, Typography } from '@material-ui/core'
import './style.css'
import Input from '../../components/Input'
import { toast } from 'react-toastify'
import load from '../../assets/images/carregando.svg'

interface Name {
  name: string
}

interface Data {
  body: Name
}

interface Logs {
  id: number
  title: string
  details: any
  company: string
}
interface Company {
  id: number
  name: string
}
interface iOption {
  label: string
  value: string
}

const Logs: React.FC = () => {
  let date = new Date()
  date.setDate(date.getDate() - 1)

  const [maxDate, setMaxDate] = useState<any>(
    new Date().toISOString().split('T')[0]
  )
  const [minDate, setMinDate] = useState<any>(date.toISOString().split('T')[0])
  const [log, setLog] = useState<any>({})
  const [logs, setLogs] = useState<any[]>([])
  const [rows, setRows] = useState<any[]>([])

  const [clientValue, setClientValue] = useState<any>()
  const [clients, setClients] = useState<any>()
  const [clientsBySearch, setClientsBySearch] = useState<any>()

  const [userValue, setUserValue] = useState<any>()
  const [users, setUsers] = useState<any>()
  const [usersBySearch, setUsersBySearch] = useState<any>()

  const [orderValue, setOrderValue] = useState<any>()
  const [orders, setOrders] = useState<any>()
  const [ordersBySearch, setOrdersBySearch] = useState<any>()
  const [loading, setLoading] = useState(false)

  const [companyValue, setCompanyValue] = useState<any>()
  const [companies, setCompanies] = useState<iOption[]>([
    { value: '', label: '' }
  ])

  useEffect(() => {
    api.get(`/company`).then(response => {
      if (response.data) {
        setCompanies(
          response.data?.map((item: Company) => {
            return { label: `${item.id} - ${item.name}`, value: item.id }
          })
        )
      }
    })

    api.get(`/logs/operations`).then(response => {
      const operations = Object.values(response.data)

      const clientsOptions = operations?.map((item: any) => {
        return { value: item.id, label: item.label }
      })

      setClients(clientsOptions)
      setClientsBySearch(clientsOptions)
    })

    api.get(`/logs/objects`).then(response => {
      const objects = Object.values(response.data)

      const clientsOptions = objects?.map((item: any) => {
        return { value: item.id, label: item.label }
      })

      setOrders(clientsOptions)
      setOrdersBySearch(clientsOptions)
    })

    api.get(`/users`).then(response => {
      const objects = Object.values(response.data)

      const userOptions = objects?.map((item: any) => {
        return { value: item.id, label: item.name }
      })

      setUsers(userOptions)
      setUsersBySearch(userOptions)
    })

    handleSearch(1)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 75 },
    {
      field: 'title',
      headerName: 'Título',
      width: 800,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: params.row.title }}></div>
        )
      }
    },
    { field: 'company', headerName: 'Empresa', width: 200 },
    {
      field: 'details',
      headerName: 'Detalhes',
      width: 180,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div>
            {params.row.details.length > 0 ? 'Ver detalhes' : 'Não há detalhes'}
          </div>
        )
      }
    }
  ]

  function handleChangeOptionClient(option: any, updateSelectedState: any) {
    updateSelectedState({ value: option.value, label: option.label })
  }

  function handleChangeInputSelect(value: string, lists: any[]) {
    if (value) {
      const search = value
      const results = lists?.filter((item: any) => {
        if (item.label?.toString().toLowerCase().includes(search)) {
          return { value: item.id, label: item.label }
        } else {
          return ''
        }
        //
      })

      setClientsBySearch(results)
    }
  }

  function handleSearch(page: number) {
    setLoading(true)

    const searchObject = {
      startDate: minDate,
      endDate: maxDate,
      operation_type: clientValue?.value,
      object_type: orderValue?.value,
      companyId: companyValue?.value,
      userId: userValue?.value,
      pageSize: 30,
      page: page
    }

    api
      .get('/logs', { params: searchObject })
      .then(response => {
        setLogs(response.data)

        setRows(
          response.data.map(
            (
              item: { id: any; title: any; company: any; details: any },
              index: number
            ) => {
              item.id = index

              return {
                id: index + 1,
                title: item.title,
                company: item.company,
                details: item.details
              }
            }
          )
        )
      })
      .then(() => {
        setLoading(false)
        toast.success('Consulta finalizada!')
      })
      .catch(() => {
        setLoading(false)
        toast.error('Erro ao consultar!')
      })
  }

  function loadMore() {
    const page = logs.length / 30 + 1

    setLoading(true)

    const searchObject = {
      startDate: minDate,
      endDate: maxDate,
      operation_type: clientValue?.value,
      object_type: orderValue?.value,
      companyId: companyValue?.value,
      userId: userValue?.value,
      pageSize: 30,
      page: page
      //fromId: 100
    }

    api
      .get('/logs', { params: searchObject })
      .then(response => {
        const array = response.data

        logs.push(...array)

        setRows(
          logs.map(
            (
              item: { id: any; title: any; company: any; details: any },
              index: number
            ) => {
              item.id = index

              return {
                id: index + 1,
                title: item.title,
                company: item.company,
                details: item.details
              }
            }
          )
        )
      })
      .then(() => {
        setLoading(false)
        toast.success('Consulta finalizada!')
      })
      .catch(() => {
        setLoading(false)
        toast.error('Erro ao consultar!')
      })
  }

  function handleClose() {
    setLog('')
  }

  function handleSelectScheduling(x: any) {
    const log = logs.find((item: any, index: any) => index + 1 === x.row.id)

    setLog(log)
  }

  let startDate = new Date(minDate)
  let finalDate = new Date(maxDate)
  startDate.setDate(startDate.getDate() - 1)
  finalDate.setDate(finalDate.getDate() + 1)

  function ReturnSuspendWarning() {
    return (
      <>
        <Box className='modal load'>
          <Modal
            open={loading}
            onClose={() => setLoading(true)}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            className='modal-instance'
          >
            <Box className=' suspend-loading body'>
              <Box className='container'>
                <Box>
                  <Typography
                    id='modal-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    <div className='loading'>
                      <img src={load} alt='load' />
                    </div>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      </>
    )
  }

  function checkIfMore() {
    if (Number.isInteger(logs.length / 30)) {
      return false
    }

    return true
  }

  return (
    <div className={`historic-list productList ${loading ? 'blur-1' : ''}`}>
      <ReturnSuspendWarning />

      <header>
        <div>
          <strong>Logs</strong>
        </div>
      </header>
      <div className='history-area log search'>
        <div className='infor-container'>
          <div className='row'>
            <div className={`input select`}>
              <label>Operações </label>
              <div className='select'>
                <Select
                  className='select-options'
                  value={clientValue}
                  options={clientsBySearch}
                  onInputChange={value =>
                    handleChangeInputSelect(value, clients)
                  }
                  onChange={option =>
                    handleChangeOptionClient(option as iOption, setClientValue)
                  }
                />
                {clientValue && (
                  <Button
                    className='erase-button'
                    onClick={() => setClientValue(null)}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>

            <div className={`input select`}>
              <label>Objetos </label>
              <div className='select'>
                <Select
                  className='select-options'
                  value={orderValue}
                  options={ordersBySearch}
                  onInputChange={value =>
                    handleChangeInputSelect(value, orders)
                  }
                  onChange={option =>
                    handleChangeOptionClient(option as iOption, setOrderValue)
                  }
                />
                {orderValue && (
                  <Button
                    className='erase-button'
                    onClick={() => setOrderValue(null)}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>

            <div className={`input select`}>
              <label>Usuários </label>
              <div className='select'>
                <Select
                  className='select-options'
                  value={userValue}
                  options={usersBySearch}
                  onInputChange={value => handleChangeInputSelect(value, users)}
                  onChange={option =>
                    handleChangeOptionClient(option as iOption, setUserValue)
                  }
                />
                {userValue && (
                  <Button
                    className='erase-button'
                    onClick={() => setUserValue(null)}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>
            <div className='company input select'>
              <label>Empresa</label>
              <div className='select select-company'>
                <Select
                  className='select-options'
                  value={companyValue}
                  options={companies}
                  onChange={option =>
                    handleChangeOptionClient(option as iOption, setCompanyValue)
                  }
                />

                {companyValue && (
                  <Button
                    className='erase-button t'
                    onClick={() => {
                      setCompanyValue({ value: '', label: '' })
                    }}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className='row'>
            <div className={`input date`}>
              <label>De:</label>
              <div className='select'>
                <Input
                  value={minDate}
                  onChange={e => setMinDate(e.target.value)}
                  type='date'
                />
                {minDate && (
                  <Button
                    className='erase-button'
                    onClick={() => setMinDate('')}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>
            <div className={`input date`}>
              <label>Até:</label>
              <div className='select'>
                <Input
                  value={maxDate}
                  onChange={e => setMaxDate(e.target.value)}
                  type='date'
                  min={minDate}
                />
                {maxDate && (
                  <Button
                    className='erase-button'
                    onClick={() => setMaxDate('')}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>
          </div>

          <button
            id='btSave'
            onClick={() => {
              handleSearch(1)
            }}
            type='submit'
          >
            Pesquisar
          </button>

          <Tooltip
            title={<h1>Carregar mais registros dessa pesquisa</h1>}
            arrow
          >
            <button
              id='btSave'
              disabled={checkIfMore()}
              className={checkIfMore() ? 'disabled' : ''}
              onClick={() => {
                loadMore()
              }}
            >
              Carregar mais
            </button>
          </Tooltip>
        </div>
      </div>

      {log?.id > -1 ? (
        <div className='log-details'>
          <LogDetails id={log?.id} info={log} handleClose={handleClose} />
        </div>
      ) : (
        <></>
      )}

      <div className='logList' style={{ height: 658 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableColumnMenu
          components={{ Toolbar: GridToolbar }}
          rowsPerPageOptions={[8]}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          onRowClick={(e: any) => handleSelectScheduling(e)}
        />
      </div>
    </div>
  )
}

export default Logs
