import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { RouterWrapper, RouterExpeditionWrapper, RouterTermWrapper } from '.'
import Company from '../pages/Company'
import NewEditCompany from '../pages/Company/NewEditCompany'
import Logs from '../pages/Logs'
import Customer from '../pages/Customer'
import NewEditCustomer from '../pages/Customer/NewEditCustomer'
import ShippingCompany from '../pages/ShippingCompany'
import NewEditShippingCompany from '../pages/ShippingCompany/NewEditShippingCompany'

import Landing from '../pages/Landing'
import Dashboard from '../pages/Dashboard'

import Login from '../pages/Login'
import { ExpeditionLogin } from '../pages/App/Expedition/ExpeditionLogin/ExpeditionLogin'

import User from '../pages/User'
import Product from '../pages/Product'
import NewEditProduct from '../pages/Product/NewEditProduct'
import Order from '../pages/Order'
import NewEditOrder from '../pages/Order/NewEditOrder'
import Driver from '../pages/Driver'
import NewEditDriver from '../pages/Driver/NewEditDriver'
import Truck from '../pages/Truck'
import NewEditTruck from '../pages/Truck/NewEditTruck'
import Scheduling from '../pages/Order/Scheduling'
import NewEditUser from '../pages/User/NewEditUser'
import EditLoggedUser from '../pages/User/EditLoggedUser'
import EditLoggedAdmin from '../pages/User/EditLoggedAdmin'
import Settings from '../pages/Settings'
import Lgpd from '../pages/Lgpd'
import NewLgpd from '../pages/Lgpd/NewEditLgpd/index'
import AproveTerm from '../pages/Lgpd/AproveTerm/index'
import ShippingDashboard from '../pages/ShippingCompany/Dashboard/Index'
import Joints from '../pages/Joints'
import JointBindShippingCompany from '../pages/Joints/bindShippingCompany'

import { ExpeditionHome } from '../pages/App/Expedition/ExpeditionHome/ExpeditionHome'
import DriverLogs from '../pages/Driver/Logs/index'

import NewBrand from '../pages/Brands/NewBrand'
import EditBrand from '../pages/Brands/EditBrand'
import Brand from '../pages/Brands/index'

import NewTruckModel from '../pages/TruckModels/NewModel'
import EditTruckModel from '../pages/TruckModels/EditModel'
import TruckModel from '../pages/TruckModels/index'

import Elimidia from '../pages/Elemedia/index'
import History from '../pages/loadHistory/index'
import QueueAdmin from '../pages/Queue'

import Recover from '../pages/Recover'
import { DriverRoutes } from './DriverRoutes'

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <RouterWrapper
          title='Login'
          path='/login'
          roles={['Usuario', 'Administrador', 'Transportadora', 'Login']}
        >
          <Login />
        </RouterWrapper>

        <RouterWrapper
          title='Home'
          path='/admin'
          exact
          roles={['Usuario', 'Administrador', 'Transportadora']}
        >
          <Landing />
        </RouterWrapper>

        <RouterWrapper
          title='Histórico'
          path='/transportadora/dashboard'
          roles={['Administrador', 'Usuario', 'Transportadora']}
        >
          <ShippingDashboard />
        </RouterWrapper>
        <RouterWrapper
          title='Editar Perfil'
          path='/admin/edit'
          exact
          roles={['Administrador']}
        >
          <EditLoggedAdmin />
        </RouterWrapper>

        <RouterWrapper title='Fila Virtual' path='/fila' roles={['Usuario']}>
          <QueueAdmin />
        </RouterWrapper>

        <RouterWrapper
          title='Dashboard'
          path='/Dashboard'
          roles={['Usuario', 'Administrador']}
        >
          <Dashboard />
        </RouterWrapper>

        <RouterWrapper
          title='Usuarios'
          ext={true}
          path='/usuarios'
          roles={['Administrador']}
        >
          <User />
        </RouterWrapper>

        <RouterWrapper
          title='Usuarios'
          path='/user/edit'
          roles={['Usuario', 'Transportadora']}
          exact
        >
          <EditLoggedUser />
        </RouterWrapper>

        <RouterWrapper
          title='Usuarios'
          path='/usuario/novo/:id?'
          roles={['Administrador']}
        >
          <NewEditUser />
        </RouterWrapper>

        <RouterWrapper
          title='Histórico'
          path='/historico'
          roles={['Administrador']}
        >
          <History />
        </RouterWrapper>

        <RouterWrapper
          title='Empresas'
          ext={true}
          path='/empresas'
          roles={['Administrador']}
        >
          <Company />
        </RouterWrapper>

        <RouterWrapper
          title='Cadastro de empresas'
          path='/empresa/novo/:id?'
          roles={['Administrador']}
        >
          <NewEditCompany />
        </RouterWrapper>

        <RouterWrapper
          title='Logs'
          ext={true}
          path='/logs'
          roles={['Administrador']}
        >
          <Logs />
        </RouterWrapper>

        <RouterWrapper
          title='Pedidos'
          ext={true}
          path='/pedidos'
          roles={['Transportadora', 'Usuario', 'Administrador']}
        >
          <Order />
        </RouterWrapper>

        <RouterWrapper
          title='Cadastro de pedidos'
          path='/pedido/novo/:id?'
          roles={['Usuario', 'Administrador']}
        >
          <NewEditOrder />
        </RouterWrapper>

        <RouterWrapper
          title='Agendamento de pedidos'
          path='/agendamento/novo/:id?'
          roles={['Transportadora', 'Usuario', 'Administrador']}
        >
          <Scheduling />
        </RouterWrapper>

        <RouterWrapper
          title='Clientes'
          ext={true}
          path='/clientes'
          roles={['Usuario', 'Administrador']}
        >
          <Customer />
        </RouterWrapper>

        <RouterWrapper
          title='Cadastro de clientes'
          path='/cliente/novo/:id?'
          roles={['Usuario', 'Administrador']}
        >
          <NewEditCustomer />
        </RouterWrapper>

        <RouterWrapper
          title='Transportadoras'
          ext={true}
          path='/transportadoras'
          roles={['Usuario', 'Administrador']}
        >
          <ShippingCompany />
        </RouterWrapper>

        <RouterWrapper
          title='Cadastro de transportadoras'
          path='/transportadora/novo/:id?'
          roles={['Usuario', 'Administrador']}
        >
          <NewEditShippingCompany />
        </RouterWrapper>

        <RouterWrapper
          title='Produtos'
          ext={true}
          path='/produtos'
          roles={['Usuario', 'Administrador']}
        >
          <Product />
        </RouterWrapper>

        <RouterWrapper
          title='Cadastro de Produtos'
          path='/produto/novo/:id?'
          roles={['Usuario', 'Administrador']}
        >
          <NewEditProduct />
        </RouterWrapper>

        <RouterWrapper
          title='Motoristas Logs'
          path='/motoristas/logs/:id?'
          roles={['Transportadora', 'Usuario', 'Administrador']}
        >
          <DriverLogs />
        </RouterWrapper>

        <RouterWrapper
          title='Cadastro de Motoristas'
          path='/motorista/novo/:id?'
          roles={['Transportadora', 'Usuario', 'Administrador']}
        >
          <NewEditDriver />
        </RouterWrapper>

        <RouterWrapper
          title='Motoristas'
          ext={true}
          path='/motoristas/'
          roles={['Transportadora', 'Usuario', 'Administrador']}
        >
          <Driver />
        </RouterWrapper>

        <RouterWrapper
          title='Caminhões'
          ext={true}
          path='/caminhoes'
          roles={['Transportadora', 'Usuario', 'Administrador']}
        >
          <Truck />
        </RouterWrapper>

        <RouterWrapper
          title='Cadastro de Caminhões'
          path='/caminhao/novo/:id?'
          roles={['Transportadora', 'Usuario', 'Administrador']}
        >
          <NewEditTruck />
        </RouterWrapper>

        <RouterWrapper
          title='Marcas'
          ext={true}
          path='/marcas'
          roles={['Administrador']}
        >
          <Brand />
        </RouterWrapper>

        <RouterWrapper
          title='Cadastro de Marcas'
          path='/marca/novo/'
          exact
          roles={['Administrador']}
        >
          <NewBrand />
        </RouterWrapper>

        <RouterWrapper
          title='Edição de Marcas'
          path='/marca/:id'
          exact
          roles={['Administrador']}
        >
          <EditBrand />
        </RouterWrapper>

        <RouterWrapper
          title='Modelos'
          ext={true}
          path='/modelos'
          roles={['Administrador']}
        >
          <TruckModel />
        </RouterWrapper>

        <RouterWrapper
          title='Cadastro de Modelos'
          path='/modelo/novo/'
          exact
          roles={['Administrador']}
        >
          <NewTruckModel />
        </RouterWrapper>

        <RouterWrapper
          title='Edição de Modelos'
          path='/modelo/:id'
          exact
          roles={['Administrador']}
        >
          <EditTruckModel />
        </RouterWrapper>

        <RouterWrapper
          title='Conjuntos'
          exact
          path='/conjuntos'
          roles={['Administrador']}
        >
          <Joints />
        </RouterWrapper>

        <RouterWrapper
          title='Conjuntos'
          exact
          path='/conjuntos/vincular/transportadora'
          roles={['Administrador']}
        >
          <JointBindShippingCompany />
        </RouterWrapper>

        <RouterWrapper
          title='Configurações'
          path='/settings'
          roles={['Administrador']}
        >
          <Settings />
        </RouterWrapper>

        <RouterWrapper
          title='Criar Termo'
          path='/termos-de-acesso/:id/novo/:termId'
          roles={['Usuario', 'Administrador']}
        >
          <NewLgpd />
        </RouterWrapper>

        <RouterWrapper
          title='Configurações'
          exact
          path='/termos-de-acesso'
          roles={['Usuario', 'Administrador']}
        >
          <Lgpd />
        </RouterWrapper>

        <RouterTermWrapper
          title='Termos de Uso'
          exact
          path='/aprovar-termo/transportadora/empresa/:companyId/:driverId'
        >
          <AproveTerm />
        </RouterTermWrapper>

        <RouterWrapper
          title='Recuperar Faltosos'
          exact
          path='/recuperar'
          roles={['Usuario', 'Administrador']}
        >
          <Recover />
        </RouterWrapper>

        {/* Expedition APP */}

        <RouterExpeditionWrapper
          title='Elimidia'
          path='/elemidia'
          roles={['Expedicao', 'Espectador']}
        >
          <Elimidia />
        </RouterExpeditionWrapper>

        <RouterExpeditionWrapper
          title='Login Expedição'
          path='/expedicao/login'
          roles={['Expedicao', 'Espectador', 'Portaria']}
        >
          <ExpeditionLogin />
        </RouterExpeditionWrapper>

        <RouterExpeditionWrapper
          title='Home'
          path='/expedicao/:shippingCompanyIdEncrypted?'
          exact
          roles={['Expedicao', 'Espectador', 'Portaria']}
        >
          <ExpeditionHome />
        </RouterExpeditionWrapper>

        <DriverRoutes />
      </Switch>
    </BrowserRouter>
  )
}
export default Routes
