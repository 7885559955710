import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridCellParams, ptBR } from '@mui/x-data-grid'

import { Link } from 'react-router-dom'
import { MdSearch } from 'react-icons/md'
import api from '../../services/api'

import './styles.css'
import ModalDelete from '../../components/ModalDelete'
import { toast } from 'react-toastify'
import { useAuth } from '../../contexts/auth'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import Tooltip from '@material-ui/core/Tooltip'

interface TruckModel {
  id: number
  name: string
}

const TruckModel: React.FC = () => {
  const [truckModels, setTruckModels] = useState<TruckModel[]>([])
  const [truckModelsFilter, setTruckModelsFilter] = useState<TruckModel[]>([])
  const [modal, setModal] = useState('')
  const [name, setName] = useState('')
  const [id, setId] = useState(0)

  const { role, company } = useAuth()
  const companyId = company || localStorage.getItem('@App:companyId')

  useEffect(() => {
    let path = ''

    if (role === 'Administrador') {
      path = '/truck-model'
    } else if (role === 'Usuario') {
      path = `/truck/company/${companyId}`
    } else {
      const shippingCompanyId = localStorage.getItem('@App:shippingCompanyId')
      path = `/truck/shipping-company/${shippingCompanyId}`
    }

    api.get(path).then(response => {
      setTruckModels(response.data)
      setTruckModelsFilter(response.data)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // definição da tabela
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Código', width: 80, hide: true },
    {
      field: 'code',
      headerName: 'Código',
      width: 240,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='owner'>
              <div className='code'>
                <div className='owner'>
                  <label>Código:</label>
                  <span>{params.row.id}</span>
                </div>
                <div className='owner'>
                  <span>{params.row.owner}</span>
                </div>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'name',
      headerName: 'Nome',
      width: 240,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='owner'>
              <div className='code'>
                <div className='owner'>
                  <label>{params.row.licensePlate}</label>
                </div>
                <div className='owner'>
                  <span>{params.row.owner}</span>
                </div>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      width: 80,

      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Tooltip className='edit-icon' title={<h1>Editar</h1>}>
              <Link to={{ pathname: `/modelo/${params.row.id}` }}>
                {' '}
                <AiOutlineEdit />{' '}
              </Link>
            </Tooltip>
            <Tooltip className='delete-icon' title={<h1>Deletar</h1>}>
              <Link to={{ pathname: `` }}>
                <AiOutlineDelete
                  onClick={() => {
                    handleCorfirmDelete(params.row.id, params.row.name)
                  }}
                />
              </Link>
            </Tooltip>
          </>
        )
      }
    }
  ]
  // add linhas a tabela
  const rows = truckModels.map(item => {
    return { id: item.id, licensePlate: item.name }
  })

  function handleChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const search = event.target.value

    const results = truckModelsFilter.filter(
      truckModel =>
        truckModel.id.toString().toLowerCase().includes(search.toLowerCase()) ||
        truckModel.name.toString().toLowerCase().includes(search) ||
        truckModel.name.toString().toLowerCase().includes(search)
    )

    setTruckModels(results)
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete(id: any, name: any) {
    showModal()
    setName(name)
    setId(parseInt(id))
  }

  function handleDeleteTruckModel(id: number) {
    hideModal()

    api
      .delete(`/truck-model/delete/${id}`)
      .then(response => {
        if (response.status === 200) {
          const results = truckModels.filter(item => item.id !== id)

          setTruckModels(results)
        }
      })
      .then(() => {
        toast.success('Excluido com sucesso!')
      })
      .catch(e => {
        if (e.response.data.message === 'ER_ROW_IS_REFERENCED_2')
          toast.error(
            'Erro ao excluir, existe(m) caminhões(s) com este modelo!'
          )
        else
          toast.error(
            'Erro ao excluir: ' + e.response.data.message || e.message
          )
      })
  }
  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  return (
    <div className='truckList'>
      <ModalDelete
        type='Caminhão'
        name={name}
        id={id}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteTruckModel}
      />

      <header>
        <div>
          <strong>Gerenciar Modelos</strong>
        </div>

        <div className='search-header'>
          <div className='search'>
            <input onChange={handleChangeSearch} placeholder='Buscar Modelo' />
            <MdSearch fontSize={26} color='#999999' />
          </div>
          <div className='new'>
            {' '}
            <Link to='/modelo/novo'>
              {' '}
              <button type='button'> Cadastrar </button>{' '}
            </Link>
          </div>
        </div>
      </header>

      <div className='grid'>
        <div className='content'>
          <div style={{ height: 570 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={8}
              disableColumnMenu
              rowsPerPageOptions={[8]}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TruckModel
