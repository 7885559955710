import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridCellParams, ptBR } from '@mui/x-data-grid'
import AppToolBar from '../../components/AppToolBar'
import { toast } from 'react-toastify'
import { Button } from '@material-ui/core'
import Input from '../../../../components/Input'
import BarTop from '../../components/BarTop'
import { format, startOfDay, sub } from 'date-fns'
import dateFNSPTBr from 'date-fns/locale/pt-BR'
import { PageLoading } from '../../../../components/PageLoading/PageLoading'
import { LogOperationTypes, driverLogService } from '../../../../domain'
import './styles.css'

interface LogMessage {
  operation: string
  created_at: Date
  content?: string
  contentPosition?: 'center' | 'end'
}

const typeLogMessageMap: Record<LogOperationTypes, string> = {
  LOGIN: 'Logou no sistema',
  IN_QUEUE: 'Entrou na Fila',
  OUT_QUEUE: 'Saiu da Fila',
  ATT_QUEUE: 'Atendido pela expedição',
  FAU_QUEUE: 'Faltoso pela expedição',
  TOL_QUEUE: 'Faltoso por tolerância',
  REG_DRIVER: 'Resgistro Motorista',
  CAD_DRIVER: 'Cadastro Motorista',
  CAD_TRUCK: 'Cadastrou Caminhão',
  EDIT_TRUCK: 'Editou Caminhão',
  BIND_TRUNCK: 'Vinculou Caminhão',
  UNBIND_TRUCK: 'Desvinculou Caminhão',
  IN_ORDER: 'Adicionado no Pedido',
  OUT_COMPANY: 'Mudou de empresa',
  DEL_ADM: 'Excluiu do Sistema /Admin',
  DEL_APP: 'Excluiu do Sistema /App',
  READ_POPUP: 'Visualizou Popup',
  CHECK_IN: 'fez check-in',
  CHECK_OUT: 'cancelou check-in'
}

export function DriverLogs() {
  const date7DaysAgo = sub(new Date(), { days: 7 })
  const driverId = sessionStorage.getItem('@AppDriver:id')

  const [rows, setRows] = useState([])

  const [loading, setLoading] = useState(false)

  const [maxDate, setMaxDate] = useState(() => {
    const today = new Date()
    const maxDate = format(today, 'yyyy-MM-dd')
    return maxDate
  })

  const [minDate, setMinDate] = useState(() => {
    const minDate = format(startOfDay(date7DaysAgo), 'yyyy-MM-dd')
    return minDate
  })

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 75 },
    {
      field: 'title',
      headerName: 'Título',
      width: 800,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: params.row.title }}></div>
        )
      }
    }
  ]

  function handleLogMessage({
    operation,
    created_at,
    content,
    contentPosition = 'end'
  }: LogMessage) {
    let message = ''

    const logDate = format(new Date(created_at), "dd/MM/yyyy 'às' HH:mm", {
      locale: dateFNSPTBr
    })

    if (content) {
      if (contentPosition === 'center') {
        message = `Motorista ${operation} ${content} ${logDate}`
      } else {
        message = `Motorista ${operation} ${logDate} ${content}`
      }
    } else {
      message = `Motorista ${operation} ${logDate}`
    }

    return message
  }

  function checkType(key: LogOperationTypes) {
    return typeLogMessageMap[key] || 'Logou no sistema'
  }

  /**
   * @param logs DriverLog[]
   */
  function formatLogs(logs: any[]) {
    return logs.map((item: any, index) => {
      item.id = index
      const keys = Object.keys(item.data)
      const values = Object.values(item.data) as unknown as any

      const operationType = item.operation_type as LogOperationTypes
      const operation = checkType(operationType)

      const date = new Date(item.createdAt)
      const details = []

      let title = handleLogMessage({
        created_at: date,
        operation
      })

      if (item.operation_type === 'IN_QUEUE') {
        const licensePlate = item.data?.truck?.licensePlate
        const existsLicensePlate = licensePlate

        const content = existsLicensePlate
          ? `com a Placa ${licensePlate}`
          : `como Autônomo`

        title = handleLogMessage({
          created_at: date,
          operation,
          content
        })
      }

      if (operationType === 'IN_ORDER') {
        const code = String(item.data?.scheduling?.order?.code)

        title = handleLogMessage({
          created_at: date,
          operation,
          content: code,
          contentPosition: 'center'
        })
      }

      if (operationType === 'OUT_COMPANY') {
        const content = `Para ${item.data?.companyTo?.company}`

        title = handleLogMessage({
          created_at: date,
          operation,
          content
        })
      }

      if (operationType === 'CAD_TRUCK') {
        const content = `Caminhão Placa ${item.data?.truck?.licensePlate}`

        title = handleLogMessage({
          created_at: date,
          operation,
          content
        })
      }

      if (operationType === 'EDIT_TRUCK') {
        const content = `Caminhão Placa ${item.data?.truck?.licensePlate}`

        title = handleLogMessage({
          created_at: date,
          operation,
          content
        })
      }

      if (operationType === 'UNBIND_TRUCK') {
        const content = `Caminhão Placa ${item.data?.truck?.licensePlate}`

        title = handleLogMessage({
          created_at: date,
          operation,
          content
        })
      }

      if (operationType === 'BIND_TRUNCK') {
        const content = `Caminhão Placa ${item.data?.truck?.licensePlate}`

        title = handleLogMessage({
          created_at: date,
          operation,
          content
        })
      }

      if (operationType === 'BIND_TRUNCK') {
        const content = `Caminhão Placa ${item.data?.truck?.licensePlate}`

        title = handleLogMessage({
          created_at: date,
          operation,
          content
        })
      }

      if (operationType === 'READ_POPUP') {
        const popupData = item.data.popup

        const popupTitle = popupData.title
        const popupLabel = popupData.label

        const popupTitleIsEmpty = popupTitle === ''
        const content = !popupTitleIsEmpty ? popupTitle : popupLabel

        title = handleLogMessage({
          operation,
          created_at: date,
          content,
          contentPosition: 'center'
        })
      }

      if (operationType === 'CHECK_IN') {
        title = handleLogMessage({
          operation,
          content: 'em',
          contentPosition: 'center',
          created_at: date
        })
      }

      if (operationType === 'CHECK_OUT') {
        title = handleLogMessage({
          operation,
          content: 'em',
          contentPosition: 'center',
          created_at: date
        })
      }

      for (let index = 0; index < keys.length; index++) {
        details.push({ key: keys[index], value: values[index] })
      }

      const companyId = item.data?.company?.id

      const data = {
        id: index + 1,
        title: title,
        company: companyId,
        details: details,
        created_at: date.toLocaleDateString()
      }

      return data
    })
  }

  async function getLogs() {
    if (!driverId) return

    try {
      setLoading(true)

      const logs = await driverLogService.getList({
        driverId,
        period: {
          startDate: minDate,
          endDate: maxDate
        }
      })

      setRows(formatLogs(logs) as any)

      toast.success('Consulta finalizada!')
    } catch (error) {
      toast.error('Erro ao consultar!')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='container'>
      <PageLoading loading={loading} onCloseLoading={() => setLoading(false)} />

      <BarTop />
      <div className='header'>
        <AppToolBar pathToBack='/' isStatic={false} />
      </div>

      <div className='title history-panel'>
        <h2>Histórico do motorista</h2>
      </div>

      <div className='history-area history-panel log search'>
        <div className='infor-container'>
          <div className='row'>
            <div className={`input date`}>
              <label>De:</label>
              <div className='select'>
                <Input
                  value={minDate}
                  onChange={e => setMinDate(e.target.value)}
                  type='date'
                />
                {minDate && (
                  <Button
                    className='erase-button'
                    onClick={() => setMinDate('')}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>

            <div className={`input date`}>
              <label>Até:</label>
              <div className='select'>
                <Input
                  value={maxDate}
                  onChange={e => setMaxDate(e.target.value)}
                  type='date'
                  min={minDate}
                />
                {maxDate && (
                  <Button
                    className='erase-button'
                    onClick={() => setMaxDate('')}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>
          </div>

          <button id='btSave' onClick={getLogs} type='submit'>
            {loading ? 'Pesquisando...' : 'Pesquisar'}
          </button>
        </div>
      </div>

      <div className='virtual-queue'>
        <div className=''>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              <div className='scheduling-exp painel-page history-panel'>
                <div style={{ height: 520 }}>
                  <DataGrid
                    autoHeight={true}
                    rows={rows}
                    columns={columns}
                    disableColumnMenu
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    headerHeight={30}
                    localeText={
                      ptBR.components.MuiDataGrid.defaultProps.localeText
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
