import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
  ptBR
} from '@mui/x-data-grid'

import { Link } from 'react-router-dom'
import { MdSearch } from 'react-icons/md'
import api from '../../services/api'

import './styles.css'
import ModalDelete from '../../components/ModalDelete'
import { toast } from 'react-toastify'
import { useAuth } from '../../contexts/auth'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import Tooltip from '@mui/material/Tooltip'

interface Customer {
  id: number
  code: number
  name: string
  cnpj: string
  customer: string
  uf: string
  street: string
  number: string
  complement: string
  city: string
  district: string
  zipCode: string
  status: boolean
}
const Customer: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([])
  const [customersFilter, setCustomersFilter] = useState<Customer[]>([])
  const [modal, setModal] = useState('')
  const [name, setName] = useState('')
  const [id, setId] = useState(0)

  const { role, company } = useAuth()
  const companyId = company || localStorage.getItem('@App:companyId')

  useEffect(() => {
    let path = ''

    if (role === 'Administrador') {
      path = '/customer'
    } else if (role === 'Usuario') {
      path = `/customer/company/${companyId}`
    }

    api.get(path).then(response => {
      setCustomers(response.data)
      setCustomersFilter(response.data)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // definição da tabela
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 95, hide: true },
    {
      field: 'name',
      headerName: 'Nome',
      width: 600,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='customer'>
              <div>
                <div className='code'>
                  <label>Código:</label>
                  <span>{params.row.code}</span>
                </div>
                <div className='name'>
                  <span>{params.row.name}</span>
                </div>
              </div>
            </div>
          </>
        )
      }
    },
    { field: 'cnpj', headerName: 'CNPJ', width: 160 },
    { field: 'uf', headerName: 'UF', width: 80 },
    { field: 'city', headerName: 'Cidade', width: 140 },
    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      width: 80,

      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Tooltip className='edit-icon' title={<h1>Editar</h1>}>
              <Link to={{ pathname: `/cliente/novo/${params.row.id}` }}>
                {' '}
                <AiOutlineEdit />{' '}
              </Link>
            </Tooltip>
            <Tooltip className='delete-icon' title={<h1>Deletar</h1>}>
              <Link to={{ pathname: `` }}>
                <AiOutlineDelete
                  onClick={() => {
                    handleCorfirmDelete(params.row.id, params.row.name)
                  }}
                />
              </Link>
            </Tooltip>
          </>
        )
      }
    }
  ]
  // add linhas a tabela
  const rows = customers.map(item => {
    return {
      id: item.id,
      code: item.code,
      name: item.name,
      cnpj: item.cnpj,
      uf: item.uf,
      city: item.city
    }
  })

  function handleChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const search = event.target.value

    const results = customersFilter.filter(
      customer =>
        customer.name.toLowerCase().includes(search.toLowerCase()) ||
        customer.cnpj.toLowerCase().includes(search.toLowerCase()) ||
        customer.code.toString().includes(search)
    )
    setCustomers(results)
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete(id: any, name: any) {
    showModal()
    setName(name)
    setId(parseInt(id))
  }

  async function handleDeleteCustomer(id: number) {
    hideModal()

    await api
      .delete(`/customer/delete/${id}`)
      .then(response => {
        if (response.status === 200) {
          const results = customers.filter(item => item.id !== id)

          setCustomers(results)
        }
      })
      .then(() => {
        toast.success('Excluido com sucesso!')
      })
      .catch(e => {
        if (e.response.data.message === 'ER_ROW_IS_REFERENCED_2')
          toast.error(
            'Erro ao excluir,  existe(m) pedido(s) para este cliente!'
          )
        else
          toast.error(
            'Erro ao excluir: ' + e.response.data.message || e.message
          )
      })
  }
  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  return (
    <div className='customerList'>
      <ModalDelete
        type='Cliente'
        name={name}
        id={id}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteCustomer}
      />

      <header>
        <div>
          <strong>Gerenciar clientes</strong>
        </div>

        <div className='search-header'>
          <div className='search'>
            <input onChange={handleChangeSearch} placeholder='Buscar cliente' />
            <MdSearch fontSize={26} color='#999999' />
          </div>

          <div className='new'>
            <Link to='/cliente/novo'>
              {' '}
              <button type='button'> Importar </button>{' '}
            </Link>

            <Link to='/cliente/novo/0'>
              {' '}
              <button type='button'> Novo cliente </button>{' '}
            </Link>
          </div>
        </div>
      </header>

      <div className='grid'>
        <div className='content'>
          <div style={{ height: 608 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={9}
              disableColumnMenu
              components={{
                Toolbar: GridToolbar
              }}
              rowsPerPageOptions={[8]}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Customer
