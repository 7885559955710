import React, { FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { CompactPicker } from 'react-color'
import Select from 'react-select'
import InputMask from 'react-input-mask'

import * as Yup from 'yup'

import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../../components/Input'

import { toast } from 'react-toastify'
import { Box, Button, Modal } from '@material-ui/core'
import { IoCloseOutline } from 'react-icons/io5'
import api from '../../../../services/api'
import './styles.css'

interface IOption {
  label: string
  value: string
}

const driverSchema = Yup.object().shape({
  licensePlate: Yup.string().required('Placa é obrigatório!'),
  renavam: Yup.string().required('Renavam é obrigatório!')
})

const implementTypes = [
  { type: 'TRUCK', shaftQuantity: 3, grossCapacity: 23.0, netCapacity: 16.0 },
  {
    type: 'BITRUCK',
    shaftQuantity: 4,
    grossCapacity: 29.0,
    netCapacity: 19.0
  },
  { type: 'TOCO', shaftQuantity: 5, grossCapacity: 41.5, netCapacity: 28.0 },
  {
    type: 'ROMEU & JULIETA',
    shaftQuantity: 6,
    grossCapacity: 50.0,
    netCapacity: 32.5
  },
  {
    type: 'LS (MENOR 16 MT)',
    shaftQuantity: 6,
    grossCapacity: 45.0,
    netCapacity: 31.0
  },
  {
    type: 'LS (MAIOR 16 MT)',
    shaftQuantity: 6,
    grossCapacity: 48.5,
    netCapacity: 33.5
  },
  {
    type: 'VANDERLEIA',
    shaftQuantity: 6,
    grossCapacity: 53.0,
    netCapacity: 36.0
  },
  {
    type: 'BITREM 7 EIXOS',
    shaftQuantity: 7,
    grossCapacity: 57.0,
    netCapacity: 42.0
  },
  {
    type: 'BITREM 8 EIXOS',
    shaftQuantity: 8,
    grossCapacity: 65.5,
    netCapacity: 46.5
  },
  {
    type: 'RODOTREM 9 EIXOS',
    shaftQuantity: 9,
    grossCapacity: 74.0,
    netCapacity: 52.0
  },
  {
    type: 'RODOTREM 11 EIXOS',
    shaftQuantity: 11,
    grossCapacity: 91.0,
    netCapacity: 65.6
  }
]

export function DriverRegisterTruck() {
  const [modalPlate, setModalPlate] = useState<boolean>(false)
  const [modalMessageError, setModalMessageError] = useState('')
  const [isTruck, setIsTruck] = useState<boolean>()

  const [page, setPage] = useState(1)
  const [plate, setPlate] = useState<string>()
  const [color, setColor] = useState<string>('')
  const history = useHistory()

  const [modelTypes, setModelTypes] = useState<IOption[]>([])
  const [brandTypes, setBrandTypes] = useState<IOption[]>([])
  const [implementsTypes, setImplementsTypes] = useState<IOption[]>([])
  const [implementValue, setImplementValue] = useState<IOption>({} as IOption)
  const [brandValue, setBrandValue] = useState<IOption>({} as IOption)
  const [modelValue, setModelValue] = useState<IOption>({} as IOption)

  const [vehicleModelName, setModelName] = useState('')
  const driverId = sessionStorage.getItem('@AppDriver:id')?.replace(/"/g, '')

  const driverCompanyId = sessionStorage
    .getItem('@AppDriver:companyId')
    ?.replace(/"/g, '')
  const driverName = sessionStorage
    .getItem('@AppDriver:driver')
    ?.replace(/"/g, '')

  useEffect(() => {
    const types: IOption[] = implementTypes.map((item, index) => {
      return { value: item.type, label: item.type }
    })

    setImplementsTypes(types)

    api.get(`/truck-brand/`).then(response => {
      setBrandTypes(
        response.data.map((item: any) => {
          return { value: item.id, label: item.name }
        })
      )
    })
  }, [])

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      licensePlate: '',
      renavam: '',
      vehicleModel: '',
      implementType: '',
      shaftQuantity: 0,
      grossCapacity: 0,
      netCapacity: 0
    },

    validationSchema: driverSchema,
    onSubmit: values => {
      if (isTruck && plate) {
        api
          .patch('truck/rebind', {
            truck: {
              driver: {
                id: parseInt(`${driverId}`)
              },
              licensePlate: values.licensePlate,
              renavam: values.renavam.replace(/[^\w\s]/gi, ''),
              vehicleModel: {
                id: values.vehicleModel,
                truckBrand: {
                  id: brandValue
                }
              },
              color: color,
              company: {
                id: parseInt(`${driverCompanyId}`)
              },
              grossCapacity: values.grossCapacity,
              implementType: values.implementType,
              netCapacity: values.netCapacity,
              owner: driverName,
              shaftQuantity: values.shaftQuantity
            }
          })
          .then(() => {
            toast.success('Cadastro realizado com sucesso')
            setTimeout(() => {
              history.push('/')
            }, 2500)
          })
          .catch(error => {
            setModalMessageError(error.response.data.message)
            setModalPlate(true)
          })
      } else {
        toast.error('Placa inválida')
      }
    }
  })

  function handleCreateNewTruck(event: FormEvent) {
    event.preventDefault()

    handleSubmit()
  }

  function handleBack() {
    if (page === 1) {
      history.push('/app/edit')
    } else {
      setPage(page - 1)
    }
  }

  function handleChangeComplete(color: string) {
    setColor(color)
  }

  function handleChangeOptionImplement(option: IOption) {
    const _findImplement = implementTypes.find(
      item => item.type === option.value
    )
    setValues({
      ...values,
      implementType: _findImplement?.type || '',
      shaftQuantity: _findImplement?.shaftQuantity || 0,
      grossCapacity: _findImplement?.grossCapacity || 0,
      netCapacity: _findImplement?.netCapacity || 0
    })
    setImplementValue(option)
  }

  function handleChangeOptionBrand(option: IOption) {
    setBrandValue(option)

    api.get(`/truck-model/brand/${option.value}`).then(response => {
      setModelTypes(
        response.data.map((item: any) => {
          return { value: item.id, label: item.name }
        })
      )
    })
  }

  function handleChangeOptionModel(option: IOption) {
    setModelValue(option)
    setModelName(option.label)
    setValues({ ...values, vehicleModel: option.value })
  }

  function modalIsTruck(truck: boolean) {
    if (truck === false) {
      setModalPlate(true)
    } else {
      setModalPlate(false)
    }
  }

  async function handleIsTruck(plateValue: string) {
    if (plateValue.length === 7) {
      await api
        .get(`truck/isTruck/${plateValue}`)
        .then(response => {
          setIsTruck(response?.data?.isTruck)
          modalIsTruck(response?.data?.isTruck)
        })
        .catch(e => {
          toast.error('Erro: ' + e?.response?.data?.message)
          setIsTruck(undefined)
          setModalPlate(false)
        })
      setPlate(plateValue)
    }
  }

  return (
    <div className='register-driver-container'>
      <Box className='modal expedition'>
        <Modal
          open={modalPlate}
          onClose={() => setModalPlate(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modalLicensePlate'
        >
          <div className='modalContentPlate'>
            <Button
              className='close-button'
              onClick={() => setModalPlate(false)}
            >
              <IoCloseOutline />
            </Button>
            <div className='licensePlateText'>
              <p>
                {modalMessageError
                  ? modalMessageError
                  : 'A placa informada não corresponde a um caminhão.'}
              </p>

              {!modalMessageError && (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={`https://www.fipeplaca.com.br/placa/${plate}`}
                >
                  Clique aqui para saber mais
                </a>
              )}
            </div>
          </div>
        </Modal>
      </Box>

      <div className='container' style={{ width: '90vw' }}>
        <div className='content'>
          <form onSubmit={handleCreateNewTruck} autoComplete='off'>
            <header>
              <div className='buttons-header'>
                <button id='btBack' type='button' onClick={handleBack}>
                  <MdKeyboardArrowLeft size={20} color='#fff' />
                  VOLTAR
                </button>

                <button id='btSave' type='submit'>
                  <MdKeyboardArrowRight size={20} color='#fff' />
                  Concluir
                </button>
              </div>
            </header>

            {page === 1 && (
              <div className='content-truck'>
                <div className='row'>
                  <div
                    className={`${
                      isTruck === false ? 'plateInvalid' : ''
                    } input ${errors.licensePlate ? 'error' : ''}`}
                  >
                    <Input
                      name='licensePlate'
                      placeholder='Placa (Apenas letras e números)'
                      maskText='#######'
                      onChange={e => {
                        if (e.target.value.length === 7) {
                          handleIsTruck(e.target.value)
                        }
                        if (e.target.value.length <= 6) {
                          setPlate(undefined)
                          setIsTruck(undefined)
                        }
                        if (
                          e.target.value !== '-' &&
                          ' ' &&
                          e.target.value.length < 8
                        ) {
                          e.target.value = e.target.value
                            .trim()
                            .replace(/[^\w\s]/gi, '')

                          handleChange(e)
                        }
                      }}
                      value={values.licensePlate.slice(0, 9)}
                      label='Placa'
                      maxLength={8}
                    />
                    {errors.licensePlate && (
                      <div className='validateError'>{errors.licensePlate}</div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div
                    className={`input input-block ${
                      errors.renavam ? 'error' : ''
                    }`}
                  >
                    <label style={{ fontWeight: 'bold' }}>Renavam:</label>
                    <br />
                    <InputMask
                      mask='99999999999'
                      name='renavam'
                      placeholder='Renavam'
                      value={values.renavam}
                      onChange={handleChange}
                    />

                    {errors.renavam && (
                      <div className='validateError'>{errors.renavam}</div>
                    )}
                  </div>
                  <br />

                  <div className={`input`}>
                    <label style={{ fontWeight: 'bold' }}>Marca: </label>
                    <Select
                      className='select-options'
                      value={brandValue}
                      options={brandTypes}
                      onChange={option =>
                        handleChangeOptionBrand(option as IOption)
                      }
                    />
                  </div>
                  <br />
                  <div
                    className={`input ${errors.vehicleModel ? 'error' : ''}`}
                  >
                    <label style={{ fontWeight: 'bold' }}>Modelo: </label>

                    <Select
                      className='select-options'
                      value={modelValue}
                      options={modelTypes}
                      onChange={option =>
                        handleChangeOptionModel(option as IOption)
                      }
                    />

                    {errors.vehicleModel && (
                      <div className='validateError'>{errors.vehicleModel}</div>
                    )}
                  </div>
                  <br />

                  <label style={{ fontWeight: 'bold' }}>Color:</label>
                  <div>
                    <CompactPicker
                      color={color}
                      onChangeComplete={e => handleChangeComplete(e.hex)}
                    />
                    <br />
                  </div>
                </div>

                <div className='row'>
                  <br />

                  <div
                    className={`input ${errors.implementType ? 'error' : ''}`}
                  >
                    <label style={{ fontWeight: 'bold' }}>
                      Tipo de implemento:
                    </label>

                    <Select
                      className='select-options'
                      value={implementValue}
                      options={implementsTypes}
                      onChange={option =>
                        handleChangeOptionImplement(option as IOption)
                      }
                    />

                    {errors.implementType && (
                      <div className='validateError'>
                        {errors.implementType}
                      </div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div
                    className={`input-number ${
                      errors.shaftQuantity ? 'error' : ''
                    }`}
                  >
                    <Input
                      name='shaftQuantity'
                      type='number'
                      value={values.shaftQuantity}
                      onChange={handleChange}
                      label='Qtd de eixo'
                    />

                    {errors.shaftQuantity && (
                      <div className='validateError'>
                        {errors.shaftQuantity}
                      </div>
                    )}
                  </div>

                  <div
                    className={`input-number ${
                      errors.grossCapacity ? 'error' : ''
                    }`}
                  >
                    <Input
                      name='grossCapacity'
                      type='number'
                      value={values.grossCapacity}
                      onChange={handleChange}
                      label='Capac. bruta em tonelada'
                    />

                    {errors.grossCapacity && (
                      <div className='validateError'>
                        {errors.grossCapacity}
                      </div>
                    )}
                  </div>

                  <div
                    className={`input-number ${
                      errors.netCapacity ? 'error' : ''
                    }`}
                  >
                    <Input
                      name='netCapacity'
                      type='number'
                      value={values.netCapacity}
                      onChange={handleChange}
                      label='Capac. líquida em tonelada'
                    />

                    {errors.shaftQuantity && (
                      <div className='validateError'>
                        {errors.shaftQuantity}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {page === 2 && (
              <div className='check-data'>
                <h2>Dados Caminhão:</h2>
                <div className='check-truck'>
                  <label>Placa:</label>
                  <span>{values.licensePlate}</span>

                  <label>Modelo:</label>
                  <span>{vehicleModelName}</span>

                  <label>Cor:</label>
                  <span>
                    <div
                      style={{ width: 12, height: 12, backgroundColor: color }}
                    ></div>
                  </span>

                  <label>renavam:</label>
                  <span>{values.renavam}</span>

                  <label>Tipo de Implemento:</label>
                  <span>{values.implementType}</span>

                  <label>Qnt. de eixos:</label>
                  <span>{values.shaftQuantity}</span>

                  <label>Capacidade bruta:</label>
                  <span>{values.grossCapacity}t</span>

                  <label>Capacidade líquida:</label>
                  <span>{values.netCapacity}t</span>
                </div>
              </div>
            )}
          </form>

          <br />
        </div>
      </div>
    </div>
  )
}
