import React, { useEffect, useState } from 'react'
import AppToolBar from '../../components/AppToolBar'

import api from '../../../../services/api'
import BarTop from '../../components/BarTop'
import { QueueInfo } from './QueueInfo'
import { QueueActions } from './QueueActions'
import './styles.css'

export function DriverQueue() {
  const [queueTotals, setQueueTotals] = useState(0)

  const [queueStatus, setQueueStatus] = useState(false)

  const companyId = Number(sessionStorage.getItem('@AppDriver:companyId'))
  const driverId = sessionStorage.getItem('@AppDriver:id')

  useEffect(() => {
    if (driverId) {
      api
        .get(`/queue/next-date/${companyId}`)
        .then(response => setQueueTotals(response.data.totals))

      api.get(`/queue/company/${companyId}`).then(response => {
        const index = response.data.findIndex(
          (x: any) => x.driver?.id === parseInt(driverId)
        )
        if (index > -1) {
          setQueueStatus(true)
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId])

  return (
    <div className='queue'>
      <div className='container'>
        <BarTop />
        <div className='header'>
          <AppToolBar pathToBack='/' isStatic={false} />
        </div>

        <div className='content'>
          <QueueInfo queueTotal={queueTotals} />

          <QueueActions queueStatus={queueStatus} />
        </div>
      </div>
    </div>
  )
}
