import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
  ptBR
} from '@mui/x-data-grid'
import { Chip } from '@material-ui/core'

import { Link } from 'react-router-dom'
import { MdSearch } from 'react-icons/md'
import api from '../../services/api'

import './styles.css'
import ModalDelete from '../../components/ModalDelete'
import { toast } from 'react-toastify'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import Tooltip from '@mui/material/Tooltip'

interface Company {
  id: number
  name: string
  cnpj: string
  company: string
  uf: string
  street: string
  number: string
  complement: string
  city: string
  district: string
  zipCode: string
  status: boolean
}
const Company: React.FC = () => {
  const [companys, setCompanys] = useState<Company[]>([])
  const [companysFilter, setCompanysFilter] = useState<Company[]>([])
  const [modal, setModal] = useState('')
  const [name, setName] = useState('')
  const [id, setId] = useState(0)

  useEffect(() => {
    api.get('/company').then(response => {
      setCompanys(response.data)
      setCompanysFilter(response.data)
    })
  }, [])

  // definição da tabela
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Code', width: 95, hide: true },
    {
      field: 'name',
      headerName: 'Nome',
      width: 300,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='customer'>
              <div>
                <div className='code'>
                  <label>Código:</label>
                  <span>{params.row.id}</span>
                </div>
                <div className='name'>
                  <span>{params.row.name}</span>
                </div>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'company',
      headerName: 'Empresa',
      width: 320,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='company'>
              <div className='group'>
                <div className='cnpj'>
                  <label>CNPJ:</label>
                  <span>{params.row.cnpj}</span>
                </div>
                <div className='_company'>
                  <label>Razão social:</label>
                  <span>{params.row.company}</span>
                </div>
              </div>
            </div>
          </>
        )
      }
    },
    { field: 'uf', headerName: 'UF', width: 60 },
    { field: 'city', headerName: 'Cidade', width: 220 },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {params.row.status ? (
              <Chip
                variant='outlined'
                style={{
                  height: '30px',
                  backgroundColor: '#FFF',
                  borderColor: 'green',
                  fontSize: '15px'
                }}
                label='Ativo'
              />
            ) : (
              <Chip
                variant='outlined'
                style={{
                  height: '30px',
                  backgroundColor: '#FFF',
                  borderColor: 'red',
                  fontSize: '15px'
                }}
                label='Inativo'
              />
            )}
          </>
        )
      }
    },
    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      width: 80,

      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Tooltip className='edit-icon' title={<h1>Editar</h1>}>
              <Link to={{ pathname: `/empresa/novo/${params.row.id}` }}>
                <AiOutlineEdit />{' '}
              </Link>
            </Tooltip>
            <Tooltip className='delete-icon' title={<h1>Deletar</h1>}>
              <Link to={{ pathname: `` }}>
                <AiOutlineDelete
                  onClick={() => {
                    handleCorfirmDelete(params.row.id, params.row.name)
                  }}
                />
              </Link>
            </Tooltip>
          </>
        )
      }
    }
  ]
  // add linhas a tabela
  const rows = companys.map(item => {
    return {
      id: item.id,
      name: item.name,
      company: item.company,
      cnpj: item.cnpj,
      uf: item.uf,
      city: item.city,
      status: item.status
    }
  })

  function handleChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const search = event.target.value

    const results = companysFilter.filter(
      company =>
        company.name.toLowerCase().includes(search.toLowerCase()) ||
        company.cnpj.toLowerCase().includes(search.toLowerCase()) ||
        company.company.toLowerCase().includes(search.toLowerCase())
    )
    setCompanys(results)
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete(id: any, name: any) {
    showModal()
    setName(name)
    setId(parseInt(id))
  }

  function handleDeleteCompany(id: number) {
    hideModal()

    api
      .delete(`/company/delete/${id}`)
      .then(response => {
        if (response.status === 200) {
          const results = companys.filter(item => item.id !== id)

          setCompanys(results)
        }
      })
      .then(() => {
        toast.success('Excluido com sucesso!')
      })
      .catch(e => {
        toast.error('Erro ao Cadastrar: ' + e.response.data.code)
      })
  }
  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  return (
    <div className='companyList'>
      <ModalDelete
        type='Empresa'
        name={name}
        id={id}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteCompany}
      />

      <header>
        <div>
          <strong>Gerenciar empresas</strong>
        </div>

        <div className='search-header'>
          <div className='search'>
            <input onChange={handleChangeSearch} placeholder='Buscar empresa' />
            <MdSearch fontSize={26} color='#999999' />
          </div>
          <div className='new'>
            {' '}
            <Link to='/empresa/novo'>
              {' '}
              <button type='button'> Cadastrar </button>{' '}
            </Link>
          </div>
        </div>
      </header>

      <div className='grid'>
        <div className='content'>
          <div style={{ height: 500 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableColumnMenu
              components={{
                Toolbar: GridToolbar
              }}
              pageSize={6}
              rowsPerPageOptions={[8]}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Company
