import { Driver } from "..";
import api from "../../../services/api";

export interface DriversGroupsParams {
  from_id?: number;
  page_size?: number;
  status?: boolean;
  truck_search?: string;
  driver_search?: string;
  shippingCompanyId?: number;
  companyId?: number | string | null;
}

interface DriverPositions {
  id: number;
  truckId: number;
  position: number;
}

export async function getDriversGroups(queryParams?: DriversGroupsParams) {
  const response = await api.get<Driver[]>(`/driver/groups`, {
    params: {
      from_id: 1,
      page_size: 15,
      ...queryParams
    }
  });

  const drivers = response.data;

  const driversIsEmpty = drivers.length === 0;

  if (!driversIsEmpty) {
    const driversInQueue = drivers.filter(driver => driver.queue.length > 0);

    const ids = driversInQueue.map(driver => driver.id);

    const { data: result } = await api.get<DriverPositions[]>(
      "queue/driver/positions",
      {
        params: {
          ids: ids.join(",")
        }
      }
    );

    const formattedDrivers = drivers.map(driver => {
      let currentDriver = driver;

      const driverPosition = result.find(item => item.id === driver.id);

      if (driverPosition) {
        const updatedDriver = {
          ...currentDriver,
          position: driverPosition.position,
          truckId: driverPosition.truckId
        };

        return updatedDriver;
      }

      return {
        ...currentDriver,
        position: null
      };
    });

    return formattedDrivers;
  } else {
    return drivers;
  }
}
