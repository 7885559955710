import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useExped } from "../../../../../../contexts/exped";
import "./styles.scss";

interface AttendanceWithDelayStatusProps {
  delayedTime: number;
  minutes: number;
  delayName: string;
  iconName: any;
  isExpeditionViewer: boolean;
  setDelayTime: any;
  setDelayStatus: any;
  delayTime: any;
  delayValue: any;
  companyId: any;
}

export function AttendanceWithDelayStatus({
  minutes,
  delayedTime,
  iconName,
  delayName,
  isExpeditionViewer,
  setDelayTime,
  setDelayStatus,
  delayTime,
  delayValue,
  companyId
}: AttendanceWithDelayStatusProps) {
  const { handleConfirmDelayCancelation } = useExped();

  return (
    <div className="attendance__delay__status__container">
      <div className="attendance__delay__status__wrapper">
        <div className="attendance__delay__status__content">
          <div>
            <div className="attendance__delay">
              <div className="attendance__delay__icon">
                {iconName && (
                  <FontAwesomeIcon
                    icon={["fas", iconName]}
                    size="lg"
                    color="white"
                    enableBackground={"jk"}
                  />
                )}
              </div>

              <strong className="attendance__delay__name">{delayName}</strong>
            </div>

            <div className="attendance__delay__time">
              <time>{minutes + delayedTime}</time> <span>min</span>
            </div>
          </div>

          {!isExpeditionViewer && (
            <button
              onClick={() => {
                handleConfirmDelayCancelation({
                  setDelayTime,
                  setDelayStatus,
                  minutes,
                  delayedTime,
                  delayTime,
                  delayValue,
                  companyId
                });
              }}
              className="attendance__button-end-delay"
            >
              Encerrar atraso
            </button>
          )}
        </div>
      </div>

      <p>Por favor, encerre o atraso para voltar ao atendimento</p>
    </div>
  );
}
