import React from 'react'
import { Helmet } from 'react-helmet'
import { DriverProvider } from '../../../../contexts/DriverContext/DriverContext'
import './styles.scss'

interface AppDriverLayoutProps {
  title: string
  description?: string
  children: React.ReactNode
}

export function AppDriverLayout({
  title,
  description = 'NextCargo Controle de Expedição',
  children
}: AppDriverLayoutProps) {
  return (
    <DriverProvider>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
      </Helmet>
      <section className='app mobile'>{children}</section>
    </DriverProvider>
  )
}
