import React, { FormEvent, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { useAuth } from '../../../../contexts/auth'

import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'

import ModalDeleteUser from '../../../../components/ModalDeleteUser'
import Input from '../../../../components/Input'
import { toast } from 'react-toastify'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button, Checkbox } from '@material-ui/core'
import api from '../../../../services/api'
import './styles.css'

interface Contact {
  id: number
  name: string
  fone: string
  email: string
}
interface IOption {
  label: string
  value: string
}

interface Truck {
  id: number
  licensePlate: string
  renavam: string
  owner: string
  vehicleModel: any
  implementType: string
  shaftQuantity: number
  grossCapacity: number
  netCapacity: number
  color: string
}

export function DriverProfile() {
  const { company, driverLogout } = useAuth()
  const history = useHistory()

  const [isOpenModalDeleteAccount, setIsOpenModalDeleteAccount] = useState('')
  const [truck, setTruck] = useState<Truck[]>([])
  const [shippingValue, setShippingValue] = useState<IOption>({} as IOption)
  const shippingCompanyId = localStorage.getItem('@App:shippingCompanyId')
  const companyId = sessionStorage.getItem('@AppDriver:companyId')
  const driverId = sessionStorage.getItem('@AppDriver:id')?.replace(/"/g, '')

  const [name, setName] = useState('')
  const [cpf, setCpf] = useState('')
  const [cnh, setCnh] = useState('')
  const [birthDate, setBirthDate] = useState('')

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      cnh: '',
      birthDate: '',
      truck: '',
      hasEmail: false,
      hasSMS: false,
      hasWhats: false,
      useTerm: { id: 0, updatedAt: '' }
    },
    onSubmit: values => {
      let objectDriver = {
        hasWhats: values.hasWhats,
        hasSMS: values.hasSMS,
        hasEmail: values.hasEmail,
        driver: {
          id: Number(driverId)
        },
        useTerm: {
          id: values.useTerm?.id,
          updatedAt: values.useTerm?.updatedAt
        }
      }

      api.post('useTerm/authorization/save', objectDriver)

      api
        .post('/driver/save', {
          id: parseInt(`${driverId}`),
          name: name.toUpperCase(),
          cpf: cpf,
          cnh: cnh,
          birthDate: birthDate,
          shippingCompany:
            shippingCompanyId || shippingValue.value
              ? {
                  id:
                    parseInt(shippingCompanyId || '') ||
                    parseInt(shippingValue.value) ||
                    null
                }
              : null,
          truck: values.truck[0],
          contact: contacts,
          company: { id: company || parseInt(companyId || '') || null }
        })
        .then(() => {
          toast.success('Cadastro realizado com sucesso')
          setTimeout(() => {
            history.push('/')
          }, 2000)
        })
        .catch(e => {
          if (e.response.data.errno === 1062) {
            toast.error('Erro ao cadastrar, estes dados já existem')
          } else {
            toast.error('Erro ao Cadastrar: ' + e.response.data.code)
          }
        })
    }
  })

  const [id, setId] = useState(
    Number(sessionStorage.getItem('@AppDriver:id')?.replace(/"/g, ''))
  )

  const [contacts, setContacts] = useState<Contact[]>([])

  async function handleUpdateDriverSubmitForm(event: FormEvent) {
    event.preventDefault()

    handleSubmit()
  }

  function handleEditTruck(truckId: any) {
    history.push(`/app/edit/truck/${truckId}`)

    return truckId
  }

  function handleCreateNewTruck() {
    history.push('/app/create/truck')
  }

  function handleUnbidTruck(truckId: number) {
    try {
      api
        .patch('/truck/unbind', {
          truck: {
            id: truckId
          }
        })
        .then(response => {
          toast.success('Caminhão desvinculado com sucesso')
          setTimeout(() => window.location.reload(), 1000)
        })
        .catch(error => {
          toast.error('Erro ao Desvincular: ' + error.response.data.statusCode)
        })
    } catch (error) {
      console.log(error)
    }
  }

  function handleCorfirmDelete() {
    handleOpenModalDeleteAccountDriver()
    setName(values.name)
    setId(id)
  }

  async function handleDeleteDriver(
    id: number,
    cpf: string,
    birthDate: string
  ) {
    try {
      const response = await api.delete(`/driver/app/delete/${id}`, {
        data: { cpf, birthDate }
      })

      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        toast.success('Conta excluida!')

        setTimeout(() => {
          driverLogout()

          history.replace('/')
        }, 2000)
      }
    } catch (error) {
      toast.error('Erro ao exluir sua conta')
      console.log(error)
    } finally {
      handleCloseModalDeleteDriverAccount()
    }
  }

  function handleOpenModalDeleteAccountDriver() {
    setIsOpenModalDeleteAccount('show')
  }

  function handleCloseModalDeleteDriverAccount() {
    setIsOpenModalDeleteAccount('')
  }

  useEffect(() => {
    if (driverId) {
      api.get(`/driver/${driverId}`).then(response => {
        setName(response.data.name)
        setCpf(response.data.cpf)
        setCnh(response.data.cnh)
        setBirthDate(response.data.birthDate)

        setValues({
          name: response.data.name,
          cpf: response.data.cpf,
          cnh: response.data.cnh,
          birthDate: response.data.birthDate,
          truck: response.data.truck[0],
          hasSMS: response.data?.authorization[0]?.hasSMS,
          hasWhats: response.data?.authorization[0]?.hasWhats,
          hasEmail: response.data?.authorization[0]?.hasEmail,
          useTerm: response.data?.authorization[0]?.useTerm
        })
        if (response.data.shippingCompany) {
          setShippingValue({
            value: response.data.shippingCompany.id,
            label: `${response.data.shippingCompany.code} - ${response.data.shippingCompany.name}`
          })
        }

        setContacts(response.data.contact)
        setTruck(response.data.truck)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='new-driver-container'>
      <ModalDeleteUser
        id={id}
        type='Usuário'
        cpf={cpf}
        birthDate={values.birthDate}
        className={isOpenModalDeleteAccount}
        handleClose={handleCloseModalDeleteDriverAccount}
        handleDeleteAction={handleDeleteDriver}
      />

      <form onSubmit={handleUpdateDriverSubmitForm} autoComplete='off'>
        <header className='newDriverEdit'>
          <div>
            <strong>Editar Perfil</strong>
          </div>

          <div className='buttons-header'>
            <Link to='/'>
              <button id='btBack' type='button'>
                <MdKeyboardArrowLeft size={20} color='#fff' />
                Voltar
              </button>
            </Link>

            <button id='btEditTruck' onClick={handleCreateNewTruck}>
              <MdDone size={20} color='#fff' />
              Novo Caminhão
            </button>

            <button id='btSave' type='submit'>
              <MdDone size={20} color='#fff' />
              Salvar
            </button>
          </div>
        </header>

        <div className='content'>
          <div className='row'>
            <div className={`input ${errors.name ? 'error' : ''}`}>
              <Input
                name='name'
                placeholder='Nome completo'
                value={values.name}
                label='Nome completo'
                disabled
                maxLength={25}
              />
              {errors.name && (
                <div className='validateError'>{errors.name}</div>
              )}
            </div>
            <div className={`input ${errors.cpf ? 'error' : ''}`}>
              <Input
                name='cpf'
                mask='###.###.###-##'
                placeholder='CPF'
                value={values.cpf}
                disabled
                label='CPF'
                maxLength={25}
              />

              {errors.cpf && <div className='validateError'>{errors.cpf}</div>}
            </div>
          </div>

          <div className='row'>
            <div className={`input ${errors.birthDate ? 'error' : ''}`}>
              <Input
                name='birthDate'
                type='date'
                value={values.birthDate}
                disabled
                label='Data de nascimento'
                maxLength={25}
              />
              {errors.birthDate && (
                <div className='validateError'>{errors.birthDate}</div>
              )}
            </div>

            <div className={`input ${errors.cnh ? 'error' : ''}`}>
              <Input
                name='cnh'
                placeholder='CNH'
                value={values.cnh}
                disabled
                label='CNH'
                maxLength={11}
              />

              {errors.cpf && <div className='validateError'>{errors.cpf}</div>}
            </div>
          </div>

          <div className='row'>
            <div className={`input checkbox`}>
              <fieldset>
                <legend>Contato:</legend>
                <div>
                  <Checkbox
                    name='hasSMS'
                    checked={values.hasSMS}
                    value={values.hasSMS}
                    onChange={handleChange}
                    color='default'
                    size='medium'
                  />
                  <label>Autorizar envio de mensagens sms</label>
                </div>

                <div>
                  <Checkbox
                    name='hasWhats'
                    checked={values.hasWhats}
                    value={values.hasWhats}
                    onChange={handleChange}
                    color='default'
                    size='medium'
                  />
                  <label>Autorizar mensagens ao whatsapp</label>
                </div>

                <div>
                  <Checkbox
                    name='hasEmail'
                    checked={values.hasEmail}
                    value={values.hasEmail}
                    onChange={handleChange}
                    color='default'
                    size='medium'
                  />
                  <label>Autorizar envio de mensagens ao email</label>
                </div>
              </fieldset>
            </div>
          </div>

          {truck && (
            <div>
              <div className='check-truck title'>
                <h2>Caminhões</h2>
              </div>
              <table>
                <thead>
                  <tr>
                    <th className='head'>Placa</th>
                    <th className='head'>Renavam</th>
                    <th className='head'>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {truck?.map((setTruck, key) => {
                    let licensePlate = setTruck.licensePlate

                    return (
                      <tr key={key}>
                        <td>{licensePlate.replace('-', '')}</td>
                        <td>{setTruck.renavam}</td>
                        <td>
                          <EditIcon
                            onClick={() => handleEditTruck(setTruck.id)}
                          />
                          <DeleteIcon
                            onClick={() => handleUnbidTruck(setTruck.id)}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}

          <div className='row'>
            <div className={`input delete`}>
              <Button
                variant='contained'
                className='delete-driver-button'
                onClick={handleCorfirmDelete}
              >
                Deletar Conta
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
