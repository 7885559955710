import { useContext } from 'react'
import { DriverContext } from './DriverContext'

export function useDriverContext() {
  const context = useContext(DriverContext)

  if (!context) {
    throw new Error('DriverContext must be used within a DriverProvider')
  }

  return context
}
