import React, { FormEvent, useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { Box, Modal, Typography, Button } from '@material-ui/core'
import { AiOutlineWarning } from 'react-icons/ai'
import { toast } from 'react-toastify'
import api from '../../../../../services/api'
import { useHistory } from 'react-router-dom'

interface ModalSignOutQueueProps {
  open: boolean
  onClose: () => void
}

export function ModalSignOutQueue({ open, onClose }: ModalSignOutQueueProps) {
  const history = useHistory()

  const [queueId, setQueueId] = useState<number | null>(null)

  const [token, setToken] = useState<string | null>(null)
  const [tokenInput, setTokenInput] = useState('')
  const tokenLength = token?.length

  function tokenGenerate() {
    const tokenCode = Math.floor(Math.random() * 9999) + 1000
    return tokenCode.toString()
  }

  function handleCloseModal() {
    onClose()
    setTokenInput('')
  }

  async function handleSubmitForm(event: FormEvent) {
    event.preventDefault()

    if (!tokenInput) {
      toast.error('Dígite o código')
      return
    }

    if (token === tokenInput) {
      await signOutQueue()
    } else {
      toast.error('Código incorreto')
    }
  }

  async function signOutQueue() {
    try {
      const response = await api.delete(`/queue/delete/${queueId}`)

      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        toast.success('Você saiu da fila com sucesso')

        setTimeout(() => history.push('/app/edit'), 2000)
      }
    } catch (error) {
      toast.error('Erro ao sair da fila')
      console.log(error)
    } finally {
      handleCloseModal()
    }
  }

  useEffect(() => {
    setToken(tokenGenerate())
  }, [])

  useEffect(() => {
    const driverId = sessionStorage.getItem('@AppDriver:id')

    if (driverId) {
      api.get(`/queue/position/driver/${driverId}`).then(response => {
        setQueueId(response.data.queue.id)
      })
    }
  }, [])

  return (
    <Box className='modal expedition'>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <form onSubmit={handleSubmitForm}>
          <Box className=' suspend-warning body'>
            <Box className='container diver'>
              <Box>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  <div className='transfer advice'>
                    <div className='modal-header'>
                      <div className='bold-text'>
                        <AiOutlineWarning className='warn-icon' />
                        Atenção!
                      </div>
                    </div>
                    <div className='modal-text-title'>
                      <span>
                        Para fazer edições no seu perfil é necessário sair da
                        fila. Deseja continuar?
                      </span>
                    </div>
                  </div>
                </Typography>
              </Box>

              <Box className='modal-content'>
                <div className='modal-code'>
                  <h2>Estou ciente e desejo continuar</h2>
                  <div>
                    <p>
                      Digite o código <span>{token}</span> na caixa ao lado
                    </p>

                    <InputMask
                      type='text'
                      id='token'
                      max={4}
                      min={4}
                      mask={tokenLength === 5 ? '99999' : '9999'}
                      value={tokenInput}
                      onChange={e => setTokenInput(e.target.value)}
                    />
                  </div>
                </div>
              </Box>
              <Box className='actions buttons transfer'>
                <Button type='button' onClick={handleCloseModal}>
                  Não
                </Button>

                <Button type='submit'>Sim</Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Modal>
    </Box>
  )
}
