import React from 'react'
import { MdCancel, MdSearch } from 'react-icons/md'

import './styles.scss'

interface SearchVehicleByLicensePlateProps {
  licensePlate: string
  onSearch: (licensePlate: string) => void
}

export function SearchVehicleByLicensePlate({
  licensePlate,
  onSearch
}: SearchVehicleByLicensePlateProps) {
  function clearSearch() {
    onSearch('')
  }

  return (
    <div className='search-license-plate__container'>
      <div className='search-license-plate__content'>
        <input
          value={licensePlate}
          placeholder='Buscar Placa'
          onChange={e => onSearch(e.target.value)}
        />

        <div className='search__icon'>
          {!licensePlate ? (
            <MdSearch fontSize={26} color='#999999' />
          ) : (
            <MdCancel
              onClick={clearSearch}
              fontSize={26}
              className='cancel__search'
              title='limpar pesquisa'
            />
          )}
        </div>
      </div>
    </div>
  )
}
