import React from "react";
import { Button } from "@material-ui/core";
import { GrDocumentCsv } from "react-icons/gr";
import { CSVLink } from "react-csv";

interface ExportCSVLinkButtonProps {
  rows: any[];
}

export function ExportCSVLinkButton({
  rows,
}: ExportCSVLinkButtonProps) {
  const csv = rows.map((row: any) => {

    row = {
      name: row.name,
      cpf: row.cpf,
      truck: row.truck?.licensePlate,
      shippingCompany: row.shippingCompany,
      company: row.company?.name
    }

    const obReportject: any = Object.values(row);

    obReportject.shift();
    obReportject.splice(5, 8);



    return obReportject;
  });

  return (
    <Button>
      <CSVLink data={csv}>
        <GrDocumentCsv />
      </CSVLink>
    </Button>
  );
}
