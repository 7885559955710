import React from 'react'
import styles from '../styles.module.css'
import { WarningIcon } from '../../components/WarningIcon/WarningIcon'
import { format, parseISO } from 'date-fns'
import { Link } from 'react-router-dom'

export function CheckInCancel({ cancelDate }: { cancelDate: string }) {
  const formattedDate = format(parseISO(cancelDate), "dd/MM  'às' HH:mm")

  return (
    <div className={styles['check-in-step-cancel']}>
      <div className={styles['check-in-step-warning-header']}>
        <WarningIcon size={32} />
        <h2>Atenção</h2>
      </div>

      <div className={styles['check-in-step-content']}>
        <p>
          Você acaba de sair da fila. Por esse motivo seu check-in será
          cancelado.
        </p>

        <p className={styles.date}>Check-in cancelado em {formattedDate}</p>

        <p>
          Para ser atendido é necessário retornar para ao pátio da empresa,
          acessar o WIFI disponível e refazer o check-in.
        </p>
      </div>

      <div className={styles['check-in-step-actions']}>
        <Link to='/'>
          <button className={styles['check-in-step-button']}>Ok</button>
        </Link>
      </div>
    </div>
  )
}
