import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react'
import { useAuth } from '../../../../contexts/auth'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { Helmet } from 'react-helmet'
import CryptoJS from 'crypto-js'
import InputMask from 'react-input-mask'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import nextLogo from '../../../../assets/logo.png'

import api from '../../../../services/api'
import { Link, useParams } from 'react-router-dom'
import logoPB from '../../../../assets/images/logo-dark-1_a.svg'
import WarningLogin from '../../../../components/Warning/warningLogin'
import { ReCaptcha } from 'react-recaptcha-v3'
import ExpeditionOverview from '../../../../components/expeditionOverview/Index'
import CookieConsent from 'react-cookie-consent'
import { ImCancelCircle } from 'react-icons/im'
import ModalUserTerm from '../../../../components/ModalUserTerm'
import Draft, { convertFromHTML } from 'draft-js'
import { Box, Modal, Typography, Button } from '@material-ui/core'
import { AiOutlineWarning } from 'react-icons/ai'

import caminhaoIcon from '../../../../assets/images/icons/truck-theme.svg'

import driverIcon from '../../../../assets/images/icons/Motorista-theme.svg'
import { CgArrowLongRight } from 'react-icons/cg'
import { useWindowLocationHost } from '../../../../hooks/useWindowLocationHost'
import './styles.css'

interface RouteParams {
  encryptedDriverCpf: string
  encryptedDriverBirthdate: string
}

interface Company {
  id: number
  logo: string
  name: string
  company?: string
  website?: string
  status?: boolean
}

export function DriverLogin() {
  const EditorState = Draft.EditorState
  const ContentState = Draft.ContentState

  const { windowLocationHost } = useWindowLocationHost()

  const { encryptedDriverCpf, encryptedDriverBirthdate } =
    useParams<RouteParams>()

  const driverCpf = encryptedDriverCpf
    ? CryptoJS.AES.decrypt(
        encryptedDriverCpf
          .toString()
          .replaceAll('xMl3Jk', '+')
          .replaceAll('Por21Ld', '/')
          .replaceAll('Ml32', '='),
        'SecretCpf'
      ).toString(CryptoJS.enc.Utf8)
    : ''
  const driverBirthdate = encryptedDriverBirthdate
    ? CryptoJS.AES.decrypt(
        encryptedDriverBirthdate
          .toString()
          .replaceAll('xMl3Jk', '+')
          .replaceAll('Por21Ld', '/')
          .replaceAll('Ml32', '='),
        'SecretBirthdate'
      ).toString(CryptoJS.enc.Utf8)
    : ''
  const alreadyLogged = sessionStorage.getItem('@App:logged')

  const [title, setTitle] = useState<string>('')
  const [loginEnabled, setLoginEnabled] = useState<boolean>(true)
  const [cpf, setCpf] = useState<string>(driverCpf)
  const [birthDate, setBirthday] = useState<string>(driverBirthdate)
  const [loading, setLoading] = useState<boolean>(false)
  const [recaptcha, setRecaptcha] = useState<string>('')
  const [driver, setDriver] = useState<any>('')

  const [company, setCompany] = useState<Company>()
  const [altCompany, setAltCompany] = useState<Company>()
  const [companys, setCompanys] = useState<Company[]>()
  const driverCompanyId = sessionStorage.getItem('@AppDriver:companyId')
  const [attendance, setAttendance] = useState<any>()
  const { driverLogin } = useAuth()
  const [modal, setModal] = useState('')
  const [close, setClose] = useState('')
  const [modalSuspend, setModalSuspend] = useState<any>(false)

  const [tokenModal, setTokenModal] = useState<string>('')
  const [numberCode, setNumberCode] = useState<string>('')
  const [queueDate, setQueueDate] = useState<string>('')

  const dateQueueFormat = new Date(queueDate)
    .toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })
    .split(' ')

  const characterToken = numberCode.length

  const [id, setId] = useState(
    Number(sessionStorage.getItem('@App:id')?.replace(/"/g, ''))
  )
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText(''))
  )

  useEffect(() => {
    setNumberCode(tokenGenerate())

    api.get(`/settings/operating-data/${driverCompanyId}`).then(response => {
      let start = response.data.settings.start
      let end = response.data.settings.end
      let weekDays = JSON.parse(response.data.settings.weekDays)
      weekDays = Object.values(weekDays)

      let week = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

      let selectedDays = week.filter((item: any, index: number) => {
        if (weekDays[index] === true) {
          return week[index]
        }

        return ''
      })

      api
        .get(`/attendances/today/company/${driverCompanyId}`)
        .then(response => {
          setAttendance({
            attendance:
              response.data?.id && response.data?.status ? response.data.id : 0,
            attendanceStart: start,
            attendanceEnd: end,
            days: selectedDays
          })
          localStorage.setItem(
            '@AppDriver:attendance',
            JSON.stringify({
              attendance:
                response.data?.id && response.data?.status
                  ? response.data.id
                  : 0,
              attendanceStart: start,
              attendanceEnd: end,
              days: selectedDays
            })
          )
        })
    })

    setLoginEnabled(true)

    loadReCaptcha('6LdcCZ4dAAAAAJM6_yCOqvahBvE5w8c14HMQbMJS')

    api
      .get(`/company/host/`, {
        params: {
          host: windowLocationHost
        }
      })
      .then(response => {
        setCompany(response.data)

        if (!response.data) {
          api.get(`/company`).then(response => {
            setCompanys(response.data)
          })
        }
        api.get(`/useTerm/user/company/${response.data.id}`).then(response => {
          if (response.data?.content) {
            setTitle(response.data?.tile)
            const blocksFromHTML = convertFromHTML(response.data?.content)
            const state = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            )

            setEditorState(EditorState.createWithContent(state))
          }
        })
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function loginTransferedDriver(event: FormEvent) {
    event.preventDefault()

    if (numberCode === tokenModal) {
      api
        .post('driver/save', {
          override: true,
          id: driver?.id,
          company: {
            id: company?.id
          }
        })
        .then(() => {
          handleSubmit(event)
        })
        .catch(e => {
          toast.error('Usuário ou senha inválido')
        })
    } else {
      toast.error('Token inválido')
    }
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault()
    const _birthDate = birthDate.split('/')
    setLoading(true)

    await driverLogin({
      cpf: cpf,
      company: {
        id: company?.id
      },
      birthDate: _birthDate[2] + '-' + _birthDate[1] + '-' + _birthDate[0],
      recaptcha_token: recaptcha
    })
      .then(() => {
        let companyId = sessionStorage.getItem('@App:temporaryCompanyId')
        let driverId = sessionStorage.getItem('@App:temporaryDriverId')

        api
          .get(
            `/useTerm/user/company/${Number(
              sessionStorage.getItem('@AppDriver:companyId')
            )}`
          )
          .then(response => {
            if (companyId && driverId && response.data.content) {
              window.location.assign(
                `/aprovar-termo/empresa/${companyId}/${driverId}`
              )
            } else {
              window.location.assign(`/`)
            }
          })
      })
      .catch(e => {
        if (
          e?.response?.data?.message ===
          'Usuário bloqueado, fale com o administrador do sistema!'
        ) {
          toast.error(
            `Usuário bloqueado, fale com o administrador do sistema!`,
            { autoClose: 10000 }
          )
        } else if (e?.response?.status === 406) {
          setAltCompany(e?.response?.data?.company_a)
          setDriver(e?.response?.data?.driver)
          setModalSuspend(true)
          setQueueDate(e?.response?.data?.company_a?.queue?.date)
        } else {
          toast.error('Usuário ou senha inválido')
        }

        setLoading(false)
      })
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete() {
    showModal()
    setId(id)
  }

  const showModal = () => {
    setModal('show')
  }

  const hideModal = () => {
    setModal('')
  }

  const verifyCallback = (recaptchaToken: any) => {
    setRecaptcha(recaptchaToken)
  }

  function handleLoginChange(event: ChangeEvent<HTMLInputElement>) {
    setCpf(event.target.value)
  }

  function handlePassChange(event: ChangeEvent<HTMLInputElement>) {
    setBirthday(event.target.value)
  }

  function tokenGenerate() {
    const numberCode = Math.floor(Math.random() * 9999) + 1000
    return numberCode.toString()
  }
  function handleTokenChange(event: ChangeEvent<HTMLInputElement>) {
    setTokenModal(event.target.value)
  }

  function handleAccept() {
    document.cookie = 'userTerm=accepted'
  }

  return (
    <>
      {loginEnabled === true ? (
        <div className='login driver mobile'>
          <Helmet>
            <title>Login | Motorista</title>
            <meta
              name='description'
              content='NextCargo Controle de Expedição'
            />
          </Helmet>

          <ModalUserTerm
            type='Termo'
            id={id}
            title={title}
            className={modal}
            handleClose={handleCloseModal}
            editorState={editorState}
          />

          <Box className='modal expedition'>
            <Modal
              open={modalSuspend}
              onClose={() => setModalSuspend(false)}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <form onSubmit={loginTransferedDriver}>
                <Box className=' suspend-warning body'>
                  <Box className='container diver'>
                    <Box>
                      <Typography
                        id='modal-modal-title'
                        variant='h6'
                        component='h2'
                      >
                        <div className='transfer advice'>
                          <div className='modal-header'>
                            <div className='bold-text'>
                              <AiOutlineWarning className='warn-icon' />
                              Atenção!
                            </div>
                          </div>
                          <div className='modal-text-title'>
                            Você possui cadastro na empresa{' '}
                            <span>{altCompany?.company}</span>, deseja
                            transferir para empresa <span>{company?.name}</span>
                            ?
                          </div>
                          <div className='visual-transfer'>
                            <div className='company-logo'>
                              <img
                                src={`${process.env.REACT_APP_API}/${altCompany?.logo}`}
                                alt=''
                              />
                            </div>

                            <div className='mid'>
                              <div className='icons'>
                                <div>
                                  {' '}
                                  <img alt='' src={driverIcon} />{' '}
                                </div>
                                <div>
                                  {' '}
                                  <img alt='' src={caminhaoIcon} />{' '}
                                </div>
                              </div>

                              <div className='arrow'>
                                <CgArrowLongRight />
                              </div>
                            </div>

                            <div className='company-logo'>
                              <img
                                src={`${process.env.REACT_APP_API}/${company?.logo}`}
                                alt=''
                              />
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </Box>
                    <Box className='modal-content'>
                      <div className='modal-text-paragraph'>
                        Ao efetuar a mudança,{' '}
                        <span>
                          motorista e veiculo são automaticamente desassociados
                          da fila da empresa atual e devem reiniciar o processo
                          na nova empresa.
                        </span>
                      </div>
                      {queueDate && (
                        <>
                          <div className='modal-row'></div>
                          <div className='modal-position'>
                            Sua posição na fila {altCompany?.company} com
                            previsão para{' '}
                            <span>
                              {dateQueueFormat[0]} ás {dateQueueFormat[1]}
                            </span>{' '}
                            será{' '}
                            <span className='red'>
                              cancelada automaticamente
                            </span>
                          </div>
                        </>
                      )}
                      <div className='modal-row'></div>
                      <div className='modal-code'>
                        <h2>Está ciente disso e deseja continuar</h2>
                        <div>
                          <p>
                            Digite o código <span>{numberCode}</span> na caixa
                            ao lado
                          </p>
                          <InputMask
                            max={5}
                            min={4}
                            mask={characterToken === 5 ? '99999' : '9999'}
                            id='inputToken'
                            required
                            value={tokenModal}
                            onChange={e => handleTokenChange(e)}
                            type='text'
                          />
                        </div>
                      </div>
                    </Box>
                    <Box className='actions buttons transfer'>
                      <Button
                        onClick={() => {
                          window.location.reload()
                        }}
                      >
                        Não
                      </Button>
                      <Button type='submit'>Sim</Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            </Modal>
          </Box>

          <div className='container'>
            {driverCompanyId !== null && (
              <ExpeditionOverview attendance={attendance} />
            )}

            <div className='logo'>
              <img
                src={
                  company?.logo
                    ? `${process.env.REACT_APP_API}/${company?.logo}`
                    : nextLogo
                }
                alt=''
              />
            </div>

            {/* <WarningLogin /> */}
            {company && (
              <div className='data'>
                <div className='login-box'>
                  <h1>Login</h1>
                  <form onSubmit={handleSubmit}>
                    <InputMask
                      mask='999.999.999-99'
                      type='cpf'
                      name='cpf'
                      placeholder='CPF'
                      value={cpf}
                      onChange={handleLoginChange}
                    />
                    <InputMask
                      mask='99/99/9999'
                      name='birthDate'
                      placeholder='Data Nascimento'
                      value={birthDate}
                      onChange={handlePassChange}
                    />

                    <button
                      id='button-login'
                      type='submit'
                      className='btn btn-primary btn-block btn-large'
                    >
                      {loading ? 'Carregando...' : 'Entrar'}
                    </button>
                  </form>
                  <div className='nova-conta'>
                    <Link to={`/app/driver/novo/${company?.id}`}>
                      Não possui cadastro ? Clique aqui.
                    </Link>
                  </div>
                  <div className='privacy-policy '>
                    <button
                      onClick={() => {
                        handleCorfirmDelete()
                      }}
                      className='settings'
                    >
                      {alreadyLogged !== 'true'
                        ? 'Leia nossa politica de Privacidade e Termos de Uso'
                        : 'Politica de Privacidade e Termos de Uso'}
                    </button>
                  </div>
                </div>
                <div className='logo'>
                  <img
                    src={
                      company?.logo
                        ? `${process.env.REACT_APP_API}/${company?.logo}`
                        : nextLogo
                    }
                    alt=''
                  />
                </div>
              </div>
            )}

            {companys && (
              <div className='company-menu'>
                <div className='company-title'>
                  <h1>Selecione uma empresa: </h1>
                </div>
                <div className='company-buttom'>
                  {companys?.map((item: Company) => {
                    return (
                      <div className='company-item' key={item.id}>
                        <a href={item.website}>
                          <button>
                            {item.company?.slice(0, 16).toUpperCase()}
                          </button>
                        </a>
                      </div>
                    )
                  })}
                </div>
                <div className='logo'>
                  <img
                    src={
                      company?.logo
                        ? `${process.env.REACT_APP_API}/${company?.logo}`
                        : nextLogo
                    }
                    alt=''
                  />
                </div>
              </div>
            )}
          </div>
          <div className='powerBy'>
            <div>Powered by</div>
            <div>
              <img style={{ width: 170, marginTop: 10 }} src={logoPB} alt='' />
            </div>
          </div>

          {company && !document.cookie.match('userTerm') && (
            <div className={close + ''}>
              <CookieConsent
                location='bottom'
                buttonText=''
                cookieName='acceptedUseTerm'
                buttonStyle={{
                  color: '#dedede',
                  backgroundColor: '#fff',
                  fontSize: '13px'
                }}
                expires={150}
              >
                <div className='title'>
                  <div>
                    Usamos cookies para personalizar conteúdos e melhorar a sua
                    experiência
                  </div>
                </div>
                <div className='body'>
                  <div>
                    Usamos cookies para personalizar conteúdos e melhorar a sua
                    experiência
                  </div>
                  <button
                    onClick={() => {
                      handleCorfirmDelete()
                    }}
                    className='settings'
                  >
                    Leia nossa politica de Privacidade e Termos de Uso{' '}
                  </button>
                  <button
                    onClick={() => {
                      handleAccept()
                      setClose('none')
                    }}
                    className='send'
                  >
                    Entendi e aceito
                  </button>
                  <button
                    onClick={() => {
                      setClose('none')
                    }}
                    className='close'
                  >
                    <ImCancelCircle />
                  </button>
                </div>
              </CookieConsent>
            </div>
          )}

          <ReCaptcha
            sitekey={'6LdcCZ4dAAAAAJM6_yCOqvahBvE5w8c14HMQbMJS'}
            action='action_name'
            verifyCallback={verifyCallback}
          />
        </div>
      ) : (
        <>
          <WarningLogin />
        </>
      )}
    </>
  )
}
