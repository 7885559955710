import React, { useState } from "react";
import imageStartScheduling from "../../../../../assets/images/Atendimento_Iniciado.svg";
import api from "../../../../../services/api";

import "./styles.scss";

interface StartSchedulingModalProps {
  onCloseModal: () => void;
  onToggleDriver: () => Promise<void>;
  queueId?: number;
  licensePlate?: string;
}

export function StartSchedulingModal({
  onCloseModal,
  onToggleDriver,
  licensePlate,
  queueId
}: StartSchedulingModalProps) {
  const [isStartingScheduling, setIsStartingScheduling] = useState(false);

  async function handleStartScheduling() {
    setIsStartingScheduling(true);

    await onToggleDriver();

    const response = await api.put(`/queue/start`, {
      id: queueId,
      status: true
    });

    if (response.status === 200) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }

  return (
    <div className="start-scheduling__container">
      <div className="start-scheduling__content">
        <img src={imageStartScheduling} alt="" />

        <div>
          <span>Deseja</span>
          <strong>&nbsp;iniciar</strong>&nbsp;o carregamento?
        </div>

        {licensePlate && (
          <div className="start-scheduling__content__license-plate">
            {licensePlate}
          </div>
        )}

        <div className="start-scheduling__footer">
          <button className="button__close" onClick={onCloseModal}>
            Não
          </button>
          <button
            disabled={isStartingScheduling}
            className="button__submit"
            onClick={handleStartScheduling}
          >
            Sim
          </button>
        </div>
      </div>
    </div>
  );
}
