import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridToolbar, ptBR } from '@mui/x-data-grid'
import Select from 'react-select'

import api from '../../services/api'
import './styles.css'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { UserOptions } from 'jspdf-autotable'
import { CSVLink } from 'react-csv'
import load from '../../assets/images/carregando.svg'

import { GrDocumentPdf, GrDocumentCsv, GrDocumentExcel } from 'react-icons/gr'
import { toast } from 'react-toastify'

interface Name {
  name: string
}

interface Data {
  body: Name
}
interface Company {
  id: number
  name: string
}
interface iOption {
  label: string
  value: string
}

interface jsPDFCustom extends jsPDF {
  autoTable: (content: UserOptions) => void
}

const Logs: React.FC = () => {
  const [clientValue, setClientValue] = useState<any>()
  const [clients, setClients] = useState<any>()
  const [clientsBySearch, setClientsBySearch] = useState<any>()

  const [companyValue, setCompanyValue] = useState<any>()
  const [companyOptions, setCompanyOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])

  const [orderValue, setOrderValue] = useState<any>()
  const [orders, setOrders] = useState<any>()
  const [ordersBySearch, setOrdersBySearch] = useState<any>()

  const [periodValue, setPeriodValue] = useState<any>({
    value: 30,
    label: '30 Dias'
  })
  const [periodOptions, setPeriodOptions] = useState<any[]>([])

  const [truckValue, setTruckValue] = useState<any>()
  const [trucks, setTrucks] = useState<any>()
  const [trucksBySearch, setTrucksBySearch] = useState<any>()

  const [driverValue, setDriverValue] = useState<any>()
  const [drivers, setDrivers] = useState<any>()
  const [driversBySearch, setDriverBySearch] = useState<any>()

  const [groupValue, setGroupValue] = useState<any>()
  const [groupBy, setGroupBy] = useState<any>()
  const [currentColumns, setCurrentColumns] = useState<any>()
  const [rows, setRows] = useState<any>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleSearch()
    api.get(`/company`).then(response => {
      if (response.data) {
        setCompanyOptions(
          response.data?.map((item: Company) => {
            return { label: `${item.id} - ${item.name}`, value: item.id }
          })
        )
      }
    })

    api.get(`/customer`).then(response => {
      const clientsOptions = response.data?.map((item: any) => {
        return { value: item.id, label: item.name }
      })
      setClients(clientsOptions)
      setClientsBySearch(clientsOptions)
    })

    api.get(`/order`).then(response => {
      const orderOptions = response.data?.map((item: any) => {
        return { value: item.id, label: `Código - ${item.code}` }
      })
      setOrders(orderOptions)
      setOrdersBySearch(orderOptions)
    })

    api.get(`/truck`).then(response => {
      const clientsOptions = response.data?.map((item: any) => {
        return { value: item.id, label: item.licensePlate }
      })
      setTrucks(clientsOptions)
      setTrucksBySearch(clientsOptions)
    })

    api.get(`/driver`).then(response => {
      const driversOptions = response.data?.map((item: any) => {
        return { value: item.id, label: item.name }
      })
      setDrivers(driversOptions)
      setDriverBySearch(driversOptions)
    })

    setGroupBy([
      { value: 'customer', label: 'Cliente' },
      { value: 'order', label: 'Pedido' },
      { value: 'truck', label: 'Caminhão' },
      { value: 'driver', label: 'Motorista' }
    ])

    setPeriodOptions([
      { value: 30, label: '30 Dias' },
      { value: 60, label: '60 Dias' },
      { value: 90, label: '90 Dias' },
      { value: 120, label: '120 Dias' }
    ])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 95 },
    { field: 'date', headerName: 'Data', width: 220 },
    { field: 'customer', headerName: 'Cliente', width: 350 },
    { field: 'qty', headerName: 'Qtde', width: 110 },
    { field: 'order', headerName: 'Pedido', width: 150 },
    { field: 'truck', headerName: 'Caminhão', width: 180 },
    { field: 'driver', headerName: 'Motorista', width: 220 },
    { field: 'company', headerName: 'Empresa', width: 220 }
  ]

  const columnsGroupByCustomer: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 95 },
    { field: 'customer', headerName: 'Cliente', width: 200 },
    { field: 'qty', headerName: 'Qtde', width: 110 },
    { field: 'order', headerName: 'Pedido', width: 150 },
    { field: 'truck', headerName: 'Caminhão', width: 180 },
    { field: 'driver', headerName: 'Motorista', width: 220 },
    { field: 'company', headerName: 'Empresa', width: 220 }
  ]

  const columnsGroupByOrder: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 95 },
    { field: 'order', headerName: 'Pedido', width: 150 },
    { field: 'qty', headerName: 'Qtde', width: 110 },
    { field: 'customer', headerName: 'Cliente', width: 350 },
    { field: 'truck', headerName: 'Caminhão', width: 180 },
    { field: 'driver', headerName: 'Motorista', width: 220 },
    { field: 'company', headerName: 'Empresa', width: 220 }
  ]

  const columnsGroupByTruck: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 95 },
    { field: 'truck', headerName: 'Caminhão', width: 180 },
    { field: 'qty', headerName: 'Qtde', width: 110 },
    { field: 'order', headerName: 'Pedido', width: 150 },
    { field: 'driver', headerName: 'Motorista', width: 220 },
    { field: 'customer', headerName: 'Cliente', width: 350 },
    { field: 'company', headerName: 'Empresa', width: 220 }
  ]

  const columnsGroupByDriver: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 95 },
    { field: 'driver', headerName: 'Motorista', width: 220 },
    { field: 'qty', headerName: 'Qtde', width: 110 },
    { field: 'order', headerName: 'Pedido', width: 150 },
    { field: 'truck', headerName: 'Caminhão', width: 180 },
    { field: 'customer', headerName: 'Cliente', width: 350 },
    { field: 'company', headerName: 'Empresa', width: 220 }
  ]

  function handleChangeOptionClient(option: any, setSelectedState: any) {
    setSelectedState({ value: option.value, label: option.label })
  }
  function handleChangeOptionCompany(option: iOption) {
    setCompanyValue(option)
  }
  function handleChangeInputSelect(value: string, lists: any[]) {
    if (value) {
      const search = value
      const results = lists?.filter((item: any) => {
        if (item.label.toString().toLowerCase().includes(search)) {
          return { value: item.id, label: item.name }
        } else {
          return ''
        }
        //
      })

      setClientsBySearch(results)
    }
  }

  function handleSearch() {
    let searchObject = {
      endDate: new Date().toISOString(),
      startDate: new Date(
        Date.now() - periodValue?.value * 24 * 60 * 60 * 1000
      ).toISOString(),
      driverId: driverValue?.value,
      truckId: truckValue?.value,
      companyId: companyValue?.value,
      orderId: orderValue?.value,
      customerId: clientValue?.value,
      groupBy: groupValue?.value
    }

    setLoading(true)

    api
      .get('/scheduling', { params: searchObject })
      .then(response => {
        const schedulings = response.data
        setCurrentColumns(selectColumns())

        setRows(
          schedulings.map((item: any, index: any) => {
            const driverName = item?.driver?.name
              ? item?.driver?.name.split(' ')
              : item?.driver_name?.split(' ')

            if (driverName[1] === ('de' || 'DE')) {
              driverName[1] = driverName[2]
            }

            if (groupValue?.value === 'customer') {
              return {
                id: index,
                customer: item?.order_customer_name,
                qty: item?.qtdd.toFixed(),
                order: item?.order_amount + ' Pedidos',
                truck: item?.truck_amount,
                driver: item?.driver_amount,
                company: item?.company?.company
              }
            }

            if (groupValue?.value === 'order') {
              return {
                id: index,
                order: item?.orders_code,
                qty: item?.qtdd.toFixed(),
                customer: item?.order_customer_name,
                truck: item?.truck_amount,
                driver: item?.driver_amount,
                company: item?.company?.company
              }
            }

            if (groupValue?.value === 'truck') {
              return {
                id: index,
                truck: item?.truck_licensePlate,
                qty: item?.qtdd.toFixed(),
                order: item?.order_amount + ' Pedidos',
                driver: item?.driver_amount,
                customer: item?.order_customer_name,
                company: item?.company?.company
              }
            }

            if (groupValue?.value === 'driver') {
              return {
                id: index,
                driver: item?.driver_name,
                qty: item?.qtdd.toFixed(),
                order: item?.order_amount + ' Pedidos',
                truck: item?.truck_licensePlate,
                customer: item?.order_customer_name,
                company: item?.company?.company
              }
            }

            return {
              id: item.id,
              date: new Date(item?.expedDate).toLocaleDateString('pt-Br', {
                day: '2-digit',
                year: 'numeric',
                month: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
              }),
              customer: item?.order?.customer.name,
              qty: item?.qtyTransported.toFixed(),
              order: 'Pedido ' + item?.order?.code,
              truck: item?.truck?.licensePlate,
              driver: driverName[0] + ' ' + driverName[1],
              company: item?.company?.company
            }
          })
        )

        setLoading(false)

        toast.success('Consulta finalizada!')
      })
      .catch(() => {
        setLoading(false)

        toast.error('Erro ao consultar!')
      })
  }

  function selectColumns() {
    if (groupValue?.value === 'customer') {
      return columnsGroupByCustomer
    }

    if (groupValue?.value === 'order') {
      return columnsGroupByOrder
    }

    if (groupValue?.value === 'truck') {
      return columnsGroupByTruck
    }

    if (groupValue?.value === 'driver') {
      return columnsGroupByDriver
    } else return columns
  }

  const exportPDF = () => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait'
    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size) as jsPDFCustom

    doc.setFontSize(15)

    const columns = currentColumns
    columns.shift()

    const title = 'Histórico'
    const headers = [columns.map((column: any) => column.headerName)]

    const data = rows.map((e: any) => {
      const obReportject = Object.values(e)

      obReportject.shift()

      return obReportject
    })

    let content = {
      startY: 50,
      head: headers,
      body: data,
      headStyles: {
        fillColor: '#fc8f00'
      }
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save('report.pdf')
  }

  const exportExcel = () => {
    const url = window.URL.createObjectURL(new Blob(rows))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `Historico de Descarregamento.xlsx`)
    document.body.appendChild(link)
    link.click()
  }

  function returnSuspendWarning() {
    return (
      <>
        <Box className='modal load'>
          <Modal
            open={loading}
            onClose={() => setLoading(true)}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            className='modal-instance'
          >
            <Box className=' suspend-loading body'>
              <Box className='container'>
                <Box>
                  <Typography
                    id='modal-modal-title'
                    variant='h6'
                    component='h2'
                  >
                    <div className='loading'>
                      <img src={load} alt='load' />
                    </div>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      </>
    )
  }

  return (
    <div className='historic-list productList'>
      {returnSuspendWarning()}
      <header>
        <div>
          <strong>Histórico de Carregamento </strong>
        </div>
      </header>

      <div className='history-area  search'>
        <div className='infor-container'>
          <div className='row'>
            <div className={`input select`}>
              <label>Cliente </label>
              <div className='select'>
                <Select
                  className='select-options'
                  placeholder='Todos'
                  value={clientValue}
                  options={clientsBySearch}
                  onInputChange={value =>
                    handleChangeInputSelect(value, clients)
                  }
                  onChange={option =>
                    handleChangeOptionClient(option as iOption, setClientValue)
                  }
                />

                {clientValue && (
                  <Button
                    className='erase-button'
                    onClick={() => setClientValue(null)}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>

            <div className={`input select`}>
              <label>Pedidos </label>
              <div className='select'>
                <Select
                  className='select-options'
                  placeholder='Todos'
                  value={orderValue}
                  options={ordersBySearch}
                  onInputChange={value =>
                    handleChangeInputSelect(value, orders)
                  }
                  onChange={option =>
                    handleChangeOptionClient(option as iOption, setOrderValue)
                  }
                />

                {orderValue && (
                  <Button
                    className='erase-button'
                    onClick={() => setOrderValue(null)}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>

            <div className={`input select`}>
              <label>Período </label>
              <div className='select'>
                <Select
                  className='select-options'
                  placeholder='Todos'
                  value={periodValue}
                  options={periodOptions}
                  onChange={option =>
                    handleChangeOptionClient(option as iOption, setPeriodValue)
                  }
                />
                {periodValue && (
                  <Button
                    className='erase-button'
                    onClick={() => setPeriodValue(null)}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>

            <div className={`input select`}>
              <label>Caminhão </label>
              <div className='select'>
                <Select
                  className='select-options'
                  placeholder='Todos'
                  value={truckValue}
                  options={trucksBySearch}
                  onInputChange={value =>
                    handleChangeInputSelect(value, trucks)
                  }
                  onChange={option =>
                    handleChangeOptionClient(option as iOption, setTruckValue)
                  }
                />
                {truckValue && (
                  <Button
                    className='erase-button'
                    onClick={() => setTruckValue(null)}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>

            <div className={`input select`}>
              <label>Motorista </label>
              <div className='select'>
                <Select
                  className='select-options'
                  placeholder='Todos'
                  value={driverValue}
                  options={driversBySearch}
                  onInputChange={value =>
                    handleChangeInputSelect(value, drivers)
                  }
                  onChange={option =>
                    handleChangeOptionClient(option as iOption, setDriverValue)
                  }
                />
                {driverValue && (
                  <Button
                    className='erase-button'
                    onClick={() => setDriverValue(null)}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>

            <div className={`input select`}>
              <label>Agrupar por </label>
              <div className='select'>
                <Select
                  className='select-options'
                  placeholder='Todos'
                  value={groupValue}
                  options={groupBy}
                  onChange={option =>
                    handleChangeOptionClient(option as iOption, setGroupValue)
                  }
                />
                {groupValue && (
                  <Button
                    className='erase-button'
                    onClick={() => setGroupValue(null)}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>
            <div className={`input select`}>
              <label>Empresa:</label>
              <div className='select'>
                <Select
                  className='select-options'
                  style={{ width: 400 }}
                  placeholder='Todos'
                  value={companyValue}
                  options={companyOptions}
                  onChange={option =>
                    handleChangeOptionCompany(option as iOption)
                  }
                />
                {companyValue && (
                  <Button
                    className='erase-button'
                    onClick={() => setCompanyValue(null)}
                  >
                    x
                  </Button>
                )}
              </div>
            </div>
          </div>

          <button
            id='btSave'
            onClick={() => {
              handleSearch()
            }}
            type='submit'
          >
            Filtrar
          </button>
        </div>
      </div>

      <div className='logList' style={{ height: 600 }}>
        <DataGrid
          rows={rows}
          columns={currentColumns ? currentColumns : columns}
          pageSize={9}
          disableColumnMenu
          components={{
            Toolbar: GridToolbar
          }}
          rowsPerPageOptions={[8]}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>

      <div className='export-button'>
        <Button onClick={() => exportPDF()}>
          <GrDocumentPdf />
        </Button>

        <Button onClick={() => exportExcel()}>
          <GrDocumentExcel />
        </Button>

        <Button>
          <CSVLink
            data={rows.map((e: any) => {
              const obReportject = Object.values(e)

              obReportject.shift()

              return obReportject
            })}
          >
            <GrDocumentCsv />
          </CSVLink>
        </Button>
      </div>
    </div>
  )
}

export default Logs
