import React from "react";
import { LicensePlate } from "../../../components/LicensePlate";

import {
  Modal as ModalComponent,
  ModalHeading,
  ModalTitle,
  ModalWrapper,
  ModalBox,
  ModalProps
} from "../../../components/Modal";

import "./styles.scss";

interface ConfirmRecoverModalProps extends ModalProps {
  queueId?: number;
  order?: string;
  licensePlate?: string;
  qtyTransported?: string;

  onCloseModal: () => void;
  onConfirmRecover: () => Promise<void>;
}

export function ConfirmRecoverModal({
  open,
  order,
  onCloseModal,
  onConfirmRecover,
  qtyTransported,
  licensePlate
}: ConfirmRecoverModalProps) {
  return (
    <ModalComponent open={open} onOpenChange={onCloseModal}>
      <ModalWrapper>
        <ModalHeading>
          <ModalTitle>Deseja recuperar o veículo ?</ModalTitle>
        </ModalHeading>

        <ModalBox>
          <div className="confirm__recover-modal">
            {licensePlate && <LicensePlate licensePlate={licensePlate} />}

            <div>
              <span>Peso</span>
              <p>{qtyTransported}</p>
            </div>

            <div>
              <span>Pedido</span>
              <p>{order}</p>
            </div>
          </div>

          <footer className="footer__actions-recover">
            <button className="modal__button-cancel" onClick={onCloseModal}>
              Cancelar
            </button>

            <button className="modal__button-submit" onClick={onConfirmRecover}>
              Confirmar
            </button>
          </footer>
        </ModalBox>
      </ModalWrapper>
    </ModalComponent>
  );
}
