import React, { FormEvent, useState } from 'react'
import Select from 'react-select'
import Input from '../../../../../../components/Input'
import { toast } from 'react-toastify'
import { SettingsCompanyDelay } from '../../../../../../domain'
import api from '../../../../../../services/api'
import './styles.scss'

interface Option {
  label: string
  value: string
  time: string
}

interface ExpeditionDelayReasonProps {
  settingsCompanyDelay: SettingsCompanyDelay[]
  delayStatus: boolean
  expeditionDelayReasonIsActive: boolean | number
  companyId: number | string | null
  onCloseDelayReasonForm: () => void
}

export function ExpeditionDelayReason({
  delayStatus,
  settingsCompanyDelay,
  companyId,
  expeditionDelayReasonIsActive,
  onCloseDelayReasonForm
}: ExpeditionDelayReasonProps) {
  const [selectOption, setSelectOption] = useState<Option | null>(null)
  const [delayTime, setDelayTime] = useState('00:00')

  const delayOptions = settingsCompanyDelay.map(delay => ({
    value: delay.id.toString(),
    time: delay.time,
    label: delay.id + ' - ' + delay.reason
  }))

  async function handleConfirmDelay() {
    try {
      const response = await api.put(`/queue/delay`, {
        delayId: selectOption?.value,
        time: delayTime,
        companyId: companyId
      })

      if (response.status === 200) {
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    } catch (error) {
      toast.error('Erro ao registrar o delay')
    }
  }

  async function handleSubmitForm(event: FormEvent) {
    event.preventDefault()

    if (!selectOption) {
      toast.error('Preencha os campos')
      return
    }

    if (!delayStatus) {
      await handleConfirmDelay()
      clearFieldsForm()
    }
  }

  function handleSelectOneOption(option: Option) {
    setSelectOption(option)
    setDelayTime(option.time)
  }

  function handleCloseDelayReasonForm() {
    onCloseDelayReasonForm()
    clearFieldsForm()
  }

  function clearFieldsForm() {
    setDelayTime('00:00')
    setSelectOption(null)
  }

  return (
    <div
      className={`
      delayReasonAttendanceForm 
      ${expeditionDelayReasonIsActive && 'hideDelayReasonAttendanceForm'}`}
    >
      <div className='wrapperDelayReason'>
        <div>
          Qual o motivo do{' '}
          <strong>
            Atraso
            <br /> do atendimento?
          </strong>
        </div>

        <form onSubmit={handleSubmitForm}>
          <div className='inputOption'>
            <Select
              className='select-options'
              options={delayOptions}
              value={selectOption}
              onChange={option => handleSelectOneOption(option as Option)}
            >
              <option>Selecione</option>
            </Select>
          </div>

          <div className='inputDuration'>
            <span>Duração:</span>
            <Input
              name='time'
              type='time'
              disabled={!selectOption}
              value={delayTime}
              onChange={event => setDelayTime(event.target.value)}
            />

            <div className='buttonActionsForm'>
              <button type='button' onClick={handleCloseDelayReasonForm}>
                Não
              </button>

              <button type='submit'>Sim</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
