import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/auth'
import { toast } from 'react-toastify'
import api from '../../../../services/api'
import { format, isToday } from 'date-fns'

import { AiOutlineCloseCircle } from 'react-icons/ai'
import _truckImg from '../../../../assets/images/icons/truck.svg'
import { ImWhatsapp } from 'react-icons/im'
import { LicensePlate } from '../../../../components/LicensePlate'

import { CancelSchedulingModal } from './CancelSchedulingModal'
import { StartSchedulingModal } from './StartSchedulingModal'

import Select from 'react-select'

import { Queue, Driver } from '../../../../domain'

import './styles.scss'

interface DriverOption {
  value: number
  label: string
}

type CustomDriver = Omit<Driver, 'contact'>

interface OrderDetailsProps {
  queue: Queue
  checkinId?: number | null

  limit?: any
  attended?: any
  tolerance: any
  handleClose: () => void
  isExpeditionViewer?: boolean
  isConciergeViewer?: boolean
}

const optionsStyles = {
  control: (styles: any, { isFocused }: any) => ({
    ...styles,
    fontSize: '12px',
    background: '#F7F7FB',
    border: '1px solid #CCCCCC',
    borderColor: isFocused && '#CCCCCC'
  }),

  option: (styles: any, { isSelected }: any) => ({
    ...styles,
    color: '#666666',
    fontSize: isSelected ? '12px' : '12px'
  }),

  placeholder: (styles: any) => ({
    ...styles,
    color: '#666666',
    fontSize: '12px'
  })
}

export function OrderDetails({
  queue,
  checkinId,
  limit,
  attended,
  handleClose,
  tolerance,
  isExpeditionViewer,
  isConciergeViewer
}: OrderDetailsProps) {
  const { company } = useAuth()

  const [drivers, setDrivers] = useState<CustomDriver[]>([])
  const [driverSelectedId, setDriverSelectedId] = useState<number | null>(null)

  const [isOpenCancelSchedulingModal, setIsOpenCancelSchedulingModal] =
    useState(false)

  const [isOpenStartSchedulingModal, setIsOpenStartSchedulingModal] =
    useState(false)

  const [checkboxExchangeDriver, setCheckboxExchangeDriver] = useState(false)

  const { id, scheduling, driver, date, truck } = queue

  const role = sessionStorage.getItem('@App:userRole')
  const companyId = company || localStorage.getItem('@App:companyId')
  const _date = new Date(date)

  const driversOptionsSelect = drivers.map(driver => ({
    value: driver.id,
    label: driver.name
  }))

  function handleCloseModalCalcelScheduling() {
    setIsOpenCancelSchedulingModal(false)
  }

  function handleCloseModalStartScheduling() {
    setIsOpenStartSchedulingModal(false)
  }

  function handleCancelScheduling() {
    let truckDate = new Date(date)

    let today = new Date().getDate()

    if (today === truckDate.getDate()) {
      setIsOpenCancelSchedulingModal(true)
    } else {
      toast.error('Apenas agendamentos do dia de hoje podem ser cancelados')
      handleClose()
    }
  }

  function handleOnchangeCheckbox() {
    setCheckboxExchangeDriver(prevState => !prevState)
    setDriverSelectedId(null)
  }

  function handleSelectDriverOption(driverOption: DriverOption) {
    setDriverSelectedId(driverOption.value)
  }

  async function handleToggleDriver() {
    if (!driverSelectedId) return

    await api.put(`queue/update-driver`, {
      id,
      driver: {
        id: driverSelectedId
      },
      company: {
        id: companyId
      }
    })
  }

  const buttonStartSchedulingIsDisable =
    checkboxExchangeDriver && !driverSelectedId

  const productDescription = scheduling?.order?.orderItem.reduce(
    (scheduling: any) => {
      return scheduling
    }
  ).product.name

  function handleStartScheduling() {
    if (!driver?.id) {
      return
    }

    const route = `/scheduling/driver/${driver?.id}`

    api
      .get(route)
      .then(response => {
        if (limit < attended) {
          toast.error('Limite de atendimento atingido!')
        } else if (response?.data[0]?.id) {
          api
            .put('queue/update', {
              id: id,
              scheduling: { id: response.data[0].id },
              company: companyId ? { id: companyId } : null,
              driver: { id: driver?.id }
            })
            .then(() => {
              setIsOpenStartSchedulingModal(true)
            })
        } else if (!response?.data.id) {
          toast.error('Adicione o veículo e o Motorista em um pedido!')
        } else {
          toast.error('Adicione o veículo e o Motorista em um pedido!')
        }
      })
      .catch(e => {
        toast.error('Adicione o veículo e o Motorista em um pedido!')
      })
  }

  const dateHours =
    _date?.getHours() < 10 ? '0' + _date?.getHours() : _date?.getHours()

  const dateMinutes =
    _date?.getMinutes() < 10 ? '0' + _date?.getMinutes() : _date?.getMinutes()

  const serviceForecastDate = format(
    _date,
    `dd'/'MM 'às' ${dateHours}':'${dateMinutes}`
  )

  const foneDD = driver?.contact?.[0]?.fone?.slice(0, 4)

  const foneValue = driver?.contact?.[0]?.fone
    ?.slice(5, 20)
    .replace(/ /g, '')
    .replace(/-/g, '.')

  const foneFormated = foneDD + ' ' + foneValue
  const foneParenthesis = driver?.contact?.[0]?.fone.slice(1, 3)
  const foneFormatedZap = driver?.contact?.[0]?.fone
    ?.slice(5, 20)
    .replace(/ /g, '')
    .replace(/-/g, '')

  const driverHasTel = driver?.contact?.[0]

  const whatsappUrl = `https://api.whatsapp.com/send?phone=55${
    foneParenthesis + '' + foneFormatedZap
  }&text=Olá+Motorista`

  useEffect(() => {
    async function getDrivers() {
      const response = await api.get<CustomDriver[]>(
        `/driver/company/${companyId}`
      )

      const drivers = response.data

      setDrivers(drivers)
    }

    getDrivers()
  }, [companyId])

  return (
    <div className='order-details__container'>
      {isOpenCancelSchedulingModal && (
        <CancelSchedulingModal
          queueId={id}
          driverId={driver?.id}
          companyId={queue.company?.id}
          schedulingId={scheduling?.id}
          checkinId={checkinId}
          onCloseModal={handleCloseModalCalcelScheduling}
          licensePlate={scheduling?.truck?.licensePlate}
        />
      )}

      {isOpenStartSchedulingModal && (
        <StartSchedulingModal
          queueId={id}
          onCloseModal={handleCloseModalStartScheduling}
          licensePlate={scheduling?.truck?.licensePlate}
          onToggleDriver={handleToggleDriver}
        />
      )}

      <div className='order-details__content'>
        <div className='order-details__header'>
          <span>Carregamento</span>

          <button onClick={handleClose}>
            <AiOutlineCloseCircle size={32} />
          </button>
        </div>

        <section className='order-details__service-forecast'>
          <span>
            Previsão de
            <br />
            Atendimento
          </span>
          <time>{`${serviceForecastDate}`}</time>
        </section>

        <section className='order-details__vehicle'>
          <LicensePlate
            licensePlate={truck?.licensePlate ?? ''}
            fontSize='22px'
            className='order-details__license-plate'
          />

          <div className='order-details__vehicle__info'>
            <span className='vehicle__name'>{truck?.vehicleModel?.name}</span>
            <div>
              <span className='vehicle__truck-brand'>
                {truck?.vehicleModel?.truckBrand?.name}
              </span>
            </div>
          </div>
        </section>

        <section
          className={`order-details__driver order-details__wrapper ${
            driverHasTel && 'minimize'
          }`}
        >
          <div className='order-details__driver-info'>
            <span className='driver-tag'>Motorista</span>

            <span className='driver-name'>{driver?.name}</span>
          </div>

          {driverHasTel && !isExpeditionViewer && !isConciergeViewer && (
            <div className='order-details__driver-tel'>
              <span className='tel-tag'>Telefone</span>

              <p>
                {foneFormated}

                <a href={whatsappUrl} target='_blank' rel='noopener noreferrer'>
                  <ImWhatsapp />
                </a>
              </p>
            </div>
          )}
        </section>

        {scheduling ? (
          <div className='order-details__driver-order'>
            <div className='order-details__driver-order-content'>
              {scheduling?.order?.shippingCompany?.name && (
                <div className='order-details__column order-details__wrapper shipping-company__name'>
                  <span className='driver-order__title'>Transportadora</span>
                  <p>{scheduling?.order?.shippingCompany.name}</p>
                </div>
              )}

              {scheduling?.order?.customer?.name && (
                <div className='order-details__column order-details__wrapper'>
                  <span className='driver-order__title'>Destinatário</span>
                  <p>{scheduling?.order?.customer.name}</p>
                </div>
              )}

              {scheduling?.order?.customer?.city && (
                <div className='order-details__column order-details__wrapper'>
                  <span className='driver-order__title'>Município</span>
                  <p>{scheduling?.order?.customer.city}</p>
                </div>
              )}

              {productDescription && (
                <div className='order-details__column driver-order__product order-details__wrapper'>
                  <span className='driver-order__title'>Produto</span>

                  <p>{productDescription}</p>
                </div>
              )}

              <div className='order-details__wrapper shipping__order'>
                <div className='input-exchange'>
                  {!isExpeditionViewer && !isConciergeViewer && (
                    <>
                      <input
                        type='checkbox'
                        id='driver_exchange'
                        name='driver_exchange'
                        checked={checkboxExchangeDriver}
                        onChange={handleOnchangeCheckbox}
                      />
                      <label htmlFor='driver_exchange'>
                        Troca de Motorista
                      </label>
                    </>
                  )}
                </div>

                {checkboxExchangeDriver && (
                  <div className='select-group'>
                    <Select
                      options={driversOptionsSelect}
                      placeholder='Selecione...'
                      styles={optionsStyles}
                      minValue={5}
                      onChange={option =>
                        handleSelectDriverOption(option as DriverOption)
                      }
                    ></Select>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className='order-details__driver-autonomous'>
            <div className='driver-autonomous__truck-image'>
              <img src={_truckImg} alt='' />

              <span>
                Motorista
                <strong> Autonômo</strong>
              </span>
            </div>

            <div className='order-details__checkbox-actions'>
              <div className='order-details__checkbox-actions__heading'>
                <div className='input-exchange'>
                  {!isExpeditionViewer && !isConciergeViewer && (
                    <>
                      <input
                        type='checkbox'
                        id='driver_exchange'
                        name='driver_exchange'
                        checked={checkboxExchangeDriver}
                        onChange={handleOnchangeCheckbox}
                      />
                      <label htmlFor='driver_exchange'>
                        Troca de Motorista
                      </label>
                    </>
                  )}
                </div>
              </div>

              <div className='order-details__checkbox-actions__content'>
                {checkboxExchangeDriver && (
                  <div className='select-group'>
                    <Select
                      options={driversOptionsSelect}
                      placeholder='Selecione...'
                      minValue={5}
                      styles={optionsStyles}
                      onChange={option =>
                        handleSelectDriverOption(option as DriverOption)
                      }
                    ></Select>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!isExpeditionViewer && !isConciergeViewer && (
          <footer className='order-details__footer'>
            {!tolerance && isToday(_date) ? (
              <button
                onClick={handleCancelScheduling}
                className='button__cancel-service'
              >
                Cancelar
              </button>
            ) : (
              <button className='button__ghost'></button>
            )}

            {role !== 'Transportadora' && (
              <button
                disabled={buttonStartSchedulingIsDisable}
                onClick={handleStartScheduling}
                className='button__start-service'
              >
                Iniciar
              </button>
            )}
          </footer>
        )}
      </div>
    </div>
  )
}
