import React from 'react'

import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowParams
} from '@mui/x-data-grid'
import { LicensePlate } from '../../../../../../components/LicensePlate'
import { ServiceForecastDriver } from '../../../../../../components/ServiceForecastDriver'
import { QueueTableRow } from '../ExpeditionTables/expeditionTableTypes'

interface ShippingCompanyTableProps {
  date: string
  rows: QueueTableRow[]
  shippingCompanyId: number | null
  returnToleranceTime: (attendanceTime: string) => void
  handleSelectScheduling: (row: GridRowParams) => void
  returnLimitTime: any
  returnTime: any
}

export function ShippingCompanyTable({
  date,
  shippingCompanyId,
  rows,
  handleSelectScheduling,
  returnToleranceTime,
  returnLimitTime,
  returnTime
}: ShippingCompanyTableProps) {
  const columns: GridColDef[] = [
    {
      field: 'position__tolerance',
      headerName: '',
      width: 62,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { position, toleranceTime, isInTolerance } = params.row

        return (
          <ServiceForecastDriver
            position={position}
            modeTolerance={isInTolerance}
            toleranceTime={toleranceTime}
          />
        )
      }
    },
    {
      field: 'licensePlate',
      headerName: '',
      width: 200,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <LicensePlate
            licensePlate={params.row.licensePlate}
            themeColor={params.row.isInTolerance && '#fc8f00'}
          />
        )
      }
    },
    {
      field: 'driver',
      headerName: '',
      sortable: false,
      width: 300,
      renderCell: (params: GridCellParams) => {
        return (
          <div className='queue-table__row__driver'>
            <div className='driver__labels'>
              {params.row.isFreelancer === true && (
                <span className='label__freelancer'>Autônomo</span>
              )}
              {params.row.isInTolerance === true && (
                <span className='label__tolerance'>
                  Tolerância
                  <div className='dot'></div>
                  {returnToleranceTime(params.row)}
                </span>
              )}
            </div>

            <div className='driver__name'>{params.row.driverName}</div>
          </div>
        )
      }
    }
  ]

  const columnsShipping: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
      hide: true,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={`${
              params.row.isActive ? 'active position' : 'position'
            }`}
          >
            {params.row.id}
          </div>
        )
      }
    },
    {
      field: 'position__tolerance',
      headerName: '',
      width: 62,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { position, toleranceTime, isInTolerance } = params.row
        const date = new Date(toleranceTime)
        const hours = date.getHours()
        const minutes = date.getMinutes()

        return (
          <div
            className={`queue-table__row__position ${
              isInTolerance && 'mode-tolerance'
            }`}
          >
            <span>{position}</span>
            <time className='markup__time'>
              {hours}:{minutes}
            </time>
          </div>
        )
      }
    },
    {
      field: 'licensePlate',
      headerName: '',
      width: 200,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={`
                ${params.row.isActive === true ? 'active' : ''}
                ${params.row.isInTolerance === true ? 'is-tolerance' : ''}
              `}
          >
            <div className='license-plate-field'>
              <div>{params.row.licensePlate}</div>
            </div>
          </div>
        )
      }
    },
    {
      field: 'column2',
      headerName: '',
      sortable: false,
      width: 300,
      renderCell: (params: GridCellParams) => {
        return (
          <div className='queue-table__row__driver'>
            <div className='status_card'>
              <span className='label__legend'>
                <div className='content'>
                  Motorista
                  {params.row.freelancer === true && (
                    <span className='label__freelancer'>Autônomo</span>
                  )}
                  {params.row.isInTolerance === true && (
                    <span className='label__tolerance'>
                      Tolerância
                      <div className='dot'></div>
                      {returnToleranceTime(params.row)}
                    </span>
                  )}
                </div>
              </span>
              <span className='label__value'>
                {params.row?.driverName
                  ?.replace(' de ', ' ')
                  ?.replace(' das ')
                  ?.replace(' do ')
                  ?.split(' ')
                  ?.slice(0, 3)
                  ?.join(' ')}
              </span>
            </div>

            <div className='status_card'>
              <span className='label__legend'>Cliente</span>
              <span className='label__value'>
                {params.row?.scheduling?.order?.customer?.name}
              </span>
            </div>
          </div>
        )
      }
    },
    {
      field: 'column3',
      headerName: '',
      sortable: false,
      width: 300,
      renderCell: (params: GridCellParams) => {
        return (
          <div className='queue-table__row__driver'>
            <div className='status_card'>
              <span className='label__legend'>Previsão</span>
              <span className='label__value'>
                {params.row.isInTolerance
                  ? returnToleranceTime(params.row)
                  : returnTime(params.row)}
              </span>
            </div>

            <div className='status_card'>
              <span className='label__legend'>Pedido</span>
              <span className='label__value'>
                {' '}
                {params.row.scheduling?.order?.code}
              </span>
            </div>
          </div>
        )
      }
    },
    {
      field: 'column4',
      headerName: '',
      sortable: false,
      width: 300,
      renderCell: (params: GridCellParams) => {
        return (
          <div className='queue-table__row__driver'>
            <div className='status_card'>
              <span className='label__legend'>Tolerância</span>
              <span className='label__value'>
                {returnLimitTime(params.row)}
              </span>
            </div>

            <div className='status_card'>
              <span className='label__legend'>Capacidade</span>
              <span className='label__value'>
                {params.row?.truck?.netCapacity}
              </span>
            </div>
          </div>
        )
      }
    }
  ]

  const month = date.split('/')[0]
  const day = date.split('/')[1]

  const rowsFiltered = rows.filter(
    row => row?.truck?.shippingCompany?.id === Number(shippingCompanyId)
  )

  const rowsFilteredIsEmpty = rowsFiltered.length === 0

  const rowsTable = rowsFilteredIsEmpty ? rows : rowsFiltered
  const rowsTableIsEmpty = rowsTable.length === 0

  if (rowsTableIsEmpty) {
    return <strong>Algo de errado aconteceu</strong>
  }

  return (
    <div
      className={`table ${shippingCompanyId ? 'shipping__mode no-scroll' : ''}`}
    >
      <div className='queue-table__time'>
        <span>{month + '/'}</span>
        <sup>{day}</sup>
      </div>

      <DataGrid
        pageSize={100}
        rows={rowsTable}
        columns={shippingCompanyId ? columnsShipping : columns}
        disableColumnMenu
        onRowClick={row => handleSelectScheduling(row)}
      />
    </div>
  )
}
