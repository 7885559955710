import React, { useEffect, useState } from 'react';

import { Pie, HorizontalBar } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import './styles.css'


interface Statistics {
  awaitingLoadAvg: string,
  loadingTimeAvg: string,
  queueFullFlowTimeAvg: string,
  attendedQueuesAmount: number,
  missingQueuesAmount: number,
  totalTransportedAmount: number,
  moreEntryQueueHour: string,
  moreAttendanceQueueHour: string,
  moreEntryQueueDay: string,
  moreAttendanceQueueDay: string,
  biggerTotalTransported: number,
  biggerTotalTransportedDate: string,
  attendedQueuesBeforeExpected: number,
  attendedQueuesAfterExpected: number,
  queueAmount: number
}


function Dashboad() {

  const [, setQueues] = useState([]);

  const today = new Date().toLocaleDateString();

  const [totalProgrammed, setTotalProgrammed] = useState(0);

  const [totals, setTotals] = useState({ attended: 0, faulty: 0 });
  const [totalsOrder, setTotalsOrder] = useState({ complete: 0, process: 0 });

  const [data, setData] = useState({ _result: ['', ''], faulty: [0, 0], attended: [0, 0], programmedTotal: [0, 0] });

  const options = {
    scales: {

      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

  const dataUser = {
    labels: ['Expedição'],
    datasets: [
      {
        label: ['Faltosos'],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        data: [totals.faulty]
      },
      {
        label: ['Atendidos'],
        backgroundColor: 'rgba(41, 219, 17, 0.2)',
        borderColor: 'rgba(41, 219, 17, 1)',
        borderWidth: 1,
        data: [totals.attended]

      },
      {
        label: 'Programados',
        backgroundColor: 'rgba(6, 77, 80, 0.2)',
        borderColor: 'rgba(6, 77, 80, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(6, 77, 80,, 0.5)',
        hoverBorderColor: 'rgba(6, 77, 80,, 1)',
        data: [totalProgrammed]
      },
    ]

  }

  const _data = {
    labels: data._result,
    datasets: [
      {
        label: ['Faltosos'],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        data: data.faulty
      },
      {
        label: ['Atendidos'],
        backgroundColor: 'rgba(41, 219, 17, 0.2)',
        borderColor: 'rgba(41, 219, 17, 1)',
        borderWidth: 1,
        data: data.attended

      },
      {
        label: 'Programados',
        backgroundColor: 'rgba(6, 77, 80, 0.2)',
        borderColor: 'rgba(6, 77, 80, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(6, 77, 80,, 0.5)',
        hoverBorderColor: 'rgba(6, 77, 80,, 1)',
        data: data.programmedTotal
      },
    ]
  };

  const state = {
    labels: ['Completos', 'Em andamento'],
    datasets: [
      {
        label: 'Pedidos',
        backgroundColor: [
          '#2FDE00',
          '#00A6B4',

        ],
        hoverBackgroundColor: [
          '#175000',
          '#003350',
        ],
        data: [totalsOrder.complete, totalsOrder.process]
      }
    ]
  }

  

  const { role, company } = useAuth();
  const history = useHistory();


  useEffect(() => {

    if(role==="Transportadora"){
      history.push('/pedidos');
    }

    const companyId = company || localStorage.getItem('@App:companyId')

    let path = "company/" + companyId;
    if (companyId !== undefined) {
 
      path = "companys";
    } else {
      path = `company/${companyId}`;
    }

     api.get("/queue/" + path).then( response => {
          const _queue = response.data
          setQueues(_queue);
           
        });

       api.get(`/scheduling/totals/all`).then(response => {
        const _result = response.data.totals.map((item: any) => item.name)
        const faulty = response.data.totals.map((item: any) => item.faulty)
        const attended = response.data.totals.map((item: any) => item.attended)
        const programmedTotal = response.data.totals.map((item: any) => item.programmedTotal)


        setData({ _result, faulty, attended, programmedTotal });
      });


      api.get(`/settings/company/${companyId}`).then(response => {
        const _totalProgrammed = response.data.programmedTotal || 0;

        setTotalProgrammed((_totalProgrammed));
      });


      api.get(`order/totals/${path}`).then(response => {
        setTotalsOrder({ complete: response.data.complete, process: response.data.process })

      });

      api.get(`/scheduling/totals/company/${companyId}`).then(response => {
        setTotals(response.data);

      });



     
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <div id="dashboard">
      <div id="dashboard-container" className="dashboard-container">


        <div className=''>
          <div className="today">
            Hoje, {today}
          </div>


          <div>
            {role === 'Administrador' ?
              <HorizontalBar data={_data} options={options} width={500} />
              :
              <HorizontalBar data={dataUser} options={options} width={500} />
            }
            <Pie width={500}
              data={state}
              options={{
                title: {
                  display: true,
                  text: 'Pedidos',
                  fontSize: 20,

                },
                legend: {
                  display: true,
                  position: 'right'
                }
              }}
            /> 
          </div>
        </div>
      </div>
    </div>


  )
}


export default Dashboad;