import { Search } from '@material-ui/icons'
import React from 'react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../contexts/auth'
import api from '../../services/api'
import { handleMaskQtyTransported } from '../../utils/mask-qty-transported'

import {
  Modal as ModalComponent,
  ModalHeading,
  ModalTitle,
  ModalWrapper,
  ModalProps,
  ModalBox
} from '../Modal'

import './styles.scss'
import { EditAttendance } from './EditAttendance'
import { AttendanceItem } from './AttendanceItem'

interface ModalVehicleAttendanceProps extends ModalProps {
  className?: string
  onCloseModal: () => void
  isExpeditionViewer: boolean
}

export interface VehiclesAttendedData {
  id: number
  attended_at: string

  driver: {
    id: number
    name: string
  }

  scheduling: {
    id: number
    qtyTransported: number
  }

  truck: {
    licensePlate: string
  }
}

export interface UpdateTons {
  driverId: number
  schedulingId: number
  newQtyTransported: string
}

export function ModalVehicleAttendance({
  open,
  className,
  onCloseModal,
  isExpeditionViewer
}: ModalVehicleAttendanceProps) {
  const { company } = useAuth()
  const companyId = company || localStorage.getItem('@App:companyId')

  const [vehiclesAttended, setVehiclesAttended] = useState(
    [] as VehiclesAttendedData[]
  )

  const [vehiclesAttendedFiltered, setVehiclesAttendedFiltered] = useState(
    [] as VehiclesAttendedData[]
  )

  const [newQtyTransported, setNewQtyTransported] = useState('')

  const [isEditTons, setEditTons] = useState(false)
  const [isUpdateNewTon, setIsUpdateNewTon] = useState(false)
  const [whichDriverIsEdit, setWhichDriverIsEdit] = useState<number | null>(
    null
  )

  const [searchByLicensePlate, setSearchByLicensePlate] = useState('')

  const newQtyTransportedIsZero =
    Number(newQtyTransported.replace(/,/g, '')) === 0

  function updateQtyTransported(event: React.ChangeEvent<HTMLInputElement>) {
    const { qtyTransported } = handleMaskQtyTransported(event)
    setNewQtyTransported(qtyTransported)
  }

  function handleEnableTonsEditing(driverId: number) {
    setEditTons(true)
    setWhichDriverIsEdit(driverId)
  }

  function handleDisableTonsEditing() {
    setEditTons(false)
  }

  function closeModal() {
    onCloseModal()
    handleDisableTonsEditing()
    setSearchByLicensePlate('')
  }

  async function handleUpdateTons({
    schedulingId,
    newQtyTransported,
    driverId
  }: UpdateTons) {
    if (driverId === whichDriverIsEdit) {
      if (!newQtyTransported.trim()) {
        return
      }

      setIsUpdateNewTon(true)

      const formatQtyTransported = newQtyTransported.replace(/,/g, '.')

      await api
        .put(`/scheduling/update`, {
          id: schedulingId,
          qtyTransported: Number(formatQtyTransported)
        })
        .then(response => {
          const updateVehiclesAttended = vehiclesAttended.map(vehicle => {
            return vehicle.id === driverId
              ? {
                  ...vehicle,
                  scheduling: {
                    id: vehicle.scheduling.id,
                    qtyTransported: response.data.qtyTransported
                  }
                }
              : vehicle
          })

          setVehiclesAttended(updateVehiclesAttended)
          setNewQtyTransported('')

          setIsUpdateNewTon(false)
          handleDisableTonsEditing()
          toast.success('Peso editado')
        })
        .catch(error => {
          setIsUpdateNewTon(false)
          setNewQtyTransported('')
          toast.error(error.response.data.message)
        })
    }

    return
  }

  async function getVehicleByLicensePlate() {
    if (searchByLicensePlate.length > 0) {
      const formattedLicensePlate = searchByLicensePlate.toUpperCase()

      const response = await api.get(
        `/queue/attendeds/${companyId}?licensePlate=${formattedLicensePlate}`
      )

      const data = response.data

      setVehiclesAttendedFiltered(data)
    }
  }

  useEffect(() => {
    getVehicleByLicensePlate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchByLicensePlate])

  function sortByAttendedAt(queueA: any, queueB: any) {
    if (queueA.attended_at < queueB.attended_at) {
      return 1
    }
    if (queueA.attended_at > queueB.attended_at) {
      return -1
    }
    return 0
  }

  async function getVehiclesAttendance() {
    const response = await api.get(`/queue/attendeds/${companyId}`)

    const data = response.data.sort(sortByAttendedAt)

    setVehiclesAttended(data)
  }

  useEffect(() => {
    getVehiclesAttendance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, open])

  return (
    <ModalComponent open={open} onOpenChange={closeModal}>
      <ModalWrapper>
        <ModalHeading className={className}>
          <ModalTitle>
            Veículos <span>Atendidos</span>
          </ModalTitle>
        </ModalHeading>

        <ModalBox>
          <form>
            <input
              type='text'
              placeholder='Buscar placa'
              value={searchByLicensePlate}
              onChange={e => setSearchByLicensePlate(e.target.value)}
            />

            <div className='icon-search'>
              <Search fontSize='large' />
            </div>
          </form>

          <section className='modal__box-section'>
            <div className='modal__vehicles__attended__list'>
              {searchByLicensePlate.length > 0 ? (
                <>
                  {vehiclesAttendedFiltered.map(vehicleAttended => {
                    return (
                      <AttendanceItem
                        attendance={vehicleAttended}
                        isEditTons={isEditTons}
                        whichDriverIsEdit={whichDriverIsEdit}
                        handleDisableTonsEditing={handleDisableTonsEditing}
                        handleEnableTonsEditing={handleEnableTonsEditing}
                        isExpeditionViewer={isExpeditionViewer}
                      >
                        <EditAttendance
                          updateQtyTransported={updateQtyTransported}
                          newQtyTransported={newQtyTransported}
                          newQtyTransportedIsZero={newQtyTransportedIsZero}
                          isUpdateNewTon={isUpdateNewTon}
                          handleUpdateTons={handleUpdateTons}
                          attendance={vehicleAttended}
                        />
                      </AttendanceItem>
                    )
                  })}
                </>
              ) : (
                <>
                  {vehiclesAttended.map(vehicleAttended => {
                    return (
                      <AttendanceItem
                        attendance={vehicleAttended}
                        isEditTons={isEditTons}
                        whichDriverIsEdit={whichDriverIsEdit}
                        handleDisableTonsEditing={handleDisableTonsEditing}
                        handleEnableTonsEditing={handleEnableTonsEditing}
                        isExpeditionViewer={isExpeditionViewer}
                      >
                        <EditAttendance
                          updateQtyTransported={updateQtyTransported}
                          newQtyTransported={newQtyTransported}
                          newQtyTransportedIsZero={newQtyTransportedIsZero}
                          isUpdateNewTon={isUpdateNewTon}
                          handleUpdateTons={handleUpdateTons}
                          attendance={vehicleAttended}
                        />
                      </AttendanceItem>
                    )
                  })}
                </>
              )}
            </div>
          </section>
        </ModalBox>
      </ModalWrapper>
    </ModalComponent>
  )
}
