import React from 'react'
import { Button } from '@material-ui/core'
import { GrDocumentCsv } from 'react-icons/gr'
import { CSVLink } from 'react-csv'
import './styles.scss'

interface Export4DaysTableCSVProps {
  days: string[]
  dates: string[]

  onGenerateRows: (itemIndex: any) => void
  tolerance: any
  separator?: string
}

export function Export4DaysTableCSV({
  days,
  onGenerateRows,
  tolerance,
  separator
}: Export4DaysTableCSVProps) {
  const data: any[] = []

  days?.forEach((_, index) => {
    const result: any = onGenerateRows(index)

    result?.forEach((row: any) => {
      let toleranceHours = Number(tolerance.slice(0, 2)) * 60 * 60 * 1000
      let toleranceMinutes = Number(tolerance.slice(3, 5)) * 60 * 1000

      const attendanceTime = new Date(row.time).toLocaleTimeString('pt-Br', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      })

      const toleranceTimeMilleseconds = new Date(row.toleranceTime).getTime()

      const toleranceTime = new Date(
        toleranceTimeMilleseconds + (toleranceHours + toleranceMinutes)
      ).toLocaleTimeString('pt-Br', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      })

      data.push({
        id: row.id || '',
        position: row.position || '',
        licensePlate: row.licensePlate || '',
        random: '',
        attendanceTime: attendanceTime,
        toleredTime: toleranceTime
      })
    })
  })

  return (
    <>
      <Button>
        <CSVLink data={data} separator={separator}>
          <div className='csv__container'>
            <GrDocumentCsv />

            <div className='csv__content'>
              Exportar
              <div className='csv__content__days-container'>
                dias&nbsp;
                {days.map((day, index) => {
                  if (index < 4) {
                    return <span key={`${day}-${index}`}>{day}</span>
                  } else {
                    return <></>
                  }
                })}
              </div>
            </div>
          </div>
        </CSVLink>
      </Button>
    </>
  )
}
