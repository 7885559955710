import React, { useEffect, useState } from "react"
import api from "../../../../services/api"
import "./styles.scss"

const BarTop = () => {

    const [dateBrwoser, setDateBrowser] = useState('')

    useEffect(() => {
        api.get('settings/date-time').then(response => setDateBrowser(response.data))
    })

    const date = new Date(dateBrwoser)

    let day = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"]
    let month = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", 'novembro', "dezembro"]

    const dayPresent = day[date?.getDay()]
    const monthPresent = month[date?.getMonth()]
    const minutesTime = date?.getMinutes() < 10 ? '0' + date?.getMinutes() : date?.getMinutes()



    return (
        <div className="BarTop">
            <div className="BarTopContent">
                {(!isNaN(date.getDate())) && (<h2>{dayPresent}, {date?.getDate()} de {monthPresent} de {date?.getFullYear()} • {date?.getHours() + "h" + minutesTime}</h2>)}
            </div>
        </div>
    )
}

export default BarTop