import React, { FormEvent, useEffect, useState } from "react";
import cx from "clsx";
import Draft, { convertFromHTML, convertToRaw } from "draft-js";
import { toast } from "react-toastify";
import draftToHtml from "draftjs-to-html";

import { TextEditor } from "./DescriptionEditor";

import { PopupImageEmpty } from "./PopupImageEmpty";
import { PopupPreview } from "./PopupPreview";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import api from "../../../../services/api";
import { BiArrowBack } from "react-icons/bi";
import { format, parseISO } from "date-fns";
import styles from "./styles.module.scss";

interface CreatePopupData {
  id: number | null;
  label: string;
  title: string;
  content: string;
  active: boolean;
  start_at: Date;
  end_at: Date;
  imageUrl: string | null;
  company: {
    id: number;
  };
}

interface UploadPopupImage {
  id: number;
  image: File;
}

type PopupData = CreatePopupData;

interface PopupFormProps {
  popup: PopupData | null;
  companyId: number;
  onClosePopupForm: () => void;
}

export function PopupForm({
  companyId,
  onClosePopupForm,
  popup
}: PopupFormProps) {
  const EditorState = Draft.EditorState;
  const ContentState = Draft.ContentState;

  const [popupLabel, setPopupLabel] = useState(() => popup?.label || "");
  const [popupTitle, setPopupTitle] = useState(() => popup?.title || "");
  const [popupImage, setPopupImage] = useState<File | null>(null);

  const [popupStartDate, setPopupStartDate] = useState(() => {
    if (popup) {
      return format(new Date(popup.start_at), "yyyy-MM-dd");
    }

    return "";
  });

  const [popupEndDate, setPopupEndDate] = useState(() => {
    if (popup) {
      return format(new Date(popup.end_at), "yyyy-MM-dd");
    }

    return "";
  });

  const [popupActive, setPopupActive] = useState(() => popup?.active || false);

  const [popupText, setPopupText] = useState(() => {
    if (popup?.content) {
      return handleConvertHtmlContentToText(popup.content);
    }

    return EditorState.createEmpty();
  });

  const [popupImagePreview, setPopupImagePreview] = useState(() => {
    if (popup?.imageUrl) {
      return popup.imageUrl;
    }

    return "";
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [popupTextPreview, setPopupTextPreview] = useState(() =>
    EditorState.createEmpty()
  );

  async function handleCreatePopup(event: FormEvent) {
    event.preventDefault();

    const data: CreatePopupData = {
      id: popup?.id || null,
      start_at: parseISO(popupStartDate),
      end_at: parseISO(popupEndDate),

      active: popupActive,
      title: popupTitle,
      label: popupLabel,
      imageUrl: null,
      content: handleDraftToHtml(popupText),
      company: {
        id: companyId
      }
    };

    try {
      const response = await api.post("/popups/save", data);

      const popupId = response.data.id;

      if (popupImage) {
        const popupUpdated = await handleUploadImagePopup({
          id: popupId,
          image: popupImage as File
        });

        if (popupUpdated) {
          await api.post("/popups/save", popupUpdated);
        }
      }

      const toastMessage = popup
        ? "Popup atualizado com sucesso!"
        : "Popup criado com sucesso!";
      toast.success(toastMessage);

      await new Promise(resolve => setTimeout(resolve, 2000));

      onClosePopupForm();
    } catch (error) {
      toast.error("Erro ao criar popup!");
      console.log(error);
    }
  }

  function handlePreviewImage(image: File) {
    const imagePreview = URL.createObjectURL(image);
    setPopupImagePreview(imagePreview);
  }

  async function handleUploadImagePopup({ image, id }: UploadPopupImage) {
    const popupId = popup?.id || id;

    try {
      const data = new FormData();
      data.append("image", image);
      data.append("id", String(popupId));

      const response = await api.post("/popups/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      return response.data as PopupData;
    } catch (error) {
      toast.error("Erro ao fazer upload da imagem!");
      console.log(error);
    }
  }

  function handleDraftToHtml(editorContent: any) {
    const content = draftToHtml(
      convertToRaw(editorContent.getCurrentContent())
    );

    return content;
  }

  function handleTextEditorChange(editorState: any) {
    setPopupText(editorState);

    const content = handleDraftToHtml(popupText);

    handleGetTextEditorContent(content);
  }

  function handleGetTextEditorContent(content: string) {
    setPopupTextPreview(handleConvertHtmlContentToText(content));
  }

  function handleConvertHtmlContentToText(htmlContent: string) {
    const { contentBlocks, entityMap } = convertFromHTML(htmlContent);

    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );

    return EditorState.createWithContent(contentState);
  }

  const popupPreviewData = {
    label: popupLabel,
    title: popupTitle,
    imageUrl: popupImagePreview
  };

  const popupImageIsUploaded =
    popupImagePreview !== "" && popupImagePreview.includes("uploads");
  const popupImageIsPreview =
    popupImagePreview !== "" && popupImagePreview.includes("blob");

  useEffect(() => {
    if (popup) {
      setPopupTextPreview(handleConvertHtmlContentToText(popup.content));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup]);

  return (
    <div className={styles["popup-form-container"]}>
      <form
        onSubmit={handleCreatePopup}
        className={styles["popup-form"]}
        id="popup-form"
        encType="multipart/form-data"
      >
        <div>
          <button
            type="button"
            onClick={onClosePopupForm}
            className={styles["popup-form-back"]}
          >
            <BiArrowBack />
          </button>

          <h3 className={styles.title}>{popup ? popup.label : "Novo Popup"}</h3>
        </div>

        <div className={styles["popup-description"]}>
          <div className={styles["popup-form-field"]}>
            <label htmlFor="description">Descrição</label>
            <input
              type="text"
              id="description"
              value={popupLabel}
              onChange={e => setPopupLabel(e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12
            }}
          >
            <label
              htmlFor="popup-status"
              className={cx("switch", {
                // disabled: true
              })}
            >
              <input
                id="popup-status"
                type="checkbox"
                name="status"
                placeholder="Status"
                checked={popupActive}
                onChange={e => setPopupActive(e.target.checked)}
              />

              <div className={cx("slider round", styles["slider-custom"])}>
                <span className="on"></span>
                <span className="off"></span>
              </div>
            </label>

            <span id="popup-status">Ativo</span>
          </div>
        </div>

        <div
          className={cx(styles["popup-form-field"], styles["popup-duration"])}
        >
          <span className={styles.label}>Veiculação</span>

          <div>
            <div>
              <label htmlFor="start_duration">Início</label>

              <input
                type="date"
                id="start_duration"
                value={popupStartDate}
                onChange={e => setPopupStartDate(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="end_duration">Fim</label>

              <input
                type="date"
                id="end_duration"
                value={popupEndDate.toString()}
                onChange={e => setPopupEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className={styles["popup-form-field"]}>
          <label htmlFor="title">Título</label>
          <input
            type="text"
            id="title"
            value={popupTitle}
            onChange={e => setPopupTitle(e.target.value)}
          />
        </div>

        <div
          className={cx(
            styles["popup-form-field"],
            styles["popup-image-upload"]
          )}
        >
          <span className={styles.label}>Imagem</span>

          {popupImageIsUploaded ? (
            <img
              src={`${process.env.REACT_APP_API}/${popupImagePreview}`}
              alt="Imagem do popup"
            />
          ) : popupImageIsPreview ? (
            <img src={popupImagePreview} alt="Imagem do popup" />
          ) : (
            <PopupImageEmpty />
          )}

          <label htmlFor="popup_image">Escolher arquivo</label>

          <input
            type="file"
            id="popup_image"
            accept="image/png, image/jpeg, image/jpg"
            onChange={e => {
              const files = e.target.files;
              const image = files && files[0];

              setPopupImage(image);
              handlePreviewImage(image!);
            }}
          />
        </div>

        <div className={styles["popup-form-field"]}>
          <label htmlFor="description">Texto</label>

          <TextEditor
            editorState={popupText}
            onEditorStateChange={handleTextEditorChange}
          />
        </div>

        <div className={styles["popup-form-actions"]}>
          <button type="submit" form="popup-form">
            Salvar
          </button>
        </div>
      </form>

      <PopupPreview
        popupData={popupPreviewData}
        popupImagePreview={popupImagePreview}
        popupTextPreview={handleDraftToHtml(popupText)}
      />
    </div>
  );
}
