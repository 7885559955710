import React, { InputHTMLAttributes } from 'react';
import './styles.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name?: string;
    label?: string;
    type?: string;
    mask?: string;
    maskText?: string;

}

function handleMask(event: React.KeyboardEvent<HTMLInputElement>, mask: string) {

    let t = event.currentTarget;
    let texto = event.currentTarget.value;
    
    texto = texto.replace(/\D/g, '');
    let l = texto.length;
    let lm = mask.length;
    let id;

    if (window.event) {
        id = event.keyCode;
    } else if (event.which) {
        id = event.which;
    }

    let livre = false;
    if (id)
        if (id === 16 || id === 19 || (id >= 33 && id <= 40)) livre = true;

    if (!livre) {
        if (id !== 8) {

            t.value = "";
            let j = 0;
            for (let i = 0; i < lm; i++) {
                if (mask.substr(i, 1) === "#") {
                    t.value += texto.substr(j, 1);
                    j++;
                } else if (mask.substr(i, 1) !== "#") {
                    t.value += mask.substr(i, 1);
                   

                }

                if ((j) === l + 1) break;
            }
        }
    }
}
function handleMaskText(event: React.KeyboardEvent<HTMLInputElement>, mask: string) {

    let t = event.currentTarget;
    let texto = event.currentTarget.value;
  
    let l = texto.length;
    let lm = mask.length;
    let id;

    if (window.event) {
        id = event.keyCode;
    } else if (event.which) {
        id = event.which;
    }

    let livre = false;
    if (id)
        if (id === 16 || id === 19 || (id >= 33 && id <= 40)) livre = true;

    if (!livre) {
        if (id !== 8) {

            t.value = "";
            let j = 0;
            for (let i = 0; i < lm; i++) {
                if (mask.substr(i, 1) === "#") {
                    t.value += texto.substr(j, 1).toUpperCase();
                    j++;
                } else if (mask.substr(i, 1) !== "#") {
                    t.value += mask.substr(i, 1);
                    j++;

                }

                if ((j) === l + 1) break;
            }
        }
    }
}
const Input: React.FC<InputProps> = ({ label, name, type, mask,maskText, value, disabled=false, onChange, placeholder, min, max}) => {
    return (
        <div className="input-block">
            <label htmlFor={name}>{label}</label>
            <input
                id={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                type={type}
                disabled = {disabled}
                //caracter # no local em que você deseja que apareçam os números
             
                { ...(mask) ? { onKeyUp: (e) => handleMask(e, mask) } : null}
                {...(maskText) ? { onKeyUp: (e) => handleMaskText(e, maskText) } : null}

                //date-limit
                min={min}    
                max={max}         

            />
        </div>
    );
}

export default Input;