import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../components/Input'
import api from '../../../services/api'

import { useFormik } from 'formik'

import './styles.css'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

interface RouteParams {
  id: string
}

interface Contact {
  id: number
  name: string
  fone: string
  email: string
}

interface User {
  name: string
  email: string
  role: string
  password?: string
}

interface iOption {
  label: string
  value: string
  company?: string
}

const NewEditUser: React.FC = (): JSX.Element => {
  const [companyValue, setCompanyValue] = useState<iOption>({
    value: '',
    label: ''
  })
  const [shippingValue, setShippingValue] = useState<iOption>({
    value: '',
    label: ''
  })

  const id = sessionStorage.getItem('@App:id')?.replace(/"/g, '')

  const companySchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório!'),
    email: Yup.string()
      .email('E-email inválido')
      .required('E-mail é obrigatório!')
  })

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      name: '',
      email: '',
      role: 'Usuario',
      cpf: '',
      fone: '',
      password: ''
    },
    validationSchema: companySchema,
    onSubmit: values => {
      api
        .post('users/save', {
          id: parseInt(`${id}`),
          shippingCompany:
            values.role !== 'Transportadora'
              ? null
              : { id: shippingValue.value },
          company:
            values.role === 'Transportadora'
              ? null
              : companyValue.value
              ? { id: companyValue.value }
              : null,
          ...values
        })
        .then(() => {
          toast.success('Cadastro realizado com sucesso')
          setTimeout(() => {
            window.location.reload()
          }, 2500)
        })
        .catch(e => {
          if (e.response.data.code === 'ER_DUP_ENTRY')
            toast.error('Erro ao Cadastrar: Email já cadastrado!')
          else toast.error('Erro ao Cadastrar: ' + e.response.data.message)
        })
    }
  })

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let shippingCompanyId = 0

    if (id) {
      api.get(`/users/${id}`).then(response => {
        shippingCompanyId = response.data.shippingCompanyId
        setValues({
          name: response.data.name,
          email: response.data.email,
          role: response.data.role,
          cpf: response.data.cpf,
          fone: response.data.fone,
          password: ''
        })

        if (response.data.company) {
          setCompanyValue({
            value: response.data.company.id,
            label: `${response.data.company.id} - ${response.data.company.name}`
          })
        }

        if (response.data.shippingCompany) {
          setShippingValue({
            value: response.data.shippingCompany.id,
            label: `${response.data.shippingCompany.code} - ${response.data.shippingCompany.name}`
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!id) {
    return <> </>
  }

  return (
    <div className='new-user-container'>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>{`${!id ? ' Cadastrar ' : 'Editar'}`} Perfil</strong>
          </div>

          <div className='buttons-header'>
            <Link to='/usuarios'>
              <button id='btBack' type='button'>
                <MdKeyboardArrowLeft size={20} color='#fff' />
                Voltar
              </button>
            </Link>
            <button id='btSave' type='submit'>
              <MdDone size={20} color='#fff' />
              Salvar
            </button>
          </div>
        </header>

        <div className='content'>
          <div className='row'>
            <div className={`input ${errors.name ? 'error' : ''}`}>
              <Input
                name='name'
                placeholder='Nome completo'
                onChange={handleChange}
                value={values.name}
                label='Nome completo'
                maxLength={25}
              />
              {errors.name && (
                <div className='validateError'>{errors.name}</div>
              )}
            </div>

            <div className={`input ${errors.fone ? 'error' : ''}`}>
              <Input
                name='fone'
                placeholder='Telefone'
                type='fone'
                mask='(##) # ####-####'
                value={values.fone}
                onChange={handleChange}
                label='Telefone'
                maxLength={25}
              />

              {errors.fone && (
                <div className='validateError'>{errors.fone}</div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className={`input ${errors.password ? 'error' : ''}`}>
              <Input
                name='password'
                placeholder='Senha'
                type='password'
                value={values.password}
                onChange={handleChange}
                label='Senha'
                maxLength={11}
              />
            </div>
          </div>
        </div>
      </form>

      <br />
    </div>
  )
}

export default NewEditUser
