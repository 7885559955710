import { driverCheckInApi } from './driverCheckInApi'
import { CreateCheckInApi, CreateCheckinCheckout } from './driverCheckInTypes'

async function create(data: CreateCheckInApi) {
  const checkin = await driverCheckInApi.create(data)
  return checkin
}

async function getCheckInActives(companyId: string) {
  const checkInsActives = await driverCheckInApi.getCheckInActives(companyId)
  return checkInsActives
}

async function getCount(companyId: string) {
  const count = driverCheckInApi.getCount(companyId)
  return count
}

async function createLogCheckout(data: CreateCheckinCheckout) {
  const checkout = await driverCheckInApi.createLogCheckout(data)
  return checkout
}

export const driverCheckInServices = {
  create,
  getCount,
  getCheckInActives,
  createLogCheckout
}
