import React from 'react'
import { Switch } from 'react-router-dom'
import { RouterAppWrapper, RouterPubWrapper, RouterRegisterAppWrapper } from '.'
import { DriverLogin } from '../pages/App/Driver/DriverLogin/DriverLogin'
import { DriverProfile } from '../pages/App/Driver/DriverProfile/DriverProfile'
import { DriverEditTruck } from '../pages/App/Driver/DriverEditTruck/DriverEditTruck'
import { DriverRegisterTruck } from '../pages/App/Driver/DriverRegisterTruck/DriverRegisterTruck'
import { DriverCheckIn } from '../pages/App/Driver/DriverCheckIn/CheckIn'
import { DriverQueueStatus } from '../pages/App/Driver/DriverQueueStatus/DriverQueueStatus'
import { DriverScheduling } from '../pages/App/Driver/DriverScheduling/DriverScheduling'
import { DriverQueuePanel } from '../pages/App/Driver/DriverQueuePanel/DriverQueuePanel'
import { DriverPanel } from '../pages/App/Driver/DriverPanel/DriverPanel'
import { DriverLogs } from '../pages/App/Driver/DriverLogs/DriverLogs'
import { DriverHome } from '../pages/App/Driver/DriverHome/DriverHome'
import { DriverQueue } from '../pages/App/Driver/DriverQueue/DriverQueue'
import { DriverCompanyMap } from '../pages/App/Driver/DriverCompanyMap/DriverCompanyMap'
import { RegisterDriver } from '../pages/App/Driver/DriverRegister/DriverRegister'

import AproveTerm from '../pages/Lgpd/AproveTerm'
import Maps from '../pages/maps'

export function DriverRoutes() {
  return (
    <Switch>
      <RouterAppWrapper title='Login Motorista' path='/app/login' exact>
        <DriverLogin />
      </RouterAppWrapper>

      <RouterAppWrapper
        title='Login Motorista'
        path='/driver-login/:encryptedDriverCpf/:encryptedDriverBirthdate'
      >
        <DriverLogin />
      </RouterAppWrapper>

      <RouterRegisterAppWrapper
        title='Cadastro Motorista'
        path='/app/driver/novo/:id'
      >
        <RegisterDriver />
      </RouterRegisterAppWrapper>

      <RouterAppWrapper
        title='Termos de Uso'
        exact
        path='/aprovar-termo/empresa/:companyId/:driverId'
      >
        <AproveTerm />
      </RouterAppWrapper>

      <RouterAppWrapper title='Perfil Motorista' path='/app/edit' exact>
        <DriverProfile />
      </RouterAppWrapper>

      <RouterAppWrapper
        title='Editar Caminhão Motorista'
        path='/app/edit/truck/:id'
        exact
      >
        <DriverEditTruck />
      </RouterAppWrapper>

      <RouterAppWrapper
        title='Cadastrar Caminhão Motorista'
        path='/app/create/truck'
        exact
      >
        <DriverRegisterTruck />
      </RouterAppWrapper>

      <RouterAppWrapper title='Painel' path='/app/painel' exact>
        <DriverPanel />
      </RouterAppWrapper>

      <RouterAppWrapper title='Painel' path='/app/history' exact>
        <DriverLogs />
      </RouterAppWrapper>

      <RouterAppWrapper title='Home' path='/' exact>
        <DriverHome />
      </RouterAppWrapper>

      <RouterAppWrapper title='Chekin motorista' path='/app/checkin' exact>
        <DriverCheckIn />
      </RouterAppWrapper>

      <RouterAppWrapper title='Painel da Fila' path='/app/painel/fila'>
        <DriverQueuePanel />
      </RouterAppWrapper>

      <RouterAppWrapper title='Fila' path='/app/fila'>
        <DriverQueue />
      </RouterAppWrapper>

      <RouterAppWrapper title='Status' path='/app/agendamento/status'>
        <DriverQueueStatus />
      </RouterAppWrapper>

      <RouterAppWrapper title='Agendamento' path='/app/agendamento'>
        <DriverScheduling />
      </RouterAppWrapper>

      <RouterAppWrapper title='Como chegar' path='/app/como-chegar'>
        <DriverCompanyMap />
      </RouterAppWrapper>

      <RouterPubWrapper title='mapa' path='/maps'>
        <Maps />
      </RouterPubWrapper>
    </Switch>
  )
}
