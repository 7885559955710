import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { CheckInStepWarning } from './CheckInSteps/CheckInStepWarning'
import { CheckInStepConfirm } from './CheckInSteps/CheckInStepConfirm'
import { CheckInCompleted } from './CheckInSteps/CheckInCompleted'
import AppTooBar from '../../components/AppToolBar'
import { useLocation } from 'react-router-dom'
import { CheckInCancel } from './CheckInSteps/CheckInCancel'
import { CreateCheckInResponseApi } from '../../../../domain'

const TOTAL_STEPS = 4

interface LocationStateData {
  stepId: string
  checkin: CreateCheckInResponseApi | null
}

export function DriverCheckIn() {
  const location = useLocation<LocationStateData>()

  const [currentStep, setCurrentStep] = useState(1)

  const [checkInCancelDate, setCheckInCancelDate] = useState('')

  useEffect(() => {
    if (location?.state) {
      const { stepId, checkin } = location.state

      stepId && setCurrentStep(+location.state.stepId)
      checkin && setCheckInCancelDate(location.state?.checkin?.updatedAt ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleNextStepForm = () => {
    if (currentStep < TOTAL_STEPS) {
      setCurrentStep(prevState => prevState + 1)
    }
  }

  function renderStep(stepIndex: number) {
    switch (stepIndex) {
      case 1:
        return <CheckInStepWarning onNextStep={handleNextStepForm} />

      case 2:
        return <CheckInStepConfirm onNextStep={handleNextStepForm} />

      case 3:
        return <CheckInCompleted />

      case 4:
        return <CheckInCancel cancelDate={checkInCancelDate} />
    }
  }

  return (
    <div className={styles['check-in-root']}>
      <AppTooBar pathToBack='/' isStatic />

      <div className={styles['check-in-step-wrapper']}>
        <div>{renderStep(currentStep)}</div>
      </div>
    </div>
  )
}
