import React from 'react'
import { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/auth'
import api from '../../services/api'

import { ServiceForecastDriver } from '../ServiceForecastDriver'
import { LicensePlate } from '../LicensePlate'
import { Search } from '@material-ui/icons'

import {
  Modal as ModalComponent,
  ModalHeading,
  ModalTitle,
  ModalWrapper,
  ModalProps,
  ModalBox
} from '../Modal'

import './styles.scss'

interface ModalVehicleDropoutProps extends ModalProps {
  className?: string
  onCloseModal: () => void
}

interface VehiclesDropoutData {
  closedAt: string
  date: string
  device: string
  ip: string

  driver: {
    id: number
    name: string
  }

  scheduling: {
    id: number
    qtyTransported: number
  }

  truck: {
    licensePlate: string
  }
}

export function ModalVehicleDropout({
  open,
  className,
  onCloseModal
}: ModalVehicleDropoutProps) {
  const { company } = useAuth()
  const [vehiclesDropouts, setVehiclesDropouts] = useState(
    [] as VehiclesDropoutData[]
  )

  const companyId = company || localStorage.getItem('@App:companyId')

  const vehiclesDropoutsFormatted = vehiclesDropouts.map(vehicle => {
    const closedAtDate = new Date(vehicle.closedAt)

    const closedAtHours = closedAtDate.getHours()
    const closedAtMinutes = closedAtDate.getMinutes()

    const dateClosedAtFormatted = new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      timeZone: 'UTC'
    }).format(closedAtDate)

    const scheduledDateCanceled = new Date(vehicle.date)

    const scheduledDateCanceledHours = scheduledDateCanceled.getHours()
    const scheduledDateCanceledMinutes = scheduledDateCanceled.getMinutes()

    const scheduledDateCanceledFormatted = new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'UTC'
    }).format(scheduledDateCanceled)

    return {
      ...vehicle,
      closedAtHours: closedAtHours,
      closedAtMinutes: closedAtMinutes,
      dateClosedAtFormatted: dateClosedAtFormatted,
      scheduledDateCanceledHours: scheduledDateCanceledHours,
      scheduledDateCanceledMinutes: scheduledDateCanceledMinutes,
      scheduledDateCanceledFormatted: scheduledDateCanceledFormatted
    }
  })

  const [searchByLicensePlate, setSearchByLicensePlate] = useState('')

  const filteredListVehiclesDropouts =
    searchByLicensePlate.length > 0
      ? vehiclesDropoutsFormatted.filter(vehicle =>
          vehicle.truck.licensePlate
            .toString()
            .toLowerCase()
            .includes(searchByLicensePlate.toLowerCase())
        )
      : []

  function closeModal() {
    onCloseModal()
    setSearchByLicensePlate('')
  }

  useEffect(() => {
    async function getVehiclesDropouts() {
      const response = await api.get(`/logs/queue-drops/${companyId}`)
      const data = response.data

      setVehiclesDropouts(data)
    }

    getVehiclesDropouts()
  }, [companyId, open])

  return (
    <ModalComponent open={open} onOpenChange={closeModal}>
      <ModalWrapper>
        <ModalHeading className={className}>
          <ModalTitle>
            Veículos <span>Desistentes</span>
          </ModalTitle>
        </ModalHeading>

        <ModalBox>
          <form>
            <input
              type='text'
              placeholder='Buscar placa'
              value={searchByLicensePlate}
              onChange={e => setSearchByLicensePlate(e.target.value)}
            />

            <div className='icon-search'>
              <Search fontSize='large' />
            </div>
          </form>

          <section className='modal__box-section'>
            <div className='modal__vehicles__dropout__list'>
              {searchByLicensePlate.length > 0 ? (
                <>
                  {filteredListVehiclesDropouts.map(vehicle => {
                    return (
                      <div
                        className='modal__box-section__item'
                        key={vehicle.driver.id}
                      >
                        <div className='box__container'>
                          <div className='box__left'>
                            <ServiceForecastDriver
                              modeDefault
                              serviceForecastDate={vehicle.closedAt}
                            />

                            <LicensePlate
                              licensePlate={vehicle?.truck?.licensePlate}
                              themeColor='#666666'
                            />
                          </div>

                          <div className='box__right'>
                            <div className='modal__box-section__driver'>
                              <span className='driver__name'>
                                {vehicle?.driver?.name}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className='modal__footer'>
                          <div className='modal__footer__container'>
                            <div className='forecast__canceled'>
                              <div>
                                <span>Previsão cancelada</span>
                                <p>
                                  {vehicle.scheduledDateCanceledFormatted} ás{' '}
                                  {vehicle.scheduledDateCanceledHours}:
                                  {vehicle.scheduledDateCanceledMinutes}
                                </p>
                              </div>

                              <div className='device-ip'>
                                <span>IP</span>
                                <p>{vehicle.ip}</p>
                              </div>
                            </div>

                            <div className='device__driver'>
                              <span>Dispositivo</span>

                              <p>{vehicle.device}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : (
                <>
                  {vehiclesDropoutsFormatted.map(vehicle => {
                    return (
                      <div
                        className='modal__box-section__item'
                        key={vehicle.driver.id}
                      >
                        <div className='box__container'>
                          <div className='box__left'>
                            <ServiceForecastDriver
                              modeDefault
                              serviceForecastDate={vehicle.closedAt}
                            />

                            <LicensePlate
                              licensePlate={vehicle?.truck?.licensePlate}
                              themeColor='#666666'
                            />
                          </div>

                          <div className='box__right'>
                            <div className='modal__box-section__driver'>
                              <span className='driver__name'>
                                {vehicle.driver.name}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className='modal__footer'>
                          <div className='modal__footer__container'>
                            <div className='forecast__canceled'>
                              <div>
                                <span>Previsão cancelada</span>
                                <p>
                                  {vehicle.scheduledDateCanceledFormatted} ás{' '}
                                  {vehicle.scheduledDateCanceledHours}:
                                  {vehicle.scheduledDateCanceledMinutes}
                                </p>
                              </div>

                              <div className='device-ip'>
                                <span>IP</span>
                                <p>{vehicle.ip}</p>
                              </div>
                            </div>

                            <div className='device__driver'>
                              <span>Dispositivo</span>

                              <p>{vehicle.device}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
          </section>
        </ModalBox>
      </ModalWrapper>
    </ModalComponent>
  )
}
