import React from 'react'
import { ExpeditionTab } from './ExpeditionTab'
import { TabQueueOptions } from '../../expedTypes'

interface TabsHeadingProps {
  tabSelected: TabQueueOptions
  onChangeTabActive: (tabOption: TabQueueOptions) => void

  courtyardCount: number
  queueCount: number
  attendanceCount: number
}

export function TabsHeading({
  tabSelected,
  onChangeTabActive,
  attendanceCount,
  courtyardCount,
  queueCount
}: TabsHeadingProps) {
  return (
    <div className='tabs__heading'>
      {attendanceCount > 0 && (
        <ExpeditionTab
          id='attendance'
          count={attendanceCount}
          label='Atendimento'
          tabSelected={tabSelected}
          onChangeTab={() => onChangeTabActive('attendance')}
        />
      )}

      <ExpeditionTab
        id='courtyard'
        count={courtyardCount}
        label='Pátio'
        tabSelected={tabSelected}
        onChangeTab={() => onChangeTabActive('courtyard')}
      />

      <ExpeditionTab
        id='queue'
        count={queueCount}
        label='Fila'
        tabSelected={tabSelected}
        onChangeTab={() => onChangeTabActive('queue')}
      />
    </div>
  )
}
