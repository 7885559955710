function get(key: string) {
  return localStorage.getItem(key)
}

function set(key: string, value: string) {
  return localStorage.setItem(key, value)
}

function remove(key: string) {
  return localStorage.removeItem(key)
}

export const localStorageUtils = { get, set, remove }
