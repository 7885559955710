import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridToolbar, ptBR } from '@mui/x-data-grid'

import { Link, useHistory, useParams } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import { AiOutlineWhatsApp } from 'react-icons/ai'

import Input from '../../../components/Input'
import api from '../../../services/api'

import { useFormik } from 'formik'

import './styles.css'
import { toast } from 'react-toastify'
import ModalDelete from '../../../components/ModalDelete'
import CSVReader, { IFileInfo } from 'react-csv-reader'
import { useAuth } from '../../../contexts/auth'
import Axios from 'axios'

interface RouteParams {
  id: string
}

interface Contact {
  id: number
  name: string
  fone: string
  email: string
  whatsapp: boolean
}

interface Customer {
  code: number
  cnpj: string
  name: string
  company: string
  street: string
  city: string
  number?: string
  complement?: string
  district: string
  stateRegistration: string
  uf: string
  zipCode: string
  contact?: Contact[]
  _company: object
}

const NewEditCustomer: React.FC = (): JSX.Element => {
  const { company } = useAuth()
  const companyId = company || localStorage.getItem('@App:companyId')

  const [WhatsAppAtive, setWhatsAppAtive] = useState<boolean>(false)

  const [zipCode, setZipeCode] = useState<string>('')

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      name: '',
      code: 0,
      cnpj: '',
      company: '',
      uf: '',
      street: '',
      number: '',
      complement: '',
      city: '',
      district: '',
      zipCode: '',
      stateRegistration: ''
    },
    onSubmit: values => {
      // edit mode
      if (id) {
        api
          .post('customer/create', {
            id: parseInt(id),
            code: values.code,
            name: values.name,
            company: values.company,
            stateRegistration: values.stateRegistration || '',
            cnpj: values.cnpj.slice(0, 18),
            uf: values.uf || '',
            street: values.street || '',
            number: values.number || '',
            complement: values.complement || '',
            city: values.city || '',
            district: values.district || '',
            zipCode: values.zipCode || '',
            contact: contacts,
            _company: { id: companyId } || 0
          })
          .then(() => {
            toast.success('Cadastro realizado com sucesso')
            setTimeout(() => {
              history.push('/clientes')
            }, 2500)
          })
          .catch(e => {
            toast.error('Erro ao Cadastrar: ' + e.response.data.code)
          })

        // import mode
      } else {
        api
          .post('customer/import', customers)
          .then(() => {
            toast.success('Cadastro realizado com sucesso')
            setTimeout(() => {
              history.push('/clientes')
            }, 2500)
          })
          .catch(e => {
            toast.error(e.response.data.message + ' ' + e.response.data.error)
          })
      }
    }
  })

  const { id } = useParams<RouteParams>()

  const [customers, setCustomers] = useState<Customer[]>([])
  const [contacts, setContacts] = useState<Contact[]>([])
  const [contact, setContact] = useState<string>('')
  const [contactFone, setContactFone] = useState<string>('')
  const [contactEmail, setContactEmail] = useState<string>('')

  const [modal, setModal] = useState('')
  const [nameModal, setNameModal] = useState('')
  const [idContact, setIdContact] = useState(0)
  const [key, setKey] = useState('')

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0
  })

  const history = useHistory()

  useEffect(() => {
    if (id) {
      api.get(`/customer/${id}`).then(response => {
        setValues({
          code: response.data.code,
          cnpj: response.data.cnpj,
          name: response.data.name,
          company: response.data.company,
          uf: response.data.uf,
          street: response.data.street,
          number: response.data.number,
          complement: response.data.complement,
          city: response.data.city,
          district: response.data.district,
          stateRegistration: response.data.stateRegistration,
          zipCode: response.data.zipCode
        })
        setContacts(response.data.contact)
        setZipeCode(response.data?.zipCode)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // definição da tabela
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Code', width: 95 },
    { field: 'name', headerName: 'Name', width: 400 },
    { field: 'company', headerName: 'Razão Social', width: 400 },
    { field: 'cnpj', headerName: 'CNPJ', width: 160 },
    { field: 'uf', headerName: 'UF', width: 80 },
    { field: 'city', headerName: 'Cidade', width: 140 },
    { field: 'zipCode', headerName: 'CEP', width: 140 }
  ]

  // add linhas a tabela
  const rows = customers.map(item => {
    return {
      id: item.code,
      name: item.name,
      company: item.company,
      cnpj: item.cnpj,
      uf: item.uf,
      city: item.city,
      zipCode: item.zipCode
    }
  })

  function handleAddContact() {
    setContacts([
      ...contacts,
      {
        id: 0,
        name: contact,
        fone: contactFone,
        email: contactEmail,
        whatsapp: WhatsAppAtive
      }
    ])
    setContact('')
    setContactFone('')
    setContactEmail('')
    setWhatsAppAtive(false)
  }

  async function handleChangeContact(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContact(event.target.value)
  }

  async function handleChangeContactFone(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContactFone(event.target.value.slice(0, 16))
  }

  async function handleChangeContactEmail(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContactEmail(event.target.value)
  }

  async function handleEditContact(
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) {
    const value =
      type === 'fone' ? event.target.value.slice(0, 16) : event.target.value
    const newContacts = contacts.map(item => {
      if (item.id === id)
        return {
          id: id,
          name: type === 'name' ? value : item.name,
          fone: type === 'fone' ? value : item.fone,
          email: type === 'email' ? value : item.email,
          whatsapp:
            type === 'whatsapp' ? Boolean(value) : Boolean(item.whatsapp)
        }
      else return item
    })

    setContacts(newContacts)
  }

  function onEdit(id: number) {
    setInEditMode({
      status: true,
      rowKey: id
    })
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete(contact: Contact) {
    showModal()
    setNameModal(contact.name)
    setIdContact(contact.id)
    setKey(contact.fone)
  }

  function handleCsvFile(data: Array<any>, info: IFileInfo) {
    let lines: Customer[] = []
    data.forEach((item, index) => {
      if (index === 0 || item[0] === '') return

      lines.push({
        code: parseInt(item[0]),
        name: item[1],
        cnpj: item[2],
        company: item[3],
        street: item[4],
        number: item[5],
        uf: item[6],
        city: item[7],
        zipCode: item[8],
        complement: item[9],
        district: item[10],
        stateRegistration: item[11],
        _company: { id: company || 0 },
        contact:
          item[13] || item[14] || item[15]
            ? [
                {
                  id: 0,
                  name: item[12],
                  fone: `(${item[13]}) ${item[14]}`,
                  email: item[15],
                  whatsapp: false
                }
              ]
            : []
      })
    })
    setCustomers(lines)
  }

  function handleDeleteContact(id: number) {
    hideModal()

    if (id) {
      api
        .delete(`/contact/delete/${id}`)
        .then(response => {
          if (response.status === 200) {
            toast.success('Excluido com sucesso!')
            const results = contacts.filter(item => item.id !== id)
            setContacts(results)
          }
        })
        .catch(() => {
          toast.success('Erro ao excluir!')
        })
    } else {
      const results = contacts.filter(item => item.fone !== key)
      setContacts(results)
    }
  }

  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  async function handleChangeZipCode(zipCode: string) {
    const _zipCode = zipCode
    if (zipCode.length > 8) {
      _zipCode.replace('/[^0-9]/', '')
      await Axios.get(`https://viacep.com.br/ws/${zipCode}/json/`).then(
        response => {
          setValues({
            ...values,
            city: response.data.localidade,
            district: response.data.bairro,
            street: response.data.logradouro,
            uf: response.data.uf,
            complement: response.data.complemento,
            zipCode: zipCode
          })
        }
      )
    }
    setZipeCode(zipCode)
  }

  function handleWhatapp() {
    setWhatsAppAtive(!WhatsAppAtive)
  }
  return (
    <div className='new-company-container'>
      <ModalDelete
        type='Contato'
        name={nameModal}
        id={idContact}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteContact}
      />
      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>
              {`${!id ? ' Importar' : id === '0' ? 'Cadastrar' : 'Editar'}`}{' '}
              cliente{!id ? 's' : ''}
            </strong>
          </div>

          <div className='buttons-header'>
            <Link to='/clientes'>
              <button id='btBack' type='button'>
                <MdKeyboardArrowLeft size={20} color='#fff' />
                Voltar
              </button>
            </Link>
            <button id='btSave' type='submit'>
              <MdDone size={20} color='#fff' />
              Salvar
            </button>
          </div>
        </header>

        {!id ? (
          <div>
            <br />
            <span>
              {' '}
              <Link target='blank' to='../modelscsv/CadastroClientes.csv'>
                Modelo CSV para importação
              </Link>
            </span>
            <br />
            <CSVReader
              onFileLoaded={(data, fileInfo) => handleCsvFile(data, fileInfo)}
            />
            <br />
            {rows.length !== 0 && (
              <div style={{ height: 370 }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  disableColumnMenu
                  components={{
                    Toolbar: GridToolbar
                  }}
                  rowsPerPageOptions={[8]}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </div>
            )}
          </div>
        ) : (
          <div className='content'>
            <div className='row'>
              <div className={`input ${errors.cnpj ? 'error' : ''}`}>
                <Input
                  name='cnpj'
                  placeholder='CPF/CNPJ'
                  onChange={handleChange}
                  value={values.cnpj.slice(0, 18)}
                  label='CPF/CNPJ'
                  maxLength={14}
                />

                {errors.cnpj && (
                  <div className='validateError'>{errors.cnpj}</div>
                )}
              </div>

              <div className={`input ${errors.cnpj ? 'error' : ''}`}>
                <Input
                  name='code'
                  placeholder='Código'
                  onChange={handleChange}
                  value={values.code}
                  label='Código'
                />

                {errors.code && (
                  <div className='validateError'>{errors.code}</div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className={`input ${errors.name ? 'error' : ''}`}>
                <Input
                  name='name'
                  placeholder='Nome fantasia da empresa'
                  onChange={handleChange}
                  value={values.name}
                  label='Nome fantasia'
                  maxLength={25}
                />
                {errors.name && (
                  <div className='validateError'>{errors.name}</div>
                )}
              </div>
              <div className={`input ${errors.company ? 'error' : ''}`}>
                <Input
                  name='company'
                  placeholder='Razão social'
                  value={values.company}
                  onChange={handleChange}
                  label='Razão social'
                  maxLength={25}
                />

                {errors.company && (
                  <div className='validateError'>{errors.company}</div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className={`input ${errors.zipCode ? 'error' : ''}`}>
                <Input
                  mask='#####-###'
                  name='zipCode'
                  placeholder='CEP'
                  value={zipCode}
                  onChange={e => handleChangeZipCode(e.target.value)}
                  label='CEP'
                  maxLength={9}
                />

                {errors.zipCode && (
                  <div className='validateError'>{errors.zipCode}</div>
                )}
              </div>

              <div className={`input ${errors.street ? 'error' : ''}`}>
                <Input
                  name='street'
                  placeholder='Rua'
                  value={values.street}
                  onChange={handleChange}
                  label='Rua'
                  maxLength={25}
                />

                {errors.street && (
                  <div className='validateError'>{errors.street}</div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className={`input ${errors.district ? 'error' : ''}`}>
                <Input
                  name='district'
                  placeholder='Bairro'
                  value={values.district}
                  onChange={handleChange}
                  label='Bairro'
                  maxLength={25}
                />

                {errors.district && (
                  <div className='validateError'>{errors.district}</div>
                )}
              </div>

              <div className={`input ${errors.number ? 'error' : ''}`}>
                <Input
                  name='number'
                  placeholder='Numero'
                  value={values.number}
                  onChange={handleChange}
                  label='Numero'
                  maxLength={5}
                />
              </div>
            </div>

            <div className='row'>
              <div className='input'>
                <Input
                  name='complement'
                  placeholder='Complemento'
                  value={values.complement}
                  onChange={handleChange}
                  label='Complemento'
                />
              </div>

              <div className={`input ${errors.name ? 'error' : ''}`}>
                <Input
                  name='stateRegistration'
                  placeholder='Inscrição estadual'
                  onChange={handleChange}
                  value={values.stateRegistration}
                  label='Inscrição estadual'
                  maxLength={25}
                />
                {errors.stateRegistration && (
                  <div className='validateError'>
                    {errors.stateRegistration}
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className={`uf input ${errors.uf ? 'error' : ''}`}>
                <Input
                  name='uf'
                  placeholder='Estado'
                  value={values.uf}
                  onChange={handleChange}
                  label='Estado'
                  maxLength={2}
                />

                {errors.uf && <div className='validateError'>{errors.uf}</div>}
              </div>

              <div className={`input ${errors.city ? 'error' : ''}`}>
                <Input
                  name='city'
                  placeholder='Cidade'
                  value={values.city}
                  onChange={handleChange}
                  label='Cidade'
                  maxLength={25}
                />

                {errors.city && (
                  <div className='validateError'>{errors.city}</div>
                )}
              </div>
            </div>

            <div className='contacts'>
              <fieldset>
                <legend>Contatos</legend>
                <table>
                  <thead>
                    <tr>
                      <th>Contato</th>
                      <th>Telefone</th>
                      <th>Email</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contacts.length > 0 ? (
                      contacts.map((contact, index) => (
                        <tr key={index}>
                          <td>
                            {inEditMode.status &&
                            inEditMode.rowKey === index ? (
                              <Input
                                value={contact.name}
                                onChange={event =>
                                  handleEditContact(contact.id, event, 'name')
                                }
                              />
                            ) : (
                              contact.name
                            )}
                          </td>
                          <td style={{ display: 'flex', alignItems: 'center' }}>
                            <AiOutlineWhatsApp
                              size={18}
                              color={!contact.whatsapp ? 'gray' : 'green'}
                            />

                            {inEditMode.status &&
                            inEditMode.rowKey === index ? (
                              <Input
                                mask='(##) # ####-####'
                                value={contact.fone}
                                onChange={event =>
                                  handleEditContact(contact.id, event, 'fone')
                                }
                              />
                            ) : (
                              contact.fone
                            )}
                          </td>
                          <td>
                            {inEditMode.status &&
                            inEditMode.rowKey === index ? (
                              <Input
                                value={contact.email}
                                onChange={event =>
                                  handleEditContact(contact.id, event, 'email')
                                }
                              />
                            ) : (
                              contact.email
                            )}
                          </td>

                          <td>
                            <button
                              type='button'
                              className='actionButton blue'
                              onClick={() => onEdit(index)}
                            >
                              editar
                            </button>

                            <button
                              type='button'
                              className='actionButton red'
                              onClick={() => {
                                handleCorfirmDelete(contact)
                              }}
                            >
                              excluir
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4} style={{ textAlign: 'center' }}>
                          Nenhum resultado foi encontrado
                        </td>
                      </tr>
                    )}
                    <tr className='contact'>
                      <td>
                        <Input
                          name='contato'
                          placeholder='Contato'
                          value={contact}
                          onChange={handleChangeContact}
                          label=''
                        />
                      </td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        <AiOutlineWhatsApp
                          size={32}
                          color={!WhatsAppAtive ? 'gray' : 'green'}
                          onClick={handleWhatapp}
                        />
                        <Input
                          mask='(##) # ####-####'
                          name='fone'
                          placeholder='Telefone'
                          value={contactFone}
                          onChange={handleChangeContactFone}
                          label=''
                        />
                      </td>
                      <td>
                        <Input
                          name='email'
                          placeholder='E-mail'
                          value={contactEmail}
                          onChange={handleChangeContactEmail}
                          label=''
                        />
                      </td>
                      <td>
                        <button
                          type='button'
                          id='addContact'
                          onClick={handleAddContact}
                          {...(!contact || !contactFone || !contactEmail
                            ? { disabled: true }
                            : { disabled: false })}
                        >
                          + Adicionar
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
        )}
      </form>

      <br />
    </div>
  )
}

export default NewEditCustomer
