import api from '../../../services/api'
import {
  CheckInActiveApi,
  CheckInCountApi,
  CreateCheckInApi,
  CreateCheckInResponseApi,
  CreateCheckinCheckout
} from './driverCheckInTypes'

async function create(data: CreateCheckInApi) {
  const response = await api.post<CreateCheckInResponseApi>(
    `/checkins/save`,
    data
  )
  return response.data
}

async function getCheckInActives(companyId: string) {
  const response = await api.get<CheckInActiveApi[]>(
    `/checkins/company/actives/${companyId}`
  )
  return response.data
}

async function getCount(companyId: string) {
  const response = await api.get<CheckInCountApi>(
    `/checkins/count/${companyId}`
  )
  return response.data
}

/**
 * @description creates the check-in output log
 */
async function createLogCheckout(data: CreateCheckinCheckout) {
  const response = await api.post('/checkins/checkout', data)
  return response.data
}

export const driverCheckInApi = {
  create,
  getCheckInActives,
  getCount,
  createLogCheckout
}
