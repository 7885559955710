import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { Link, useHistory, useParams } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../components/Input'
import api from '../../../services/api'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import './styles.css'
import { toast } from 'react-toastify'

interface RouteParams {
  id: string
}

interface iOption {
  label: string
  value: string
}

// Validate data from form
const truckSchema = Yup.object().shape({
  // owner: Yup
  //   .string()
  //   .max(80, "Tamanho máximo de 80 caracteres")
  //   .required("Nome do proprietário é obrigatório!"),
  //licensePlate: Yup
  //  .string()
  //  .required("Placa é obrigatório!"),
})

const NewTruckModel: React.FC = (): JSX.Element => {
  const [brandTypes, setBrandTypes] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [brandValue, setBrandValue] = useState<iOption>({
    value: '',
    label: ''
  })

  const { handleSubmit, values, handleChange, errors } = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      licensePlate: '',
      renavam: '',
      owner: '',
      vehicleModel: '',
      implementType: '',
      shaftQuantity: 0,
      grossCapacity: 0,
      netCapacity: 0
    },
    validationSchema: truckSchema,
    onSubmit: values => {
      api
        .post('truck-model/save', {
          id: null,
          name: values.owner,
          truckBrand: {
            id: brandValue.value
          }
        })
        .then(() => {
          toast.success('Cadastro realizado com sucesso')
          setTimeout(() => {
            history.push('/modelos')
          }, 2500)
        })
        .catch(e => {
          toast.error('Erro ao Cadastrar: ' + e.response.data.code)
        })
    }
  })

  const { id } = useParams<RouteParams>()

  const history = useHistory()

  useEffect(() => {
    api.get(`/truck-brand/`).then(response => {
      setBrandTypes(
        response.data.map((item: any) => {
          return { value: item.id, label: item.name }
        })
      )
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleChangeOptionBrand(option: iOption) {
    setBrandValue(option)
  }

  return (
    <div className='new-truck-container'>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>{`${!id ? ' Cadastrar ' : 'Editar'}`} Modelo</strong>
          </div>

          <div className='buttons-header'>
            <Link to='/modelos'>
              <button id='btBack' type='button'>
                <MdKeyboardArrowLeft size={20} color='#fff' />
                Voltar
              </button>
            </Link>
            <button id='btSave' type='submit'>
              <MdDone size={20} color='#fff' />
              Salvar
            </button>
          </div>
        </header>

        <div className='content'>
          <div className='row'>
            <div className={`input`}>
              <label>Marca: </label>
              <Select
                className='select-options'
                value={brandValue}
                options={brandTypes}
                onChange={option => handleChangeOptionBrand(option as iOption)}
              />
            </div>

            <div className={`input ${errors.owner ? 'error' : ''}`}>
              <Input
                name='owner'
                placeholder='Nome'
                onChange={handleChange}
                value={values.owner}
                label='Nome'
                maxLength={50}
              />
              {errors.owner && (
                <div className='validateError'>{errors.owner}</div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewTruckModel
