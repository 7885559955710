import { queueApi } from './queueApi'

async function getQueueShippingCompanyPositions(shippingCompanyId: string) {
  const queuePositions = await queueApi.getQueueShippingCompanyPositions(
    shippingCompanyId
  )
  return queuePositions
}

interface GetQueueCompany {
  from: 'company' | 'shipping-company'
  companyId: string
}

async function getQueueCompany({ companyId, from }: GetQueueCompany) {
  if (from === 'company') {
    const queueCompany = queueApi.getQueueCompany(companyId)
    return queueCompany
  } else {
    const queueShippingCompany = queueApi.getQueueShippingCompany(companyId)
    return queueShippingCompany
  }
}

async function getQueueActiveCompany({ companyId, from }: GetQueueCompany) {
  if (from === 'company') {
    const queueActiveCompany = await queueApi.getQueueActiveCompany(companyId)
    return queueActiveCompany
  } else {
    const queueActiveShippingCompany =
      queueApi.getQueueActiveShippingCompany(companyId)
    return queueActiveShippingCompany
  }
}

async function getServicesStatus(companyId: string) {
  const servicesStatus = await queueApi.getServicesStatus(companyId)
  return servicesStatus
}

export const queueServices = {
  getQueueCompany,
  getQueueActiveCompany,
  getQueueShippingCompanyPositions,
  getServicesStatus
}
