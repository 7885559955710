import React from 'react'
import { WarningIcon } from '../../components/WarningIcon/WarningIcon'
import styles from '../styles.module.css'

interface CheckInStepWarningProps {
  onNextStep: () => void
}

export function CheckInStepWarning({ onNextStep }: CheckInStepWarningProps) {
  return (
    <div className={styles['check-in-step-warning-container']}>
      <div className={styles['check-in-step-warning-header']}>
        <WarningIcon size={32} />
        <h2>Atenção</h2>
      </div>

      <div className={styles['check-in-step-content']}>
        <p className={styles.bold}>
          Está ação tem como objetivo facilitar a organização física dos
          veículos presentes respeitando a sequência da fila geral, ou seja, é
          um filtro para organizar o fluxo de chamada dos veículos.
        </p>

        <p>
          O check-in está disponível exclusivamente para veículos no pátio, caso
          a ação seja realizada e o motorista se ausente da indústria, será
          necessário refazer o check-in.
        </p>

        <p>
          Esta ação não cancela/altera o tempo de tolerância do veículo, logo
          seguimos recomendando a chegada com 6 horas de antecedência.
        </p>
      </div>

      <div className={styles['check-in-step-actions']}>
        <button onClick={onNextStep} className={styles['check-in-step-button']}>
          continuar
        </button>
      </div>
    </div>
  )
}
