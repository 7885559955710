import React from 'react'
import { AiOutlineFieldTime } from 'react-icons/ai'
import { BsMoon } from 'react-icons/bs'
import { GiBackwardTime } from 'react-icons/gi'
import toleranceIcon from '../../../../../../assets/images/icons/tolerance-Icon.svg'
import './styles.scss'
import { Queue } from '../../../../../../domain'
interface ExpeditionActionsProps {
  attendance: any
  active: Queue[]
  toleranceIsEnabled: boolean
  handleTolerance: () => Promise<void>
  handleDelay: () => Promise<void>
  handleSuspend: () => void
}

export function ExpeditionActions({
  attendance,
  active,
  toleranceIsEnabled,
  handleTolerance,
  handleDelay,
  handleSuspend
}: ExpeditionActionsProps) {
  const whichTextShowWhenWithoutTolerance = !toleranceIsEnabled
    ? 'Ativar'
    : 'Desativar'
  const whichCSSClassWhenToleranceIsActive = toleranceIsEnabled
    ? 'active'
    : 'disabled'
  const whichCSSClassWhenHasQueueActive = active && 'active'

  const whichTextShowWhenWithoutAttendance = !attendance
    ? 'Iniciar'
    : 'Encerrar'

  return (
    <div className='expedition-actions__container'>
      <div
        className={`tolerance-${whichCSSClassWhenToleranceIsActive}`}
        onClick={handleTolerance}
      >
        <img src={toleranceIcon} alt='' />
        <div>
          <strong>{whichTextShowWhenWithoutTolerance}</strong>
          <span>Tolerância</span>
        </div>
      </div>

      <div className='separator' onClick={handleDelay}>
        <GiBackwardTime size={42} />
        <div>
          <strong>Atrasar</strong>
          <span>Atendimento</span>
        </div>
      </div>

      <div
        className={`suspend-attendance-${whichCSSClassWhenHasQueueActive}}`}
        onClick={handleSuspend}
      >
        {!attendance ? <AiOutlineFieldTime size={42} /> : <BsMoon size={42} />}
        <div>
          <strong>{whichTextShowWhenWithoutAttendance}</strong>
          <span>Atendimento</span>
        </div>
      </div>
    </div>
  )
}
