import 'react-toastify/dist/ReactToastify.min.css'
import './styles.css'

import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useAuth } from '../../../../contexts/auth'

import { toast } from 'react-toastify'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import nextLogo from '../../../../assets/logo.png'
import logoPB from '../../../../assets/images/logo-dark-1_a.svg'
import { useWindowLocationHost } from '../../../../hooks/useWindowLocationHost'
import { companyServices } from '../../../../domain'

interface Company {
  id: number
  logo: string
  name: string
}

export function ExpeditionLogin() {
  const { windowLocationHost } = useWindowLocationHost()
  const [company, setCompany] = useState<Company>({} as Company)

  const [password, setPassword] = useState('')
  const [recaptcha, setRecaptcha] = useState('')
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const { Login } = useAuth()

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    setLoading(true)

    Login({
      email: email,
      password: password,
      company: {
        id: company.id
      },
      recaptcha_token: recaptcha
    })
      .then(response => {
        const role = sessionStorage.getItem('@App:userRole')

        if (
          role !== 'Expedicao' &&
          role !== 'Espectador' &&
          role !== 'Portaria'
        ) {
          toast.warning('Você não tem permissão de acesso!')
          setLoading(false)
        } else if (role === 'Expedicao') {
          handleLoginTimestamp()

          window.location.assign('/expedicao')
        } else if (role === 'Espectador') {
          handleLoginTimestamp()

          window.location.assign('/expedicao')
        } else if (role === 'Portaria') {
          handleLoginTimestamp()

          window.location.assign('/expedicao')
        }
      })
      .catch(e => {
        let phone = ''

        if (
          e?.response?.data?.message ===
          'Usuário bloqueado, fale com o administrador do sistema!'
        ) {
          if (e?.response?.data?.contact.length > 0) {
            phone = e?.response?.data?.contact[0].fone
          }

          toast.error(
            `Usuário bloqueado, fale com o administrador do sistema! ${phone}`,
            { autoClose: 10000 }
          )
        } else {
          toast.error('Usuário ou senha inválido')
        }

        setLoading(false)
      })
  }

  function handleLoginTimestamp() {
    sessionStorage.setItem(
      '@reloadedTimestamp',
      new Date().toLocaleDateString('pt-br') +
        ' ás ' +
        new Date().toLocaleTimeString()
    )
  }

  function handleLoginChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value)
  }

  function handlePassChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value)
  }

  const verifyCallback = (recaptchaToken: any) => {
    setRecaptcha(recaptchaToken)
    // Here you will get the final recaptchaToken!!!
  }

  async function fetchCompany() {
    const company = await companyServices.getCompanyByHost(windowLocationHost)
    setCompany(company)

    loadReCaptcha('6LdcCZ4dAAAAAJM6_yCOqvahBvE5w8c14HMQbMJS')
  }

  useEffect(() => {
    fetchCompany()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowLocationHost])

  return (
    <>
      <Helmet>
        <title>Login | Expedição</title>
      </Helmet>

      <div className='login'>
        <div className='container'>
          <div className='login-box'>
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
              <input
                type='email'
                name='email'
                placeholder='e-mail'
                value={email}
                onChange={handleLoginChange}
              />
              <input
                type='password'
                name='p'
                placeholder='Senha'
                value={password}
                onChange={handlePassChange}
              />

              <button
                id='button-login'
                type='submit'
                className='btn btn-primary btn-block btn-large'
              >
                {loading ? 'Carregando...' : 'Entrar'}
              </button>
            </form>
          </div>

          <ReCaptcha
            sitekey={'6LdcCZ4dAAAAAJM6_yCOqvahBvE5w8c14HMQbMJS'}
            action='action_name'
            verifyCallback={verifyCallback}
          />

          <div className='logo'>
            <img
              src={
                company?.logo
                  ? `${process.env.REACT_APP_API}/${company?.logo}`
                  : nextLogo
              }
              alt=''
            />
          </div>
        </div>

        <div className='powerBy'>
          <div>Powered by</div>
          <div>
            <img style={{ width: 170, marginTop: 10 }} src={logoPB} alt='' />
          </div>
        </div>
      </div>
    </>
  )
}
