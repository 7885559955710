import React, { ComponentProps } from 'react'
import { ShippingCompanys } from '../../../../../../domain'

interface Props extends ComponentProps<'select'> {
  shippingOptions: ShippingCompanys[]
  onSelectOption: (companyId: number) => void
}

export function ExpeditionShippingOptions({
  shippingOptions,
  onSelectOption,
  ...rest
}: Props) {
  const optionsEmpty = shippingOptions.length === 0

  return (
    <select
      {...rest}
      onChange={e => onSelectOption(Number(e.target.value))}
      disabled={optionsEmpty}
    >
      <option value=''>Transportadora</option>

      {shippingOptions.map(shippingCompany => (
        <option key={shippingCompany.id} value={shippingCompany.id}>
          {shippingCompany.name}
        </option>
      ))}
    </select>
  )
}
