import Select from 'react-select'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { CompactPicker } from 'react-color';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../services/api';
import Input from '../Input';
import * as Yup from 'yup';
import InputMask from "react-input-mask";
import "./styles.css";

interface Modal {
    type: string,
    name: string,
    id: number,
    className: string,
    driver: any,
    handleClose(): void,
    handleDeleteAction(id: number): void
}

interface Driver {
    id: number,
    name: string,
    cpf: string,
    cnh: string,
    birthDate: string,
    company: {
        id:number
    }
  }

interface iOption {
    label: string;
    value: string;
  };

const ModalDelete: React.FC<Modal> = ({ type, name, id, driver, className, handleClose, handleDeleteAction }) => {

      // Validate data from form
  const driverSchema = Yup.object().shape({
    licensePlate: Yup
      .string()
      .required("Placa é obrigatório!"),
    renavam: Yup
      .string()
      .required("Renavam é obrigatório!"),
  });


  const [color, setColor] = useState<string>('');

  const history = useHistory();

  const [implementsTypes, setimplementsTypes] = useState<iOption[]>([{ value: "", label: "" }])
  const [implementValue, setImplementValue] = useState<iOption>({ value: "", label: "" })

  const [brandValue, setBrandValue] = useState<iOption>({ value: "", label: "" })
  const [modelValue, setModelValue] = useState<iOption>({ value: "", label: "" })
  
  const [brandTypes, setBrandTypes] = useState<iOption[]>([{ value: "", label: "" }])
  const [modelTypes, setModelTypes] = useState<iOption[]>([{ value: "", label: "" }]) 

  const implementTypes = [
    { type: "TRUCK", shaftQuantity: 3, grossCapacity: 23.0, netCapacity: 16.0 },
    { type: "BITRUCK", shaftQuantity: 4, grossCapacity: 29.0, netCapacity: 19.0 },
    { type: "TOCO", shaftQuantity: 5, grossCapacity: 41.5, netCapacity: 28.0 },
    { type: "ROMEU & JULIETA", shaftQuantity: 6, grossCapacity: 50.0, netCapacity: 32.5 },
    { type: "LS (MENOR 16 MT)", shaftQuantity: 6, grossCapacity: 45.0, netCapacity: 31.0 },
    { type: "LS (MAIOR 16 MT)", shaftQuantity: 6, grossCapacity: 48.5, netCapacity: 33.5 },
    { type: "VANDERLEIA", shaftQuantity: 6, grossCapacity: 53.0, netCapacity: 36.0 },
    { type: "BITREM 7 EIXOS", shaftQuantity: 7, grossCapacity: 57.0, netCapacity: 42.0 },
    { type: "BITREM 8 EIXOS", shaftQuantity: 8, grossCapacity: 65.5, netCapacity: 46.5 },
    { type: "RODOTREM 9 EIXOS", shaftQuantity: 9, grossCapacity: 74.0, netCapacity: 52.0 },
    { type: "RODOTREM 11 EIXOS", shaftQuantity: 11, grossCapacity: 91.0, netCapacity: 65.6 },
  ]

  useEffect(() => {

    const types: iOption[] = implementTypes.map((item, index) => {
      return { value: item.type, label: item.type }
    });

    setimplementsTypes(types);

    api.get(`/truck-brand/`).then(response => {
    
      setBrandTypes(response.data.map((item: any) => {
        return { value: item.id, label: item.name }
      }));

    });

    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      licensePlate: "",
      renavam: "",
      vehicleModel: "",
      implementType: "",
      shaftQuantity: 0,
      grossCapacity: 0,
      netCapacity: 0,
    },
  
    validationSchema: driverSchema,
    onSubmit: values => {
      api.patch('truck/rebind',
        
          {
            truck: {
            driver: {
              id: parseInt(`${driver.id}`)
            },
            licensePlate: values.licensePlate,
            renavam: values.renavam,
            vehicleModel: {
              id: values.vehicleModel,
              truckBrand: {
                id: brandValue.value
              }
            },
            color: color,
            company: {
              id: parseInt(`${driver.company.id}`)
            },
            grossCapacity: values.grossCapacity,
            implementType: values.implementType,
            netCapacity: values.netCapacity,
            owner: driver.name,
            shaftQuantity: values.shaftQuantity
            }
          }
    
      ).then(() => {
        toast.success('Cadastro realizado com sucesso');
        setTimeout(() => { history.go(0) }, 2500);
  
      }).catch(e => {
       
          toast.error("Erro ao Cadastrar: " + e?.response?.data?.message)
      });
    },

  });

  function handleChangeComplete(color: string) {
    setColor(color);
  }

  function handleChangeOptionImplement(option: iOption) {
    const _findImplement = implementTypes.find(item => item.type === option.value);
    setValues({
      ...values,
      implementType: _findImplement?.type || '',
      shaftQuantity: _findImplement?.shaftQuantity || 0,
      grossCapacity: _findImplement?.grossCapacity || 0,
      netCapacity: _findImplement?.netCapacity || 0,

    })
    setImplementValue(option);
  }


  function handleChangeOptionBrand(option: iOption) {
    setBrandValue(option);

    api.get(`/truck-model/brand/${option.value}`).then(response => {

      setModelTypes(response.data.map((item: any) => {
        return { value: item.id, label: item.name }
      }));

    });
  }

  function handleChangeOptionModel(option: iOption) {
    setModelValue(option);
    setValues({ ...values, vehicleModel: option.value })
  }


    return (

        <div id="myModal" className={` ${className} modal`}  >
            <div className="modal-content">

                <div className="site-content cf">
                    <div className="boxed-group dangerzone">
                        <h3>
                            Adicionar caminhão
                            <span className="close" onClick={handleClose}>&times;</span>
                        </h3>

                        <div className="boxed-group-inner">
                            <section>
                                <div className="content-truck">

                                    <div className="row">

                                        <div className={`input ${errors.licensePlate ? 'error' : ''}`}>

                                            <Input 
                                              name="licensePlate" 
                                              placeholder="Placa (Apenas letras e números)"
                                              maskText="#######"
                                              onChange={(e) => {
                                                  if((e.target.value !== '-' && ' ') && e.target.value.length < 8){
                                                    e.target.value =  e.target.value.trim().replace(/[^\w\s]/gi, '')
                                            
                                                    handleChange(e)
                                                  }
                                              }}
                                              value={values.licensePlate}
                                              label="Placa"
                                              maxLength={7}
                                            />
                                            {errors.licensePlate && <div className="validateError">{errors.licensePlate}</div>}

                                        </div>
                                        
                                        <div className={`input input-block ${errors.renavam ? 'error' : ''}`}>
                                          <label style={{ fontWeight: 'bold' }}>Renavam:</label>
                                              <br />
                                              <InputMask mask="99999999999"name="renavam" placeholder="Renavam"
                                              value={values.renavam}
                                              onChange={handleChange}
                                              />
                                              {errors.renavam && <div className="validateError">{errors.renavam}</div>}
                                        </div>
                                        <br/>
                                    
                                    </div>

                                    <div className="row">

                                      <div className={`input`}>
                                          <label style={{ fontWeight: 'bold' }}>Marca: </label>
                                          <Select className="select-options"
                                          value={brandValue}
                                          options={brandTypes}
                                          onChange={(option) => handleChangeOptionBrand(option as iOption)}
                                          />

                                      </div>
                                      <br />

                                      <div className={`input ${errors.implementType ? 'error' : ''}`}>
                                          <label style={{ fontWeight: 'bold' }}>Tipo de implemento:</label>

                                         <Select className="select-options"
                                          value={implementValue}
                                          options={implementsTypes}
                                          onChange={(option) => handleChangeOptionImplement(option as iOption)}
                                         />

                                          {errors.implementType && <div className="validateError">{errors.implementType}</div>}

                                      </div>

                                      <br />
                                    </div>
                    
                                    <div className="row">

                                        <div className={`input ${errors.vehicleModel ? 'error' : ''}`}>
                                            <label style={{ fontWeight: 'bold' }}>Modelo: </label>

                                            <Select className="select-options"
                                            value={modelValue}
                                            options={modelTypes}
                                            onChange={(option) => handleChangeOptionModel(option as iOption)}
                                            />

                                            {errors.vehicleModel && <div className="validateError">{errors.vehicleModel}</div>}

                                        </div>
                                        <br />
                                    
                                  
                                          <label style={{ fontWeight: 'bold' }}>Color:</label>
                                          <div>

                                              <CompactPicker
                                              color={color}
                                              onChangeComplete={(e) => handleChangeComplete(e.hex)}
                                              />
                                              <br />
                                          </div>
                                     

                                    </div>

                                    <div className="row">

                                        <div className={`input-number ${errors.shaftQuantity ? 'error' : ''}`}>
                                            <Input name="shaftQuantity"
                                            type="number"
                                            value={values.shaftQuantity}
                                            onChange={handleChange}
                                            label="Qtd de eixo"

                                            />

                                            {errors.shaftQuantity && <div className="validateError">{errors.shaftQuantity}</div>}

                                        </div>

                                        <div className={`input-number ${errors.grossCapacity ? 'error' : ''}`}>
                                            <Input name="grossCapacity"
                                            type="number"
                                            value={values.grossCapacity}
                                            onChange={handleChange}
                                            label="Capac. bruta em tonelada"

                                            />

                                            {errors.grossCapacity && <div className="validateError">{errors.grossCapacity}</div>}

                                        </div>

                                        <div className={`input-number ${errors.netCapacity ? 'error' : ''}`}>
                                            <Input name="netCapacity"
                                            type="number"
                                            value={values.netCapacity}
                                            onChange={handleChange}
                                            label="Capac. líquida em tonelada"

                                            />

                                            {errors.shaftQuantity && <div className="validateError">{errors.shaftQuantity}</div>}

                                        </div>
                                    </div>

                                </div>
                            </section>

                            <section className="section-bottom">
                                <button 
                                  type="button" 
                                  className="btn btn-add boxed-action"
                                  id="delete-account"
                                  onClick={() => handleSubmit()}>
                                    Cadastrar 
                                </button>
                                            
                                <h4> </h4>
                               
                            </section>
                        </div>
                    </div>
                </div>
           

            </div>
        </div>

    );
}

export default ModalDelete;