import React from "react";
import { Editor, EditorProps } from "react-draft-wysiwyg";
import "./styles.css";

interface TextEditorProps extends EditorProps {}

export function TextEditor({
  editorState,
  onEditorStateChange
}: TextEditorProps) {
  return (
    <>
      <Editor
        readOnly={false}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        editorClassName="popup-editor-class"
        toolbarClassName="popup-editor-toolbar-class"
      />
    </>
  );
}
