import React from "react";
import "./styles.css";

interface Modal {
  type: string;
  title?: string;
  name: string;
  id: number;
  className: string;
  handleClose(): void;
  handleDeleteAction(id: number): void;
}

const ModalDelete: React.FC<Modal> = ({
  type,
  name,
  title,
  id,
  className,
  handleClose,
  handleDeleteAction,
}) => {
  return (
    <div id="myModal" className={` ${className} modal`}>
      <div className="modal-content">
        <div className="site-content cf">
          <div className="boxed-group dangerzone">
            <h3>
              {type !== "Agendamento" ? `${title || "Excluir"} ${type}` : `Voltar para fila`}
              <span className="close" onClick={handleClose}>
                &times;
              </span>
            </h3>

            <div className="boxed-group-inner">
              <section>
                <button
                  type="button"
                  className="btn btn-danger boxed-action"
                  id="delete-account"
                  onClick={() => handleDeleteAction(id)}
                >
                  {type !== "Agendamento" ? (
                    <>{title || "Excluir"}</>
                  ) : (
                    <>Voltar para fila</>
                  )}
                </button>

                {type !== "Agendamento" ? (
                  <>
                    <h4>
                      {title || "Excluir"} {type}: {name} ?{" "}
                    </h4>
                  </>
                ) : (
                  <>
                    <h4>Deseja voltar o Veiculo {name} para a fila?</h4>
                  </>
                )}

                <p>Esta é uma ação permanente!</p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDelete;
