import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid'

import { Link, useHistory, useParams } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../components/Input'
import api from '../../../services/api'

import { useFormik } from 'formik'

import './styles.css'
import { toast } from 'react-toastify'
import CSVReader, { IFileInfo } from 'react-csv-reader'
import { useAuth } from '../../../contexts/auth'

interface RouteParams {
  id: string
}

interface Company {
  id: number
}

interface Product {
  code: number
  name: string
  group: number
  ncmcode: number
  company?: Company
}
const NewEditProduct: React.FC = (): JSX.Element => {
  const { company } = useAuth()

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      code: '',
      name: '',
      group: '',
      ncmcode: ''
    },

    onSubmit: values => {
      // edit mode
      if (id) {
        api
          .post('product/create', values)
          .then(() => {
            toast.success('Cadastro realizado com sucesso')
            setTimeout(() => {
              history.push('/produtos')
            }, 2500)
          })
          .catch(e => {
            toast.error('Erro ao Cadastrar: ' + e.response.data.code)
          })

        // import mode
      } else {
        api
          .post('product/import', products)
          .then(() => {
            toast.success('Cadastro realizado com sucesso')
            setTimeout(() => {
              history.push('/produtos')
            }, 2500)
          })
          .catch(e => {
            toast.error('Erro ao Cadastrar: ' + e.response.data.code)
          })
      }
    }
  })

  const { id } = useParams<RouteParams>()

  const [products, setProducts] = useState<Product[]>([])

  const history = useHistory()

  useEffect(() => {
    if (id) {
      api.get(`/product/${id}`).then(response => {
        setValues({
          code: response.data.code,
          name: response.data.name,
          group: response.data.group,
          ncmcode: response.data.ncmcode
        })
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // definição da tabela
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Código', width: 95 },
    { field: 'name', headerName: 'Nome', width: 400 },
    { field: 'group', headerName: 'Grupo', width: 400 },
    { field: 'ncmcode', headerName: 'Código NCM', width: 160 }
  ]

  // add linhas a tabela
  const rows = products.map(item => {
    return {
      id: item.code,
      name: item.name,
      group: item.group ? 'AGRICOLA' : 'SIDERURGICA',
      ncmcode: item.ncmcode
    }
  })

  function handleCsvFile(data: Array<any>, info: IFileInfo) {
    let lines: Product[] = []
    data.forEach((item, index) => {
      if (index === 0 || item[0] === '') return
      lines.push({
        code: parseInt(item[0]),
        name: item[1],
        group: item[2].toLowerCase() === 'agricola' ? 0 : 1,
        ncmcode: item[3],
        company: { id: company || 0 }
      })
    })
    setProducts(lines)
  }

  return (
    <div className='new-product-container'>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>
              {`${!id ? ' Importar ' : 'Editar'}`} produto{!id ? 's' : ''}
            </strong>
          </div>

          <div className='buttons-header'>
            <Link to='/produtos'>
              <button id='btBack' type='button'>
                <MdKeyboardArrowLeft size={20} color='#fff' />
                Voltar
              </button>
            </Link>
            <button id='btSave' type='submit'>
              <MdDone size={20} color='#fff' />
              Salvar
            </button>
          </div>
        </header>

        {!id ? (
          <div>
            <br />
            <span>
              {' '}
              <Link target='blank' to='../modelscsv/CadastroProdutos.csv'>
                Modelo CSV para importação
              </Link>
            </span>
            <br />
            <CSVReader
              onFileLoaded={(data, fileInfo) => handleCsvFile(data, fileInfo)}
            />
            <br />
            {rows.length !== 0 && (
              <div style={{ height: 370 }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  disableColumnMenu
                  rowsPerPageOptions={[8]}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </div>
            )}
          </div>
        ) : (
          <div className='content'>
            <div className='row'>
              <div className={`input ${errors.code ? 'error' : ''}`}>
                <Input
                  name='code'
                  placeholder='Código'
                  onChange={handleChange}
                  value={values.code}
                  label='Código'
                  disabled
                />

                {errors.code && (
                  <div className='validateError'>{errors.code}</div>
                )}
              </div>

              <div className={`input ${errors.code ? 'error' : ''}`}>
                <div className={`input ${errors.name ? 'error' : ''}`}>
                  <Input
                    name='name'
                    placeholder='Nome'
                    onChange={handleChange}
                    value={values.name}
                    label='Nome'
                    maxLength={80}
                  />
                  {errors.name && (
                    <div className='validateError'>{errors.name}</div>
                  )}
                </div>
              </div>
            </div>

            <div className='row'>
              <div className={`input ${errors.ncmcode ? 'error' : ''}`}>
                <Input
                  name='ncmCode'
                  placeholder='Código NCM'
                  onChange={handleChange}
                  value={values.ncmcode}
                  label='Código NCM'
                />
                {errors.ncmcode && (
                  <div className='validateError'>{errors.ncmcode}</div>
                )}
              </div>
              <div className={`input-block ${errors.group ? 'error' : ''}`}>
                <label>Grupo</label>
                <select
                  name='group'
                  value={values.group}
                  onChange={handleChange}
                >
                  <option value='0'>AGRICOLA</option>
                  <option value='1'>SIDERURGICA</option>
                </select>

                {errors.ncmcode && (
                  <div className='validateError'>{errors.ncmcode}</div>
                )}
              </div>
            </div>
          </div>
        )}
      </form>

      <br />
    </div>
  )
}

export default NewEditProduct
