import { useEffect, useState } from 'react'

/**
 * @reference https://api.ipify.org/?format=json
 * @returns `ip` for example: `45.71.42.252`
 */

export function useIPNetwork() {
  const [IPNetwork, setIPNetwork] = useState('')

  useEffect(() => {
    fetch('https://api.ipify.org/?format=json')
      .then(response => response.json())
      .then(data => {
        setIPNetwork(data.ip)
      })
  }, [])

  return IPNetwork
}
