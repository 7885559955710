import React, { useEffect } from 'react'

import { Link, useHistory, useParams } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../components/Input'
import api from '../../../services/api'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import './styles.css'
import { toast } from 'react-toastify'

interface RouteParams {
  id: string
}

// Validate data from form
const truckSchema = Yup.object().shape({
  name: Yup.string()
    .max(80, 'Tamanho máximo de 80 caracteres')
    .required('Nome do proprietário é obrigatório!')
})

const EditBrand: React.FC = (): JSX.Element => {
  const { id } = useParams<RouteParams>()

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      name: '',
      owner: ''
    },
    validationSchema: truckSchema,
    onSubmit: values => {
      api
        .post('truck-brand/save', {
          id: Number(id),
          name: values.name,
          truckBrand: {
            id: Number(id)
          }
        })
        .then(() => {
          toast.success('Cadastro realizado com sucesso')
          setTimeout(() => {
            history.push('/marcas')
          }, 2500)
        })
        .catch(e => {
          toast.error('Erro ao Cadastrar: ' + e.response.data.code)
        })
    }
  })

  const history = useHistory()

  useEffect(() => {
    if (id) {
      api.get(`/truck-brand/${id}`).then(response => {
        setValues({
          name: response.data.name,
          owner: response.data.owner
        })
      })
    }

    // api.get(`/truck-brand/`).then(response => {

    //   setBrandTypes(response.data.map((item: any) => {
    //       return {value: item.id, label: item.name}
    //   }));

    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='new-truck-container'>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>{`${!id ? ' Cadastrar ' : 'Editar'}`} Marca</strong>
          </div>

          <div className='buttons-header'>
            <Link to='/marcas'>
              <button id='btBack' type='button'>
                <MdKeyboardArrowLeft size={20} color='#fff' />
                Voltar
              </button>
            </Link>
            <button id='btSave' type='submit'>
              <MdDone size={20} color='#fff' />
              Salvar
            </button>
          </div>
        </header>

        <div className='content'>
          <div className='row'>
            <div className={`input ${errors.owner ? 'error' : ''}`}>
              <Input
                name='name'
                placeholder='Marca'
                onChange={handleChange}
                value={values.name}
                label='Marca'
                maxLength={50}
              />
              {errors.owner && (
                <div className='validateError'>{errors.owner}</div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditBrand
