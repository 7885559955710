import React, { ReactNode } from 'react'

interface TabFiltersProps {
  children: ReactNode

  shippingCompanyId: number | null
  LeftComponent: React.ReactElement
  RightComponent?: React.ReactElement
}

export function TabFilters({
  children,
  shippingCompanyId,
  LeftComponent,
  RightComponent
}: TabFiltersProps) {
  return (
    <div>
      <div className='queue-heading__actions'>
        {LeftComponent}

        {!shippingCompanyId && <>{RightComponent}</>}
      </div>

      {children}
    </div>
  )
}
