import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import "./fonts/FE-FONT.ttf";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-loading-skeleton/dist/skeleton.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
