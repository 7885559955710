import React from 'react'

interface WarningIconProps {
  size?: number
}

export function WarningIcon({ size = 25 }: WarningIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
    >
      <path
        d='M14.9412 2.03442L14.9411 2.03447L14.9462 2.04457L24.1742 20.185C24.6454 21.1117 24.5857 22.1983 24.1227 23.0538C23.6643 23.9009 22.8244 24.5 21.7284 24.5H3.27138C2.17603 24.5 1.33587 23.9007 0.877252 23.0533C0.414127 22.1977 0.354565 21.1114 0.826565 20.1855L0.82676 20.1851L10.0547 2.04457L10.0548 2.04462L10.0597 2.03442C10.2805 1.57491 10.6268 1.18705 11.0586 0.915557C11.4904 0.644063 11.9902 0.5 12.5005 0.5C13.0107 0.5 13.5105 0.644063 13.9424 0.915557C14.3742 1.18705 14.7204 1.57491 14.9412 2.03442Z'
        fill='#FFDF6C'
        stroke='black'
      />

      <g transform='translate(11, 8)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.13701 0C1.43856 0 1.72776 0.119714 1.94099 0.332805C2.15422 0.545897 2.27402 0.83491 2.27402 1.13627V6.81761C2.27402 7.11896 2.15422 7.40798 1.94099 7.62107C1.72776 7.83416 1.43856 7.95387 1.13701 7.95387C0.835455 7.95387 0.546252 7.83416 0.333022 7.62107C0.119792 7.40798 0 7.11896 0 6.81761V1.13627C0 0.83491 0.119792 0.545897 0.333022 0.332805C0.546252 0.119714 0.835455 0 1.13701 0ZM1.13701 9.65828C1.43856 9.65828 1.72776 9.77799 1.94099 9.99108C2.15422 10.2042 2.27402 10.4932 2.27402 10.7945V11.3627C2.27402 11.664 2.15422 11.953 1.94099 12.1661C1.72776 12.3792 1.43856 12.4989 1.13701 12.4989C0.835455 12.4989 0.546252 12.3792 0.333022 12.1661C0.119792 11.953 0 11.664 0 11.3627V10.7945C0 10.4932 0.119792 10.2042 0.333022 9.99108C0.546252 9.77799 0.835455 9.65828 1.13701 9.65828Z'
          fill='black'
        />
      </g>
    </svg>
  )
}
