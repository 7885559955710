import React, { useEffect, useState } from 'react'
import FBEEditor from '../FBEEditor/FBEEditor'
import { ContentState, convertFromHTML } from 'draft-js'

import { Link, useParams } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import api from '../../../services/api'

import { useFormik } from 'formik'

import './styles.css'
import { toast } from 'react-toastify'
import { Checkbox } from '@material-ui/core'
import Input from '../../../components/Input'

interface RouteParams {
  id: string
  termId: string
}

interface User {
  name: string
  email: string
  role: string
  password?: string
}

interface iOption {
  label: string
  value: string
  company?: string
}

const NewEditLgpd: React.FC = (): JSX.Element => {
  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      title: '',
      text: '',
      isActive: false
    },
    onSubmit: values => {
      api
        .post('useTerm/save', {
          id: termId !== 'undefined' ? Number(termId) : null,
          content: htmlContent,
          active: values.isActive,
          title: values.title,
          company: {
            id: Number(id)
          }
        })
        .then(() => {
          toast.success('Cadastro realizado com sucesso')
          setTimeout(() => {
            window.location.assign('/settings')
          }, 2500)
        })
        .catch(e => {
          if (e.response.data.code === 'ER_DUP_ENTRY')
            toast.error('Erro ao Cadastrar: Dado já cadastrado!')
          else toast.error('Erro ao Cadastrar: ' + e.response.data.message)
        })
    }
  })

  const { id, termId } = useParams<RouteParams>()

  const [initialText, setInitialText] = useState<any>('')

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    if (termId !== 'undefined') {
      api.get(`/useTerm/${termId}`).then(response => {
        setValues({
          title: response.data.title,
          text: response.data.content,
          isActive: response.data.active
        })

        const blocksFromHTML = convertFromHTML(response.data.content)
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        )

        setInitialText(state)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [htmlContent, setHtmlContent] = useState<any>('')

  const getContent = (htmlContentProp: any) => {
    setHtmlContent(htmlContentProp)
  }

  if (termId !== 'undefined' && initialText === '') {
    return <></>
  }

  return (
    <div className='new-user-container'>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <div className='content'>
          <header>
            <div>
              <strong>
                {`${!id ? ' Cadastrar ' : 'Editar'}`} Termo de Acesso
              </strong>
            </div>

            <div className='buttons-header'>
              <Link to='/settings'>
                <button id='btBack' type='button'>
                  <MdKeyboardArrowLeft size={20} color='#fff' />
                  Voltar
                </button>
              </Link>
              <button id='btSave' type='submit'>
                <MdDone size={20} color='#fff' />
                Salvar
              </button>
            </div>
          </header>

          <div className='row'>
            <div className='input'>
              <div className='timeAttendence'>
                <Input
                  name='title'
                  placeholder='Título'
                  value={values.title}
                  type='text'
                  onChange={handleChange}
                  label='Título'
                  maxLength={25}
                />
              </div>
            </div>

            <div className={`input checkbox ${errors.isActive ? 'error' : ''}`}>
              <Checkbox
                name='isActive'
                checked={values.isActive}
                value={values.isActive}
                onChange={handleChange}
                color='default'
              />
              <label>Ativar Termo:</label>
            </div>
          </div>

          <div className='editor create'>
            <FBEEditor
              readOnly={false}
              initialText={initialText}
              getContent={getContent}
            />
          </div>
        </div>
      </form>
      <br />
    </div>
  )
}

export default NewEditLgpd
