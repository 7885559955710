import { useEffect, useState } from 'react'
import { SettingsCompanyApi } from '../companyTypes'
import { companyServices } from '../companyServices'

export function useGetSettings(companyId: string) {
  const [data, setData] = useState<SettingsCompanyApi | null>(null)

  async function fetchData() {
    try {
      const settingsCompany = await companyServices.getSettings(companyId)

      setData(settingsCompany)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    settings: data
  }
}
