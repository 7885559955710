import React from 'react';
import { createStyles, makeStyles} from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import goback from '../../../../assets/images/back.png';
import { Link } from 'react-router-dom';
//import AccountCircle from '@material-ui/icons/AccountCircle';
import logo from '../../../../assets/images/logo-white-2.svg';
import logo_color from '../../../../assets/images/logo-dark-1_a.svg';




const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: useTheme().spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);
interface AppTooBar {
  pathToBack: string;
}
const AppTooBar: React.FC<AppTooBar> = ({ pathToBack }) => {
  const classes = useStyles();


  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  return (
    <div className={classes.root}>

      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            {window.location.pathname !== "/app" && (
              <Link to={pathToBack}>
                <img id="goback" src={goback} alt="" />
              </Link>
            )}

          </IconButton>


          <div className="img">
            {window.location.pathname !== "/expedicao" && (<img src={logo} alt="" />)}
            {window.location.pathname === "/expedicao" && (<img src={logo_color} alt="" />)}
          </div>

          
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default AppTooBar;