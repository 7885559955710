import React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Close } from "@material-ui/icons";
import "./styles.scss";

export type ModalProps = React.ComponentProps<typeof DialogPrimitive.Root> & {
  backgroundOverlay?: string;
};

export function Modal({
  children,
  backgroundOverlay = "rgba(0, 0, 0, 0.06)",
  ...props
}: ModalProps) {
  return (
    <DialogPrimitive.Root {...props}>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay
          className="dialog__overlay"
          style={{
            backgroundColor: backgroundOverlay
          }}
        />
        {children}
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
}

export type ModalWrapperProps = DialogPrimitive.DialogContentProps & {
  withButtonCloseModal?: boolean;
};

export function ModalWrapper({
  children,
  withButtonCloseModal = true,
  ...props
}: ModalWrapperProps) {
  return (
    <DialogPrimitive.Content {...props} className="dialog__content">
      {children}

      {withButtonCloseModal && (
        <ModalButtonClose>
          <Close fontSize="large" />
        </ModalButtonClose>
      )}
    </DialogPrimitive.Content>
  );
}

interface ModalHeadingProps {
  children: React.ReactNode;
  className?: string;
}

export function ModalHeading({ children, className }: ModalHeadingProps) {
  return <div className={`modal__heading ${className}`}>{children}</div>;
}

interface ModalBoxProps {
  children: React.ReactNode;
}

export function ModalBox({ children }: ModalBoxProps) {
  return <div className="modal__box">{children}</div>;
}

type ModalTitleProps = DialogPrimitive.DialogTitleProps;

export function ModalTitle({ children }: ModalTitleProps) {
  return (
    <DialogPrimitive.Title className="modal__title">
      {children}
    </DialogPrimitive.Title>
  );
}

type ModalButtonCloseProps = DialogPrimitive.DialogCloseProps;

export function ModalButtonClose({ children }: ModalButtonCloseProps) {
  return (
    <DialogPrimitive.Close className="modal__button-close" title="fechar modal">
      {children}
    </DialogPrimitive.Close>
  );
}

export const ModalTrigger = DialogPrimitive.Trigger;
