import React, { useState } from 'react'

import imageCalcelScheduling from '../../../../../assets/images/Data_Cancelamento.svg'
import api from '../../../../../services/api'

import './styles.scss'
import { LicensePlate } from '../../../../../components/LicensePlate'
import { driverCheckInServices } from '../../../../../domain'

interface CancelSchedulingModalProps {
  onCloseModal: () => void
  driverId?: number
  companyId?: number
  schedulingId?: number
  queueId?: number
  licensePlate?: string
  checkinId?: number | null
}

export function CancelSchedulingModal({
  onCloseModal,
  schedulingId,
  queueId,
  driverId,
  companyId,
  licensePlate,
  checkinId
}: CancelSchedulingModalProps) {
  const [isConfirmingCalcelScheduling, setIsConfirmingCalcelScheduling] =
    useState(false)

  async function cancelCheckIn() {
    if (!queueId || !driverId || !companyId) return

    await driverCheckInServices.create({
      id: driverId,
      company: { id: companyId },
      queue: { id: queueId },
      isPresent: false,
      status: true
    })
  }

  async function createLogCheckout() {
    if (!checkinId || !driverId) return

    await driverCheckInServices.createLogCheckout({
      id: checkinId,
      driver: { id: driverId }
    })
  }

  async function handleConfirmCancelScheduling() {
    setIsConfirmingCalcelScheduling(true)

    await api.post('/scheduling/save', [
      {
        id: schedulingId,
        status: false
      }
    ])

    const response = await api.post(`/queue/close/${queueId}`)

    await cancelCheckIn()
    await createLogCheckout()

    if (response.status === 201) {
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  return (
    <div className='cancel-scheduling__container'>
      <div className='cancel-scheduling__content'>
        <img src={imageCalcelScheduling} alt='' />

        <div>
          <span>Deseja</span>
          <strong>&nbsp;cancelar</strong>&nbsp;
          <br />a posição na fila?
        </div>

        {licensePlate && (
          <LicensePlate
            licensePlate={licensePlate}
            themeColor='#fff'
            className='cancel-scheduling__content__license-plate'
          />
        )}

        <div className='cancel-scheduling__footer'>
          <button className='button__close' onClick={onCloseModal}>
            Não
          </button>
          <button
            disabled={isConfirmingCalcelScheduling}
            className='button__submit'
            onClick={handleConfirmCancelScheduling}
          >
            Sim
          </button>
        </div>
      </div>
    </div>
  )
}
