import React from 'react'
import { Link } from 'react-router-dom'

export function Header() {
  return (
    <header>
      <div className='content'>
        <strong>Gerenciar Conjuntos / Transportadora</strong>
        <div className='new'>
          <Link to='/conjuntos'>
            <button type='button'> Voltar </button>
          </Link>
        </div>
      </div>
    </header>
  )
}
