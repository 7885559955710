import React from 'react'
import clsx from 'clsx'
import { Driver, Truck } from '..'

interface SearchResultRowProps {
  driver: Partial<Driver>
  truck: Partial<Truck>
}

export function SearchResultRow({ driver, truck }: SearchResultRowProps) {
  return (
    <div className='row'>
      {driver && (
        <div className='column'>
          <div className='status'>
            <span className='code'>{driver?.id}</span>
            <span
              className={clsx('cell', {
                active: driver.isEnabled,
                deactived: !driver.isEnabled
              })}
            >
              {driver?.isEnabled ? 'Ativo' : 'Inativo'}
            </span>
          </div>
          <div className='text'>{driver?.name}</div>
        </div>
      )}

      {truck && (
        <div className='auto'>
          <div className='column'>
            <div className='status'>
              <span className='code'>{truck?.id}</span>
              <span
                className={clsx('cell', {
                  active: truck.isEnabled,
                  deactived: !truck.isEnabled
                })}
              >
                {truck?.isEnabled ? 'Ativo' : 'Inativo'}
              </span>
            </div>
            <div className='text'>{truck?.licensePlate}</div>
          </div>
        </div>
      )}

      <div className='column'>
        <div className='text'>{truck?.vehicleModel?.name}</div>
      </div>
    </div>
  )
}
