import React from 'react'
import { Link } from 'react-router-dom'

type QueueActionsProps = {
  queueStatus: boolean
}

export function QueueActions({ queueStatus }: QueueActionsProps) {
  return (
    <div className='queue-actions'>
      {!queueStatus ? (
        <div className='buttons'>
          <Link to='/app/agendamento'>
            <button>Entrar na Fila</button>
          </Link>
        </div>
      ) : (
        <>
          <p
            style={{
              marginBottom: 10
            }}
          >
            Você já possui um agendamento na fila
          </p>
          <div className='buttons'>
            <Link to='/app/agendamento/status'>
              <button>Ir para minha posição</button>
            </Link>
          </div>
        </>
      )}
    </div>
  )
}
