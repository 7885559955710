import React, { useEffect, useState } from 'react'
import styles from '../styles.module.css'
import { useHistory } from 'react-router-dom'
import { driverCheckInServices, driverServices } from '../../../../../domain'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../contexts/auth'
import { LicensePlate } from '../../../../../components/LicensePlate'
import truckIcon from './truckIcon.svg'
interface CheckInStepConfirmProps {
  onNextStep: () => void
}

interface CheckInData {
  queueId: number

  companyId: number
  truckLicensePlate: string
}

export function CheckInStepConfirm({ onNextStep }: CheckInStepConfirmProps) {
  const history = useHistory()

  const [checkInData, setCheckInData] = useState<CheckInData | null>(null)
  const [checkInLoading, setCheckInLoading] = useState(false)

  const { driver } = useAuth()
  const driverId = sessionStorage.getItem('@AppDriver:id')
  const driverCompanyId = sessionStorage.getItem('@AppDriver:companyId')

  const driverFirstName = driver?.replace(/"/g, '').split(' ')[0]

  function navigateDriverHome() {
    history.push('/')
  }

  async function handleCheckIn() {
    try {
      if (!checkInData) throw new Error('Você não tem posição na fila')
      setCheckInLoading(true)

      const data = {
        id: null,
        isPresent: true,
        status: true,
        queue: {
          id: checkInData.queueId
        },
        company: {
          id: checkInData.companyId
        }
      }

      await driverCheckInServices
        .create(data)
        .then(() => onNextStep())
        .catch(() => toast.error('Erro ao fazer check-in'))
    } catch (error) {
    } finally {
      setCheckInLoading(false)
    }
  }

  async function fetchQueuePositionDriver() {
    if (!driverId) return

    const driverIdParse = Number(driverId)
    const driverCompanyIdParse = Number(driverCompanyId)
    const queuePositionDriver = await driverServices.getQueuePositionDriver(
      driverIdParse
    )

    if (queuePositionDriver) {
      setCheckInData({
        queueId: queuePositionDriver.queue.id,
        companyId: driverCompanyIdParse,
        truckLicensePlate: queuePositionDriver.queue.truck.licensePlate
      })
    } else {
      setCheckInData(null)
    }
  }

  useEffect(() => {
    fetchQueuePositionDriver()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId])

  return (
    <div className={styles['check-in-step-confirm']}>
      <span className={styles['driver-name']}>{driverFirstName},</span>

      <div className={styles['check-in-step-content']}>
        <p>
          Ao realizar o check-in você confirma estar ciente e utilizando todos
          os EPIs necessários para acessar nossa indústria.
        </p>

        <p className={styles.bold}>
          Capacete, máscara, óculos, protetor auricular, camisa, calças e botas.
        </p>

        <p>
          Da mesma forma, o veículo abaixo está completamente livre de
          contaminação de outros materiais e apto para realizar carregamento e
          transporte do nosso produto.
        </p>

        {checkInData && checkInData.truckLicensePlate && (
          <div className={styles.truck}>
            <img src={truckIcon} alt='' />
            <LicensePlate
              themeColor='#fff'
              licensePlate={checkInData?.truckLicensePlate || ''}
            />
          </div>
        )}
      </div>

      <div className={styles['check-in-step-confirm-actions']}>
        <h2>Deseja realizar o check-in?</h2>

        <div className={styles['check-in-step-actions']}>
          <button
            className={styles['check-in-step-button']}
            onClick={navigateDriverHome}
            disabled={checkInLoading}
          >
            não
          </button>

          <button
            onClick={handleCheckIn}
            className={styles['check-in-step-button-action']}
            disabled={checkInLoading}
          >
            {checkInLoading ? 'Realizando check-in...' : 'sim'}
          </button>
        </div>
      </div>
    </div>
  )
}
