import React from 'react';
import { FaCircle } from 'react-icons/fa'

function Index({attendance}: any) {

    return (
            <div className="settings-container">
                  
                    <div> 
                      <div className={`status-icon ${attendance?.attendance ? 'actived' : '' }  `} >
                        <FaCircle />  
                      </div> 
                      <span className="text">Atendimento</span>
                      
                      <div className="status"><span>{attendance?.attendance ? "Aberto" : "Fechado"}</span></div></div> 
                    <div className="times">
                      <div>{attendance?.days[0]} - {attendance?.days[attendance?.days.length - 1]} de {attendance?.attendanceStart}h às {attendance?.attendanceEnd}h</div>  
                    </div>
             
            </div>
    )
}

export default Index
