import React, { useEffect, useState } from 'react'
import { CompactPicker } from 'react-color'
import Select from 'react-select'
import InputMask from 'react-input-mask'
import AppBar from '@material-ui/core/AppBar'
import * as Yup from 'yup'

import { useHistory, useParams } from 'react-router-dom'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../../components/Input'
import api from '../../../../services/api'

import { useFormik } from 'formik'

import { toast } from 'react-toastify'
import { Box, Button, Modal } from '@material-ui/core'
import { IoCloseOutline } from 'react-icons/io5'
import BarTop from '../../components/BarTop'
import './styles.css'

interface RouteParams {
  id: string
}

interface iOption {
  label: string
  value: string
}

const driverSchema = Yup.object().shape({
  name: Yup.string()
    .max(80, 'Tamanho máximo de 80 caracteres')
    .required('Nome é obrigatório!'),
  cpf: Yup.string().required('Número de CPF é obrigatório!'),
  birthDate: Yup.string().required('Data de nascimento é obrigatório!'),
  licensePlate: Yup.string().required('Placa é obrigatório!'),
  renavam: Yup.string().required('Renavam é obrigatório!'),
  vehicleModel: Yup.number().required('Modelo é obrigatório!'),
  implementType: Yup.string().required('Tipo de implemento é obrigatório!'),
  shaftQuantity: Yup.number().required('Quantidade é obrigatório!')
})

export function RegisterDriver() {
  const { id } = useParams<RouteParams>()
  const [page, setPage] = useState(0)

  const [color, setColor] = useState<string>('')

  const history = useHistory()

  const [implementsTypes, setimplementsTypes] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [implementValue, setImplementValue] = useState<iOption>({
    value: '',
    label: ''
  })

  const [brandValue, setBrandValue] = useState<iOption>({
    value: '',
    label: ''
  })
  const [modelValue, setModelValue] = useState<iOption>({
    value: '',
    label: ''
  })
  const [vehicleModelName, setModelName] = useState('')

  const [brandTypes, setBrandTypes] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [modelTypes, setModelTypes] = useState<iOption[]>([
    { value: '', label: '' }
  ])

  const implementTypes = [
    { type: 'TRUCK', shaftQuantity: 3, grossCapacity: 23.0, netCapacity: 16.0 },
    {
      type: 'BITRUCK',
      shaftQuantity: 4,
      grossCapacity: 29.0,
      netCapacity: 19.0
    },
    { type: 'TOCO', shaftQuantity: 5, grossCapacity: 41.5, netCapacity: 28.0 },
    {
      type: 'ROMEU & JULIETA',
      shaftQuantity: 6,
      grossCapacity: 50.0,
      netCapacity: 32.5
    },
    {
      type: 'LS (MENOR 16 MT)',
      shaftQuantity: 6,
      grossCapacity: 45.0,
      netCapacity: 31.0
    },
    {
      type: 'LS (MAIOR 16 MT)',
      shaftQuantity: 6,
      grossCapacity: 48.5,
      netCapacity: 33.5
    },
    {
      type: 'VANDERLEIA',
      shaftQuantity: 6,
      grossCapacity: 53.0,
      netCapacity: 36.0
    },
    {
      type: 'BITREM 7 EIXOS',
      shaftQuantity: 7,
      grossCapacity: 57.0,
      netCapacity: 42.0
    },
    {
      type: 'BITREM 8 EIXOS',
      shaftQuantity: 8,
      grossCapacity: 65.5,
      netCapacity: 46.5
    },
    {
      type: 'RODOTREM 9 EIXOS',
      shaftQuantity: 9,
      grossCapacity: 74.0,
      netCapacity: 52.0
    },
    {
      type: 'RODOTREM 11 EIXOS',
      shaftQuantity: 11,
      grossCapacity: 91.0,
      netCapacity: 65.6
    }
  ]

  const [isTruck, setIsTruck] = useState<boolean>()
  const [plate, setPlate] = useState<string>()
  const [modalPlate, setModalPlate] = useState<boolean>(false)

  useEffect(() => {
    const types: iOption[] = implementTypes.map((item, index) => {
      return { value: item.type, label: item.type }
    })

    setimplementsTypes(types)

    api.get(`/truck-brand/`).then(response => {
      setBrandTypes(
        response.data.map((item: any) => {
          return { value: item.id, label: item.name }
        })
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function TestaCPF(cpf: any) {
    let Soma
    let Resto
    Soma = 0

    let withoutDot: any = cpf.replace('.', '')
    withoutDot = withoutDot.replace('.', '')
    let strCPF: any = withoutDot.replace('-', '')

    if (strCPF === '00000000000') return false

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
    Resto = (Soma * 10) % 11

    if (Resto === 10 || Resto === 11) Resto = 0
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false

    Soma = 0
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
    Resto = (Soma * 10) % 11

    if (Resto === 10 || Resto === 11) Resto = 0
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false
    return true
  }

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      cnh: '',
      birthDate: '',
      fone: '',
      email: '',
      licensePlate: '',
      renavam: '',
      brand: '',
      vehicleModel: '',
      implementType: '',
      shaftQuantity: 0,
      grossCapacity: 0,
      netCapacity: 0
    },
    validationSchema: driverSchema,
    onSubmit: values => {
      const _birthDate = values.birthDate.split('/')

      let cpfIsReal = TestaCPF(values.cpf)

      if (cpfIsReal === true) {
        const driverId = sessionStorage.getItem('@App:driverId')

        let objectRequest: any = {
          driver: {
            id: NaN,
            name: values.name.toUpperCase(),
            cpf: values.cpf,
            cnh: values.cnh,
            isEnabled: true,
            shippingCompany: null,
            birthDate:
              _birthDate[2] + '-' + _birthDate[1] + '-' + _birthDate[0],
            contact: [
              {
                id: null,
                name: values.name.toUpperCase(),
                fone: values.fone,
                email: values.email
              }
            ],
            company: id ? { id: parseInt(id) } : null
          },
          truck: {
            driver: {
              id: Number(driverId)
            },
            licensePlate: values.licensePlate,
            renavam: values.renavam.replace(/[^\w\s]/gi, ''),
            vehicleModel: {
              id: values.vehicleModel,
              truckBrand: {
                id: brandValue.value
              }
            },
            color: color,
            company: {
              id: parseInt(id) || null
            },
            grossCapacity: values.grossCapacity,
            implementType: values.implementType,
            netCapacity: values.netCapacity,
            owner: values.name.toUpperCase(),
            shaftQuantity: values.shaftQuantity
          }
        }

        if (driverId) {
          objectRequest = {
            truck: {
              driver: {
                id: Number(driverId)
              },
              licensePlate: values.licensePlate,
              renavam: values.renavam.replace(/[^\w\s]/gi, ''),
              vehicleModel: {
                id: values.vehicleModel,
                truckBrand: {
                  id: brandValue.value
                }
              },
              color: color,
              company: {
                id: parseInt(id) || null
              },
              grossCapacity: values.grossCapacity,
              implementType: values.implementType,
              netCapacity: values.netCapacity,
              owner: values.name.toUpperCase(),
              shaftQuantity: values.shaftQuantity
            }
          }
        }

        if (isTruck && plate) {
          api
            .patch('truck/rebind', objectRequest)
            .then(() => {
              sessionStorage.removeItem('@App:driverId')

              toast.success('Cadastro realizado com sucesso')
              setTimeout(() => {
                history.push('/')
              }, 2500)
            })
            .catch(e => {
              if (e.response?.data?.response?.driver?.id) {
                sessionStorage.setItem(
                  '@App:driverId',
                  e.response?.data?.response?.driver?.id
                )
              }

              if (e.response.data.code === 'ER_DUP_ENTRY')
                toast.error('Já existe cadastro!')
              else toast.error('Erro ao Cadastrar: ' + e.response.data?.message)
            })
        } else {
          toast.error('Placa inválida')
        }
      } else if (cpfIsReal === false && id) {
        toast.error('Cpf Inválido')
      }
    }
  })

  function handleNext() {
    if (page === 0) setPage(1)
    if (page === 1) setPage(2)

    if (page === 2) {
      handleSubmit()
      if (errors.name || errors.cpf || errors.birthDate) setPage(0)
      else if (
        errors.licensePlate ||
        errors.renavam ||
        errors.vehicleModel ||
        errors.implementType
      )
        setPage(1)
    }
  }

  function handleBack() {
    if (page === 0) history.push('/')
    else setPage(page - 1)
  }

  function handleChangeComplete(color: string) {
    setColor(color)
  }

  function handleChangeOptionImplement(option: iOption) {
    const _findImplement = implementTypes.find(
      item => item.type === option.value
    )
    setValues({
      ...values,
      implementType: _findImplement?.type || '',
      shaftQuantity: _findImplement?.shaftQuantity || 0,
      grossCapacity: _findImplement?.grossCapacity || 0,
      netCapacity: _findImplement?.netCapacity || 0
    })
    setImplementValue(option)
  }

  function handleChangeOptionBrand(option: iOption) {
    setBrandValue(option)

    api.get(`/truck-model/brand/${option.value}`).then(response => {
      setModelTypes(
        response.data.map((item: any) => {
          return { value: item.id, label: item.name }
        })
      )
    })
  }

  function handleChangeOptionModel(option: iOption) {
    setModelValue(option)
    setModelName(option.label)
    setValues({ ...values, vehicleModel: option.value })
  }

  function modalIsTruck(truck: boolean) {
    if (truck === false) {
      setModalPlate(true)
    } else {
      setModalPlate(false)
    }
  }

  async function handleIsTruck(plateValue: string) {
    if (plateValue.length === 7) {
      await api
        .get(`truck/isTruck/${plateValue}`)
        .then(response => {
          setIsTruck(response?.data?.isTruck)
          modalIsTruck(response?.data?.isTruck)
        })
        .catch(e => {
          toast.error('Erro: ' + e?.response?.data?.message)
          setIsTruck(undefined)
          setModalPlate(false)
        })
      setPlate(plateValue)
    }
  }

  return (
    <div className='register-driver-container'>
      <Box className='modal expedition'>
        <Modal
          open={modalPlate}
          onClose={() => setModalPlate(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modalLicensePlate'
        >
          <div className='modalContentPlate'>
            <Button
              className='close-button'
              onClick={() => setModalPlate(false)}
            >
              <IoCloseOutline />
            </Button>
            <div className='licensePlateText'>
              <p>A placa informada não corresponde a um caminhão. </p>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={`https://www.fipeplaca.com.br/placa/${plate}`}
              >
                Clique aqui para saber mais
              </a>
            </div>
          </div>
        </Modal>
      </Box>
      <div className='container' style={{ width: '90vw' }}>
        <div className='content'>
          <form onSubmit={handleSubmit} autoComplete='off'>
            <BarTop />
            <AppBar className='register-header'>
              <div className='buttons-header'>
                <button id='btBack' type='button' onClick={handleBack}>
                  <MdKeyboardArrowLeft size={20} color='#fff' />
                  VOLTAR
                </button>

                <button id='btSave' type='button' onClick={handleNext}>
                  <MdKeyboardArrowRight size={20} color='#fff' />
                  {page === 2 ? 'Concluir' : 'Próximo'}
                </button>
              </div>
            </AppBar>

            {page === 0 && (
              <div className='register content-driver'>
                <div className='row'>
                  <div className={`input ${errors.name ? 'error' : ''}`}>
                    <Input
                      name='name'
                      placeholder='Nome completo'
                      onChange={handleChange}
                      value={values.name.slice(0, 80)}
                      label='Nome completo'
                      maxLength={80}
                    />
                    {errors.name && (
                      <div className='validateError'>{errors.name}</div>
                    )}
                  </div>
                  <div
                    className={`input input-block ${errors.cpf ? 'error' : ''}`}
                  >
                    <label style={{ fontWeight: 'bold' }}>CPF:</label>
                    <br />
                    <InputMask
                      mask='999.999.999-99'
                      name='cpf'
                      placeholder='CPF'
                      value={values.cpf.slice(0, 14)}
                      onChange={handleChange}
                    />

                    {errors.cpf && (
                      <div className='validateError'>{errors.cpf}</div>
                    )}
                  </div>

                  <div
                    className={`input input-block  ${
                      errors.cnh ? 'error' : ''
                    }`}
                  >
                    <label style={{ fontWeight: 'bold' }}>CNH:</label>

                    <br />
                    <InputMask
                      mask='99999999999'
                      name='cnh'
                      placeholder='CNH'
                      value={values.cnh}
                      onChange={handleChange}
                    />

                    {errors.cnh && (
                      <div className='validateError'>{errors.cnh}</div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div
                    className={`input input-block ${
                      errors.birthDate ? 'error' : ''
                    }`}
                  >
                    <label style={{ fontWeight: 'bold' }}>
                      Data de nascimento:
                    </label>
                    <br />
                    <InputMask
                      mask='99/99/9999'
                      name='birthDate'
                      onChange={handleChange}
                      value={values.birthDate}
                    />
                    {errors.birthDate && (
                      <div className='validateError'>{errors.birthDate}</div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div className={`input input-block`}>
                    <label style={{ fontWeight: 'bold' }}>Telefone:</label>
                    <br />
                    <InputMask
                      mask='(99) 9 9999-9999'
                      name='fone'
                      placeholder='fone'
                      value={values.fone.slice(0, 16)}
                      onChange={handleChange}
                    />
                  </div>

                  <div className={`input`}>
                    <Input
                      name='email'
                      placeholder='email'
                      value={values.email.slice(0, 50)}
                      onChange={handleChange}
                      label='E-mail'
                    />
                  </div>
                </div>
              </div>
            )}

            {page === 1 && (
              <div className='register content-truck'>
                <div className='row'>
                  <div
                    className={`${
                      isTruck === false ? 'plateInvalid' : ''
                    }  input ${errors.licensePlate ? 'error' : ''}`}
                  >
                    <Input
                      name='licensePlate'
                      placeholder='Placa (Apenas letras e números)'
                      maskText='#######'
                      onChange={e => {
                        if (e.target.value.length === 7) {
                          handleIsTruck(e.target.value)
                        }
                        if (e.target.value.length <= 6) {
                          setPlate(undefined)
                          setIsTruck(undefined)
                        }
                        if (
                          e.target.value !== '-' &&
                          ' ' &&
                          e.target.value.length < 8
                        ) {
                          e.target.value = e.target.value
                            .trim()
                            .replace(/[^\w\s]/gi, '')

                          handleChange(e)
                        }
                      }}
                      value={values.licensePlate.slice(0, 9)}
                      label='Placa'
                      maxLength={7}
                    />
                    {errors.licensePlate && (
                      <div className='validateError'>{errors.licensePlate}</div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div
                    className={`input input-block ${
                      errors.renavam ? 'error' : ''
                    }`}
                  >
                    <label style={{ fontWeight: 'bold' }}>Renavam:</label>
                    <br />
                    <InputMask
                      mask='999999999-99'
                      name='renavam'
                      placeholder='Renavam'
                      value={values.renavam}
                      onChange={handleChange}
                    />

                    {errors.renavam && (
                      <div className='validateError'>{errors.renavam}</div>
                    )}
                  </div>
                  <br />

                  <div className={`input `}>
                    <label style={{ fontWeight: 'bold' }}>Marca: </label>
                    <Select
                      className='select-options'
                      value={brandValue}
                      options={brandTypes}
                      onChange={option =>
                        handleChangeOptionBrand(option as iOption)
                      }
                    />
                  </div>
                  <br />
                  <div
                    className={`input ${errors.vehicleModel ? 'error' : ''}`}
                  >
                    <label style={{ fontWeight: 'bold' }}>Modelo: </label>

                    <Select
                      className='select-options'
                      value={modelValue}
                      options={modelTypes}
                      onChange={option =>
                        handleChangeOptionModel(option as iOption)
                      }
                    />

                    {errors.vehicleModel && (
                      <div className='validateError'>{errors.vehicleModel}</div>
                    )}
                  </div>
                  <br />

                  <label style={{ fontWeight: 'bold' }}>Color:</label>
                  <div>
                    <CompactPicker
                      color={color}
                      onChangeComplete={e => handleChangeComplete(e.hex)}
                    />
                    <br />
                  </div>
                </div>

                <div className='row'>
                  <br />

                  <div
                    className={`input ${errors.implementType ? 'error' : ''}`}
                  >
                    <label style={{ fontWeight: 'bold' }}>
                      Tipo de implemento:
                    </label>

                    <Select
                      className='select-options'
                      value={implementValue}
                      options={implementsTypes}
                      onChange={option =>
                        handleChangeOptionImplement(option as iOption)
                      }
                    />

                    {errors.implementType && (
                      <div className='validateError'>
                        {errors.implementType}
                      </div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div
                    className={`input-number ${
                      errors.shaftQuantity ? 'error' : ''
                    }`}
                  >
                    <Input
                      name='shaftQuantity'
                      type='number'
                      value={values.shaftQuantity}
                      onChange={handleChange}
                      label='Qtd de eixo'
                    />

                    {errors.shaftQuantity && (
                      <div className='validateError'>
                        {errors.shaftQuantity}
                      </div>
                    )}
                  </div>

                  <div
                    className={`input-number ${
                      errors.grossCapacity ? 'error' : ''
                    }`}
                  >
                    <Input
                      name='grossCapacity'
                      type='number'
                      value={values.grossCapacity}
                      onChange={handleChange}
                      label='Capac. bruta em tonelada'
                    />

                    {errors.grossCapacity && (
                      <div className='validateError'>
                        {errors.grossCapacity}
                      </div>
                    )}
                  </div>

                  <div
                    className={`input-number ${
                      errors.netCapacity ? 'error' : ''
                    }`}
                  >
                    <Input
                      name='netCapacity'
                      type='number'
                      value={values.netCapacity}
                      onChange={handleChange}
                      label='Capac. líquida em tonelada'
                    />

                    {errors.shaftQuantity && (
                      <div className='validateError'>
                        {errors.shaftQuantity}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {page === 2 && (
              <div className='register check-data'>
                <h2>Dados Pessoais:</h2>
                <div className='check-driver'>
                  <label>Nome:</label>
                  <span>{values.name}</span>

                  <label>CPF:</label>
                  <span>{values.cpf}</span>

                  <label>Cnh:</label>
                  <span>{values.cnh}</span>

                  <label>Data Nascimeto:</label>
                  <span>{values.birthDate}</span>

                  <label>Fone:</label>
                  <span>{values.fone}</span>

                  <label>Email:</label>
                  <span>{values.email}</span>
                </div>

                <h2>Dados Caminhão:</h2>
                <div className='check-truck'>
                  <label>Placa:</label>
                  <span>{values.licensePlate}</span>

                  <label>Modelo:</label>
                  <span>{vehicleModelName}</span>

                  <label>Cor:</label>
                  <span>
                    <div
                      style={{ width: 12, height: 12, backgroundColor: color }}
                    ></div>
                  </span>

                  <label>renavam:</label>
                  <span>{values.renavam}</span>

                  <label>Tipo de Implemento:</label>
                  <span>{values.implementType}</span>

                  <label>Qnt. de eixos:</label>
                  <span>{values.shaftQuantity}</span>

                  <label>Capacidade bruta:</label>
                  <span>{values.grossCapacity}t</span>

                  <label>Capacidade líquida:</label>
                  <span>{values.netCapacity}t</span>
                </div>
              </div>
            )}
          </form>

          <br />
        </div>
      </div>
    </div>
  )
}
