import React, { ChangeEvent } from 'react'
import { UpdateTons } from '.'

interface EditAttendanceProps {
  updateQtyTransported: (e: ChangeEvent<HTMLInputElement>) => void
  handleUpdateTons: (params: UpdateTons) => void

  newQtyTransported: string
  newQtyTransportedIsZero: boolean
  isUpdateNewTon: boolean

  attendance: {
    id: number
    scheduling: {
      id: number
    }
  }
}

export function EditAttendance({
  updateQtyTransported,
  newQtyTransported,
  newQtyTransportedIsZero,
  isUpdateNewTon,
  handleUpdateTons,
  attendance
}: EditAttendanceProps) {
  function updateTons() {
    handleUpdateTons({
      driverId: attendance.id,
      schedulingId: attendance.scheduling.id,
      newQtyTransported
    })
  }

  return (
    <div className='modal__edit-tons'>
      <label htmlFor='tons'>Peso Líquido</label>
      <input
        type='text'
        id='tons'
        placeholder='00,000'
        onChange={e => updateQtyTransported(e)}
        value={newQtyTransported}
        className='modal__input-tons'
      />

      <button
        className='modal__button-submit'
        disabled={newQtyTransportedIsZero || isUpdateNewTon}
        onClick={updateTons}
      >
        {isUpdateNewTon ? 'Salvando...' : 'Salvar'}
      </button>
    </div>
  )
}
