import React from "react";
import styles from "./styles.module.scss";

export function PopupImageEmpty() {
  return (
    <div className={styles["popup-image-empty"]}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="61"
        viewBox="0 0 61 61"
        fill="none"
      >
        <circle cx="30.5" cy="30.5" r="30.5" fill="#D9D9D9" />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="71"
        height="62"
        viewBox="0 0 71 62"
        fill="none"
      >
        <path d="M35.5 0L70.574 61.5H0.425972L35.5 0Z" fill="#C4C4C4" />
      </svg>
    </div>
  );
}
