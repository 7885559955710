import React from 'react'
import './styles.scss'
interface LicensePlateProps {
  themeColor?: string
  fontSize?: string

  licensePlate: string
  className?: string
}

export function LicensePlate({
  licensePlate,
  className,
  themeColor = '#de2a1c',
  fontSize = '30px'
}: LicensePlateProps) {
  return (
    <div
      className={`license-plate__container ${className}`}
      style={{
        fontSize: fontSize,
        color: themeColor,
        borderColor: themeColor
      }}
    >
      {licensePlate}
    </div>
  )
}
