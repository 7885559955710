import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { CgArrowUpO, CgArrowDownO } from 'react-icons/cg'
import { DataGrid, GridColDef, GridCellParams, ptBR } from '@mui/x-data-grid'

import api from '../../../../services/api'
import AppToolBar from '../../components/AppToolBar'
import _Cancelamento from '../../../../assets/images/Data_Cancelamento.svg'
import Timer from '../../components/Timer/Timer'
import BarTop from '../../components/BarTop'
import { PageLoading } from '../../../../components/PageLoading/PageLoading'
import { useDriverContext } from '../../../../contexts/DriverContext/useDriverContext'
import './styles.css'

interface Customer {
  code: number
  name: string
  city: string
}

interface ShippingCompany {
  name: string
}

interface Order {
  id: number
  status: string
  code?: number
  customer: Customer
  shippingCompany: ShippingCompany
  orderItem: OrderItem[]
}

interface Product {
  code: number
  name: string
}

interface OrderItem {
  id: number
  quantity: number
  product: Product
}

interface Rows {
  id: number
  logged: boolean
  position: string
  licensePlate?: string
  freelancer?: boolean
  vehicleModel?: {
    name: string
  }
}

export function DriverQueueStatus() {
  const { driverId, driverCompanyId } = useDriverContext()

  const [idQueue, setIdQueue] = useState(0)
  const [position, setPosition] = useState(0)
  const [status, setStatus] = useState(false)
  const [timmer, setTimmer] = useState(1)
  const [queueObject, steQueueObject] = useState<any>()

  const [order, setOrder] = useState<Order>()
  const [qtyOrdered, setQtyOrdered] = useState('')
  const [schedulingStatus, setSchedulingStatus] = useState(false)
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [truck, setTruck] = useState<any>()
  const [next, setNext] = useState<any[]>([])

  const [reschedule, setReschedule] = useState(1)
  const [remove, setRemove] = useState(1)
  const [orderDetails, setOrderDetails] = useState(false)
  const [totalTruckToday, setTotalTruckToday] = useState(0)
  const [toleranceTime, setToleranceTime] = useState<any>()
  const [toleranceSettings, setToleranceSettings] = useState('00:00')
  const [queue, setQueue] = useState<any>()

  const [initialTime, setInitialTime] = useState<any>(0)
  const [finalTime, setFinalTime] = useState<any>(0)
  const [enableTolerance, setEnableTolerance] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const [rows, setRows] = useState<Rows[]>([])

  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  useEffect(() => {
    if (driverId) {
      api.get(`/queue/position/driver/${driverId}`).then(response => {
        steQueueObject(response.data)

        setIdQueue(response.data.queue?.id)
        setPosition(response.data.position)
        setStatus(response.data.queue?.status)
        setTruck(response.data.queue?.truck)

        setSchedulingStatus(response.data.scheduling)
        setOrder(response.data.scheduling?.order)
        setQtyOrdered(response.data.scheduling?.qtyOrdered)
        setTotalTruckToday(response.data.totals)

        const _date = new Date(response.data.queue?.date)

        setDate(_date.getDate() + ' ' + getMonth(_date.getMonth()))
        setTime(
          (_date.getHours() < 10 ? '0' + _date.getHours() : _date.getHours()) +
            ':' +
            (_date.getMinutes() < 10
              ? '0' + _date.getMinutes()
              : _date.getMinutes())
        )

        let object = response.data

        setQueue(response.data.queue)

        api.get(`/settings/company/${driverCompanyId}`).then(response => {
          let tolerance = response.data.tolerance
          setToleranceSettings(tolerance)
          /***** */

          let toleranceHours = Number(tolerance.slice(0, 2)) * 60 * 60 * 1000
          let toleranceMinutes = Number(tolerance.slice(3, 5)) * 60 * 1000

          let time =
            new Date(object.queue?.date).getTime() +
            (toleranceHours + toleranceMinutes)
          setInitialTime(object?.queue)
          setFinalTime(time)

          let timeleft = Math.round(time - new Date().getTime())

          let days: any = Math.floor(timeleft / (1000 * 60 * 60 * 24))
          let hours: any = Math.floor(
            (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          )
          let minutes: any = Math.floor(
            (timeleft % (1000 * 60 * 60)) / (1000 * 60)
          )
          let seconds: any = Math.floor((timeleft % (1000 * 60)) / 1000)

          setToleranceTime(timeleft)

          if (seconds < 10 && seconds > 0) {
            seconds = '0' + seconds
          }
          if (minutes < 10 && minutes > 0) {
            minutes = '0' + minutes
          }
          if (hours < 10 && hours > 0) {
            hours = '0' + hours
          }
          if (days < 10 && days > 0) {
            days = '0' + days
          }
        })
      })

      api.get(`/queue/company/${driverCompanyId}`).then(response => {
        setNext(response.data)
      })

      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId])

  useEffect(() => {
    timmer > 0 &&
      setTimeout(() => {
        setTimmer(timmer + 1)
        if (idQueue)
          api
            .get(`/queue/${idQueue}`)
            .then(response => {
              setStatus(response.data.status)
            })
            .catch(e => {
              window.location.reload()
            })
      }, 500 * 60)
  }, [idQueue, timmer])

  function handleCancel() {
    setReschedule(1)
    setRemove(1)
  }

  const handleRemove = () => setRemove(0)
  const handleSeePanel = () => history.push('/app/painel/fila')

  const handleOrderDetails = () => setOrderDetails(!orderDetails)

  function handleConfirmRemove(e: any) {
    setDisabled(true)

    if (disabled === false) {
      api
        .delete(`/queue/delete/${idQueue}`)
        .then(response => {
          if (
            response.status === 204 ||
            response.status === 200 ||
            response.status === 201
          ) {
            setTimeout(() => {
              history.push('/')
            }, 1000)
          }
        })
        .catch(() => {
          setDisabled(false)
        })
    }
  }

  function getMonth(index: number) {
    const month = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ]
    return month[index]
  }

  function handleCloseLoading() {
    setIsLoading(false)
  }

  useEffect(() => {
    if (driverId) {
      api.get(`/queue/company/${driverCompanyId}`).then(response => {
        const _queue = response.data

        const customPosition = response.data.findIndex((item: any) => {
          if (item.driver?.id === parseInt(driverId)) {
            return item.driver?.id
          } else {
            return ''
          }
        })

        const rows = _queue.map((item: any, index: any) => {
          let logged = false
          let position = index + 1 + '°'

          if (customPosition === index) {
            logged = true
          }

          const licensePlateExists = item.scheduling
            ? item.scheduling.truck?.licensePlate
            : item.truck?.licensePlate

          const vehicleModelExists = item.scheduling?.truck?.vehicleModel?.name
          const isFreelancer = item.scheduling ? false : true

          return {
            id: item.id,
            logged: logged,
            position: position,
            licensePlate: licensePlateExists,
            freelancer: isFreelancer,
            vehicleModel: vehicleModelExists
          }
        })

        setRows(rows)
      })
    }

    api.get(`/settings/company/${driverCompanyId}`).then(response => {
      setEnableTolerance(response.data.enableTolerance)
    })
  }, [driverCompanyId, driverId])

  const columns: GridColDef[] = [
    { field: 'position', headerName: 'Fila', width: 90, sortable: false },
    {
      field: 'licensePlate',
      headerName: 'Placa',
      width: 150,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div className={params.row.logged ? 'logged' : ''}>
              {params.row.licensePlate}
            </div>
          </>
        )
      }
    }
  ]

  let maxTolerance = finalTime + 3 * 3600 * 1000 - new Date().getTime()
  let updateTime =
    new Date(initialTime?.date ? initialTime?.date : null).getTime() -
    new Date().getTime()

  return (
    <>
      <div className='queue driver-queue-status'>
        <div className='container'>
          <BarTop />
          <div className='header'>
            <AppToolBar pathToBack='/' isStatic={false} />
          </div>

          {isLoading ? (
            <PageLoading
              loading={isLoading}
              onCloseLoading={handleCloseLoading}
            />
          ) : (
            <>
              {position !== 0 || queue?.attended === false ? (
                <>
                  <div className={reschedule ? 'disable' : 'reschedule'}>
                    <div>
                      Deseja <strong>remarcar</strong>
                      <br /> seu agendamento?
                    </div>
                    <div>Mudança de posição na fila</div>
                    <div className='filadados'>
                      <div className='pos'>{position}°</div>

                      <div>Posição atual </div>
                      <div>
                        <strong>Nova</strong> posição{' '}
                      </div>
                    </div>

                    <div className='scheduling'>
                      <div>
                        <span>Previsão de carregamento:</span>
                        <div>
                          A data e hora acima informada trata-se de uma previsão
                          que pode ser antecipada ou adiada sem aviso prévio a
                          depender da dinâmica do fluxo de atendimento na
                          indústria. Recomendamos a consulta constante de sua
                          posição e horário de previsão, e a chegada ao local
                          com 6 horas de antecedência.
                        </div>
                      </div>
                      <span className='date-schedule'>
                        {date} às {time}
                      </span>
                      <div className='reagendar-btn'>
                        <button onClick={handleCancel}>Não</button>
                      </div>
                    </div>
                  </div>

                  <div className={remove ? 'disable' : 'remove'}>
                    <div className='fullscreen-popup'>
                      <img src={_Cancelamento} width={'40%'} alt='' />

                      <div>
                        Deseja <strong>cancelar</strong>
                        <br /> a marcação?
                      </div>
                      <div className='cancelar-btn'>
                        <button onClick={handleCancel}>Não</button>
                        <button
                          disabled={disabled}
                          onClick={handleConfirmRemove}
                        >
                          Sim
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className='content'>
                    <div className='queue-status'>
                      <div>
                        {!status && position !== 0 && (
                          <>
                            <span> Sua posição agora é: </span>
                            <strong>{position}°</strong>
                          </>
                        )}
                      </div>
                    </div>

                    {initialTime !== undefined &&
                      maxTolerance > 0 &&
                      updateTime < 0 &&
                      enableTolerance &&
                      queue?.status === false && (
                        <>
                          <div className='tolerance-message'>
                            <span className='bold'>
                              Você está dentro do prazo de tolerância.
                            </span>
                            <span>
                              Após o termino desse prazo, sua posição será
                              cancelada automaticamente.
                            </span>
                          </div>

                          <Timer
                            wholeTime={toleranceTime}
                            initialDate={initialTime}
                            finalDate={finalTime}
                            tolerance={toleranceSettings}
                          />
                        </>
                      )}

                    {maxTolerance < 0 && updateTime > 0 && (
                      <>
                        {position > totalTruckToday ? (
                          <>
                            {next.length > 0 && !status && (
                              <div className='plates-container'>
                                {position !== 1 && (
                                  <div
                                    className='flex'
                                    {...(position < 5 ? 'responsive' : 'dfsdf')}
                                  >
                                    <div className='scheduling-exp painel-page status'>
                                      <div style={{ height: 520 }}>
                                        {position > 5 ? (
                                          <DataGrid
                                            autoHeight={true}
                                            autoPageSize={false}
                                            rows={rows.slice(
                                              position - 6,
                                              position
                                            )}
                                            columns={columns}
                                            pageSize={10}
                                            headerHeight={30}
                                            disableColumnMenu
                                            rowsPerPageOptions={[8]}
                                            localeText={
                                              ptBR.components.MuiDataGrid
                                                .defaultProps.localeText
                                            }
                                          />
                                        ) : (
                                          <DataGrid
                                            autoHeight={true}
                                            autoPageSize={false}
                                            rows={rows.slice(0, position)}
                                            columns={columns}
                                            pageSize={10}
                                            headerHeight={30}
                                            disableColumnMenu
                                            rowsPerPageOptions={[8]}
                                            localeText={
                                              ptBR.components.MuiDataGrid
                                                .defaultProps.localeText
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {next.length > 0 && !status && (
                              <div className='plates-container'>
                                {position !== 1 && (
                                  <div
                                    className='flex'
                                    {...(position < 5 ? 'responsive' : 'dfsdf')}
                                  >
                                    <div className='scheduling-exp painel-page status'>
                                      <div style={{ height: 520 }}>
                                        {position > 5 ? (
                                          <DataGrid
                                            autoHeight={true}
                                            autoPageSize={false}
                                            rows={rows.slice(
                                              position - 6,
                                              position
                                            )}
                                            columns={columns}
                                            headerHeight={30}
                                            disableColumnMenu
                                            pageSize={8}
                                            rowsPerPageOptions={[8]}
                                            localeText={
                                              ptBR.components.MuiDataGrid
                                                .defaultProps.localeText
                                            }
                                          />
                                        ) : (
                                          <DataGrid
                                            autoHeight={true}
                                            autoPageSize={false}
                                            rows={rows.slice(0, position)}
                                            columns={columns}
                                            headerHeight={30}
                                            disableColumnMenu
                                            pageSize={8}
                                            rowsPerPageOptions={[8]}
                                            localeText={
                                              ptBR.components.MuiDataGrid
                                                .defaultProps.localeText
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {queue ? (
                      <div
                        className={`scheduling ${status ? 'inAttendence' : ''}`}
                      >
                        {!status && queueObject.queueVisibility && (
                          <>
                            <div>
                              <span>Previsão de carregamento:</span>
                            </div>

                            <div
                              className={`status-license-plate ${
                                updateTime < 0 ? 'tolerance' : ''
                              }`}
                            >
                              {truck?.licensePlate}
                            </div>

                            <span className='date-schedule'>
                              {date} às {time}
                            </span>
                          </>
                        )}

                        {status && date !== 'NaN undefined' && (
                          <div>Em atendimento...</div>
                        )}

                        {!status && !queueObject.queueVisibility && (
                          <>
                            <div
                              className={`status-license-plate ${
                                updateTime < 0 ? 'tolerance' : ''
                              }`}
                            >
                              {truck?.licensePlate}
                            </div>

                            <div>
                              <span className='gray-color'>
                                <b>Atenção Motorista!</b> <br />
                                Acompanhe por aqui a atualização de sua posição
                                na fila.
                              </span>
                            </div>
                          </>
                        )}

                        {schedulingStatus ? (
                          <div className='show-details'>
                            <div
                              className='details-button'
                              onClick={handleOrderDetails}
                            >
                              Detalhes do pedido
                              {orderDetails ? <CgArrowUpO /> : <CgArrowDownO />}
                            </div>

                            {orderDetails && (
                              <div>
                                <div>
                                  <div>Pedido: {order?.code}</div>
                                  <div>
                                    <span>Transportadora:</span>
                                    <div>{order?.shippingCompany.name}</div>
                                  </div>
                                  <div>
                                    <span>Destinatário:</span>
                                    {order?.customer.name}
                                  </div>
                                  <div>
                                    <span>Município:</span>
                                    {order?.customer.city}
                                  </div>
                                  <div>
                                    <span>Produto:</span>
                                    {
                                      order?.orderItem.reduce(
                                        (item: any, index: any) => {
                                          return item
                                        }
                                      ).product.name
                                    }
                                    - {qtyOrdered}t
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}

                    {!status && (
                      <div className='warning-attendance'>
                        <div></div>

                        {queueObject.queueVisibility ? (
                          <p>
                            A data e hora acima informada trata-se de uma{' '}
                            <div className='bold'>previsão</div> que pode ser
                            antecipada ou adiada sem aviso prévio a depender da
                            dinâmica do fluxo de atendimento na indústria.
                            Recomendamos a consulta constante de sua posição e
                            horário de previsão, e a chegada ao local com 6
                            horas de antecedência.
                          </p>
                        ) : (
                          <p className='outer'></p>
                        )}
                      </div>
                    )}

                    {!status && (
                      <>
                        <div className='buttons'>
                          <button onClick={handleRemove}>Sair da fila</button>

                          <button
                            onClick={() => {
                              handleSeePanel()
                            }}
                          >
                            Ver fila
                          </button>
                        </div>
                        <div className='buttons panel'></div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className='content'>
                    Você não possui marcação na fila
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
