import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { Link } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../components/Input'
import api from '../../../services/api'

import { useFormik } from 'formik'

import './styles.css'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { Checkbox } from '@material-ui/core'
import { useAuth } from '../../../contexts/auth'

interface Contact {
  id: number
  name: string
  fone: string
  email: string
}

interface User {
  name: string
  email: string
  role: string
  password?: string
}

interface iOption {
  label: string
  value: string
  company?: string
}

const NewEditUser: React.FC = (): JSX.Element => {
  const [companyOptions, setCompanyOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [companyValue, setCompanyValue] = useState<iOption>({
    value: '',
    label: ''
  })
  const [company, setCompany] = useState('')

  const [shippingOptions, setShippingOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [shippingValue, setShippingValue] = useState<iOption>({
    value: '',
    label: ''
  })

  const [isRoot, setIsRoot] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { isAdminRoot } = useAuth()
  const id = Number(sessionStorage.getItem('@App:id')?.replace(/"/g, ''))

  const companySchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório!'),
    email: Yup.string()
      .email('E-email inválido')
      .required('E-mail é obrigatório!')
  })

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      name: '',
      email: '',
      role: 'Usuario',
      cpf: '',
      fone: '',
      password: '',
      receivesEmail: false,
      useTerm: { id: 0, updatedAt: '' },
      hasEmail: false,
      hasSMS: false,
      hasWhats: false
    },
    validationSchema: companySchema,
    onSubmit: values => {
      if (values.role === 'Transportadora') {
        let objectDriver = {
          hasWhats: values?.hasWhats,
          hasSMS: values?.hasSMS,
          hasEmail: values?.hasEmail,
          user: {
            id: Number(id)
          },
          useTerm: {
            id: values?.useTerm?.id,
            updatedAt: values?.useTerm?.updatedAt
          }
        }

        api.post('useTerm/authorization/save', objectDriver)
      }

      api
        .post('users/save', {
          id: parseInt(`${id}`),
          shippingCompany:
            values.role !== 'Transportadora'
              ? null
              : { id: shippingValue.value },
          company:
            values.role === 'Transportadora'
              ? null
              : companyValue.value
              ? { id: companyValue.value }
              : null,
          ...values
        })
        .then(() => {
          toast.success('Cadastro realizado com sucesso')
          //setTimeout(() => {   window.location.reload()  }, 2500);
        })
        .catch(e => {
          if (e.response.data.code === 'ER_DUP_ENTRY')
            toast.error('Erro ao Cadastrar: Email já cadastrado!')
          else toast.error('Erro ao Cadastrar: ' + e.response.data.message)
        })
    }
  })

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let shippingCompanyId = 0

    if (id) {
      api.get(`/users/${id}`).then(response => {
        shippingCompanyId = response.data.shippingCompanyId

        if (response.data.role === 'Transportadora') {
          api
            .get(`/shipping-company/${response.data.shippingCompany.id}`)
            .then(shippingResponse => {
              setValues({
                name: response.data.name,
                email: response.data.email,
                role: response.data.role,
                cpf: response.data.cpf,
                fone: response.data.fone,
                password: '',
                receivesEmail: response.data?.receivesEmail,
                hasSMS:
                  shippingResponse.data?.user[0]?.authorization[0]?.hasSMS,
                hasWhats:
                  shippingResponse.data?.user[0]?.authorization[0]?.hasWhats,
                hasEmail:
                  shippingResponse.data?.user[0]?.authorization[0]?.hasEmail,
                useTerm:
                  shippingResponse.data?.user[0]?.authorization[0]?.useTerm
              })
            })
        } else {
          setValues({
            name: response.data.name,
            email: response.data.email,
            role: response.data.role,
            cpf: response.data.cpf,
            fone: response.data.fone,
            password: '',
            receivesEmail: response.data?.receivesEmail,
            hasSMS: false,
            hasWhats: false,
            hasEmail: false,
            useTerm: { id: 0, updatedAt: '' }
          })
        }

        if (response.data.company) {
          setCompanyValue({
            value: response.data.company.id,
            label: `${response.data.company.id} - ${response.data.company.name}`
          })
          setCompany(response.data.company.company)
        }

        if (response.data.shippingCompany) {
          setShippingValue({
            value: response.data.shippingCompany.id,
            label: `${response.data.shippingCompany.code} - ${response.data.shippingCompany.name}`
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  isAdminRoot().then(result => {
    setIsRoot(result)
    setIsLoading(false)
  })

  function handleChangeOptionShipping(option: iOption) {
    setShippingValue(option)
  }

  function handleChangeOptionCompany(option: iOption) {
    setCompanyValue(option)
    setCompany(option.company || '')
  }

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: iOption[] = []

    if (value) {
      api.get(`/${patch}/like/${value}`).then(response => {
        options_ = response.data.map((item: any) => {
          if (patch === 'company')
            return {
              value: item.id,
              label: `${item.name}`,
              company: item.company
            }

          return { value: item.id, label: `${item.code} - ${item.name}` }
        })
        setOption(options_)
      })
    }
  }

  if (isLoading) {
    return <></>
  }

  return (
    <div className='new-user-container'>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>{`${!id ? ' Cadastrar ' : 'Editar'}`} Perfil</strong>
          </div>

          <div className='buttons-header'>
            <Link to='/usuarios'>
              <button id='btBack' type='button'>
                <MdKeyboardArrowLeft size={20} color='#fff' />
                Voltar
              </button>
            </Link>
            <button id='btSave' type='submit'>
              <MdDone size={20} color='#fff' />
              Salvar
            </button>
          </div>
        </header>

        <div className='content'>
          <div className='row'>
            <div className={`input ${errors.name ? 'error' : ''}`}>
              <Input
                name='name'
                placeholder='Nome completo'
                onChange={handleChange}
                value={values.name}
                label='Nome completo'
                maxLength={25}
              />
              {errors.name && (
                <div className='validateError'>{errors.name}</div>
              )}
            </div>
            <div className={`input ${errors.email ? 'error' : ''}`}>
              <Input
                name='email'
                placeholder='email'
                type='email'
                value={values.email}
                onChange={handleChange}
                label='e-mail'
                maxLength={25}
              />

              {errors.email && (
                <div className='validateError'>{errors.email}</div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className={`input ${errors.cpf ? 'error' : ''}`}>
              <Input
                name='cpf'
                placeholder='CPF'
                mask='###.###.###-##'
                onChange={handleChange}
                value={values.cpf}
                label='CPF'
                maxLength={14}
              />
              {errors.cpf && <div className='validateError'>{errors.cpf}</div>}
            </div>
            <div className={`input ${errors.fone ? 'error' : ''}`}>
              <Input
                name='fone'
                placeholder='Telefone'
                type='fone'
                mask='(##) # ####-####'
                value={values.fone}
                onChange={handleChange}
                label='Telefone'
                maxLength={25}
              />

              {errors.fone && (
                <div className='validateError'>{errors.fone}</div>
              )}
            </div>
          </div>

          <div className='row'>
            {(isRoot || !id) && (
              <div className={`input ${errors.role ? 'error' : ''}`}>
                <div className={`input-block ${errors.role ? 'error' : ''}`}>
                  <label>Papel de Usuário</label>
                  <select
                    name='role'
                    value={values.role}
                    onChange={handleChange}
                  >
                    <option value='Usuario'>Usuário</option>
                    <option value='Administrador'>Administrador</option>
                    <option value='Expedicao'>Expedição</option>
                    <option value='Transportadora'>Transportadora</option>
                  </select>
                </div>

                {errors.role && (
                  <div className='validateError'>{errors.role}</div>
                )}

                {values.role === 'Transportadora' && (
                  <div className='select-shipping'>
                    <label>Transportadora:</label>
                    <Select
                      className='select-options'
                      value={shippingValue}
                      options={shippingOptions}
                      onInputChange={value =>
                        handleChangeInputSelect(
                          value,
                          'shipping-company',
                          setShippingOptions
                        )
                      }
                      onChange={option =>
                        handleChangeOptionShipping(option as iOption)
                      }
                    />
                  </div>
                )}
              </div>
            )}

            <div className={`input ${errors.password ? 'error' : ''}`}>
              <Input
                name='password'
                placeholder='Senha'
                type='password'
                value={values.password}
                onChange={handleChange}
                label='Senha'
                maxLength={11}
              />
            </div>
          </div>

          <div className='row'>
            {(isRoot || !id) && (
              <div className={`input ${errors.role ? 'error' : ''}`}>
                <div className='select-company'>
                  <label>Empresa:</label>
                  <Select
                    className='select-options'
                    value={companyValue}
                    options={companyOptions}
                    onInputChange={value =>
                      handleChangeInputSelect(
                        value,
                        'company',
                        setCompanyOptions
                      )
                    }
                    onChange={option =>
                      handleChangeOptionCompany(option as iOption)
                    }
                  />
                  <span style={{ fontSize: 14, marginLeft: 10, color: 'grey' }}>
                    Razão social: {company}
                  </span>
                </div>
              </div>
            )}

            {(values.role === 'Usuario' || values.role === 'Expedicao') && (
              <div
                className={`input checkbox ${
                  errors.receivesEmail ? 'error' : ''
                }`}
              >
                <Checkbox
                  name='receivesEmail'
                  checked={values.receivesEmail}
                  value={values.receivesEmail}
                  onChange={handleChange}
                  color='default'
                />
                <label>Receber Relatório Diario de Carregamento:</label>
              </div>
            )}
          </div>

          {values.role === 'Transportadora' && (
            <div className='termosContato row'>
              <div className='termosContent'>
                <h1>Contato:</h1>
                <div className='termoCheckbox'>
                  <Checkbox
                    name='hasSMS'
                    checked={values?.hasSMS}
                    value={values?.hasSMS}
                    onChange={handleChange}
                    color='default'
                    size='medium'
                  />
                  <label>Autorizar envio de mensagens sms</label>
                </div>
                <div className='termoCheckbox'>
                  <Checkbox
                    name='hasWhats'
                    checked={values?.hasWhats}
                    value={values?.hasWhats}
                    onChange={handleChange}
                    color='default'
                    size='medium'
                  />
                  <label>Autorizar mensagens ao whatsapp</label>
                </div>
                <div className='termoCheckbox'>
                  <Checkbox
                    name='hasEmail'
                    checked={values?.hasEmail}
                    value={values?.hasEmail}
                    onChange={handleChange}
                    color='default'
                    size='medium'
                  />
                  <label>Autorizar envio de mensagens ao email</label>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>

      <br />
    </div>
  )
}

export default NewEditUser
