/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { DateRangePicker } from 'react-date-range';
import { addDays, subDays } from "date-fns";

import { useHistory } from 'react-router';
import { DataGrid, GridColDef, GridCellParams, GridToolbar, ptBR } from '@mui/x-data-grid';
import ModalDelete from '../../components/ModalDelete';
import ModalDeletetHoliday from '../../components/ModalDelete';
import { Checkbox } from '@material-ui/core';

import { Link } from 'react-router-dom';
import { MdDone } from 'react-icons/md';
import Input from '../../components/Input';
import api from '../../services/api';

import { useFormik } from 'formik';

import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../../contexts/auth';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { AiOutlineSetting, AiOutlineDelete } from 'react-icons/ai';
import { GiBackwardTime, GiSettingsKnobs } from 'react-icons/gi';
import { AiFillCalendar } from 'react-icons/ai'
import Tooltip from '@material-ui/core/Tooltip';
import Select from 'react-select';

import './styles.css';
import moment from "moment";

//interface RouteParams { id: string };

interface Contact {
  id: number,
  name: string,
  fone: string,
  email: string,
}


interface User {
  name: string,
  email: string,
  role: string,
  password?: string
}

interface Delay {
  id: number;
  reason: string;
  time: string;
}

interface Holiday {
  id: number;
  title: string;
  dates: any;
}

interface iOption {
  label: string;
  value: string;
  company?: string;
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;


  return (
    <div
      style={{ flexGrow: 1 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  tabRoot: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    flexGrow: 1,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));


const Settings: React.FC = (): JSX.Element => {

  const history = useHistory();

  const [delays, setDelays] = useState<Delay[]>([])
  const [holidays, setHolidays] = useState<Holiday[]>([])
  const [reason, setReason] = useState('')
  const [time, setTime] = useState('')
  const [schedulingLimit, setSchedulingLimit] = useState("")
  const [hour_entry_Limit, setHour_entry_Limit] = useState("")
  const [dates, setDates] = useState<any[]>([])
  const { setMenuExpandFalse } = useAuth();
  const [companies, setCompanies] = useState<iOption[]>([{ value: "", label: "" }])
  const [companyValue, setCompanyValue] = useState<iOption>({ value: "", label: "" })

  const [modal, setModal] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState(0);

  const [image, setImage] = useState();
  const [cropper, setCropper] = useState<any>();
  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropImg = () => {
 
      let cropImg = cropper.getCroppedCanvas().toDataURL();
      let arr = cropImg.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

    
    return new File([u8arr], 'imagename', { type: mime });

  };

  const handleAddHoliday = () => {
    if(dates.length !== 0) {
      api.post('holidays/save',{
        company: {
          id: companyId
        },
        title:  values.holidayName,
        dates: JSON.stringify(dates)
      }).then(() => {
        getHolidays()
        toast.success('Bloqueio de Datas salvo!');
      }).catch(() => {
        toast.error('Erro ao cadastrar');
      })
    }else{
      toast.error('Data é obrigatório');
    }
  }

  let formData = new FormData();

  const { company } = useAuth();
  const companyId = company || localStorage.getItem('@App:companyId')

  const { handleSubmit, values, setValues, handleChange } = useFormik({
    initialValues: {
      id: 0,
      programmedTotal: 0,
      timeAttendence: "00:00",
      start: "00:00",
      end: "00:00",
      startInterval: "00:00",
      endInterval: "00:00",
      googleMaps:"",
      holidayName: "",
      holidayStart: "",
      holidayEnd: "",
      weekDays: {
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false
      },
      delay: [{
        id: 0,
        reason: '',
        time: ''
      }], 
      scheduling_day_limit: 0,
      hour_entry_Limit:0,
      enableTolerance: false,
      tolerance: "",
      enableRecaptcha: false,
      recaptchaSecret: "",
      
    },
    onSubmit: values => {
     
      if(image){
      formData.append('logo', getCropImg());
      if(companyId)
      formData.append('company', companyId.toString());

      api.post('/settings/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        
      })
    }

      api.post('settings/save',
        {
          id: values.id,
          programmedTotal: values.programmedTotal,
          timeAttendence: values.timeAttendence,
          start: values.start,
          end: values.end,
          startInterval: values.startInterval,
          endInterval: values.endInterval,
          googleMaps: values.googleMaps,
          delay: delays,
          company: Number(companyValue.value),
          weekDays: JSON.stringify(values.weekDays),
          scheduling_day_limit: schedulingLimit,
          hour_entry_Limit: hour_entry_Limit,
          enableRecaptcha: values.enableRecaptcha,
          recaptchaSecret: values.recaptchaSecret,
          enableTolerance: values.enableTolerance,
          tolerance: values.tolerance
        }
      ).then(() => {     
        toast.success('Alterações salvas com sucesso');
        setTimeout(() => { history.push('/settings'); }, 2500);
      }).catch(() => {
        toast.error(' Erro ao cadastrar');
      });

    },

  });

  const getHolidays = () => {
    api.get('/holidays').then(response => {
      setHolidays(response.data)
    })
  }

  const updateConfig = (id:number) => {
    api.get(`/settings/company/${id}`).then(response => {
      

      if (response.data) {
        setValues({
          id: response.data.id,
          programmedTotal: response.data.programmedTotal,
          timeAttendence: response.data.timeAttendence,
          start: response.data.start,
          end: response.data.end,
          startInterval: response.data.startInterval,
          endInterval: response.data.endInterval,
          googleMaps: response.data.googleMaps, 
          weekDays: JSON.parse(response.data.weekDays),
          delay: response.data.delay,
          scheduling_day_limit: response.data.scheduling_day_limit,
          hour_entry_Limit: response.data.hour_entry_Limit,
          holidayName: "",
          holidayStart: "",
          holidayEnd: "",
          enableTolerance: response.data.enableTolerance,
          tolerance: response.data.tolerance,
          enableRecaptcha: response.data.enableRecaptcha,
          recaptchaSecret: response.data.recaptchaSecret,
    
        });

        setSchedulingLimit(response.data.scheduling_day_limit)
        setHour_entry_Limit(response.data.hour_entry_Limit)

      }
      if (response.data.delay)
        setDelays(response.data.delay);
    });

  }

  useEffect(() => {

    getHolidays()

    api.get('/company').then(response => {
    
      setCompanies(response.data.map((item: any) => {
          return {value: item.id, label: item.name}
      }));
    
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };


  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Code', width: 80 },
    { field: 'reason', headerName: 'Motivo', width: 200 },
    { field: 'time', headerName: 'Tempo de atraso', width: 150 },
    { field: 'action', headerName: 'Ação', sortable: false,  width: 110,
       disableColumnMenu: true,
    renderCell: (params: GridCellParams) => {
     return   (
        <>
            <Tooltip className="delete-icon" title={<h1>Deletar</h1>}>
            <Link to={{ pathname: `` }}>
              <AiOutlineDelete onClick={() => { handleCorfirmDelete(params.row.id,params.row.reason) }}/> 
              </Link> 
            </Tooltip>

          </>
        )

      }
    },


  ];
  // add linhas a tabela
  const rows = delays.map((item, index) => {

    return { id: index + 1, reason: item.reason, time: item.time }
  })

  const columnsHolidays: GridColDef[] = [
    { field: 'id', headerName: 'Code', width: 80 },
    { field: 'title', headerName: 'Nome', width: 200 },
    { field: 'dates', headerName: 'Tempo de Bloqueio', width: 250 },
    { field: 'action', headerName: 'Ação', sortable: false,  width: 110,
       disableColumnMenu: true,
    renderCell: (params: GridCellParams) => {
     return   (
        <>
            <Tooltip className="delete-icon" title={<h1>Deletar</h1>}>
            <Link to={{ pathname: `` }}>
              <AiOutlineDelete onClick={() => { handleCorfirmDelete(params.row.id,params.row.title) }}/> 
              </Link> 
            </Tooltip>

          </>
        )

      }
    },
  ]

  const rowsHolidays = holidays.map((item, index) => {

    let dates = JSON.parse(item.dates)


    return { id: item.id, title: item.title, dates: dates[0] + ' - ' + dates[dates.length - 1] }
  })


  function handleAddDelay() {
    setDelays([...delays, { id: 0, reason: reason, time: time }])

    setReason('');
    setTime('');
  }

  function handleChangeReason(event: React.ChangeEvent<HTMLInputElement>) {
    setReason(event.target.value)

  }
  function handleChangeTime(event: React.ChangeEvent<HTMLInputElement>) {
    setTime(event.target.value)
  }


  function handleDeleteDelay(id: number) {
    hideModal();

    api.delete(`/delay/delete/${id}`).then(response => {
      if (response.status === 200) {
        toast.success("Excluido com sucesso!")
        const results = delays.filter(item =>
          item.id !== id
        );
        setDelays(results);
      }
    }).catch(() => {

      toast.success("Erro ao excluir!")
    });

  }

  function handleDeleteHoliday(id: number) {
    hideModal();

    api.delete(`/holidays/delete/${id}`).then(response => {
      if (response.status === 200) {
        toast.success("Excluido com sucesso!")
        const results = holidays.filter(item =>
          item.id !== id
        );
        setHolidays(results);
      }
    }).catch(() => {

      toast.error("Erro ao excluir!")
    });

  }

  function handleCorfirmDelete(id: any, name: any) {
    showModal();
    setName(name);
    setId(parseInt(id));
  }

  function handleCloseModal() {
    hideModal();
  }

  const showModal = () => {
    setModal("show");
  }
  const hideModal = () => {
    setModal("");
  }



  const [state, setState] = useState<any>([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 1),
      key: "selection"
    }
  ])

  const handleOnChange = (ranges:any) => {
    const { selection } = ranges;

    setState([selection])
    setDates(enumerateDaysBetweenDates(selection))

  };

  const enumerateDaysBetweenDates = function(selection:any) {
    var dates = [];
    var currDate = moment(selection.startDate).startOf('day');
    var lastDate = moment(selection.endDate).startOf('day');

    while(currDate.add(1, 'days').diff(lastDate) < 0) {
       
        dates.push(currDate.clone().toDate().toISOString().slice(0,10));
    }

    dates.unshift(selection.startDate.toISOString().slice(0,10))
    dates.push(selection.endDate.toISOString().slice(0,10))

    return dates;
  };

  function handleChangeOptionBrand(option: iOption) {
    setCompanyValue(option);
    updateConfig(Number(option.value))
  }

  return (
    <div className="settings-container">

      <form
        onSubmit={handleSubmit}
        autoComplete="off"
      >

        <header>

          <div>
            <strong>Configurações</strong>
          </div>

          <div className="buttons-header">
         
            <div className="new"> 
              <Link to="/termos-de-acesso/novo">
                <button type="button"> Cadastrar </button> 
              </Link>
            </div>
            
            <button id="btSave" type="submit"
            >
              <MdDone size={20} color="#fff" />
              Salvar
            </button>
          </div>
        </header>

        <div className="content">

          <div className="row company">
            <div className={`input`}>
              <label>Empresa: </label>
              <Select className="select-options"
                value={companyValue}
                options={companies}
                onChange={(option) => handleChangeOptionBrand(option as iOption)}
              />

            </div>
          </div>

          <ModalDelete type="Atraso" name={name} id={id} className={modal} handleClose={handleCloseModal} handleDeleteAction={handleDeleteDelay} />
          <ModalDeletetHoliday type="Tempo de Bloqueio" name={name} id={id} className={modal} handleClose={handleCloseModal} handleDeleteAction={handleDeleteHoliday} />


            <div className={classes.tabRoot}> 
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChangeTabs}
              aria-label="Vertical tabs example"
            >
              
              <Tab label="Sistema(Ativo)" icon={<AiOutlineSetting />} {...a11yProps(1)} />
              <Tab label="Atraso" icon={<GiBackwardTime />} {...a11yProps(0)} />
              <Tab label="Informações(Geral)"  onClick={() => { setMenuExpandFalse() }} icon={<GiSettingsKnobs />} {...a11yProps(2)}  />
              <Tab label="Bloqueios" icon={<AiFillCalendar />} {...a11yProps(3)} />
              
            </Tabs>


            <TabPanel value={value} index={1}>

              <div className="settings-delay-content">

                <div className="row">

                  <div className="settings-delay input-block">

                    <input name="reason" placeholder="Motivo"
                      value={reason}
                      onChange={handleChangeReason}


                    />
                  </div>

                  <div className="setting-time-delay input-block">
                    <input name="time-delay"
                      value={time}
                      onChange={handleChangeTime}
                      type="time"

                    />

                  </div>

                </div>


                <button type="button" id="addDelay"
                  onClick={handleAddDelay}
                >

                  + Adicionar
                </button>
              </div>


              <DataGrid autoHeight rows={rows} columns={columns} pageSize={10} disableColumnMenu 
              components={{
                Toolbar: GridToolbar,
              }}
               rowsPerPageOptions={[8]}
               localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}/>
            </TabPanel>

            <TabPanel value={value} index={0}  >
              <div className="attendence-area">
                <div className="attendence-heading">Informações dos atendimentos</div>

                  <div className="infor-container">

                    <div className="row">
                      <div className="input">
                        <div>Horário de atendimento:</div>
                        <div className="timeAttendence">
                          <Input name="start" placeholder="Início"
                            value={values.start}
                            type="time"
                            onChange={handleChange}
                            label="Início"
                            maxLength={25}

                          />
                        </div>
                      </div>

                      <div className="input">
                        <div>Horário de atendimento:</div>
                        <div className="timeAttendence">
                          <Input name="end" placeholder="Término"
                            value={values.end}
                            onChange={handleChange}
                            type="time"
                            label="Término"
                            maxLength={25}

                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="input">
                        <Input name="programmedTotal" placeholder="Máximo de atendimentos por dia"
                          onChange={handleChange}
                          value={values.programmedTotal}
                          type="number"
                          label="Nº máximo de atendimentos por dia:"
                        />
                      </div>
                      <div className="input">
                        <Input name="timeAttendence" placeholder="Tempo por atendimento"
                          onChange={handleChange}
                          value={values.timeAttendence}
                          type="time"
                          label="Tempo por atendimento:"
                        />
                      </div>
                    </div>
                    
                    <div className="row">
                        <div className="input timeIntervalAttendence">
                          <div>Intervalo de atendimento:</div>

                          <Input name="startInterval" placeholder="Início"
                            value={values.startInterval}
                            type="time"
                            onChange={handleChange}
                            label="Início"
                            maxLength={25}

                          />

                          <Input name="endInterval" placeholder="Término"
                            value={values.endInterval}
                            onChange={handleChange}
                            type="time"
                            label="Término"
                            maxLength={25}

                          />
                        </div>
                    
                        <div className="input week-days">
                          <div>Dias de atendimento:</div>
                          <input name="weekDays.mon" type="checkbox" checked={values.weekDays.mon} onChange={handleChange} /> Seg
                          <input name="weekDays.tue" type="checkbox" checked={values.weekDays.tue} onChange={handleChange} /> Ter
                          <input name="weekDays.wed" type="checkbox" checked={values.weekDays.wed} onChange={handleChange} /> Qua
                          <input name="weekDays.thu" type="checkbox" checked={values.weekDays.thu} onChange={handleChange} /> Qui
                          <input name="weekDays.fri" type="checkbox" checked={values.weekDays.fri} onChange={handleChange} /> Sex
                          <input name="weekDays.sat" type="checkbox" checked={values.weekDays.sat} onChange={handleChange} /> Sáb
                          <input name="weekDays.sun" type="checkbox" checked={values.weekDays.sun} onChange={handleChange} /> Dom
                        </div>
                    </div>

                    <div className="row">
                      <div className="input tolerance">

                        <div>
                          <div className="timeAttendence">
                            <Input
                              name="tolerance" 
                              placeholder="Início"
                              type="time"
                              value={values.tolerance}
                              onChange={handleChange}
                              label="Tolerância de Atraso"
                              maxLength={25}
                            />
                          </div>
                        </div>

                        <div>
                          <Checkbox 
                            name="enableTolerance" 
                            checked={values.enableTolerance}
                            value={values.enableTolerance}
                            onChange={handleChange}
                            color="default"
                          />
                          <label>Habilitar tolerância</label>
                        </div>

                      </div>

                      <div className="input tolerance">
                        <div>
                          <div className="timeAttendence">
                            <Input
                              name="recaptchaSecret" 
                              placeholder="Chave"
                              value={values.recaptchaSecret}
                              onChange={handleChange}
                              label="Chave Recaptcha Backend"
                              maxLength={25}
                            />
                          </div>
                        </div>

                        <div>
                          <Checkbox 
                            name="enableRecaptcha" 
                            checked={values.enableRecaptcha}
                            value={values.enableRecaptcha}
                            onChange={handleChange}
                            color="default"
                          />
                          <label>Habilitar Recaptcha</label>
                        </div>

                      </div>
                    </div>
                  </div>
              </div>
                
              <div className="scheduling-area">
                <div className="scheduling-heading">Informações dos pedidos</div>
                  
                <div className="infor-container">
                  <div className="row ">
                
                    <div className="input">
                      <div>Limite de agendamento por pedido:</div>
                      <div className="scheduling-limit">
                        <Input name="start" placeholder="Limite de agendadmento"
                          value={schedulingLimit}
                          type="number"
                          onChange={(event) => {setSchedulingLimit(`${event.target.value}`)}}
                          maxLength={25}
                        />
                      </div>
                    </div>

                    <div className="input">
                      <div >Intervalo entre último agendamento:</div>

                      <div className="last-scheduling">
                        <div>
                          <Input name="start" placeholder="Intervalo"
                            value={hour_entry_Limit}
                            type="number"
                            onChange={(event) => {setHour_entry_Limit(`${event.target.value}`)}}
                            maxLength={25}
                          />
                        </div>
                        <div className="h">H</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </TabPanel>

            <TabPanel value={value} index={2}  >
              <div className="row" >



                <div style={{ width: "100%" }}>
                  <label> Logo:</label><br />
                  <input type="file" onChange={onChange} />

                  <br />
                  <br />
                  <Cropper
                    style={{ height: "264px", width: "640px" }}
                    zoomTo={0}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    src={image}
                    viewMode={1}
                    guides={true}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={2}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => {
                      setCropper(instance);
                    }}
                  />
                </div>
                <div>
                  <div className="box" style={{ width: "100%", float: "right" }}>
                    <h1>Preview</h1>
                    <div
                      className="img-preview"
                      style={{ marginTop: 20, width: "200px", float: "left", height: "264px" }}
                    />
                  </div>

                </div>
                <br style={{ clear: "both" }} />


              </div>

            <div>

              <Input 
                name="googleMaps" placeholder="embed google maps"
                value={values.googleMaps}
                onChange={handleChange}
                label="Localização"
                maxLength={25}
              />

            <div>

                  <iframe
                   src={values.googleMaps}
                   width="400" height="300" 
                   loading="lazy">                
                   </iframe>
                  </div>
            </div>
            </TabPanel>

            <TabPanel value={value} index={3}  >
              <div className="attendence-area">
                <div className="attendence-heading">Informações dos atendimentos</div>
                  <div className="infor-container">

                    <div className="row wrap">
                        <div className="input">
                          <Input name="holidayName" placeholder="Nome"
                            onChange={handleChange}
                            value={values.holidayName}
                            type="text"
                            label="Tempo de Bloqueio"
                          />
                        </div>
            
                        <div>
                          <label>Data</label>
                          <DateRangePicker
                            onChange={handleOnChange}
                            showMonthAndYearPickers={false}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={state}
                            direction="horizontal"
                          />
                        </div>
                    </div>

                    <div className="buttons-holiday">
                      <button type="button" id="addDelay"
                        onClick={handleAddHoliday}
                      >

                        + Adicionar
                      </button>
                    </div>

                    <DataGrid autoHeight rows={rowsHolidays} columns={columnsHolidays} pageSize={10} disableColumnMenu 
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    rowsPerPageOptions={[8]}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    />

                  </div>
              </div>
            
            </TabPanel>
          </div>

          
        </div>
            <ToastContainer 
               position="bottom-right"
               autoClose={5000}
               hideProgressBar={false}
               newestOnTop={false}
               closeOnClick
               rtl={false}
               pauseOnFocusLoss
               draggable
               pauseOnHover
            />
      </form>


      <br />
    </div>

  );

}

export default Settings;