import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { Link, useHistory, useParams } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../components/Input'
import api from '../../../services/api'

import { useFormik } from 'formik'

import './styles.css'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { Checkbox } from '@material-ui/core'

interface RouteParams {
  id: string
}

interface Contact {
  id: number
  name: string
  fone: string
  email: string
}

interface User {
  name: string
  email: string
  role: string
  password?: string
}

interface Company {
  id: number
  name: string
}

interface iOption {
  label: string
  value: string
  company?: string
}

const NewEditUser: React.FC = (): JSX.Element => {
  const [companyOptions, setCompanyOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [companyValue, setCompanyValue] = useState<iOption>({
    value: '',
    label: ''
  })
  const [company, setCompany] = useState('')
  const [status, setStatus] = useState<Boolean>(true)

  const [shippingOptions, setShippingOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [shippingValue, setShippingValue] = useState<iOption>({
    value: '',
    label: ''
  })
  const [isRoot, setIsRoot] = useState<Boolean>(true)

  const companySchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório!'),
    email: Yup.string()
      .email('E-email inválido')
      .required('E-mail é obrigatório!')
  })

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      name: '',
      email: '',
      role: 'Usuario',
      cpf: '',
      fone: '',
      password: '',
      receivesEmail: false,
      isEnabled: true,
      isRoot: true
    },
    validationSchema: companySchema,
    onSubmit: values => {
      if (values.role === 'Usuario' && !companyValue.value) {
        toast.error('Selecione uma empresa para o usuário:')
        return
      }
      if (values.role === 'Transportadora' && !shippingValue.value) {
        toast.error('Selecione uma transportadora para o usuário:')
        return
      }

      api
        .post('users/save', {
          id: parseInt(id),
          shippingCompany:
            values.role !== 'Transportadora'
              ? null
              : { id: shippingValue.value },
          company:
            values.role === 'Transportadora'
              ? companyValue.value
              : companyValue.value
              ? { id: companyValue.value }
              : null,
          ...values
        })
        .then(() => {
          toast.success('Cadastro realizado com sucesso')
          setTimeout(() => {
            history.push('/usuarios')
          }, 2500)
        })
        .catch(e => {
          if (e.response.data.code === 'ER_DUP_ENTRY')
            toast.error('Erro ao Cadastrar: Dado já cadastrado!')
          else toast.error('Erro ao Cadastrar: ' + e.response.data.message)
        })
    }
  })

  const { id } = useParams<RouteParams>()

  const history = useHistory()

  console.log(values)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let shippingCompanyId = 0

    api.get(`/company`).then(response => {
      if (response.data) {
        setCompanyOptions(
          response.data?.map((item: Company) => {
            return { label: `${item.id} - ${item.name}`, value: item.id }
          })
        )
      }
    })

    if (id) {
      api.get(`/users/${id}`).then(response => {
        shippingCompanyId = response.data.shippingCompanyId
        setValues({
          name: response.data.name,
          email: response.data.email,
          role: response.data.role,
          cpf: response.data.cpf,
          fone: response.data.fone,
          password: '',
          receivesEmail: response.data.receivesEmail,
          isEnabled: response.data.isEnabled,
          isRoot: response.data.isRoot
        })

        setIsRoot(response.data.isRoot)
        setStatus(response.data.isEnabled)

        if (response.data.company) {
          setCompanyValue({
            value: response.data.company.id,
            label: `${response.data.company.id} - ${response.data.company.name}`
          })
          setCompany(response.data.company.company)
        }

        if (response.data.shippingCompany) {
          setShippingValue({
            value: response.data.shippingCompany.id,
            label: `${response.data.shippingCompany.code} - ${response.data.shippingCompany.name}`
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleChangeOptionShipping(option: iOption) {
    setShippingValue(option)
  }
  function handleChangeOptionCompany(option: iOption) {
    setCompanyValue(option)
    setCompany(option.company || '')
  }

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: iOption[] = []

    if (value) {
      api.get(`/${patch}/like/${value}`).then(response => {
        options_ = response.data.map((item: any) => {
          if (patch === 'company')
            return {
              value: item.id,
              label: `${item.name}`,
              company: item.company
            }

          return { value: item.id, label: `${item.code} - ${item.name}` }
        })
        setOption(options_)
      })
    }
  }

  async function handleChangeStatus(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setStatus(event.target.checked)
    values.isEnabled = event.target.checked
  }
  async function handleChangeRoot(event: React.ChangeEvent<HTMLInputElement>) {
    setIsRoot(event.target.checked)
    values.isRoot = event.target.checked
  }

  return (
    <div className='new-user-container'>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>{`${!id ? ' Cadastrar ' : 'Editar'}`} Usuário</strong>
          </div>

          <div className='buttons-header'>
            <Link to='/usuarios'>
              <button id='btBack' type='button'>
                <MdKeyboardArrowLeft size={20} color='#fff' />
                Voltar
              </button>
            </Link>
            <button id='btSave' type='submit'>
              <MdDone size={20} color='#fff' />
              Salvar
            </button>
          </div>
        </header>

        <div className='content'>
          <div className='row'>
            <div className={`input ${errors.name ? 'error' : ''}`}>
              <Input
                name='name'
                placeholder='Nome completo'
                onChange={handleChange}
                value={values.name}
                label='Nome completo'
                maxLength={25}
              />
              {errors.name && (
                <div className='validateError'>{errors.name}</div>
              )}
            </div>
            <div className={`input ${errors.email ? 'error' : ''}`}>
              <Input
                name='email'
                placeholder='email'
                type='email'
                value={values.email}
                onChange={handleChange}
                label='e-mail'
                maxLength={25}
              />

              {errors.email && (
                <div className='validateError'>{errors.email}</div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className={`input ${errors.cpf ? 'error' : ''}`}>
              <Input
                name='cpf'
                placeholder='CPF'
                mask='###.###.###-##'
                onChange={handleChange}
                value={values.cpf}
                label='CPF'
                maxLength={14}
              />
              {errors.cpf && <div className='validateError'>{errors.cpf}</div>}
            </div>
            <div className={`input ${errors.fone ? 'error' : ''}`}>
              <Input
                name='fone'
                placeholder='Telefone'
                type='fone'
                mask='(##) # ####-####'
                value={values.fone}
                onChange={handleChange}
                label='Telefone'
                maxLength={25}
              />

              {errors.fone && (
                <div className='validateError'>{errors.fone}</div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className={`input ${errors.role ? 'error' : ''}`}>
              <div className={`input-block ${errors.role ? 'error' : ''}`}>
                <label>Papel de Usuário</label>

                <select name='role' value={values.role} onChange={handleChange}>
                  <option value='Usuario'>Usuário</option>
                  <option value='Administrador'>Administrador</option>
                  <option value='Expedicao'>Expedição</option>
                  <option value='Transportadora'>Transportadora</option>
                  <option value='Espectador'>Espectador</option>
                  <option value='Portaria'>Portaria</option>
                </select>
              </div>

              {errors.role && (
                <div className='validateError'>{errors.role}</div>
              )}
            </div>

            <div className={`input ${errors.password ? 'error' : ''}`}>
              <Input
                name='password'
                placeholder='Senha'
                type='password'
                value={values.password}
                onChange={handleChange}
                label='Senha'
                maxLength={11}
              />
            </div>
          </div>
          <div className='row'>
            <div className={`input ${errors.role ? 'error' : ''}`}>
              {values.role !== 'Administrador' && (
                <div className='select-company'>
                  <label>Empresa:</label>
                  <Select
                    className='select-options'
                    value={companyValue}
                    options={companyOptions}
                    onInputChange={value =>
                      handleChangeInputSelect(
                        value,
                        'company',
                        setCompanyOptions
                      )
                    }
                    onChange={option =>
                      handleChangeOptionCompany(option as iOption)
                    }
                  />
                  <span style={{ fontSize: 14, marginLeft: 10, color: 'grey' }}>
                    Razão social: {company}
                  </span>
                </div>
              )}
            </div>
            {values.role === 'Transportadora' && (
              <div className={`input ${errors.role ? 'error' : ''}`}>
                <div className='select-shipping'>
                  <label>Transportadora:</label>
                  <Select
                    className='select-options'
                    value={shippingValue}
                    options={shippingOptions}
                    onInputChange={value =>
                      handleChangeInputSelect(
                        value,
                        'shipping-company',
                        setShippingOptions
                      )
                    }
                    onChange={option =>
                      handleChangeOptionShipping(option as iOption)
                    }
                  />
                </div>
              </div>
            )}
            {(values.role === 'Usuario' || values.role === 'Expedicao') && (
              <div
                className={`input checkbox ${
                  errors.receivesEmail ? 'error' : ''
                }`}
              >
                <Checkbox
                  name='receivesEmail'
                  checked={values.receivesEmail}
                  value={values.receivesEmail}
                  onChange={handleChange}
                  color='default'
                />
                <label>Receber Relatório Diario de Carregamento</label>
              </div>
            )}
          </div>
          <div className='row'>
            {values.role === 'Administrador' && (
              <div className={`input ${errors.isRoot ? 'error' : ''}`}>
                <label>Root</label>
                <div>
                  <label className='switch'>
                    <input
                      name='root'
                      placeholder='root'
                      onChange={handleChangeRoot}
                      type='checkbox'
                      {...(isRoot === true
                        ? { checked: true }
                        : { checked: false })}
                    />
                    <div className='slider round'>
                      <span className='on'>Sim</span>
                      <span className='off'>Não</span>
                    </div>
                  </label>
                </div>
              </div>
            )}
            <div className={`input ${errors.isEnabled ? 'error' : ''}`}>
              <label>Status</label>
              <div>
                <label className='switch'>
                  <input
                    name='status'
                    placeholder='Status'
                    onChange={handleChangeStatus}
                    type='checkbox'
                    {...(status === true
                      ? { checked: true }
                      : { checked: false })}
                  />
                  <div className='slider round'>
                    <span className='on'>Ativo</span>
                    <span className='off'>Inativo</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>

      <br />
    </div>
  )
}

export default NewEditUser
