/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable @typescript-eslint/no-unused-vars

import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
  ptBR
} from '@mui/x-data-grid'
import debounce from 'lodash/debounce'
import { Box, Modal, Typography } from '@material-ui/core'
import { useAuth } from '../../contexts/auth'
import { MdSearch } from 'react-icons/md'
import api from '../../services/api'
import CryptoJS from 'crypto-js'
import ModalDelete from '../../components/ModalDelete'
import load from '../../assets/images/carregando.svg'
import { AiOutlineDelete, AiOutlineEdit, AiOutlineClose } from 'react-icons/ai'
import { BiLogIn } from 'react-icons/bi'
import { toast, ToastContainer } from 'react-toastify'

import Tooltip from '@material-ui/core/Tooltip'
import Select from 'react-select'
import logIcon from '../../assets/images/icons/historico.svg'
import moment from 'moment'
import './styles.scss'
import { ExportCSVLinkButton } from './components/ExportCSVLinkButton'
import { ExportPDFButton } from './components/ExportPDFButton'
import { getDriversGroups } from './hooks/get-drivers-groups'
import cx from 'clsx'

interface Queue {
  id: number
  date: string
  attended: boolean
  attended_at: string | null
  isMissing: boolean
  deletedAt: string | null
}

export interface Driver {
  id: number
  name: string
  cpf: string
  cnh: string
  isEnabled: boolean
  birthDate: Date
  shippingCompany: any
  company: any
  truck: any[]
  queue: Queue[]
  position: number | null
  truckId: number | null
}

interface Company {
  id: number
  name: string
}

interface Option {
  label: string
  value: string
}

enum QueueStatus {
  'null',
  'pending',
  'tolerance',
  'attended',
  'fault'
}

const STATUS_OPTIONS = [
  { label: 'Selecione', value: '' },
  { label: 'Ativo', value: '1' },
  { label: 'Inativo', value: '0' }
]

const Joints: React.FC = () => {
  const { role, company } = useAuth()
  const companyId = company || localStorage.getItem('@App:companyId')

  const [id, setId] = useState(0)
  const [drivers, setDrivers] = useState<Driver[]>([])
  const [rows, setRows] = useState<any[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shippingOptions, setShippingOptions] = useState<Option[]>([])
  const [driverName, setDriverName] = useState('')
  const [switchState, setSwitchState] = useState<any>(false)
  const [modal, setModal] = useState('')

  const [companies, setCompanies] = useState<Option[]>([])
  const [companyValue, setCompanyValue] = useState<Option>({} as Option)
  const [shippingCompanies, setShippingCompanies] = useState<Option[]>([])
  const [shippingCompanyValue, setShippingCompanyValue] = useState<Option>(
    {} as Option
  )

  const [statuses, setStatuses] = useState<Option[]>(STATUS_OPTIONS)

  const [statusValue, setStatusValue] = useState<Option>({} as Option)
  const [queueStatus, setQueueStatus] = useState(false)
  const [truckStatus, setTruckStatus] = useState(true)
  const [loading, setLoading] = useState(true)
  const [licensePlate, setLicensePlate] = useState('')
  const [nameOrCpf, setNameOrCpf] = useState('')
  const columnsToExport = [
    'Id',
    'Nome',
    'CPF',
    'Caminhão',
    'Transportadora',
    'Unidade'
  ]

  const [queryParams, setQueryParams] = useState({} as any)

  const columns: GridColDef[] = [
    {
      field: 'id',
      hide: !queueStatus ? true : false,
      headerName: queueStatus ? 'Fila' : '',
      width: 70,
      renderCell: (params: GridCellParams) => {
        const { queueStatus: rowQueueStatus } = params.row
        const rowQueueStatusNotIsNull = rowQueueStatus !== null
        const rowQueueStatusNotIsAttended = rowQueueStatus !== 'attended'

        return (
          <>
            {queueStatus && (
              <div className='exped-position-status'>
                <div
                  className={`label-status ${
                    rowQueueStatusNotIsNull && rowQueueStatusNotIsAttended
                      ? rowQueueStatus
                      : 'null'
                  }`}
                />
                {params.row.position && (
                  <span className='position'>{params.row.position + '°'}</span>
                )}
              </div>
            )}
          </>
        )
      }
    },
    {
      field: 'position',
      hide: !queueStatus ? true : false,
      headerName: '',
      width: 82,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {queueStatus &&
              params.row.queue &&
              params.row.queueStatus !== null &&
              params.row.queueStatus !== 'attended' && (
                <div
                  className={`queue-table__row__position ${params.row.queueStatus}`}
                >
                  <span>{moment(params.row.queue.date).format('DD/MM')}</span>
                  <time className='markup__time'>
                    {moment(params.row.queue.date).format('hh:mm')}
                  </time>
                </div>
              )}
          </>
        )
      }
    },
    {
      field: 'name',
      headerName: 'Motorista',
      width: 200,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='driver-name'>
              <div>
                <div className='code'>
                  <span className='code-number'>{params.row.id}</span>
                  <span
                    className={`status ${
                      params.row.status ? 'active' : 'deactived'
                    }`}
                  >
                    {params.row.status ? 'Ativo' : 'Inativo'}
                  </span>
                </div>
                <div className='name'>
                  <span>
                    {params.row.name
                      .split(' ')
                      .slice(0, 2)
                      .join()
                      .replace(',', ' ')}
                  </span>
                </div>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'cpf',
      headerName: 'CPF / Nasc.',
      width: 150,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='driver-name'>
              <div className='name'>
                <span>{params.row.cpf || params.row.birthDate}</span>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'action-driver',
      headerName: '',
      sortable: false,
      width: 150,

      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Tooltip className='edit-icon' title={<h1>Editar</h1>}>
              <Link to={{ pathname: `/motorista/novo/${params.row.id}` }}>
                <AiOutlineEdit />
              </Link>
            </Tooltip>

            <Tooltip
              className='delete-icon'
              title={
                <h1>{role === 'Administrador' ? 'Deletar' : 'Desvincular'}</h1>
              }
            >
              <Link to={{ pathname: '' }}>
                {role === 'Administrador' ? (
                  <AiOutlineDelete
                    onClick={() => {
                      handleCorfirmDelete(params.row.id, params.row.name)
                    }}
                  />
                ) : (
                  <AiOutlineClose
                    onClick={() => {
                      handleCorfirmDelete(params.row.id, params.row.name)
                    }}
                  />
                )}
              </Link>
            </Tooltip>

            <Tooltip
              className='auth-icon'
              title={
                <div style={{ textAlign: 'center', padding: 3 }}>
                  <h1 style={{ marginBottom: '5px' }}>Logar como motorista</h1>
                  <h2 style={{ fontWeight: 'bold' }}>
                    Clique com o botão direito e abra em janela anônima{' '}
                  </h2>
                </div>
              }
            >
              <Link
                to={{
                  pathname: `${
                    document.location.origin
                  }/driver-login/${encryptCpf(
                    params.row.cpf
                  )}/${encryptBirthDate(params.row.birthDate)}`
                }}
              >
                <BiLogIn
                  onClick={() => {
                    handleDriverLogin(params.row.cpf, params.row.birthDate)
                  }}
                />
              </Link>
            </Tooltip>

            <Tooltip className='log-icon' title={<h1>Logs</h1>}>
              <Link to={{ pathname: `/motoristas/logs/${params.row.id}` }}>
                <img src={logIcon} alt='' />
              </Link>
            </Tooltip>
          </>
        )
      }
    },
    {
      field: 'cnh',
      headerName: 'Veículo',
      width: 120,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='driver-name'>
              <div className='name'>
                <span>{params.row?.truck?.licensePlate}</span>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'birthDate',
      headerName: 'Modelo',
      width: 130,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='driver-name'>
              <div className='name'>
                <span>{params.row?.truck?.vehicleModel?.name}</span>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'action-truck',
      headerName: '',
      sortable: false,
      width: 100,

      renderCell: (params: GridCellParams) => {
        return (
          <>
            {params.row.truck && (
              <>
                <Tooltip className='edit-icon' title={<h1>Editar</h1>}>
                  <Link
                    to={{ pathname: `/caminhao/novo/${params.row.truck.id}` }}
                  >
                    <AiOutlineEdit />
                  </Link>
                </Tooltip>

                <Tooltip
                  className='delete-icon'
                  title={
                    <h1>
                      {role === 'Administrador' ? 'Deletar' : 'Desvincular'}
                    </h1>
                  }
                >
                  <Link to={{ pathname: '' }}>
                    {role === 'Administrador' ? (
                      <AiOutlineDelete
                        onClick={() => {
                          handleCorfirmDelete(
                            params.row.truck?.id,
                            params.row.truck?.licensePlate
                          )
                        }}
                      />
                    ) : (
                      <AiOutlineClose
                        onClick={() => {
                          handleCorfirmDelete(
                            params.row.truck?.id,
                            params.row.truck?.licensePlate
                          )
                        }}
                      />
                    )}
                  </Link>
                </Tooltip>
              </>
            )}
          </>
        )
      }
    },
    {
      field: 'shipping',
      headerName: 'Transportadora',
      width: 150,
      renderCell: (params: GridCellParams) => {
        const driverShippingCompany = params.row?.shippingCompany
        const vehicleShippingCompany = params.row?.truck?.shippingCompany?.name
        const whichShippingCompanyToShow =
          vehicleShippingCompany ?? driverShippingCompany

        return (
          <>
            <div
              style={{ lineHeight: 1 }}
              className='driver-name'
              title={whichShippingCompanyToShow}
            >
              <div className='name'>
                <span>{whichShippingCompanyToShow ?? 'Nenhuma'}</span>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'company.name',
      headerName: 'Unidade',
      width: 100,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='driver-name'>
              <div className='name'>
                <span>{params.row.company?.name}</span>
              </div>
            </div>
          </>
        )
      }
    }
  ]

  const disableButtonLoadMore = queueStatus || !truckStatus

  const rowsFiltered =
    queueStatus && nameOrCpf.length > 0
      ? rows.filter(driver => {
          const driverName = driver.name.toUpperCase()
          const driverCPF = driver.cpf.toUpperCase()

          const formattedSearchValue = nameOrCpf.toUpperCase()

          return (
            driverName.includes(formattedSearchValue) ||
            driverCPF.includes(formattedSearchValue)
          )
        })
      : rows

  function fillTableRows(driversList: any[]) {
    const rows = driversList.map(item => {
      var birthDate
      try {
        let _birthDate = new Date(item.birthDate)
          .toISOString()
          .slice(0, 10)
          .split('-')
        birthDate = _birthDate[2] + '/' + _birthDate[1] + '/' + _birthDate[0]
      } catch {
        birthDate = '00/00/000'
      }

      //if has schedule
      let queueStatus = QueueStatus[item.queue[0]?.id ? 1 : 0]

      //***Tolerancia** */

      const date1: any = new Date()
      const date2: any = new Date(item?.queue[0]?.date || null)
      const diffTime = Math.floor(date2 - date1)

      let _enableTolerance: any = localStorage.getItem('@enableTolerance')

      _enableTolerance === 'true'
        ? (_enableTolerance = true)
        : (_enableTolerance = false)

      const isInTolerance = diffTime < 0 && _enableTolerance

      if (isInTolerance && item.queue[0]?.id) {
        queueStatus = QueueStatus[2]
      }

      return {
        id: item.id,
        queueStatus: queueStatus,
        position: item.position,
        name: item.name,
        cpf: item.cpf,
        status: item.isEnabled,
        cnh: item.cnh,
        birthDate: birthDate,
        shippingCompany: item.shippingCompany
          ? item.shippingCompany.name
          : 'Nenhuma',
        company: item.company ? item.company : 'Nenhuma',
        truck: item.truck[0],
        queue: item.queue[0] || null
      }
    })

    setRows(rows)
  }

  function loadInputSelect() {
    handleChangeInputSelect('', 'company', setCompanies)
    handleChangeInputSelect('', 'shipping-company', setShippingOptions)
  }

  function encryptCpf(cpf: any) {
    return CryptoJS.AES.encrypt(cpf, 'SecretCpf')
      .toString()
      .replaceAll('+', 'xMl3Jk')
      .replaceAll('/', 'Por21Ld')
      .replaceAll('=', 'Ml32')
  }

  function encryptBirthDate(birthDate: any) {
    return CryptoJS.AES.encrypt(birthDate, 'SecretBirthdate')
      .toString()
      .replaceAll('+', 'xMl3Jk')
      .replaceAll('/', 'Por21Ld')
      .replaceAll('=', 'Ml32')
  }

  function handleDriverLogin(cpf: any, birthDate: any) {
    const encryptedCpf = encryptCpf(cpf)
    const encryptedBirhdate = encryptBirthDate(birthDate)

    window.open(
      `${document.location.origin}/driver-login/${encryptedCpf}/${encryptedBirhdate}`,
      '_blank',
      `location=yes,fullscreen=yes,scrollbars=yes,status=yes,menubar=yes`
    )
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete(id: any, name: any) {
    showModal()
    setDriverName(name)
    setId(parseInt(id))
  }

  async function handleUnbidTruck(driverId: number) {
    const url = '/driver/unbind-shipping-company'

    try {
      await api.patch(url, {
        driver: {
          id: driverId
        }
      })

      toast.success('Motorista desvinculado com sucesso')
      setTimeout(() => window.location.reload(), 1000)
    } catch (error: any) {
      toast.error('Erro ao Desvincular: ' + error.response.data.statusCode)
    }
  }

  async function handleDeleteDriver(driverId: number) {
    if (role === 'Administrador') {
      try {
        const response = await api.delete(`/driver/delete/${driverId}`)

        if (response.status === 200) {
          const results = drivers.filter(item => item.id !== driverId)

          setDrivers(results)
          toast.success('Excluido com sucesso!')
        }
      } catch (error: any) {
        if (error?.response?.data.message === 'ER_ROW_IS_REFERENCED_2') {
          toast.error(
            'Erro ao excluir,  existe(m) pedido(s) para este motorista!'
          )
        } else {
          toast.error(
            'Erro ao excluir: ' + error.response.data.message || error.message
          )
        }
      }
    } else {
      handleUnbidTruck(driverId)
    }

    hideModal()
  }

  const showModal = () => {
    setModal('show')
  }

  const hideModal = () => {
    setModal('')
  }

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: Option[] = []

    if (value) {
      let path = `/${patch}/like/${value}`

      if (value === '') {
        path = `/${patch}`
      }

      if (role !== 'Administrador') {
        path = path + `/company/${company}`
      }

      if (value === 'default') {
        path = `/${patch}`
      }

      if (companyValue.value !== '' && patch !== 'company') {
        path = path + `/company/${companyValue.value}`
      }

      api.get(path).then(response => {
        options_ = response.data.map((item: any) => {
          return {
            value: item.id,
            label: `${item.code ? item.code : item.id} - ${item.name}`
          }
        })
        setOption(options_)
      })
    }
  }

  async function handleDeleteTruck(truckId: number) {
    if (role === 'Administrador') {
      try {
        const response = await api.delete(`/truck/delete/${truckId}`)
        let itemIndex = -1
        let truckIndex = -1

        if (response.status === 200) {
          const results = drivers.filter((item, _itemIndex) =>
            item?.truck.filter((truckItem: any, _truckIndex: number) => {
              if (truckItem.id === truckId) {
                itemIndex = _itemIndex
                truckIndex = _truckIndex
              }
            })
          )

          if (itemIndex > -1) {
            results[itemIndex].truck = results[itemIndex].truck.filter(
              (truckItem: any, truckItemIndex: number) =>
                truckItemIndex !== truckIndex
            )
          }

          setDrivers(results)
          toast.success('Excluido com sucesso!')
        }
      } catch (error: any) {
        if (error.response.data.message === 'ER_ROW_IS_REFERENCED_2') {
          toast.error(
            'Erro ao excluir, existe(m) pedido(s) para este caminhão!'
          )
        } else
          toast.error(
            'Erro ao excluir: ' + error.response.data.message || error.message
          )
      }
    } else {
      handleUnbidTruck(truckId)
    }

    hideModal()
  }

  async function handleChangeOption(
    option: Option,
    setValue: any,
    type: string
  ) {
    setValue(option)
    setLoading(true)

    queryParams[type] = option.value

    const result = await getDriversGroups({
      ...queryParams
    })

    setQueryParams({ ...queryParams, [type]: option.value })

    if (!result) {
      setLoading(false)
      return
    }

    if (result) {
      fillTableRows(result)
      setDrivers(result)
      setLoading(false)
    }
  }

  function handleSwitch(
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    status: boolean,
    setStatus: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    setStatus(!status)
    setSwitchState(!switchState)

    const driverKey = key as keyof Driver

    const updatedListDrivers = filterDriversBySwitch(
      driverKey === 'queue',
      driverKey === 'truck'
    )

    fillTableRows(updatedListDrivers)
  }

  function filterDriversBySwitch(filterByQueue = false, filterByTruck = false) {
    let filteredDrivers = drivers

    if (filterByQueue && filterByTruck) {
      const result = drivers.filter(
        driver => driver.queue.length && driver.truck.length <= 0
      )

      const resultIsEmpty = result.length === 0

      filteredDrivers = resultIsEmpty ? drivers : result
    } else if (filterByQueue) {
      if (!queueStatus) {
        const driversInQueue = drivers
          .filter(driver => driver.queue.length)
          .filter(driver =>
            driver.truck.find(truck => truck.id === driver.truckId)
          )

        const driversInQueueIsEmpty = driversInQueue.length === 0

        filteredDrivers = driversInQueueIsEmpty ? drivers : driversInQueue
      }

      return filteredDrivers
    } else if (filterByTruck) {
      const driversWithoutVehicles = drivers.filter(
        driver => truckStatus && driver.truck.length <= 0
      )

      const driversWithoutVehiclesIsEmpty = driversWithoutVehicles.length === 0

      filteredDrivers = driversWithoutVehiclesIsEmpty
        ? drivers
        : driversWithoutVehicles
    }

    return filteredDrivers
  }

  const LoadingComponent = () => (
    <>
      <Box className='modal load'>
        <Modal
          open={loading}
          onClose={() => setLoading(true)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='modal-instance'
        >
          <Box className=' suspend-loading body'>
            <Box className='container'>
              <Box>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  <div className='loading'>
                    <img src={load} alt='load' />
                  </div>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  )

  async function handleLoadMoreDriversGroups() {
    setLoading(true)

    const lastDriverOfList = drivers[drivers.length - 1]

    if (!lastDriverOfList) {
      toast.error('Erro ao consultar')
      setLoading(false)
      return
    }

    const fromId = lastDriverOfList.id

    const formattedDrivers = await getDriversGroups({
      from_id: fromId,
      page_size: 16,
      companyId,
      ...queryParams
    })

    if (formattedDrivers) {
      const formattedDriversWithoutLastDriver = formattedDrivers.filter(
        driver => driver.id !== fromId
      )
      const updatedListDrivers = [
        ...drivers,
        ...formattedDriversWithoutLastDriver
      ]

      fillTableRows(updatedListDrivers)
      setDrivers(updatedListDrivers)
      setLoading(false)

      toast.success('Consulta finalizada')
    }
  }

  function handleDriverGroups(driversGroups: Driver[] | undefined) {
    if (driversGroups) {
      let array: any = []

      // eslint-disable-next-line array-callback-return
      driversGroups.map(driver => {
        if (driver.truck.length) {
          const result = driver.truck.map(truck => {
            return {
              ...driver,
              truck: [truck] ?? []
            }
          })

          array.push(...result)
        } else {
          array.push(driver)
        }
      })

      fillTableRows(array)
      setDrivers(array)
      setLoading(false)
    }
  }

  useEffect(() => {
    api.get(`/company`).then(response => {
      if (response.data) {
        response.data.unshift({ name: 'Selecione', id: '' })

        setCompanies(
          response.data?.map((item: Company) => {
            return {
              label: `${item.id} ${
                item.name !== 'Selecione' ? '-' + item.name : item.name
              }`,
              value: item.id
            }
          })
        )
      }
    })

    api
      .get(`/shipping-company`, {
        params: {
          withTrucks: 1
        }
      })
      .then(response => {
        if (response.data) {
          response.data.unshift({ name: 'Selecione', id: '' })

          setShippingCompanies(
            response.data?.map((item: Company) => {
              return {
                label: `${item.id} ${
                  item.name !== 'Selecione' ? '-' + item.name : item.name
                }`,
                value: item.id
              }
            })
          )
        }
      })

    loadInputSelect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, role])

  const fetchDataLicensePlate = useCallback(
    debounce(async searchValue => {
      if (searchValue.length > 0) {
        setLoading(true)
        const formattedLicensePlate = searchValue.toUpperCase()
        const result = await getDriversGroups({
          truck_search: formattedLicensePlate
        })

        setLoading(false)
        if (!result) {
          return
        }

        handleDriverGroups(result)
      } else {
        const driversGroups = await getDriversGroups()

        handleDriverGroups(driversGroups)
      }
    }, 1000),
    []
  )

  const fetchDataDriverCPF = useCallback(
    debounce(async (searchValue: string) => {
      if (!queueStatus && searchValue.length > 0) {
        setLoading(true)
        const formattedDriver = searchValue.toUpperCase()

        const result = await getDriversGroups({
          driver_search: formattedDriver
        })

        setLoading(false)

        if (!result) {
          return
        }

        handleDriverGroups(result)
      } else if (!queueStatus && searchValue.length === 0) {
        const driversGroups = await getDriversGroups()
        handleDriverGroups(driversGroups)
      }
    }, 1000),
    [queueStatus]
  )

  useEffect(() => {
    fetchDataDriverCPF(nameOrCpf)
  }, [nameOrCpf])

  useEffect(() => {
    fetchDataLicensePlate(licensePlate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licensePlate])

  useEffect(() => {
    async function fetchData() {
      const driversGroups = await getDriversGroups()

      handleDriverGroups(driversGroups)
    }

    fetchData()
  }, [])

  return (
    <div className='joint-list'>
      <ModalDelete
        type='Motorista'
        name={driverName}
        title={role === 'Transportadora' ? 'Desvincular' : 'Excluir'}
        id={id}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteDriver}
      />

      <ModalDelete
        type='Caminhão'
        name={driverName}
        title={role === 'Transportadora' ? 'Desvincular' : 'Excluir'}
        id={id}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteTruck}
      />

      <header>
        <div className='content'>
          <strong>Gerenciar Conjuntos</strong>

          <div className='new'>
            <Link to='/motorista/novo'>
              <button type='button'> Novo Motorista </button>
            </Link>

            <Link to='/caminhao/novo'>
              <button type='button'> Novo caminhão </button>
            </Link>

            <Link to='/conjuntos/vincular/transportadora'>
              <button type='button'> Vincular Transportadora </button>
            </Link>
          </div>
        </div>

        <div className='search-header'>
          <div className='search'>
            <label>Caminhão</label>
            <div className='input-body'>
              <input
                value={licensePlate}
                onChange={e => setLicensePlate(e.target.value)}
                placeholder='Buscar placa'
              />

              <MdSearch fontSize={26} color='#999999' />
            </div>
          </div>

          <div className='search'>
            <label>Motorista</label>
            <div className='input-body'>
              <input
                value={nameOrCpf}
                onChange={e => setNameOrCpf(e.target.value)}
                placeholder='Nome ou CPF'
              />

              <MdSearch fontSize={26} color='#999999' />
            </div>
          </div>

          <div className='company'>
            <div className='select-company' style={{ width: 320 }}>
              <label>Tranportadora</label>
              <Select
                className='select-options'
                value={shippingCompanyValue}
                options={shippingCompanies}
                onInputChange={value =>
                  handleChangeInputSelect(
                    value,
                    'shipping-company',
                    setShippingCompanies
                  )
                }
                onChange={option =>
                  handleChangeOption(
                    option as Option,
                    setShippingCompanyValue,
                    'shippingCompanyId'
                  )
                }
              />
            </div>
          </div>

          <div className='company'>
            <div className='select-company' style={{ width: 320 }}>
              <label>Status</label>
              <Select
                className='select-options'
                value={statusValue}
                options={statuses}
                onInputChange={value =>
                  handleChangeInputSelect(value, 'status', setStatuses)
                }
                onChange={option =>
                  handleChangeOption(option as Option, setStatusValue, 'status')
                }
              />
            </div>
          </div>

          <div className='company'>
            <div className='select-company' style={{ width: 320 }}>
              <label>Unidade</label>
              <Select
                className='select-options'
                value={companyValue}
                options={companies}
                onInputChange={value =>
                  handleChangeInputSelect(value, 'company', setCompanies)
                }
                onChange={option =>
                  handleChangeOption(
                    option as Option,
                    setCompanyValue,
                    'companyId'
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className='switch-header'>
          <div className={`input`}>
            <label>Na Fila</label>
            <div>
              <label
                className={cx('switch', {
                  disabled: !truckStatus
                })}
              >
                <input
                  type='checkbox'
                  name='status'
                  disabled={!truckStatus}
                  placeholder='Status'
                  onChange={e =>
                    handleSwitch(e, 'queue', queueStatus, setQueueStatus)
                  }
                />
                <div className='slider round'>
                  <span className='on'></span>
                  <span className='off'></span>
                </div>
              </label>
            </div>
          </div>

          <div className={`input`}>
            <label>Sem Veículo</label>
            <div>
              <label
                className={cx('switch', {
                  disabled: queueStatus
                })}
              >
                <input
                  type='checkbox'
                  name='status'
                  placeholder='Status'
                  disabled={queueStatus}
                  onChange={e =>
                    handleSwitch(e, 'truck', truckStatus, setTruckStatus)
                  }
                />

                <div className='slider round'>
                  <span className='on'></span>
                  <span className='off'></span>
                </div>
              </label>
            </div>
          </div>

          <div>
            <Tooltip title={<h1>Carregar mais registros</h1>} arrow>
              <button
                disabled={disableButtonLoadMore}
                id='button__load-more'
                onClick={handleLoadMoreDriversGroups}
              >
                Carregar mais
              </button>
            </Tooltip>
          </div>
        </div>
      </header>

      <LoadingComponent />

      <div className='grid'>
        <div className='content'>
          <ToastContainer />

          <div style={{ height: 1400 }}>
            <DataGrid
              rowHeight={62}
              rows={rowsFiltered}
              columns={columns}
              getRowId={row => `${row?.id}-${row?.truck?.id}`}
              disableColumnMenu
              components={{
                Toolbar: GridToolbar
              }}
              pageSize={15}
              rowsPerPageOptions={[15]}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>

          <div className='export-button'>
            <label>Exportar</label>

            <ExportPDFButton columns={columnsToExport} rows={rows} />

            <ExportCSVLinkButton rows={rows} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Joints
