import { driverApi } from './driverApi'

async function getQueuePositionDriver(driverId: number) {
  const queuePositionDriver = await driverApi.getQueuePositionDriver(driverId)
  return queuePositionDriver
}

async function getList(companyId?: string) {
  const list = await driverApi.getList(companyId)
  return list
}

async function getById(driverId: string) {
  const driver = await driverApi.getById(driverId)
  return driver
}

export const driverServices = {
  getList,
  getById,
  getQueuePositionDriver
}
