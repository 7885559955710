import React from 'react'
import { Edit, Close } from '@material-ui/icons'

import { ServiceForecastDriver } from '../ServiceForecastDriver'
import { LicensePlate } from '../LicensePlate'
import { VehiclesAttendedData } from '.'
import { isToday } from 'date-fns'

interface AttendanceItemProps {
  children: React.ReactNode
  attendance: VehiclesAttendedData
  isExpeditionViewer: boolean
  isEditTons: boolean
  whichDriverIsEdit: number | null
  handleDisableTonsEditing: () => void
  handleEnableTonsEditing: (driverId: number) => void
}

export function AttendanceItem({
  children,
  attendance,
  isEditTons,
  whichDriverIsEdit,
  isExpeditionViewer,
  handleDisableTonsEditing,
  handleEnableTonsEditing
}: AttendanceItemProps) {
  const isDriverBeingEdited = whichDriverIsEdit === attendance.id
  const attendedAt = new Date(attendance.attended_at)
  const isTodayAttended = isToday(attendedAt)

  const renderEditAttendance =
    !isExpeditionViewer && isEditTons && isDriverBeingEdited

  return (
    <div className='modal__box-section__item' key={attendance.id}>
      <div className='box__container'>
        <div className='box__left'>
          <ServiceForecastDriver
            modeDefault
            serviceForecastDate={attendance.attended_at}
          />

          <LicensePlate
            themeColor='#666666'
            licensePlate={attendance?.truck?.licensePlate}
          />
        </div>

        <div className='box__right'>
          <div className='modal__box-section__driver'>
            <span className='driver__name' title={attendance?.driver?.name}>
              {attendance?.driver?.name || ''}
            </span>
            <span className='driver__tons'>
              {attendance?.scheduling?.qtyTransported || 0}t
            </span>
          </div>

          {!isExpeditionViewer && isEditTons && isDriverBeingEdited ? (
            <button
              className='modal__box-section__button close'
              type='button'
              onClick={handleDisableTonsEditing}
            >
              <Close />
            </button>
          ) : (
            <>
              {!isExpeditionViewer && isTodayAttended && (
                <button
                  className='modal__box-section__button edit'
                  type='button'
                  onClick={() => handleEnableTonsEditing(attendance.id)}
                >
                  <Edit />
                </button>
              )}
            </>
          )}
        </div>
      </div>

      {renderEditAttendance && children}
    </div>
  )
}
