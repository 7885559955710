import React, { useState } from 'react'
import { ModalVehicleMissing } from '../../../../../../../components/ModalVehicleMissing'
import { ModalVehicleAttendance } from '../../../../../../../components/ModalVehiclesAttendance'
import { ModalVehicleDropout } from '../../../../../../../components/ModalVehicleDropout'

import './styles.scss'

interface ExpeditionQueueStatusBoxProps {
  className?: string

  service: {
    key: string
    title?: string
    status: string
    value?: number | string
    isVisible?: boolean
  }

  isExpeditionViewer: boolean
  isConciergeViewer: boolean
}

type ModalKeys = 'dropout' | 'attended' | 'missing'

export function ExpeditionQueueStatusBox({
  service,
  className,
  isExpeditionViewer,
  isConciergeViewer
}: ExpeditionQueueStatusBoxProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const modalKey = service.key as ModalKeys

  function handleOpenModal() {
    if ((service.key as ModalKeys) === 'missing') {
      setModalIsOpen(true)
    }

    if ((service.key as ModalKeys) === 'attended') {
      setModalIsOpen(true)
    }

    if ((service.key as ModalKeys) === 'dropout') {
      setModalIsOpen(true)
    }
  }

  function handleCloseModal() {
    setModalIsOpen(false)
  }

  return (
    <>
      <div
        className={`card__service-status
        ${service.isVisible ? 'show-card' : 'hide-card'}`}
        onClick={handleOpenModal}
      >
        <div className={className}>
          <sup>{Number(service.value)}</sup>
        </div>

        <p>
          <strong>
            {service.title} {service.status}
          </strong>
        </p>
      </div>

      {modalKey === 'attended' && (
        <ModalVehicleAttendance
          open={modalIsOpen}
          onCloseModal={handleCloseModal}
          className={service.key}
          isExpeditionViewer={isExpeditionViewer}
        />
      )}

      {modalKey === 'missing' && (
        <ModalVehicleMissing
          open={modalIsOpen}
          onCloseModal={handleCloseModal}
          className={service.key}
          isExpeditionViewer={isExpeditionViewer}
          isConciergeViewer={isConciergeViewer}
        />
      )}

      {modalKey === 'dropout' && (
        <ModalVehicleDropout
          open={modalIsOpen}
          onCloseModal={handleCloseModal}
          className={service.key}
        />
      )}
    </>
  )
}
