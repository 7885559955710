import { companyApi } from './companyApi'

async function getSettings(companyId: string) {
  const settingsCompany = await companyApi.getSettings(companyId)
  return settingsCompany
}

async function getCompanyByHost(host: string) {
  const company = await companyApi.getCompanyByHost(host)
  return company
}

async function getListShippingCompany(shippingCompanyId: string) {
  const shippingCompanys = await companyApi.getListShippingCompany(
    shippingCompanyId
  )

  return shippingCompanys
}

export const companyServices = {
  getSettings,
  getCompanyByHost,
  getListShippingCompany
}
