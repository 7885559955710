import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { Link, useHistory, useParams } from 'react-router-dom'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../components/Input'
import api from '../../../services/api'

import { useFormik } from 'formik'

import { toast, ToastContainer } from 'react-toastify'
import ModalDelete from '../../../components/ModalDelete'
import { useAuth } from '../../../contexts/auth'
import './styles.css'

interface RouteParams {
  id: string
}

interface iOption {
  netCapacity?: number | undefined
  label: string
  value: string
  vehicleModel?: {
    name: string
  }
}

interface Customer {
  code: number
  name: string
  city: string
}

interface ShippingCompany {
  name: string
}
interface Company {
  id: number
}

interface Product {
  code: number
  name: string
}
interface OrderItem {
  id: number
  quantity: number
  product: Product
}

interface Driver {
  id: number
  name: string
}
interface Truck {
  id: number
  licensePlate: string
  netCapacity?: number
}

interface TruckOption {
  id: number
  name: string
  licensePlate: string
  netCapacity?: number
}
interface Scheduling {
  id: number
  order: _Order
  qtyOrdered: number
  qtyTransported: number
  expedDate?: any
  expedInitialDate?: any
  driver: Driver
  truck: Truck
  company: Company
}

interface _Order {
  id: number
}

interface Order {
  id: number
  status: string
  customer: Customer
  shippingCompany: ShippingCompany
  product: Product
  qntOrdered: number
  qntShip: number
}

const Scheduling: React.FC = (): JSX.Element => {
  const [order, setOrder] = useState<Order>()
  const [schedulings, setSchedulings] = useState<Scheduling[]>([])
  const [vehicleModel, setVehiculoModel] = useState('')

  const [licensePlateOptions, setLicensePlateOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [licensePlateValue, setLicensePlateValue] = useState<Truck>({
    id: 0,
    licensePlate: '',
    netCapacity: 0
  })
  const [selectedPlate, setSelectedPlate] = useState<any>()

  const [driverOptions, setDriverOptions] = useState<iOption[]>([
    { value: '', label: '' }
  ])
  const [driverValue, setDriverValue] = useState<Driver>({ id: 0, name: '' })
  const [selectedDriver, setSelectedDriver] = useState<any>()

  const [quantity, setQuantity] = useState(0)
  const [totalShip, setTotalShip] = useState(0)

  const [modal, setModal] = useState('')
  const [nameModal, setNameModal] = useState('')
  const [schedulingId, setSchedulingId] = useState(0)
  const [key, setKey] = useState(0)

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0
  })

  const { handleSubmit } = useFormik({
    initialValues: {
      id: '',
      driver: { id: 0, name: '' },
      truck: { id: 0, licensePlate: '' },
      qtyOrdered: 0,
      qtyTransported: 0
    },

    onSubmit: values => {
      api
        .post('scheduling/save', schedulings)
        .then(() => {
          toast.success('Cadastro realizado com sucesso', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
          setTimeout(() => {
            history.push('/pedidos')
          }, 2500)
        })
        .catch(e => {
          toast.error('Erro ao Cadastrar: ' + e.response.data.message, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
        })
    }
  })

  const { id } = useParams<RouteParams>()

  const history = useHistory()
  const { role, company } = useAuth()
  const companyId =
    company || parseInt(JSON.stringify(localStorage.getItem('@App:companyId')))

  useEffect(() => {
    if (id) {
      api.get(`/order/${id}`).then(response => {
        const { customer } = response.data
        const { shippingCompany } = response.data
        const { orderItem } = response.data

        const qntOrdered = orderItem.reduce((sum: any, item: OrderItem) => {
          return sum + item.quantity
        }, 0)

        const product = {
          code: orderItem[0]?.product.code,
          name: orderItem[0]?.product.name
        }

        setOrder({
          id: parseInt(id),
          customer: customer,
          shippingCompany: shippingCompany,
          status: parseFloat(response.data.status).toFixed(0),
          product: product,
          qntOrdered: qntOrdered,
          qntShip: 0
        })
      })
    }

    loadSchedules()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function loadSchedules() {
    api.get(`/scheduling/order/${id}`).then(response => {
      let totalship = 0
      const scheduling = response.data.map((item: Scheduling) => {
        totalship = totalship + item.qtyTransported
        return {
          id: item.id,
          order: item.order,
          qtyOrdered: item.qtyOrdered,
          qtyTransported: item.qtyTransported,
          expedDate: item.expedDate ? item.expedDate : '',
          expedInitialDate: item.expedInitialDate
            ? item.expedInitialDate
            : null,
          driver: item.driver,
          truck: item.truck
        }
      })

      setSchedulings(scheduling)
      setTotalShip(totalship)
    })
  }

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: iOption[] = []

    if (value) {
      let path = `/${patch}/like/${value}`

      if (role === 'Usuario') {
        path = path + `/company/${company}`
      }

      if (role === 'Transportadora') {
        const shippingCompanyId = localStorage.getItem('@App:shippingCompanyId')
        path = path + `/shipping-company/${shippingCompanyId}`
      }

      api.get(path).then(response => {
        // eslint-disable-next-line array-callback-return

        options_ = response.data.map((item: any) => {
          if (patch === 'truck') {
            return {
              value: item.id,
              label: `${item.licensePlate}`,
              vehicleModel: item.vehicleModel,
              netCapacity: item.netCapacity
            }
          } else if (patch === 'driver') {
            return { value: item.id, label: `${item.name}` }
          } else {
            return ''
          }
        })
        setOption(options_)
      })
    }
  }
  function handleCorfirmDelete(scheduling: Scheduling, index: number) {
    showModal()
    setNameModal(scheduling.driver.name)
    setSchedulingId(scheduling.id)
    setKey(index)
  }

  function handleChangeOptionLicensePlate(option: iOption) {
    setLicensePlateValue({
      id: parseInt(option.value),
      licensePlate: option.label,
      netCapacity: option.netCapacity
    })

    setVehiculoModel(option.vehicleModel?.name || '')
    setQuantity(option.netCapacity || 0)
    setSelectedPlate({ value: parseInt(option.value), label: option.label })

    api.get(`/truck/${option.value}`).then((response: any) => {
      const driverId = response.data?.driver?.id
      const driverCompanyId = response.data.company.id || company

      if (driverId) {
        api.get(`/driver/company/${driverCompanyId}`).then(response => {
          // eslint-disable-next-line array-callback-return

          const driversCompany = response.data

          let driverObject = { value: '', label: '' }

          setDriverOptions([driverObject])
          setSelectedDriver(driverObject)
          setDriverValue({
            id: parseInt(driverObject.value),
            name: driverObject.label
          })

          driversCompany.filter((item: TruckOption) => {
            if (item.id === driverId) {
              driverObject = { value: item.id.toString(), label: item.name }

              setDriverOptions([driverObject])
              setSelectedDriver(driverObject)
              setDriverValue({
                id: parseInt(driverObject.value),
                name: driverObject.label
              })
            }

            return item.id === driverId
          })
        })
      }
    })
  }

  function handleChangeOptionDriver(option: iOption) {
    setSelectedDriver(option)
    setDriverValue({ id: parseInt(option.value), name: option.label })
  }

  function handleCloseModal() {
    hideModal()
  }
  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  function reset() {
    setSelectedDriver({ id: -1, name: '' })
    setQuantity(0)
    setDriverOptions([])
    setDriverValue({ id: 0, name: '' })
    setSelectedPlate({})
    handleChangeInputSelect('', 'truck', setLicensePlateOptions)
    handleChangeOptionLicensePlate({} as iOption)
  }

  function handleAddScheduling() {
    const qntOrdered = schedulings.reduce((sum: any, item: any) => {
      return sum + item.qtyOrdered
    }, 0)

    if (qntOrdered + quantity > (order?.qntOrdered || 0)) {
      toast.error('Quantidade ultrapassa o valor pedido')
      return
    }

    if (quantity > (licensePlateValue.netCapacity || 0)) {
      toast.error('Quantidade ultrapassa o capacidade do veículo')
      return
    }

    api
      .post('scheduling/save', [
        {
          id: 0,
          qtyOrdered: quantity,
          qtyTransported: 0,
          order: { id: parseInt(id) },
          truck: {
            id: licensePlateValue.id,
            licensePlate: licensePlateValue.licensePlate
          },
          driver: { id: driverValue.id, name: driverValue.name },
          company: { id: companyId }
        }
      ])
      .then(() => {
        toast.success('Cadastro realizado com sucesso', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })

        loadSchedules()

        setSchedulings([
          ...schedulings,
          {
            id: 0,
            qtyOrdered: quantity,
            qtyTransported: 0,
            order: { id: parseInt(id) },
            truck: {
              id: licensePlateValue.id,
              licensePlate: licensePlateValue.licensePlate
            },
            driver: { id: driverValue.id, name: driverValue.name },
            company: { id: companyId }
          }
        ])

        reset()
      })
      .catch(e => {
        toast.error('Erro ao Cadastrar: ' + e.response.data.message, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
      })
  }

  function handleChangeQuantity(event: React.ChangeEvent<HTMLInputElement>) {
    setQuantity(parseInt(event.target.value))
  }

  function handleDeleteSchedule(id: number) {
    hideModal()

    if (id) {
      api
        .delete(`/scheduling/delete/${id}`)
        .then(response => {
          if (response.status === 200) {
            toast.success('Excluido com sucesso!')
            const results = schedulings.filter(item => item.id !== id)
            setSchedulings(results)

            api.post('scheduling/save', results)
          }
        })
        .catch(() => {
          toast.success('Erro ao excluir!')
        })
    } else {
      schedulings.splice(key, 1)
      setSchedulings(schedulings)
    }
  }

  function onEdit(id: number) {
    if (inEditMode.status === false) {
      setInEditMode({
        status: true,
        rowKey: id
      })
    } else {
      const newSchedules = schedulings.map((item, index) => {
        if (index === id) {
          item.expedDate = null

          return item
        } else return item
      })
      //schedulings

      api
        .post('scheduling/save', newSchedules)
        .then(() => {
          toast.success('Cadastro editado com sucesso')
        })
        .catch(e => {
          toast.error('Erro ao Cadastrar: ' + e.response.data.message)
        })

      setInEditMode({
        status: false,
        rowKey: id
      })
    }
  }

  async function handleEditTruck(id: number, option: iOption) {
    const newSchedulings = schedulings.map(item => {
      if (item.truck.id === id) {
        item.truck.id = parseInt(option.value)
        item.truck.licensePlate = option.label

        return item
      } else return item
    })
    setSchedulings(newSchedulings)
  }
  async function handleEditDriver(id: number, option: iOption) {
    const newSchedulings = schedulings.map(item => {
      if (item.driver.id === id) {
        item.driver.id = parseInt(option.value)
        item.driver.name = option.label

        return item
      } else return item
    })
    setSchedulings(newSchedulings)
  }
  async function handleQtyOrdered(id: number, value: string) {
    const newSchedulings = schedulings.map(item => {
      if (item.id === id) {
        item.qtyOrdered = parseInt(value)
        return item
      } else return item
    })
    setSchedulings(newSchedulings)
  }
  return (
    <div className='new-scheduling-container'>
      <ModalDelete
        type='Agendamento '
        name={nameModal}
        id={schedulingId}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteSchedule}
      />

      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>Gerenciar Carregamentos</strong>
          </div>

          <div className='buttons-header'>
            <Link to='/pedidos'>
              <button id='btBack' type='button'>
                <MdKeyboardArrowLeft size={20} color='#fff' />
                Voltar
              </button>
            </Link>
          </div>
        </header>

        <div>
          <div>
            <fieldset>
              <legend>Detalhes do Pedido</legend>
              <div className='order-details'>
                <div className='customer-info'>
                  <label>Destinatário</label>
                  <div>{order?.customer.name}</div>

                  <label>Município</label>
                  <div>{order?.customer.city}</div>
                </div>
                <div className='product-info'>
                  <label>Produto</label>
                  <div>{order?.product.name}</div>

                  <label>Transportadora</label>
                  <div>{order?.shippingCompany.name}</div>
                </div>
                <div className='order-info'>
                  <label>Quant. Pedida</label>
                  <div>
                    {order?.qntOrdered}
                    <span>t</span>
                  </div>

                  <label>Quant. Entregue</label>
                  <div>
                    {totalShip}
                    <span>t</span>
                  </div>

                  <label>Status</label>
                  <div>
                    {order?.status}
                    <span>%</span>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <fieldset
            id='new-schedule'
            style={{ background: 'white', marginTop: '80px !important' }}
          >
            <legend>Novo Carregamento</legend>
            <div className='schedule-content'>
              <div className='row'>
                <div className='input-block'>
                  <div className='select-truck'>
                    <label>Caminhão:</label>
                    <Select
                      className='select-options'
                      value={selectedPlate}
                      options={licensePlateOptions}
                      onInputChange={value =>
                        handleChangeInputSelect(
                          value,
                          'truck',
                          setLicensePlateOptions
                        )
                      }
                      onChange={option =>
                        handleChangeOptionLicensePlate(option as iOption)
                      }
                    />
                    {vehicleModel && (
                      <span
                        style={{ fontSize: 14, marginLeft: 10, color: 'grey' }}
                      >
                        Modelo: {vehicleModel}
                      </span>
                    )}
                  </div>
                </div>
                <div className='input-block'>
                  <div className='select-driver'>
                    <label>Motorista:</label>
                    <Select
                      className='select-options'
                      value={selectedDriver}
                      options={driverOptions}
                      onInputChange={value =>
                        handleChangeInputSelect(
                          value,
                          'driver',
                          setDriverOptions
                        )
                      }
                      onChange={option =>
                        handleChangeOptionDriver(option as iOption)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <Input
                  name='quantity'
                  placeholder='Quantidade'
                  onChange={e => handleChangeQuantity(e)}
                  value={quantity}
                  type='number'
                  label='Quantidade pedida:'
                />
              </div>

              <button
                type='button'
                id='addProduct'
                onClick={handleAddScheduling}
                {...(!licensePlateValue.id || !driverValue.id || quantity === 0
                  ? { disabled: true }
                  : { disabled: false })}
              >
                + Adicionar
              </button>
            </div>
          </fieldset>
        </div>

        <div className='schedulings'>
          <fieldset>
            <legend>Carregamento</legend>
            <table>
              <thead>
                <tr>
                  <th>Placa</th>
                  <th>Motorista</th>
                  <th>Quantidade Pedida</th>
                  <th>Quantidade Transportada</th>
                  <th>Data expedição</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {schedulings.length > 0 ? (
                  schedulings.map((scheduling, index) => (
                    <tr key={index}>
                      <td>
                        {inEditMode.status && inEditMode.rowKey === index ? (
                          <div className='select-truck'>
                            <Select
                              className='select-options'
                              options={licensePlateOptions}
                              onInputChange={value =>
                                handleChangeInputSelect(
                                  value,
                                  'truck',
                                  setLicensePlateOptions
                                )
                              }
                              onChange={option =>
                                handleEditTruck(
                                  scheduling.truck.id,
                                  option as iOption
                                )
                              }
                            />
                          </div>
                        ) : (
                          scheduling.truck.licensePlate
                        )}
                      </td>
                      <td>
                        {inEditMode.status && inEditMode.rowKey === index ? (
                          <div className='select-driver'>
                            <Select
                              className='select-options'
                              options={driverOptions}
                              onInputChange={value =>
                                handleChangeInputSelect(
                                  value,
                                  'driver',
                                  setDriverOptions
                                )
                              }
                              onChange={option =>
                                handleEditDriver(
                                  scheduling.driver.id,
                                  option as iOption
                                )
                              }
                            />
                          </div>
                        ) : (
                          scheduling.driver.name
                        )}
                      </td>
                      <td>
                        {inEditMode.status && inEditMode.rowKey === index ? (
                          <div className='select-qtyOrdered'>
                            <Input
                              className='select-options'
                              type='number'
                              value={scheduling.qtyOrdered}
                              onChange={e =>
                                handleQtyOrdered(scheduling.id, e.target.value)
                              }
                            />
                          </div>
                        ) : (
                          scheduling.qtyOrdered?.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        )}
                        t
                      </td>
                      <td>
                        {scheduling.qtyTransported?.toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                        t
                      </td>
                      <td>
                        {scheduling.expedDate
                          ? new Date(scheduling.expedDate).toLocaleDateString()
                          : ''}
                      </td>

                      <td>
                        {!scheduling.expedDate && (
                          <>
                            {role !== 'Transportadora' && (
                              <button
                                type='button'
                                className='actionButton blue'
                                onClick={() => onEdit(index)}
                              >
                                editar
                              </button>
                            )}

                            {scheduling.expedInitialDate === null && (
                              <button
                                type='button'
                                disabled={scheduling.expedInitialDate}
                                className='actionButton red'
                                style={{ color: '#FFF' }}
                                onClick={() => {
                                  handleCorfirmDelete(scheduling, index)
                                }}
                              >
                                excluir
                              </button>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      Nenhum resultado foi encontrado
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </fieldset>
        </div>
        <ToastContainer
          position='bottom-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </form>

      <br />
    </div>
  )
}

export default Scheduling
