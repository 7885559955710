import React from "react";
import jsPDF from "jspdf";
import { Button } from "@material-ui/core";
import { GrDocumentPdf } from "react-icons/gr";
import { UserOptions } from "jspdf-autotable";

interface ExportPDFButtonProps {
  rows: any[];
  day: string;
  columns: ColumnsExport[];
  tolerance: string;
}

type ColumnsExport = {
  headerName: string
}

interface jsPDFCustom extends jsPDF {
  autoTable: (content: UserOptions) => void;
}

export function ExportPDFButton({
  rows,
  columns,
  day,
  tolerance,
}: ExportPDFButtonProps) {
  const exportPDF = (rows: any, day: string) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size) as jsPDFCustom;

    doc.setFontSize(15);

    columns.shift();

    const title = `Expedição ${day}`;
    const headers = [columns.map((column: any) => column.headerName)];

    const data = rows.map((e: any) => {
      const obReportject: any = Object.values(e);

      let toleranceHours = Number(tolerance.slice(0, 2)) * 60 * 60 * 1000;
      let toleranceMinutes = Number(tolerance.slice(3, 5)) * 60 * 1000;

      const toleranceTime = new Date(
        new Date(obReportject[5]).getTime() +
        (toleranceHours + toleranceMinutes)
      );

      obReportject[4] = new Date(obReportject[4]).toLocaleTimeString("pt-Br", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      obReportject[5] = toleranceTime.toLocaleTimeString("pt-Br", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      obReportject.shift();

      return obReportject;
    });

    let content = {
      startY: 50,
      head: headers,
      body: data,
      headStyles: {
        fillColor: "#fc8f00",
      },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`Expedição ${day}.pdf`);
  };

  return (
    <Button onClick={() => exportPDF(rows, day)}>
      <GrDocumentPdf />
    </Button>
  );
}
