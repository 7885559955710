import React from 'react'

type QueueInfoProps = {
  queueTotal: number
}

export function QueueInfo({ queueTotal }: QueueInfoProps) {
  return (
    <div className='queue-info'>
      <span>Neste momento, temos:</span>

      <div className='queue-status'>
        <div>
          <strong>{queueTotal}</strong>
        </div>
        <div>
          <span>Veículos em espera</span>
        </div>
      </div>
    </div>
  )
}
