//import id from 'date-fns/esm/locale/id/index.js';
import React, { createContext, useContext } from "react";
import api from "../services/api";

interface ExpedContextData {
  handleConfirmTolerance: ({ companyId }: any) => void;
  handleConfirmDelay: ({ delayValue, delayTime, companyId }: any) => void;

  getDelay: ({
    setDelayStatus,
    setDelayTime,
    setDelayedTime,
    setDelayName,
    setIconName,
    companyId
  }: any) => void;
  queryQueue: ({
    shippingCompanyId,
    companyId,
    setQueuesByDays,
    setQueuesByDaysFilter,
    setQueues,
    setDays,
    setDates,
    setActive,
    setTotals,
    setAttendanceLimit,
    setLoading,
    returnRowsByDay
  }: any) => Promise<void>;
  handleConfirmSuspend: ({
    attendance,
    userId,
    companyId,
    setIsOpenDisableToleranceModal
  }: any) => void;

  handleConfirmDelayCancelation: ({
    setDelayTime,
    setDelayStatus,
    minutes,
    delayedTime,
    delayTime,
    delayValue,
    companyId
  }: any) => void;
  handleReturnQueue: ({
    id,
    hideModal,
    active,
    queryQueue,
    setActive
  }: any) => void;
}

const ExpedContext = createContext<ExpedContextData>({} as ExpedContextData);

export const ExpedProvider: React.FC = ({ children }) => {
  const getDelay = ({
    setDelayStatus,
    setDelayTime,
    setDelayedTime,
    setDelayName,
    setIconName,
    companyId
  }: any) => {
    if (companyId) {
      try {
        api.get(`queue/delayStatus/company/${companyId}`).then(response => {
          const { data } = response;

          if (data) {
            const { delayedTime, delay, expedition, created_at } = data;
            const todayInMilliseconds = new Date().getTime();

            setDelayStatus(true);
            setDelayTime(delayedTime);
            setDelayedTime(0);
            setDelayName(delay.reason);
            setIconName(delay.icon);

            let timer = todayInMilliseconds - new Date(expedition).getTime();

            if (isNaN(timer)) {
              let date = new Date(created_at);

              timer = todayInMilliseconds - date.getTime();

              let time = Math.round(Math.floor(timer / (60 * 1000)));
              setDelayedTime(time);
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  async function queryQueue({
    shippingCompanyId,
    companyId,
    ////estados
    setQueuesByDays,
    setQueuesByDaysFilter,
    setQueues,
    setDays,
    setDates,
    setActive,
    ///estados de outras reqs
    setTotals,
    setAttendanceLimit,
    setLoading,
    //funcs
    returnRowsByDay
  }: any) {
    const { data } = await api.get(
      `/queue/shipping-company/${shippingCompanyId}/positions`
    );
    const _queuePositions = data;

    const route = shippingCompanyId
      ? `/queue/shipping-company/${shippingCompanyId}`
      : `/queue/company/${companyId}`;
    const activeRoute = shippingCompanyId
      ? `/queue/active/shipping-company/${shippingCompanyId}`
      : `/queue/active/company/${companyId}`;

    let { data: _queue }: any = await api.get(route, {
      params: {
        days_limit: 5
      }
    });

    await api.get(`${activeRoute}`).then(response => {
      let _active = [];

      if (response.data[0]) {
        setActive(response.data);
        _active = response.data;
      }

      let _days: any[] = [];
      let _dates: any[] = [];

      for (let index = 0; index < _queue.length; index++) {
        let date: any = `${new Date(_queue[index].date).getDate()}`;
        _days.push(date);
      }

      for (let index = 0; index < _queue.length; index++) {
        let date: any = `${new Date(_queue[index].date).getDate()}/${
          new Date(_queue[index].date).getMonth() + 1
        }`;
        _dates.push(date);
      }

      let _queuesByDays: any[] = [];
      let unique = _days.filter(onlyUnique);
      let uniqueDates = _dates.filter(onlyUnique);

      for (let index = 0; index < unique.length; index++) {
        _queuesByDays.push(
          returnRowsByDay(_queue, unique[index], _active, _queuePositions)
        );
      }

      setQueuesByDays(_queuesByDays);
      setQueuesByDaysFilter(_queuesByDays);
      setQueues(_queue);
      setDays(unique);
      setDates(uniqueDates);
    });

    await api.get(`/queue/totals/company/${companyId}`).then(response => {
      setTotals({
        total: response.data.totals,
        attended: response.data.attended,
        missing: response.data.missing,
        totalToday: response.data.totals,
        totalTransportedToday: response.data.totalTransportedToday
          .toString()
          .split(".")
      });
    });

    await api.get(`/settings/company/${companyId}`).then(response => {
      setAttendanceLimit(response.data.programmedTotal);
    });

    setLoading(false);
  }

  function handleConfirmSuspend({
    attendance,
    userId,
    companyId,
    setIsOpenDisableToleranceModal
  }: any) {
    if (!attendance) {
      api
        .post(`/attendances/save`, {
          user: { id: Number(userId) },
          company: { id: Number(companyId) }
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } else {
      setIsOpenDisableToleranceModal(true);
    }
  }

  function handleConfirmTolerance({ companyId }: any) {
    api
      .post("settings/toggle-tolerance", {
        company: companyId,
        tolerance: true
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  }

  function handleConfirmDelay({ delayValue, delayTime, companyId }: any) {
    api
      .put(`/queue/delay`, {
        delayId: delayValue.value,
        time: delayTime,
        companyId: companyId
      })
      .then(response => {
        if (response.status === 200) {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(() => {});
  }

  function handleConfirmDelayCancelation({
    setDelayTime,
    setDelayStatus,
    minutes,
    delayedTime,
    delayTime,
    delayValue,
    companyId
  }: any) {
    let subTime = minutes + delayedTime;
    let delayTimeMinutes = parseInt(delayTime) * 60;
    let remainingTime = delayTimeMinutes - subTime;

    var num = remainingTime;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var sminutes = (hours - rhours) * 60;
    var rminutes = Math.round(sminutes);
    var delayedTimeMinutes = "0";

    rminutes < 10
      ? (delayedTimeMinutes = `0${rminutes}`)
      : (delayedTimeMinutes = `${rminutes}`);
    let delayedTimeHours = `-0${rhours}:${delayedTimeMinutes}`;

    setDelayTime(delayedTimeHours);
    setDelayStatus(false);

    api
      .put(`/queue/delay`, {
        delayId: delayValue.value,
        time: delayedTimeHours,
        companyId: companyId
      })
      .then(response => {
        if (response.status === 200) {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
  }

  function handleReturnQueue({ id, hideModal, active, setActive }: any) {
    hideModal();

    api
      .put(`/queue/start`, {
        id: id,
        status: false
      })
      .then(response => {
        const results = active.filter((item: any) => item.id !== id);

        setActive(results);
      })
      .catch(() => {});
  }

  return (
    <ExpedContext.Provider
      value={{
        getDelay,
        queryQueue,
        handleConfirmSuspend,
        handleConfirmTolerance,
        handleConfirmDelay,
        handleConfirmDelayCancelation,
        handleReturnQueue
      }}
    >
      {children}
    </ExpedContext.Provider>
  );
};

export function useExped() {
  const context = useContext(ExpedContext);

  return context;
}
