import React, { useEffect, useState } from 'react'
import styles from '../styles.module.css'
import { useHistory } from 'react-router-dom'

const THREE_SECONDS = 3

export function CheckInCompleted() {
  const history = useHistory()

  const [seconds, setSeconds] = useState(THREE_SECONDS)

  useEffect(() => {
    if (seconds === 0) {
      history.push('/')
      return
    }

    const timer = setTimeout(() => {
      setSeconds(seconds - 1)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [history, seconds])

  return (
    <div className={styles['check-in-step-completed']}>
      <h1>Check-In Realizado</h1>
      <CheckInCompletedIcon />

      <p>Voltando para o início em: {seconds}</p>
    </div>
  )
}

function CheckInCompletedIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='56'
      height='56'
      fill='none'
      viewBox='0 0 227 227'
    >
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M113.5 227c62.684 0 113.5-50.816 113.5-113.5S176.184 0 113.5 0 0 50.816 0 113.5 50.816 227 113.5 227zm-66.88-94.595c-5.557-5.557-5.557-14.568 0-20.125 5.558-5.558 14.568-5.558 20.126 0l30.188 30.188 70.439-70.44c5.557-5.557 14.568-5.557 20.125 0 5.558 5.558 5.558 14.569 0 20.126l-80.501 80.502c-5.558 5.557-14.568 5.557-20.126 0l-40.25-40.251z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}
