import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import { AiOutlineSchedule, AiOutlineWarning } from 'react-icons/ai'
import AppToolBar from '../../components/AppToolBar'

import { toast } from 'react-toastify'

import _Confirmacao from '../../../../assets/images/Data_Confirmacao.svg'

import flagBrasil from '../../../../assets/images/icons/flagBrasil.png'

import './styles.css'
import api from '../../../../services/api'
import BarTop from '../../components/BarTop'

interface Truck {
  id: number
  vehicleModel: { name: string }
  licensePlate: string
  selected?: boolean
  color: string
}

interface Customer {
  code: number
  name: string
  city: string
}

interface ShippingCompany {
  name: string
}

interface Order {
  id: number
  status: string
  code: string
  customer: Customer
  shippingCompany: ShippingCompany
  orderItem: OrderItem[]
}

interface Product {
  code: number
  name: string
}

interface OrderItem {
  id: number
  quantity: number
  product: Product
}

interface Scheduling {
  id?: number
  qtyOrdered?: number
  order?: Order
  selected?: boolean
}

export function DriverScheduling() {
  const history = useHistory()

  const [trucks, setTrucks] = useState<Truck[]>([
    {
      id: 0,
      vehicleModel: { name: '' },
      licensePlate: '',
      color: '',
      selected: false
    }
  ])
  const [scheduling, setScheduling] = useState<Scheduling[]>([])

  const [disable, setDisable] = useState(1)

  const [error, setError] = useState({
    status: 1,
    message1: '',
    message2: '',
    message3: ''
  })

  const [anonPage, setAnonPage] = useState(0)
  const [truckId, setTruckId] = useState(0)
  const [ifAnon, setIfAnon] = useState(false)

  const [selectedLicesePlate, setSelectedLicensePlate] = useState('')
  const [selectedScheduling, setSelectedScheduling] = useState<Scheduling>()
  const [selectedVehicleModel, setSelectedVehicleModel] = useState('')
  const [selectedVehicleColor, setSelectedVehicleColor] = useState('')

  const [isSelectingVehicle, setIsSelectingVehicle] = useState(false)
  const companyId = sessionStorage.getItem('@AppDriver:companyId')
  const driverId = sessionStorage.getItem('@AppDriver:id')

  let loading = false
  let selectedTruckId = 0

  async function fetchSchedulingDriverData() {
    if (!driverId) return

    api.get(`/scheduling/driver/${driverId}`).then(response => {
      let schedulingTrucks = response.data.map((item: any) => {
        return item.truck
      })

      let resArr: any = []

      if (schedulingTrucks.length > 0) {
        schedulingTrucks.filter((item: Truck, index: number) => {
          if (index === 0) {
            setSelectedLicensePlate(item?.licensePlate)
            setSelectedVehicleModel(item.vehicleModel?.name)
            setSelectedVehicleColor(item?.color)
          }

          var i = resArr.findIndex(
            (x: Truck) => x.licensePlate === item.licensePlate
          )

          if (i <= -1) {
            resArr.push({
              vehicleModel: item.vehicleModel,
              licensePlate: item.licensePlate,
              color: item.color,
              selected: index === 0 ? true : false
            })
          }

          return null
        })

        setTrucks(resArr)
      }

      if (schedulingTrucks.length === 0) {
        setIfAnon(true)
        setAnonPage(1)

        api.get(`/truck/driver/${driverId}`).then(response => {
          let truck_array = response.data.map((item: any) => {
            return item
          })
          resArr = truck_array
          setTrucks(resArr)
        })
      }
    })
  }

  function handleSelected(truck: Truck) {
    setSelectedLicensePlate('')
    setSelectedVehicleModel('')

    const _trucks = trucks.map((item: Truck) => {
      if (item.licensePlate === truck.licensePlate) {
        setTruckId(item.id)
        if (!item.selected) {
          setSelectedVehicleModel(truck.vehicleModel?.name || '')
          setSelectedVehicleColor(truck.color)
          setSelectedLicensePlate(truck.licensePlate)
        }

        return {
          id: item.id,
          vehicleModel: item.vehicleModel,
          licensePlate: item.licensePlate,
          color: item.color,
          selected: !item.selected
        }
      } else
        return {
          id: item.id,
          vehicleModel: item.vehicleModel,
          licensePlate: item.licensePlate,
          color: item.color,
          selected: false
        }
    })

    setTrucks(_trucks)
  }

  function handleSelectedSchedule(scheduling_: Scheduling) {
    setSelectedScheduling({})
    const _scheduling = scheduling.map((item: Scheduling, index: number) => {
      if (item.id === scheduling_.id) {
        if (!item.selected) {
          setSelectedScheduling(scheduling_)
        }
        return { ...item, selected: !item.selected }
      } else return { ...item, selected: false }
    })
    setScheduling(_scheduling)
  }

  function handleNext() {
    const id = sessionStorage.getItem('@AppDriver:id')

    api
      .get(`/scheduling/driver/${id}/licenseplate/${selectedLicesePlate}`)
      .then(response => {
        if (response.data.length === 0) {
          toast.error('Não existem pedidos para esse veículo!')
          setTimeout(() => {
            history.push('/')
          }, 2500)
        }

        setScheduling(
          response.data.map((item: Scheduling, index: number) => {
            if (index === 0) {
              api.get(`/scheduling/${item.id}`).then(response => {
                selectedTruckId = response.data.truck.id
                setTruckId(selectedTruckId)
              })
              setSelectedScheduling(item)
              return { ...item, selected: true }
            } else return item
          })
        )
      })
  }

  function handleConfirmAnon(e: any) {
    e.preventDefault()

    if (truckTotal > 0) {
      setAnonPage(2)
    } else if (truckTotal === 0) {
      if (!loading) {
        loading = true
        handleConfirm(e)
      }
    }
  }

  function handleConfirm(e: any) {
    e.preventDefault()

    const id = parseInt(sessionStorage.getItem('@AppDriver:id') || '')

    if (truckId) {
      setIsSelectingVehicle(true)

      api
        .post('queue/save', {
          driver: { id: id },
          company: {
            id: parseInt(companyId || '')
          },
          truck: {
            id: truckId
          },
          scheduling: selectedScheduling?.id
            ? { id: selectedScheduling.id }
            : null
        })
        .then(() => {
          setDisable(0)
          setTimeout(() => history.push('/app/agendamento/status'), 3000)
        })
        .catch(e => {
          let message = e.response.data.message

          if (message?.search('Você') !== -1) {
            setError({
              status: 0,
              message1: message?.slice(0, message?.length - 80),
              message2: message?.slice(50, 80),
              message3: message?.slice(80, message?.length)
            })
          } else {
            setError({
              status: 0,
              message1: message,
              message2: message.slice(0, 0),
              message3: message.slice(0, 0)
            })
          }

          setTimeout(() => {
            history.push('/')
          }, 5000)
        })
    } else {
      toast.error('Selecione um veículo!')
    }
  }

  function handleConfirmValidation(e: any) {
    e.preventDefault()

    if (!loading) {
      loading = true
      handleConfirm(e)
    }
  }

  const schedulingTotal = scheduling.length
  const truckTotal = trucks.length

  useEffect(() => {
    fetchSchedulingDriverData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='queue'>
      <div className={error.status ? 'disable' : 'error-schedule'}>
        <AiOutlineSchedule size={80} />
        <div>Erro!</div>
        <div className='queue-failure'>
          <div>
            <strong>{error.message1}</strong>
          </div>
          <div>
            <strong>{error.message2}</strong>
          </div>
          <div>
            <strong>{error.message3}</strong>
          </div>
        </div>
      </div>

      <div className={disable ? 'disable' : 'sucess-schedule'}>
        <img src={_Confirmacao} width={'40%'} alt='' />
        <div>Marcação</div>
        <strong>Confirmada</strong>
      </div>

      <div className='container'>
        <BarTop />

        <div className='header'>
          <AppToolBar pathToBack='/' isStatic={false} />
        </div>

        <div className='content'>
          {selectedLicesePlate && (
            <div className='vehicle-info'>
              <div
                className='icon-vehicle-model'
                style={{ backgroundColor: selectedVehicleColor }}
              />

              <div className='vehicle-model'>{selectedVehicleModel}</div>
              <span>{selectedLicesePlate}</span>
            </div>
          )}

          {!ifAnon && schedulingTotal === 0 ? (
            <>
              <span> Selecione um veículo</span>
              <div className='licensePlates'>
                {trucks.map((item, index) => (
                  <div
                    key={index}
                    className='license-container'
                    id={item.licensePlate}
                    onClick={() => handleSelected(item)}
                  >
                    <span
                      className={item.selected ? 'checked' : ''}
                      style={{ display: 'none' }}
                    >
                      <MdCheckCircle />
                    </span>
                    <div className='licensePlate'>
                      <div className='info'>
                        <div>BRASIL</div>
                        <span>
                          <img width={24} src={flagBrasil} alt='' />
                        </span>
                      </div>
                      <div className='license'>{item.licensePlate}</div>
                    </div>
                  </div>
                ))}
              </div>

              <div className='buttons base'>
                {selectedLicesePlate !== '' ? (
                  <button onClick={handleNext}>Próximo</button>
                ) : (
                  <> </>
                )}
              </div>
            </>
          ) : (
            <> </>
          )}

          <SchedulingQueue
            schedulingTotal={schedulingTotal}
            anonPage={anonPage}
            truckTotal={truckTotal}
            onConfirmAnon={handleConfirmAnon}
          />

          <TruckMessageWarning
            schedulingTotal={schedulingTotal}
            anonPage={anonPage}
            truckTotal={truckTotal}
          />

          {schedulingTotal === 0 && anonPage === 2 && truckTotal > 0 && (
            <>
              <span>Selecione um veículo</span>

              <div className='licensePlates'>
                {trucks.map((item, index) => (
                  <div
                    key={index}
                    className='license-container'
                    id={item.licensePlate}
                    onClick={() => handleSelected(item)}
                  >
                    <span
                      className={item.selected ? 'checked' : ''}
                      style={{ display: 'none' }}
                    >
                      <MdCheckCircle />
                    </span>

                    <div className='licensePlate'>
                      <div className='info'>
                        <div>BRASIL</div>
                        <span>
                          <img width={24} src={flagBrasil} alt='' />
                        </span>
                      </div>
                      <div className='license'>{item.licensePlate}</div>
                    </div>
                  </div>
                ))}
              </div>

              <div className='buttons base'>
                {selectedLicesePlate !== '' ? (
                  <button
                    disabled={isSelectingVehicle}
                    className='button-select-vehicle'
                    onClick={e => {
                      handleConfirmValidation(e)
                    }}
                  >
                    Continuar
                  </button>
                ) : (
                  <> </>
                )}
              </div>
            </>
          )}

          {schedulingTotal > 0 && (
            <div>
              <span> Selecionar o pedido:</span>

              <div className='schedulings'>
                {scheduling.map((item: Scheduling, index) => (
                  <div
                    key={index}
                    className='scheduling'
                    onClick={() => handleSelectedSchedule(item)}
                  >
                    <div
                      className={item.selected ? 'checked' : ''}
                      style={{ display: 'none' }}
                    >
                      <MdCheckCircle />
                    </div>
                    <div>Pedido: {item.order?.code}</div>
                    <div>
                      <span>Transportadora:</span>
                      {item.order?.shippingCompany.name}
                    </div>
                    <div>
                      <span>Destinatário:</span>
                      {item.order?.customer.name}
                    </div>
                    <div>
                      <span>Município:</span>
                      {item.order?.customer.city}
                    </div>
                    <div>
                      <span>Produto:</span>
                      {
                        item.order?.orderItem.reduce(
                          (item: any, index: any) => {
                            return item
                          }
                        ).product.name
                      }
                      - {item.qtyOrdered}t
                    </div>
                  </div>
                ))}
              </div>

              <div className='buttons base'>
                {selectedScheduling?.id ? (
                  <button
                    disabled={loading}
                    onClick={e => {
                      handleConfirmValidation(e)
                    }}
                  >
                    Confirmar
                  </button>
                ) : (
                  <> </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

interface SchedulingQueueProps {
  schedulingTotal: number
  anonPage: number
  truckTotal: number
  onConfirmAnon: (event: any) => void
}

function SchedulingQueue({
  anonPage,
  schedulingTotal,
  truckTotal,
  onConfirmAnon
}: SchedulingQueueProps) {
  if (schedulingTotal === 0 && anonPage === 1 && truckTotal !== 0) {
    return (
      <div className='schedule-frelancer'>
        <div className='order-message-empty'>
          <span>Você não possui pedidos!</span>
          <span>Deseja registrar-se na fila como Autônomo?</span>
        </div>

        <button type='button' onClick={onConfirmAnon}>
          Entrar na Fila
        </button>
      </div>
    )
  }

  return null
}

interface TruckMessageWarningProps {
  schedulingTotal: number
  anonPage: number
  truckTotal: number
}

function TruckMessageWarning({
  anonPage,
  schedulingTotal,
  truckTotal
}: TruckMessageWarningProps) {
  if (schedulingTotal === 0 && anonPage === 1 && truckTotal === 0) {
    return (
      <div className='advice schedule-page'>
        <div>
          <div className='warn-icon'>
            <AiOutlineWarning />
          </div>
          <div className='bold-text'>ATENÇÃO!</div>
        </div>

        <p>
          Não há nenhum caminhão no seu cadastro. Edite seu perfil e cadastre
          um.
        </p>
      </div>
    )
  }

  return null
}
