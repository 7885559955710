/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react'
import AppToolBar from '../../components/AppToolBar'

import api from '../../../../services/api'
import BarTop from '../../components/BarTop'
import './styles.css'

export function DriverCompanyMap() {
  const [googleMaps, setGoogleMaps] = useState('')

  const companyId = sessionStorage.getItem('@AppDriver:companyId')

  useEffect(() => {
    if (companyId) {
      api.get(`/settings/company/${companyId}`).then(response => {
        setGoogleMaps(response.data.googleMaps)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='home-driver'>
      <div className='container'>
        <BarTop />
        <div className='header'>
          <AppToolBar pathToBack='/' isStatic={false} />
        </div>

        <div className='content'>
          <iframe
            src={googleMaps}
            width='100%'
            height='500'
            loading='lazy'
          ></iframe>
        </div>
      </div>
    </div>
  )
}
