import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
});

axios.defaults.params = {}
axios.defaults.params['userAgent'] = navigator.userAgent

export default api;
