import React from 'react';




function Landing() {


 

  return (
    <div id="Landing">
      
    </div>


  )
}


export default Landing;