import React, { useCallback, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";

import { VscError } from "react-icons/vsc";
import { AiOutlineCheckCircle } from "react-icons/ai";

interface ConnectionCheckModalProps {
  open: boolean;
  onClose: () => void;
  isUserConnected: boolean;
}

export function ConnectionCheckModal({
  isUserConnected,
  open,
  onClose
}: ConnectionCheckModalProps) {
  const autoCloseAfterThreeSeconds = useCallback(
    () => setTimeout(() => onClose(), 3000),
    [onClose]
  );

  useEffect(() => {
    autoCloseAfterThreeSeconds();
  }, [autoCloseAfterThreeSeconds]);

  return (
    <Box className="modal expedition">
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="body">
          <Box className="container">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Verificando Conexão
            </Typography>
            <Typography id="modal-modal-description">
              {isUserConnected ? (
                <div className="status">
                  Conectado
                  <div>
                    <AiOutlineCheckCircle />
                  </div>
                </div>
              ) : (
                <div
                  className={isUserConnected ? "status" : "status desconnected"}
                >
                  Desconectado
                  <div>
                    <VscError />
                  </div>
                </div>
              )}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
