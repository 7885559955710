import { useEffect, useState } from 'react'
import { DriverApi } from '../driverTypes'
import { driverServices } from '../driverServices'

interface UseDriverGetListOptions {
  companyId?: string
}

/**
 * @property {string} [companyId] - Optional company ID to filter drivers by company.
 */
export function useDriverGetList(options?: UseDriverGetListOptions) {
  const [data, setData] = useState<DriverApi[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  async function fetchData() {
    try {
      setLoading(true)
      const list = await driverServices.getList(options?.companyId)

      setData(list)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    list: data,
    isError: error,
    isLoading: loading
  }
}
