import React, { useEffect, useState } from "react";
import Select from "react-select";

import { CompactPicker } from "react-color";

import { useHistory, useParams } from "react-router-dom";
import { MdDone, MdKeyboardArrowLeft } from "react-icons/md";
import { AiOutlineWhatsApp } from "react-icons/ai";
import Input from "../../../components/Input";
import api from "../../../services/api";

import { useFormik } from "formik";
import * as Yup from "yup";

import { toast, ToastContainer } from "react-toastify";
import ModalDelete from "../../../components/ModalDelete";
import { useAuth } from "../../../contexts/auth";
import { Button } from "@material-ui/core";
import "./styles.css";

interface RouteParams {
  id: string;
}

interface Contact {
  id: number;
  name: string;
  fone: string;
  email: string;
  whatsapp: boolean;
}

interface Driver {
  id: number;
  name: string;
}
interface Company {
  id: number;
  name: string;
}
interface iOption {
  label: string;
  value: string;
}

interface Truck {
  name: string;
  cpf: string;
  licensePlate: string;
  renavam: string;
  owner: string;
  vehicleModel: string;
  implementType: string;
  shaftQuantity: number;
  grossCapacity: number;
  netCapacity: number;
  contact?: Contact[];
}

const truckSchema = Yup.object().shape({
  licensePlate: Yup.string()
    .max(7, "Tamanho máximo de 7 caracteres")
    .required("Placa é obrigatório!"),
  renavam: Yup.string()
    .max(12, "Tamanho máximo de 12 caracteres")
    .required("Renavam é obrigatório!"),
  vehicleModel: Yup.string()
    .max(50, "Tamanho máximo de 50 caracteres")
    .required("Modelo é obrigatório!"),
  implementType: Yup.string()
    .max(80, "Tamanho máximo de 25 caracteres")
    .required("Tipo de implemento é obrigatório!"),
  shaftQuantity: Yup.number().required("Qtd de eixo é obrigatório!"),

  grossCapacity: Yup.number().required("Capac. bruta é obrigatório!"),

  netCapacity: Yup.number().required("Capac. líquida é obrigatório!")
});

const NewEditTruck: React.FC = (): JSX.Element => {
  const [modal, setModal] = useState("");
  const [nameModal, setNameModal] = useState("");

  const [shippingOptions, setShippingOptions] = useState<iOption[]>([
    { value: "", label: "" }
  ]);
  const [implementsTypes, setimplementsTypes] = useState<iOption[]>([
    { value: "", label: "" }
  ]);
  const [brandTypes, setBrandTypes] = useState<iOption[]>([
    { value: "", label: "" }
  ]);
  const [modelTypes, setModelTypes] = useState<iOption[]>([
    { value: "", label: "" }
  ]);
  const [status, setStatus] = useState<Boolean>(true);
  const [color, setColor] = useState<string>("");

  const [shippingValue, setShippingValue] = useState<iOption>({
    value: "",
    label: ""
  });
  const [implementValue, setImplementValue] = useState<iOption>({
    value: "",
    label: ""
  });
  const [brandValue, setBrandValue] = useState<iOption>({
    value: "",
    label: ""
  });
  const [modelValue, setModelValue] = useState<iOption>({
    value: "",
    label: ""
  });

  const [companyValue, setCompanyValue] = useState<iOption>({
    value: "",
    label: ""
  });
  const [companyOption, setCompanyOption] = useState<iOption[]>([
    { value: "", label: "" }
  ]);
  const companyId = localStorage.getItem("@App:companyId");

  const [WhatsAppAtive, setWhatsAppAtive] = useState<boolean>(false);

  const { role, company } = useAuth();

  const history = useHistory();
  // const previousRoute = history.previousLocation ? history.previousLocation.pathname : null;

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      name: "",
      cpf: "",
      licensePlate: "",
      renavam: "",
      owner: "",
      holder: "",
      vehicleModel: "",
      implementType: "",
      shaftQuantity: 0,
      grossCapacity: 0,
      netCapacity: 0,
      isEnabled: false
    },
    validationSchema: truckSchema,
    onSubmit: values => {
      api
        .post("truck/save", {
          id: parseInt(id),
          color: color,
          shippingCompany: { id: parseInt(shippingValue.value) || null },
          ...values,
          vehicleModel: {
            id: values.vehicleModel
          },
          driver: {
            id: parseInt(`${selectedDriver.value}`)
          },
          contact: contacts,
          isEnabled: status,
          company:
            role === "Administrador"
              ? { id: parseInt(companyValue.value) }
              : { id: company || parseInt(companyId || "") || null }
        })
        .then(() => {
          toast.success("Cadastro realizado com sucesso");
          setTimeout(() => {
            history.goBack();
          }, 2000);
        })
        .catch(e => {
          if (e?.response?.data?.response.response.status === 409) {
            toast.error("Erro ao Cadastrar: " + e.response?.data?.message);
          } else {
            toast.error("Erro ao Cadastrar: " + e.response.data.code);
          }
        });
    }
  });

  const { id } = useParams<RouteParams>();

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [contact, setContact] = useState<string>("");
  const [contactFone, setContactFone] = useState<string>("");
  const [contactEmail, setContactEmail] = useState<string>("");

  const [driverOptions, setDriverOptions] = useState<iOption[]>([
    { value: "", label: "" }
  ]);
  const [selectedDriver, setSelectedDriver] = useState<any>();

  const [idContact, setIdContact] = useState(0);
  const [key, setKey] = useState("");

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0
  });

  const implementTypes = [
    { type: "TRUCK", shaftQuantity: 3, grossCapacity: 23.0, netCapacity: 16.0 },
    {
      type: "BITRUCK",
      shaftQuantity: 4,
      grossCapacity: 29.0,
      netCapacity: 19.0
    },
    { type: "TOCO", shaftQuantity: 5, grossCapacity: 41.5, netCapacity: 28.0 },
    {
      type: "ROMEU & JULIETA",
      shaftQuantity: 6,
      grossCapacity: 50.0,
      netCapacity: 32.5
    },
    {
      type: "LS (MENOR 16 MT)",
      shaftQuantity: 6,
      grossCapacity: 45.0,
      netCapacity: 31.0
    },
    {
      type: "LS (MAIOR 16 MT)",
      shaftQuantity: 6,
      grossCapacity: 48.5,
      netCapacity: 33.5
    },
    {
      type: "VANDERLEIA",
      shaftQuantity: 6,
      grossCapacity: 53.0,
      netCapacity: 36.0
    },
    {
      type: "BITREM 7 EIXOS",
      shaftQuantity: 7,
      grossCapacity: 57.0,
      netCapacity: 42.0
    },
    {
      type: "BITREM 8 EIXOS",
      shaftQuantity: 8,
      grossCapacity: 65.5,
      netCapacity: 46.5
    },
    {
      type: "RODOTREM 9 EIXOS",
      shaftQuantity: 9,
      grossCapacity: 74.0,
      netCapacity: 52.0
    },
    {
      type: "RODOTREM 11 EIXOS",
      shaftQuantity: 11,
      grossCapacity: 91.0,
      netCapacity: 65.6
    }
  ];

  useEffect(() => {
    api.get(`/company`).then(response => {
      if (response.data) {
        setCompanyOption(
          response.data?.map((item: Company) => {
            return { label: `${item.id} - ${item.name}`, value: item.id };
          })
        );
      }
    });
    if (id) {
      api.get(`/truck/${id}`).then(response => {
        let licensePlate = response.data.licensePlate;

        if (response.data.company) {
          setCompanyValue({
            value: response.data.company.id,
            label: `${response.data.company.id} - ${response.data.company.name}`
          });
        }

        setValues({
          name: response.data.name,
          cpf: response.data.cpf,
          licensePlate: licensePlate.replace("-", ""),
          renavam: response.data.renavam,
          owner: response.data.owner,
          vehicleModel: response.data.vehicleModel.id,
          implementType: response.data.implementType,
          shaftQuantity: response.data.shaftQuantity,
          grossCapacity: response.data.grossCapacity,
          netCapacity: response.data.netCapacity,
          isEnabled: response.data.isEnabled,
          holder: response.data?.driver?.name
        });

        setStatus(response.data.isEnabled);
        setSelectedDriver({
          value: response.data.driver?.id,
          label: response.data.driver?.name
        });

        if (response.data.vehicleModel.truckBrand) {
          setBrandValue({
            value: response.data.vehicleModel.truckBrand.id,
            label: response.data.vehicleModel.truckBrand.name
          });
          setModelValue({
            value: response.data.vehicleModel.id,
            label: response.data.vehicleModel.name
          });
        }
        if (response.data.color) setColor(response.data.color);

        if (response.data.shippingCompany) {
          setShippingValue({
            value: response.data.shippingCompany.id,
            label: `${response.data.shippingCompany.code} - ${response.data.shippingCompany.name}`
          });
        }
        setContacts(response.data.contact);
        setImplementValue({
          value: response.data.implementType,
          label: response.data.implementType
        });
      });
    }
    const types: iOption[] = implementTypes.map((item, index) => {
      return { value: item.type, label: item.type };
    });

    setimplementsTypes(types);

    api.get(`/truck-brand/`).then(response => {
      setBrandTypes(
        response.data.map((item: any) => {
          return { value: item.id, label: item.name };
        })
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleAddContact() {
    setContacts([
      ...contacts,
      {
        id: 0,
        name: contact,
        fone: contactFone,
        email: contactEmail,
        whatsapp: WhatsAppAtive
      }
    ]);
    setContact("");
    setContactFone("");
    setContactEmail("");
    setWhatsAppAtive(false);
  }

  async function handleChangeContact(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContact(event.target.value);
  }

  async function handleChangeContactFone(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContactFone(event.target.value.slice(0, 16));
  }

  async function handleChangeContactEmail(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContactEmail(event.target.value);
  }

  async function handleEditContact(
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) {
    const value =
      type === "fone" ? event.target.value.slice(0, 16) : event.target.value;

    const newContacts = contacts.map(item => {
      if (item.id === id)
        return {
          id: id,
          name: type === "name" ? value : item.name,
          fone: type === "fone" ? value : item.fone,
          email: type === "email" ? value : item.email,
          whatsapp:
            type === "whatsapp" ? Boolean(value) : Boolean(item.whatsapp)
        };
      else return item;
    });

    setContacts(newContacts);
  }

  function onEdit(id: number) {
    setInEditMode({
      status: true,
      rowKey: id
    });
  }

  function handleCloseModal() {
    hideModal();
  }

  function handleCorfirmDelete(contact: Contact) {
    showModal();
    setNameModal(contact.name);
    setIdContact(contact.id);
    setKey(contact.fone);
  }

  function handleDeleteContact(id: number) {
    hideModal();

    if (id) {
      api
        .delete(`/contact/delete/${id}`)
        .then(response => {
          if (response.status === 200) {
            toast.success("Excluido com sucesso!");
            const results = contacts.filter(item => item.id !== id);
            setContacts(results);
          }
        })
        .catch(() => {
          toast.success("Erro ao excluir!");
        });
    } else {
      const results = contacts.filter(item => item.fone !== key);
      setContacts(results);
    }
  }

  function handleChangeOptionShipping(option: iOption) {
    setShippingValue(option);
  }
  function handleChangeOptionCompany(option: iOption) {
    setCompanyValue(option);
  }

  function handleChangeOptionImplement(option: iOption) {
    const _findImplement = implementTypes.find(
      item => item.type === option.value
    );
    setValues({
      ...values,
      implementType: _findImplement?.type || "",
      shaftQuantity: _findImplement?.shaftQuantity || 0,
      grossCapacity: _findImplement?.grossCapacity || 0,
      netCapacity: _findImplement?.netCapacity || 0
    });
    setImplementValue(option);
  }

  function handleChangeOptionBrand(option: iOption) {
    setBrandValue(option);

    api.get(`/truck-model/brand/${option.value}`).then(response => {
      setModelTypes(
        response.data.map((item: any) => {
          return { value: item.id, label: item.name };
        })
      );
    });
  }

  function handleChangeOptionModel(option: iOption) {
    setModelValue(option);
    setValues({ ...values, vehicleModel: option.value });
  }

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: iOption[] = [];

    if (value) {
      let path = `/${patch}/like/${value}`;

      if (role !== "Administrador") {
        path = path + `/company/${company}`;
      }

      api.get(path).then(response => {
        options_ = response.data.map((item: any) => {
          return { value: item.id, label: `${item.code} - ${item.name}` };
        });
        setOption(options_);
      });
    }
  }

  const showModal = () => {
    setModal("show");
  };
  const hideModal = () => {
    setModal("");
  };

  function handleChangeComplete(color: string) {
    setColor(color);
  }

  function handleWhatapp() {
    setWhatsAppAtive(!WhatsAppAtive);
  }

  async function handleChangeStatus(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setStatus(event.target.checked);
  }

  function handleChangeOptionDriver(option: iOption) {
    setSelectedDriver(option);
    //setDriverValue({ id: parseInt(option.value), name: option.label });
  }

  function handleChangeInputDriver(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: iOption[] = [];

    if (value) {
      let path = `/${patch}/like/${value}`;

      if (role === "Usuario") {
        path = path + `/company/${company}`;
      }

      if (role === "Transportadora") {
        const shippingCompanyId = localStorage.getItem(
          "@App:shippingCompanyId"
        );
        path = path + `/shipping-company/${shippingCompanyId}`;
      }

      api.get(path).then(response => {
        // eslint-disable-next-line array-callback-return

        options_ = response.data.map((item: any) => {
          if (patch === "truck") {
            return {
              value: item.id,
              label: `${item.licensePlate}`,
              vehicleModel: item.vehicleModel,
              netCapacity: item.netCapacity
            };
          } else if (patch === "driver") {
            return { value: item.id, label: `${item.name}` };
          } else {
            return "";
          }
        });
        setOption(options_);
      });
    }
  }

  return (
    <div className="new-truck-container">
      <ModalDelete
        type="Contato"
        name={nameModal}
        id={idContact}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteContact}
      />
      <form onSubmit={handleSubmit} autoComplete="off">
        <header>
          <div>
            <strong>{`${!id ? " Cadastrar " : "Editar"}`} Caminhão</strong>
          </div>

          <div className="buttons-header">
            <button onClick={history.goBack} id="btBack" type="button">
              <MdKeyboardArrowLeft size={20} color="#fff" />
              Voltar
            </button>

            <button id="btSave" type="submit">
              <MdDone size={20} color="#fff" />
              Salvar
            </button>
          </div>
        </header>

        <div className="content">
          <ToastContainer />

          <div className="row">
            {Number(id) ? (
              <div className={`input ${errors.owner ? "error" : ""}`}>
                <Input
                  name="owner"
                  placeholder="Proprietário"
                  onChange={handleChange}
                  value={values.owner}
                  label="Proprietário"
                  maxLength={50}
                />
                {errors.owner && (
                  <div className="validateError">{errors.owner}</div>
                )}
              </div>
            ) : (
              <></>
            )}

            <div className={`input ${errors.owner ? "error" : ""}`}>
              <div className="select-driver">
                <label>Motorista:</label>
                <Select
                  className="select-options"
                  value={selectedDriver}
                  options={driverOptions}
                  onInputChange={value =>
                    handleChangeInputDriver(value, "driver", setDriverOptions)
                  }
                  onChange={option =>
                    handleChangeOptionDriver(option as iOption)
                  }
                />
              </div>

              {errors.holder && (
                <div className="validateError">{errors.holder}</div>
              )}
            </div>

            <div className={`input ${errors.licensePlate ? "error" : ""}`}>
              <Input
                name="licensePlate"
                placeholder="Placa (Apenas letras e números)"
                maskText="#######"
                onChange={e => {
                  if (
                    e.target.value !== "-" &&
                    " " &&
                    e.target.value.length < 8
                  ) {
                    e.target.value = e.target.value
                      .trim()
                      .replace(/[^\w\s]/gi, "");

                    handleChange(e);
                  }
                }}
                value={values.licensePlate.slice(0, 8)}
                label="Placa"
                maxLength={8}
              />
              {errors.licensePlate && (
                <div className="validateError">{errors.licensePlate}</div>
              )}
            </div>

            <div className={`input ${errors.isEnabled ? "error" : ""}`}>
              <label>Status</label>
              <div>
                <label className="switch">
                  <input
                    name="status"
                    placeholder="Status"
                    onChange={handleChangeStatus}
                    type="checkbox"
                    {...(status === true
                      ? { checked: true }
                      : { checked: false })}
                  />
                  <div className="slider round">
                    <span className="on">Ativo</span>
                    <span className="off">Inativo</span>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className={`input ${errors.renavam ? "error" : ""}`}>
              <Input
                name="renavam"
                mask="#########-##"
                placeholder="Renavam"
                value={values.renavam.slice(0, 12)}
                onChange={handleChange}
                label="Renavam"
                maxLength={12}
              />

              {errors.renavam && (
                <div className="validateError">{errors.renavam}</div>
              )}
            </div>

            <div className={`input`}>
              <label>Marca: </label>
              <Select
                className="select-options"
                value={brandValue}
                options={brandTypes}
                onChange={option => handleChangeOptionBrand(option as iOption)}
              />
            </div>

            <div className={`input ${errors.vehicleModel ? "error" : ""}`}>
              <label>Modelo: </label>

              <Select
                className="select-options"
                value={modelValue}
                options={modelTypes}
                onChange={option => handleChangeOptionModel(option as iOption)}
              />

              {errors.vehicleModel && (
                <div className="validateError">{errors.vehicleModel}</div>
              )}
            </div>
          </div>

          <div className="row">
            <div
              className={`input ${errors.implementType ? "error" : ""}`}
              style={{ top: -50 }}
            >
              <label>Tipo de implemento</label>
              <Select
                className="select-options"
                value={implementValue}
                options={implementsTypes}
                onChange={option =>
                  handleChangeOptionImplement(option as iOption)
                }
              />

              {errors.implementType && (
                <div className="validateError">{errors.implementType}</div>
              )}
            </div>
            <label style={{ marginLeft: "14em" }}>Cor:</label>

            <div>
              <CompactPicker
                color={color}
                onChangeComplete={e => handleChangeComplete(e.hex)}
              />
            </div>
          </div>

          <div className="row">
            <div
              className={`input-number ${errors.shaftQuantity ? "error" : ""}`}
            >
              <Input
                name="shaftQuantity"
                type="number"
                value={values.shaftQuantity}
                onChange={handleChange}
                label="Qtd de eixo"
                disabled
              />

              {errors.shaftQuantity && (
                <div className="validateError">{errors.shaftQuantity}</div>
              )}
            </div>

            <div
              className={`input-number ${errors.grossCapacity ? "error" : ""}`}
            >
              <Input
                name="grossCapacity"
                type="number"
                value={values.grossCapacity}
                onChange={handleChange}
                label="Capac. bruta"
                disabled
              />

              {errors.grossCapacity && (
                <div className="validateError">{errors.grossCapacity}</div>
              )}
            </div>

            <div
              className={`input-number ${errors.netCapacity ? "error" : ""}`}
            >
              <Input
                name="netCapacity"
                type="number"
                value={values.netCapacity}
                onChange={handleChange}
                label="Capac. líquida"
                disabled
              />

              {errors.shaftQuantity && (
                <div className="validateError">{errors.shaftQuantity}</div>
              )}
            </div>
          </div>
        </div>

        {role !== "Transportadora" && (
          <div className="row">
            <div className="input select">
              <div className="select-shipping" style={{ width: 500 }}>
                <label>Transportadora:</label>
                <Select
                  className="select-options"
                  value={shippingValue}
                  options={shippingOptions}
                  onInputChange={value =>
                    handleChangeInputSelect(
                      value,
                      "shipping-company",
                      setShippingOptions
                    )
                  }
                  onChange={option =>
                    handleChangeOptionShipping(option as iOption)
                  }
                />
              </div>

              {shippingValue && (
                <div className="truck-profile">
                  <Button
                    className="erase-button t"
                    onClick={() => {
                      setShippingValue({ value: "", label: "" });
                    }}
                  >
                    x
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        {role === "Administrador" && (
          <div className="row">
            <div className={`input select ${errors.isEnabled ? "error" : ""}`}>
              <div className="select-shipping" style={{ width: 500 }}>
                <label>Empresa:</label>
                <Select
                  className="select-options"
                  value={companyValue}
                  options={companyOption}
                  onChange={option =>
                    handleChangeOptionCompany(option as iOption)
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div className="contacts">
          <fieldset>
            <legend>Contatos</legend>
            <table>
              <thead>
                <tr>
                  <th>Contato</th>
                  <th>Telefone</th>
                  <th>Email</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {contacts.length > 0 ? (
                  contacts.map((contact, index) => (
                    <tr key={index}>
                      <td>
                        {inEditMode.status && inEditMode.rowKey === index ? (
                          <Input
                            value={contact.name}
                            onChange={event =>
                              handleEditContact(contact.id, event, "name")
                            }
                          />
                        ) : (
                          contact.name
                        )}
                      </td>
                      <td style={{ display: "flex", alignItems: "center" }}>
                        <AiOutlineWhatsApp
                          size={18}
                          color={!contact.whatsapp ? "gray" : "green"}
                        />

                        {inEditMode.status && inEditMode.rowKey === index ? (
                          <Input
                            mask="(##) # ####-####"
                            value={contact.fone}
                            onChange={event =>
                              handleEditContact(contact.id, event, "fone")
                            }
                          />
                        ) : (
                          contact.fone
                        )}
                      </td>
                      <td>
                        {inEditMode.status && inEditMode.rowKey === index ? (
                          <Input
                            value={contact.email}
                            onChange={event =>
                              handleEditContact(contact.id, event, "email")
                            }
                          />
                        ) : (
                          contact.email
                        )}
                      </td>

                      <td>
                        <button
                          type="button"
                          className="actionButton blue"
                          onClick={() => onEdit(index)}
                        >
                          editar
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="actionButton red"
                          onClick={() => {
                            handleCorfirmDelete(contact);
                          }}
                        >
                          excluir
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center" }}>
                      Nenhum resultado foi encontrado
                    </td>
                  </tr>
                )}
                <tr className="contact">
                  <td>
                    <Input
                      name="contato"
                      placeholder="Contato"
                      value={contact}
                      onChange={handleChangeContact}
                      label=""
                    />
                  </td>
                  <td style={{ display: "flex", alignItems: "center" }}>
                    <AiOutlineWhatsApp
                      size={32}
                      color={!WhatsAppAtive ? "gray" : "green"}
                      onClick={handleWhatapp}
                    />
                    <Input
                      mask="(##) # ####-####"
                      name="fone"
                      placeholder="Telefone"
                      value={contactFone}
                      onChange={handleChangeContactFone}
                      label=""
                    />
                  </td>
                  <td>
                    <Input
                      name="email"
                      placeholder="E-mail"
                      value={contactEmail}
                      onChange={handleChangeContactEmail}
                      label=""
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      id="addContact"
                      onClick={handleAddContact}
                      {...(!contact || !contactFone || !contactEmail
                        ? { disabled: true }
                        : { disabled: false })}
                    >
                      + Adicionar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>
      </form>
    </div>
  );
};

export default NewEditTruck;
