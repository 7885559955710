import './styles.scss'
import './styles.css'
import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridCellParams, ptBR } from '@mui/x-data-grid'

import BarTop from '../../components/BarTop'
import AppToolBar from '../../components/AppToolBar'
import { useDriverContext } from '../../../../contexts/DriverContext/useDriverContext'
import { Queue, useGetQueue } from '../../../../domain'
import { differenceInMilliseconds } from 'date-fns'
import { PageLoading } from '../../../../components/PageLoading/PageLoading'
import { localStorageUtils } from '../../../../utils'

interface RowData {
  id: number
  logged: boolean
  underTolerance: boolean
  created_at: string
  time: Date
  position: string
  licensePlate?: string
  freelancer: boolean
  vehicleModel?: string
}

export function DriverQueuePanel() {
  const { settingsCompany, driverId, driverCompanyId } = useDriverContext()

  const { list: queue, isLoading } = useGetQueue(driverCompanyId!, 'company')
  const queueEmpty = queue.length === 0

  const [rows, setRows] = useState<RowData[]>([])
  const rowsEmpty = rows.length === 0

  function fillRows(queue: Queue[]) {
    const customPosition = queue.findIndex(
      it => it.driver?.id === Number(driverId)
    )

    // clear last position
    localStorageUtils.remove('@panelPosition')

    const mappedList = queue.map((item, index) => {
      let logged = false
      let underTolerance = false

      if (customPosition === index) {
        logged = true
      }

      let position = index + 1

      const toleranceTime = differenceInMilliseconds(
        new Date(item.date),
        new Date()
      )

      const toleranceTimeOver = toleranceTime < 0

      if (!settingsCompany?.enableTolerance) {
        localStorageUtils.set('@panelPosition', position.toString())
      }

      if (settingsCompany?.enableTolerance) {
        if (toleranceTimeOver) {
          localStorageUtils.set('@panelPosition', index.toString())
          underTolerance = true
        } else {
          position = index - Number(localStorageUtils.get('@panelPosition'))
        }
      }

      const licensePlateExists = item?.scheduling
        ? item?.scheduling?.truck?.licensePlate
        : item?.truck?.licensePlate

      const vehicleModelExists = item?.scheduling?.truck?.vehicleModel?.name

      return {
        id: item.id,
        logged: logged,
        underTolerance: underTolerance,
        created_at: item.createdAt,
        time: item.date,
        position: position + '°',
        licensePlate: licensePlateExists,
        freelancer: item?.scheduling ? false : true,
        vehicleModel: vehicleModelExists
      }
    })

    return mappedList
  }

  useEffect(() => {
    if (!queueEmpty) {
      const list = fillRows(queue)

      setRows(list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsCompany?.enableTolerance, queueEmpty])

  function returnTime(attendanceTime: any) {
    let attendanceData = new Date(attendanceTime.created_at).toLocaleString(
      'pt-BR',
      { day: '2-digit', month: '2-digit', hour: 'numeric', minute: '2-digit' }
    )

    return attendanceData
  }

  function returnSec(attendanceTime: any) {
    let attendanceData = new Date(attendanceTime.created_at).toLocaleString(
      'pt-BR',
      { second: '2-digit' }
    )

    return attendanceData + 's'
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 50, hide: true },
    { field: 'position', headerName: 'Fila', width: 60, sortable: false },
    {
      field: 'licensePlate',
      headerName: 'Placa',
      width: 200,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={`licensePlate-cell ${
              params.row.underTolerance ? 'under-tolerance' : ''
            }`}
          >
            {params.row.underTolerance ? <span>Tolerancia</span> : <></>}
            <div
              className={`${
                params.row.logged ? 'logged' : ''
              }   queue-panel licensePlate`}
            >
              {params.row.licensePlate}
            </div>
            {window.innerWidth < 600 && (
              <div className={`} createdAt-queue licensePlate`}>
                <label>Entrada na Fila</label>
                <div className='entrance-time'>
                  {returnTime(params.row)}
                  <span className='seconds'>{returnSec(params.row)}</span>
                </div>
              </div>
            )}
          </div>
        )
      }
    },
    {
      field: 'time',
      headerName: 'Entrada na Fila',
      width: window.innerWidth > 600 ? 150 : 0,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {window.innerWidth > 600 && (
              <div className='createdAt-queue'>
                <label>Entrada na Fila</label>
                <div className='entrance-time'>
                  {returnTime(params.row)}
                  <span className='seconds'>{returnSec(params.row)}</span>
                </div>
              </div>
            )}
          </>
        )
      }
    }
  ]

  return (
    <div className='container'>
      <BarTop />
      <div className='header'>
        <AppToolBar pathToBack='/' isStatic={false} />
      </div>

      {isLoading ? (
        <PageLoading loading={isLoading} onCloseLoading={() => {}} />
      ) : (
        <div className='virtual-queue'>
          {rowsEmpty ? (
            <strong>Nada por aqui...</strong>
          ) : (
            <div className='scheduling-exp painel-page'>
              <div style={{ height: 520 }}>
                <DataGrid
                  autoHeight={true}
                  rows={rows}
                  columns={columns}
                  disableColumnMenu
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  headerHeight={30}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
