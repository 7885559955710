/* eslint-disable @typescript-eslint/no-unused-vars */
const CALMAP_URL = 'devcalmap.nextcargo.app'
const SM_URL = 'devsm.nextcargo.app'
const SANTA_MARIA_URL = 'devsantamaria.nextcargo.app'

export function useWindowLocationHost() {
  return {
    windowLocationHost: window.location.host
  }
}
