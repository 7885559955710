import React, { useEffect, useState } from "react";
import cx from "clsx";
import { Editor } from "react-draft-wysiwyg";
import styles from "./styles.module.scss";
import Draft, { convertFromHTML } from "draft-js";

interface PopupProps {
  title: string;
  label?: string;
  content: string;
  imageUrl?: string;
  onClosePopup?: () => void;
}

export function Popup({ title, content, onClosePopup, imageUrl }: PopupProps) {
  const EditorState = Draft.EditorState;
  const ContentState = Draft.ContentState;

  const [popupContent, setPopupContent] = useState(() => {
    if (content) {
      return handleConvertHtmlContentToText(content);
    }

    return EditorState.createEmpty();
  });

  const [popupExibitionDurantion, setPopupExibitionDurantion] = useState(5);

  const popupExibitionExpired = popupExibitionDurantion === 0;

  const popupContentIsEmpty = content === "<p></p>\n";

  function handleConvertHtmlContentToText(htmlContent: string) {
    const { contentBlocks, entityMap } = convertFromHTML(htmlContent);

    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );

    return EditorState.createWithContent(contentState);
  }

  const popupImageIsUploaded = imageUrl && imageUrl.includes("uploads");
  const popupImageIsPreview = imageUrl && imageUrl.includes("blob");

  useEffect(() => {
    if (content) {
      setPopupContent(handleConvertHtmlContentToText(content));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (popupExibitionDurantion === 0) {
        return;
      }

      setPopupExibitionDurantion(prev => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [popupExibitionDurantion]);

  return (
    <div className={cx(styles.popup)}>
      <div className={cx(styles["popup-overlay"])}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={styles["popup-duration"]}>
            <span>
              Você pode fechar o popup em {popupExibitionDurantion} segundos
            </span>
          </div>

          <div className={styles["popup-container"]}>
            <div className={styles["popup-content"]}>
              {title && (
                <div className={styles["popup-header"]}>
                  <span>{title}</span>
                </div>
              )}

              {!popupContentIsEmpty ? (
                <div className={styles["popup-body"]}>
                  <Editor readOnly editorState={popupContent} toolbarHidden />
                </div>
              ) : (
                <div>
                  {popupImageIsUploaded ? (
                    <img
                      src={`${process.env.REACT_APP_API}/${imageUrl}`}
                      alt="Imagem do popup"
                      style={{
                        width: "100%"
                      }}
                    />
                  ) : (
                    popupImageIsPreview && (
                      <img
                        src={imageUrl}
                        alt="Imagem do popup"
                        style={{
                          width: "100%"
                        }}
                      />
                    )
                  )}
                </div>
              )}

              {popupExibitionExpired && (
                <button
                  type="button"
                  className={styles["popup-close-button"]}
                  onClick={onClosePopup}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.37768 5.98195L4.2844 0.888672L0.888943 4.28413L5.98222 9.37741L0.888916 14.4707L4.28437 17.8662L9.37768 12.7729L14.4708 17.866L17.8662 14.4705L12.7731 9.37741L17.8662 4.28434L14.4707 0.888886L9.37768 5.98195Z"
                      fill="white"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
