import api from '../../services/api'
import { DriverApi, QueuePositionDriverApi } from './driverTypes'

async function getQueuePositionDriver(driverId: number) {
  const response = await api.get<QueuePositionDriverApi>(
    `/queue/position/driver/${driverId}`
  )
  return response.data
}

async function getList(companyId?: string) {
  const path = companyId ? `/driver/company/${companyId}` : '/driver'

  const response = await api.get<DriverApi[]>(path)
  return response.data
}

async function getById(id: string) {
  const response = await api.get<DriverApi>(`/driver/${id}`)
  return response.data
}

export const driverApi = {
  getQueuePositionDriver,
  getList,
  getById
}
