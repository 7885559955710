import React from 'react'
import {  AiOutlineWarning } from 'react-icons/ai';

import './style.css'

function warningLogin() {
    return (
        <div className="advice">
            <div>
                <div className="warn-icon" >
                    <AiOutlineWarning />
                </div>
                <div className="bold-text">
                    ATENÇÃO! 
                </div>
            </div>
            <div>
                {/* O Recesso do setor de Expedição  será entre os dias 23/12 e 09/01. Novas marcações de fila serão permitidas para as datas após esse período. */}
                Por questões técnicas, o retorno da expedição foi adiado para o dia 12/1.
            </div>
            <div>
                Todas as posições da Fila Virtual foram mantidas, apenas mudamos o início do retorno da operação. Agradecemos a compreensão.
                {/* Previsão de retorno dia 18/10. */}
            </div> 
        </div>
    )
}



export default warningLogin
