import React from 'react';

import './styles.css';


interface PubLayout {
  title: string
}

const PubLayout: React.FC<PubLayout> = ({ title, children }) => {

  document.title = title;

  return (

    <section className="pub page-layout">
     
           {children}
   
    </section>

  );
}

export default PubLayout;