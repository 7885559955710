import React from 'react'

interface GreetingProps {
  driverName?: string
  companyName?: string
}

export function DriverGreeting({ driverName, companyName }: GreetingProps) {
  return (
    <>
      <strong> Olá {driverName},</strong>
      <br />
      <span>
        Bem-vindo(a) ao controle de fila
        <br />
        da {companyName}.
      </span>
    </>
  )
}
