import React from "react";
import "./styles.scss";

interface LastUpdateExpeditionProps {
  timestamp: string;
}

export function LastUpdateExpedition({ timestamp }: LastUpdateExpeditionProps) {
  const today = new Date().toLocaleDateString("pt-br");

  return (
    <div className="last__update__expedition__container">
      <strong>Hoje, </strong> {today}
      <div className="last__update__expedition_date">
        <span> Última atualização: {timestamp}</span>
      </div>
    </div>
  );
}
